import React from "react";
import Modal from "react-modal";
import { ClosedX } from "../../Basics";
import "./stylesMFlex.css";

export default function ModalFlex({modal, ClosedModal, Component}) {

  return (
    <Modal
      className         = "contain-content-flex"
      overlayClassName  = "contain-overlay-flex"
      onRequestClose    = {ClosedModal}
      isOpen            = {modal}
    >
      <div className = "contain-modal-flex">
        <ClosedX onClick = {ClosedModal}/>
        {Component}
      </div>
    </Modal>
  );
}
             