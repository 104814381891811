import React                  from "react";
import { BiX }                from "react-icons/bi";
import { MdLock }             from "react-icons/md";
import Modal                  from "react-modal";
import { 
  CompInputForm, Loading,
} from "../../Basics/";
import zxcvbn                 from "zxcvbn";
import Swal                   from "sweetalert2";

import "./stylesMNP.css";

Modal.setAppElement('#root');

export default function ModalNewPass({modal, ClosedModal, onClick}) {
  
  // States para os inputs
  const [oldPass, setOldPass]     = React.useState("");
  const [newPass, setNewPass]     = React.useState("");
  const [confPass, setConfPass]   = React.useState("");
  // States para a força da senha
  const [forca, setForca]         = React.useState(0);
  const [loading, setLoading]     = React.useState(false);
  // useEffect para pegar o enter e enviar o formulário
  React.useEffect(() => {
    const handleKeyDown = async (e) => {
      if(e.key == "Enter"){
        await StartRangePass();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [oldPass, newPass, confPass]);

  // Função que atualiza a força da senha
  React.useEffect(() => {
    if(newPass != ""){
      const { score } = zxcvbn(newPass);
      // Verifica a força da senha
      switch (score) {
        case 0:
          setForca("Senha muito fraca");
        break;
        case 1:
          setForca("Senha fraca");
        break;
        case 2:
          setForca("Senha razoável");
        break;
        case 3:
          setForca("Senha forte");
        break;
        case 4:
          setForca("Senha muito forte");
        break;
        default:
          setForca("");
        break;
      } 
    } else setForca("");
  }, [newPass]);
  
  // Função que valida os inputs e envia o formulário
  async function StartRangePass(){
    if(oldPass === "" || newPass === "" || confPass === "") 
      Swal.fire({
        icon                : "warning",
        title               : "Campo vazio",
        text                : "Por favor, preencha todos os campos para prosseguir",
        confirmButtonText   : "Entendi",
        confirmButtonColor  : "#DA5A34",
      });
    else {
      if(newPass === confPass){
        setLoading(true);
        await onClick(oldPass, newPass);
        setLoading(false);
        ClosedModal();
      } else return Swal.fire({
        icon                : "warning",
        title               : "Aviso",
        text                : "As senhas não iguais, por favor verifique-as e insira novamente",
        confirmButtonText   : "Entendi",
        confirmButtonColor  : "#DA5A34",
      })
    }
  }

  return (
    <Modal
      isOpen            = {modal}
      id                = "id-modal-new-pass"
      className         = "contain-content-modal-new-pass"
      overlayClassName  = "contain-overlay-modal-new-pass"
      onRequestClose    = {ClosedModal}
    >
      
      <div 
        className = "contain-modal-new-pass"
      >
        <BiX
          className = "icon-close-modal-new-pass"
          size      = {25}
          onClick   = {() => {ClosedModal()}}
        />
        <h1>Alterar Senha</h1>
        { loading ? <div className = "contain-loading"><Loading mode={false}/> </div> :
          <>
          
            <CompInputForm
              placeHolder   = {"Senha Atual"}
              typeA         = {"password"}
              value         = {oldPass}
              onChange      = {(e) => {
                setOldPass(e.target.value);
              }}
              Icon          = {MdLock}
              lock          = {true}
              autoComplete  = {"off"}
            />
            <CompInputForm
              placeHolder   = {"Nova Senha"}
              typeA         = {"password"}
              value         = {newPass}
              onChange      = {(e) => {
                setNewPass(e.target.value);
              }}
              Icon          = {MdLock}
              lock          = {true}
              autoComplete  = {"off"}
            />
            <CompInputForm
              placeHolder   = {"Confirmar Senha"}
              typeA         = {"password"}
              value         = {confPass}
              onChange      = {(e) => {
                setConfPass(e.target.value);
              }}
              Icon          = {MdLock}
              lock          = {true}
              autoComplete  = {"off"}
            />
            
            {
              forca != "" &&
              <label>
                {forca}
              </label>
            }
            <button
              onClick = {async () => {
                StartRangePass();
              }}
            >
              Salvar
            </button>
          </>
        }
      </div>
    </Modal>
  );
}