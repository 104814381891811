import React                        from "react";
import { DigitedComp, SmartCheck }              from "../../../assets";

import "./stylesCAM.css";
import { BiDownArrowAlt } from "react-icons/bi";

export default function CompAvisoMobile() {

  return (
    <div 
      className = "contain-max-page-mobile"
      style     = {{
        height: window.innerHeight
      }}
    >
      <div className = "contain-comp-aviso-mobile">
        <img
          src     = {DigitedComp}
        />
        <h1>
          O site Gestor Agendei está disponível apenas para computadores!
        </h1>
        <h2>
          Pelo celular, você pode abrir o App Gestor Agendei
        </h2>
        <BiDownArrowAlt
          size = {30}
          color = "black"
        />
        <button
          className = "btt-acess-link"
          onClick   = {() => window.open("https://bit.ly/gestoragendei")}
        >
          <SmartCheck
            className = "icon-btt-smartcheck"
          />
          <h3>
            Ir para o aplicativo
          </h3>
        </button>
      </div>
    </div>
  );
}