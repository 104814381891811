import React          from "react";

import "./stylesEC.css";
import { RiWifiOffLine } from "react-icons/ri";

export default function ErroComunic({onClick}) {

  return (
    <div className = "contain-erro-comunication">
      <h2>Estamos com problemas de comunicação</h2>
      <RiWifiOffLine
        className = "icon-erro-comunication"
      />
      <p>Por favor, verifique sua conexão com a internet, ou tente novamente mais tarde</p>
      <button 
        className = "btt-error-comunication"
        onClick   = { () => onClick()}
      >
        Entendi
      </button>
    </div>
  );
}