import React      from "react";
import { 
  BiCalendarCheck, BiCalendarHeart, BiCalendarX, BiCalendarStar
} from "react-icons/bi";
import { CalClock }     from "../../../assets";
import { Services }     from "../../../services";

import "./stylesTLPed.css";

export default function TLPedido({pedido, onClick}) {

  const color = {
    Solicitado: '#ff7043',
    Reservado: '#24ad49', //Agendado
    Realizado: '#00cda4',
    Cancelado: '#bf3324',
    Avaliado:  '#FFD700',
    Ofuscado:  '#adadad',
  }
  
  const gradientes = {
    Solicitado_Aprovado   : 'linear-gradient(to right, #ff7043, #24ad49)',
    Solicitado_Cancelado  : 'linear-gradient(to right, #ff7043, #bf3324)',
    Solicitado_Ofuscado   : 'linear-gradient(to right, #ff7043, #adadad)',
    Aprovado_Realizado    : 'linear-gradient(to right, #24ad49, #00cda4)',
    Aprovado_Cancelado    : 'linear-gradient(to right, #24ad49, #bf3324)',
    Aprovado_Ofuscado     : 'linear-gradient(to right, #24ad49, #adadad)',
    Realizado_Avaliado    : 'linear-gradient(to right, #00cda4, #FFD700)',
    Realizado_Ofuscado    : 'linear-gradient(to right, #00cda4, #adadad)',
  }
 
  // Testes de status:
  const pedidoAprovado      = pedido.dataAprovacao !== null;
  const pedidoCriadoPorUser = pedido.marketplace;
  const pedidoRealizado     = pedido.status === "REALIZADO";
  const pedidoAgendado      = pedido.status === "AGENDADO";
  const pedidoAvaliado      = pedido.avaliacao_idavaliacao !== null;
  const pedidoCancelado     = pedido.status === "CANCELADO";
 //console.log("Pedido: ", pedido);
  

  /*
    //console.log("Pedido Aprovado: ", pedidoAprovado);
    //console.log("Pedido Criado Por User: ", pedidoCriadoPorUser);
    //console.log("Pedido Realizado: ", pedidoRealizado);
    //console.log("Pedido Agendado: ", pedidoAgendado);
    //console.log("Pedido Avaliado: ", pedidoAvaliado);
  //*/
  // Componente circulo da time line
  function CompCircle({Icon, color, textoT, rotuloA, rotuloB, rotuloC, onClick, av}){
   //console.log("Avaliação ativar: ", av);
    return(
      <div
        className = "contain-circle-time-line"
        style     = {{borderColor: color}}
      >
        <Icon 
          size      = {30}
          style     = {{color:color, width: "30px", height: "30px"}}
        />
        <div className="contain-texto-info-status">
          <h2 style = {{color:color}}>{textoT}</h2>
          <p>{rotuloA}</p>
          <p>{rotuloB}</p>
          {rotuloC && <p>{`Com o seguinte motivo: ${rotuloC}`}</p>}
          { av &&
            <button
              className = "btt-ver-avaliacao"
              onClick   = {() => {onClick()}}
            >
              Ver avaliação
            </button>
          }
        </div>
      </div>    
    );
  }
  //console.log("Pedido: ", pedido);
  // Verifica se o pedido foi cancelado!!!
  if(!pedidoCriadoPorUser) { // Criado pelo gestor não aparece o solicitado!!!
    if(!pedidoCancelado){ // Fluxo normal criado pelo gestor !!!
      return(
        <div className = "contain-time-line"
          style = {{maxWidth: "700px"}}
        >
          <div className = "contain-time-line-interior">
            <CompCircle
              Icon    = { BiCalendarCheck }
              color   = { color.Reservado }
              textoT  = "APROVADO"
              rotuloA = {`Por: ${pedido.criadoPor}`}
              rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", (pedido.createdAt))}`}
            />
              <div
                className = "contain-line-gradiente"
                style     = {{background : (pedidoRealizado ? gradientes.Aprovado_Realizado : (pedidoAgendado || !pedidoCriadoPorUser ?  gradientes.Aprovado_Ofuscado : color.Ofuscado ))}}
              />
            <CompCircle
              Icon  = { BiCalendarCheck }
              color = { pedidoRealizado ? color.Realizado : color.Ofuscado}
              textoT  = "REALIZADO"
              rotuloA = {pedidoRealizado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataInicio)}` : ""}
              rotuloB = {""}
            />
              <div
                className = "contain-line-gradiente"
                style     = {{background : (pedidoAvaliado ? gradientes.Realizado_Avaliado : (pedidoRealizado ? gradientes.Realizado_Ofuscado : color.Ofuscado))}}
              />
            <CompCircle
              Icon    = { BiCalendarStar }
              color   = { pedidoAvaliado ? color.Avaliado : color.Ofuscado}
              textoT  = { pedidoAvaliado ? "AVALIADO" : "A AVALIAR"}
              rotuloA = { pedidoAvaliado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.avaliacao.createdAt)}` : ""}
              av      = { pedidoAvaliado }
              onClick = {() => {onClick()}}
            />
          </div>
        </div>
      );
    } else { // Fluxo com o pedido cancelado na qual foi criado pelo gestor
      return(
        <div className = "contain-time-line"
          style = {{maxWidth: "500px"}}
        >
          <div className = "contain-time-line-interior">  
            <CompCircle
              Icon    = { BiCalendarCheck }
              color   = { color.Reservado }
              textoT  = "APROVADO"
              rotuloA = {`Por: ${pedido.criadoPor}`}
              rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", (pedidoCriadoPorUser ? pedido.createdAt : pedido.dataAprovacao))}`}
            />
              <div
                className = "contain-line-gradiente"
                style = {{background : gradientes.Aprovado_Cancelado}}
              />
            <CompCircle
              Icon  = { BiCalendarX }
              color = { color.Cancelado }
              textoT  = "CANCELADO"
              rotuloA = {`Por: ${pedido.canceladoPor === "USUARIO" ? pedido.usuario.nome : pedido.canceladoPor}`}
              rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataCancelamento)}`}
              rotuloC = {pedido.motivoCancelamento !== null && pedido.motivoCancelamento}
            />
          </div>
        </div>
      );
    }
  } else { // Criado pelo usuário
    if(!pedidoCancelado){ // Fluxo normal criado pelo usuário
      if((pedido.formaPagamento === "PIX" || pedido.formaPagamento === "CARTAO_CREDITO") && pedido.status !== "SOLICITADO"){
        return(
          <div className = "contain-time-line">
            <div className = "contain-time-line-interior">  
              <CompCircle
                Icon  = { BiCalendarHeart }
                color = { pedidoAprovado ? color.Reservado : ( !pedidoCriadoPorUser ? color.Reservado : color.Ofuscado)}
                textoT  = "APROVADO"
                rotuloA = {pedidoAprovado ? `Por: ${pedido.aprovadoPor === "Pagamento Online" ? "Pagamento Online" : pedido.aprovadoPor}` : ""}
                rotuloB = {pedidoAprovado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataAprovacao)}`: ""}
              />
                <div
                  className = "contain-line-gradiente"
                  style     = {{background : (pedidoRealizado ? gradientes.Aprovado_Realizado : (pedidoAgendado || !pedidoCriadoPorUser ?  gradientes.Aprovado_Ofuscado : color.Ofuscado ))}}
                />
              <CompCircle
                Icon  = { BiCalendarCheck }
                color = { pedidoRealizado ? color.Realizado : color.Ofuscado}
                textoT  = "REALIZADO"
                rotuloA = {pedidoRealizado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataInicio)}` : ""}
                rotuloB = {""}
              />
                <div
                  className = "contain-line-gradiente"
                  style     = {{background : (pedidoAvaliado ? gradientes.Realizado_Avaliado : (pedidoRealizado ? gradientes.Realizado_Ofuscado : color.Ofuscado))}}
                />
              <CompCircle
                Icon    = { BiCalendarStar }
                color   = { pedidoAvaliado ? color.Avaliado : color.Ofuscado}
                textoT  = { pedidoAvaliado ? "AVALIADO" : "A AVALIAR"}
                rotuloA = { pedidoAvaliado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.avaliacao.createdAt)}` : ""}
                av      = { pedidoAvaliado }
                onClick = {() => {onClick()}}
              />
            </div>
          </div>
        );
      } else {
        return(
          <div className = "contain-time-line">
            <div className = "contain-time-line-interior">  
              <CompCircle
                Icon    = {CalClock}
                color   = {color.Solicitado}
                textoT  = "SOLICITADO"
                rotuloA = {`Por: ${pedidoCriadoPorUser ? pedido.usuario.nome : pedido.criadoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.createdAt)}`}
              />
                <div
                  className = "contain-line-gradiente"
                  style = {{background : (pedidoAprovado ? gradientes.Solicitado_Aprovado : ( !pedidoCriadoPorUser ? gradientes.Solicitado_Aprovado : gradientes.Solicitado_Ofuscado))}}
                />
              <CompCircle
                Icon  = { BiCalendarHeart }
                color = { pedidoAprovado ? color.Reservado : ( !pedidoCriadoPorUser ? color.Reservado : color.Ofuscado)}
                textoT  = "APROVADO"
                rotuloA = {pedidoAprovado ? `Por: ${pedido.aprovadoPor}` : ""}
                rotuloB = {pedidoAprovado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataAprovacao)}`: ""}
              />
                <div
                  className = "contain-line-gradiente"
                  style     = {{background : (pedidoRealizado ? gradientes.Aprovado_Realizado : (pedidoAgendado || !pedidoCriadoPorUser ?  gradientes.Aprovado_Ofuscado : color.Ofuscado ))}}
                />
              <CompCircle
                Icon  = { BiCalendarCheck }
                color = { pedidoRealizado ? color.Realizado : color.Ofuscado}
                textoT  = "REALIZADO"
                rotuloA = {pedidoRealizado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataInicio)}` : ""}
                rotuloB = {""}
              />
                <div
                  className = "contain-line-gradiente"
                  style     = {{background : (pedidoAvaliado ? gradientes.Realizado_Avaliado : (pedidoRealizado ? gradientes.Realizado_Ofuscado : color.Ofuscado))}}
                />
              <CompCircle
                Icon    = { BiCalendarStar }
                color   = { pedidoAvaliado ? color.Avaliado : color.Ofuscado}
                textoT  = { pedidoAvaliado ? "AVALIADO" : "A AVALIAR"}
                rotuloA = { pedidoAvaliado ? `Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.avaliacao.createdAt)}` : ""}
                av      = { pedidoAvaliado }
                onClick = {() => {onClick()}}
              />
            </div>
          </div>
        );
      }
    } else { // Fluxo com o pedido cancelado
      if(!pedidoAprovado){ // Cancelado depois do pedido ser solicitado
        return(
          <div className = "contain-time-line">
            <div className = "contain-time-line-interior-cancel">
              <CompCircle
                Icon    = {CalClock}
                color   = {color.Solicitado}
                textoT  = "SOLICITADO"
                rotuloA = {`Por: ${pedidoCriadoPorUser ? pedido.usuario.nome : pedido.criadoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.createdAt)}`}
              />
                <div
                  className = "contain-line-gradiente"
                  style = {{background : gradientes.Solicitado_Cancelado}}
                />
              <CompCircle
                Icon    = { BiCalendarX }
                color   = { color.Cancelado }
                textoT  = "CANCELADO"
                rotuloA = {`Por: ${pedido.canceladoPor === "USUARIO" ? pedido.usuario.nome : pedido.canceladoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataCancelamento)}`}
                rotuloC = {pedido.motivoCancelamento && pedido.motivoCancelamento}
              />
            </div>
          </div>
        )
      } else { // Cancelado depois do pedido ser aprovado
        return(
          <div className = "contain-time-line">
            <div className = "contain-time-line-interior-cancel-2">
              <CompCircle
                Icon    = {CalClock}
                color   = {color.Solicitado}
                textoT  = "SOLICITADO"
                rotuloA = {`Por: ${pedidoCriadoPorUser ? pedido.usuario.nome : pedido.criadoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.createdAt)}`}
              />
                <div
                  className = "contain-line-gradiente"
                  style = {{background : gradientes.Solicitado_Aprovado}}
                />
              <CompCircle
                Icon    = { BiCalendarCheck }
                color   = { color.Reservado }
                textoT  = "APROVADO"
                rotuloA = {`Por: ${pedido.aprovadoPor === "Pagamento Online" ? pedido.aprovadoPor : pedido.aprovadoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", (pedidoCriadoPorUser ? pedido.createdAt : pedido.dataAprovacao))}`}
              />
                <div
                  className = "contain-line-gradiente"
                  style = {{background : gradientes.Aprovado_Cancelado}}
                />
              <CompCircle
                Icon  = { BiCalendarX }
                color = { color.Cancelado }
                textoT  = "CANCELADO"
                rotuloA = {`Por: ${pedido.canceladoPor === "USUARIO" ? pedido.usuario.nome : pedido.canceladoPor}`}
                rotuloB = {`Em: ${Services.DataFormat("dia,DD/MM/YY", pedido.dataCancelamento)}`}
                rotuloC = {(pedido.motivoCancelamento !== null) && pedido.motivoCancelamento}
              />
            </div>
          </div>
        )
      }
    }
  }
}