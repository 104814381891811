import React    from 'react';
import "./stylesCM.css";

export default function ContainMid({ children }) {
  
  const [height, setHeight] = React.useState(window.innerHeight - 55);
  // Pegar tamanho da tela
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight - 55);
    });
  }, []);

  return(
    <div className = "contain-mid-center"
      style={{height: height}}
    >
      {children}
    </div>
  );
}
