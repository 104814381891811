import React            from "react";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { Services }     from "../../../services";
import "./stylesMsng.css";

export default function Mensage({msg}){

  const _date = new Date(msg.createdAt);
  const _verif = (!msg.criadaPorAdmin ? "user" : "gestor")
 //console.log("Mensagem: ", msg);
  return (
    <div className = {"contain-line-texto-chat "+ _verif}>
      {/*<div className = {"arrow-seta " + (!msg.criadaPorAdmin ? "user" : "gestor")}/>*/}
      <div 
        className = {
          (!msg.criadaPorAdmin ? "contain-texto-msg-gestor " : "contain-texto-msg-user ") +  _verif
          //"contain-texto-msg-user "
        }
      >
        <h2>{msg.enviadoPor}</h2>
        <h3>{msg.texto}</h3>
        <div className = "contain-data-check">
          <label>{Services.ReturnDataMsg(_date)}</label>
          { msg.criadaPorAdmin &&
            <BiCheckDouble 
              size = {25}
              color = { msg.status === "LIDA" && msg.criadaPorAdmin ? "#34B7F1" : "#808080" }
            /> 
          }
          {/*
            msg.status === "LIDA" ?
              <BiCheckDouble 
                size = {25}
                className = {!msg.criadaPorAdmin ? "icon-check-user" : "icon-check-gestor"}
              /> 
            : 
              <BiCheck 
                size = {25}
                className = {!msg.criadaPorAdmin ? "icon-check-user" : "icon-check-gestor"}  
              />
          */}
        </div>
      </div>
    </div>
  )
}