import React                from "react";
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import AuthContextProvider  from "./contexts/AuthContext";
import ChatContextProvider  from "./contexts/ChatContext";
import RequireAuth          from "./contexts/RequireAuth";
import { CompAvisoMobile }  from "./components/Basics/";
import {
  AcessEst,
  Login, Menu
} from "./screens/";

export default function AppRouter() {

  const [width, setWidth] = React.useState(window.innerWidth);

  // Atualiza o valor do width quando a tela for redimensionada
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const labels = {
    login         : "/",
    menu          : "/centro-esportivo/:username/*",
    acessEst      : "/retrospectiva-empresa-2023/",
  }
  function Rotas(){
    return (
      <Routes>
        <Route path = {labels.login}         element={ <Login/> }       />
        <Route path = {labels.acessEst}      element={ <AcessEst/> }    />
        <Route path = {labels.menu}          element={ <RequireAuth> <Menu/> </RequireAuth> } />
      </Routes>
    );
  }

  return (
    <>
      {
        width < 767 ?
        <CompAvisoMobile/>
          :
        <Router>
          <AuthContextProvider>
            { Rotas() }
          </AuthContextProvider>
        </Router>
      }
    </>
  );
}