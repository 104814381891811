import React                              from "react";
import { GmtAviso }                       from "../../Complext";
import { 
  ModalAv, ModalCP, ModalFlex, ModalInfos, 
} from "../../Modais/";
import { 
  CalClock, ClosedX, UserSet, semFoto,
} from "../../../assets/";
import { 
  Alerts, Loading, TLPedido, CompPay,
} from "../../Basics";
import { 
  BiArrowBack, BiCalendarCheck, BiCalendarHeart, 
  BiCalendarX, BiChevronRight, BiEditAlt, BiMoney
} from "react-icons/bi";
import { IoMdChatbubbles }                from "react-icons/io";
import { AuthContext }                    from "../../../contexts/AuthContext";
import { ChatContext }                    from "../../../contexts/ChatContext";
import { LocalStorage, Services }         from "../../../services/";
import { FaCheck }                        from "react-icons/fa";
import RouterHook                         from "../../../contexts/RouterHook";
import Swal                               from "sweetalert2";
import moment                             from "moment";
import { EmpresaAPI, PedidosAPI }         from "../../../servicesAPI";
import { useLocation }                    from "react-router-dom";

import "./stylesDApp.css";
import 'moment-timezone';

moment.locale('pt-br');

export default function DetailsAppoint({}) {

  const serverRouter                = RouterHook();
  const location                    = useLocation();
  const state                       = LocalStorage.get("pedidoDetails");
  console.log("State: ", state);
  const { 
    date, token, modalOpen, handleMOP
  }  = React.useContext(AuthContext);
  const { actions }               = React.useContext(ChatContext);
  //const [pedido, setPedido]       = React.useState(developer ? Pedidos.solic_por_usuario : state.pedido);
  //state.pedido.status = "CANCELADO"
  const [pedido, setPedido]         = React.useState(state.pedido);
  const [valorS, setValorS]         = React.useState(pedido.valorTotal.toFixed(2));
  const [valorManual, setValorM]    = React.useState("0.00");
  const [obs, setObs]               = React.useState("");
  const [loading, setLoading]       = React.useState(false);
  const [loadingPay, setLoadingPay] = React.useState(false);
  const [horaUser, setHoraUser]     = React.useState("");
  const [modal, setModal]           = React.useState({
    avaliacao       : false,
    cancelamento    : false,
    viewCP          : false,
    viewAP          : false,
    newValor        : false,
    gerenciarValor  : false,
  });
  //console.log("Pedido: ", pedido);
  React.useEffect(() => {
    //console.log("Aqui!!!")
    document.title = "Agendamento | Gestor Agendei";
    // Modificar url para /agendamento/:idpedido
    // GMT do pedido
    function defineGMT(gmt) {
      switch (gmt) {
          case -5:
              return '-05:00';
          case -4:
              return '-04:00';
          case -3:
              return '-03:00';
          case -2:
              return '-02:00';
          default:
              return gmt;
      }
    }
    const gmtUser = moment().format("Z");
    //const gmtUser = "-04:00";
    //console.log("GMT do usuário: ", gmtUser);
    if(defineGMT(pedido.empresa.gmt) !== gmtUser){
    //if(defineGMT(-4) !== gmtUser){
      // Converter GMT de horas para minutos
      //console.log("Entrou aqui!");
      let gmtEmpresaMinutos = pedido.empresa.gmt * 60;
      //let gmtEmpresaMinutos = -4 * 60;
      // Analisar a data considerando o fuso horário da data
      let dataInicio = moment.parseZone(pedido.dataInicio).utcOffset(gmtEmpresaMinutos);
      let dataFinal  = moment.parseZone(pedido.dataFinal).utcOffset(gmtEmpresaMinutos);
      // Converter essa data para o fuso horário do usuário
      dataInicio = dataInicio.utcOffset(parseFloat(gmtUser) * 60);
      dataFinal  = dataFinal.utcOffset(parseFloat(gmtUser) * 60);
      // Modal Info com alerta do fuso horário
      
      const label = `${dataInicio.format("HH:mm")} às ${dataFinal.format("HH:mm")}`;
      setHoraUser(label);
      //console.log("Label: ", label);
    } else {
      const label = `${Services.ReturnHoraMin(new Date(pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(pedido.dataFinal))}`;
      setHoraUser(label);
    }
    // Atualiza o pedido periodicamente!

  }, []);
  // Adiciona o evento de escuta
  function handleBackButtonEvent(){
    if(modalOpen){
      handleMOP(false);
    } else {
     //console.log("Aqui!!!");
      serverRouter("back");
    }
  }
  React.useEffect(() => {
    //console.log("State: ", state);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", handleBackButtonEvent);
    };
  }, [modalOpen]);

  // Escuta os modais para atualizar o modalOpen
  React.useEffect(() => {
    //console.log("Modal: ", modal);
    if(modal.avaliacao || modal.cancelamento || modal.newValor || modal.viewCP || modal.gerenciarValor || modal.viewAP){ 
            handleMOP(true);
    } else  handleMOP(false);
  }, [modal]);

  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    if(!modalOpen){
      setModal({
        avaliacao     : false,
        cancelamento  : false,
        viewCP        : false,
        newValor      : false,
        gerenciarValor: false,
        viewAP        : false,
      });
    }
  }, [modalOpen]);
  // Verificar quando url muda
  React.useEffect(() => {
    //console.log("A url mudou!! ", location);
    // pegar o id do pedido na url
    // Buscar o pedido e iniciar o timer para atualizar o pedido!
    //console.log("Aqui!!!")
    const array = location.pathname.split("/");
    // Transforma o id em um número
    const idpedido = parseInt(array[array.length - 1]);
    //console.log("Pedido: ", pedido);
    //console.log("Idpedido: ", idpedido);
    if(idpedido !== undefined && pedido.idpedido !== (idpedido)){
      //console.log("Aqui!!!");
      setLoading(true);
      PedidosAPI.BuscarPedidoId(token, state.pedido.idpedido).then(
        (req) => {
         //console.log("Req: ", req);
          if(req.status){
            //console.log("Setou pedido!");
            setPedido(req.req);
            setLoading(false);
          }
        }
      );
    }
  }, [location]);
  //console.log("Pedido: ", pedido);
  //console.log("Usuario: ", date.user);

  async function VerificaStates(){
    // Buscar o pedido e iniciar o timer para atualizar o pedido!
    //console.log("Aqui!!!")
    setLoading(true);
    const req = await PedidosAPI.BuscarPedidoId(token, state.pedido.idpedido);
    //console.log("Pedido Vindo da API: ", req.req);
    if (req.status) {
      if(state.page === "avaliacao"){
        // Abre modal de avaliação
        setModal({...modal, avaliacao: true});
        //Seta como page= agendamentos
        LocalStorage.set("pedidoDetails", {page: "agendamentos", pedido: req.req});
      }
      //console.log("Setou pedido!");
      setPedido(req.req);
      setLoading(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon  : 'warning',
        title : 'Aviso',
        text  : 'Não foi possível buscar o agendamento, por favor tente novamente mais tarde',
        confirmButtonText   : 'Entendi',
        confirmButtonColor  : '#DAA520',
        timer               : 6000,
      });
    }
  }

  async function UpDatePedido(){
    const req = await PedidosAPI.BuscarPedidoId(token, state.pedido.idpedido);
    if(req.status){
      //console.log("Teste Status: ", (req.req.status !== pedido.status));
      //console.log("Status req - pedido ::", req.req.status, pedido.status );
      if(req.req.status !== pedido.status){
        //console.log("Setou pedido!");
        setPedido(req.req);
      }
    }
  }

  // Atualiza o tamanho da tela eo pedido de 35 em 35 segundos!!!
  React.useEffect(() => {
    // Verifica se o usuário está logado
    VerificaStates();
    // Atualiza o estado com o tamanho da tela
   
    if(pedido.status !== "CANCELADO" && pedido.status !== "REALIZADO"){
      const interval = setInterval(async () => {
        await UpDatePedido();
      }, 35000);
      return () => {
        clearInterval(interval);
      }
    }
  }, []);


  /* --- Cores dos status dos horários:
      colorSolicitado: '#ff7043',
      colorReservado: '#24ad49', -- Agendado
      colorRealizado: '#00cda4',
      colorCancelado: '#bf3324',
      colorSolicitadoAlpha: 'rgba(255, 112, 67, 0.3)',
      colorReservadoAlpha: 'rgba(36, 173, 73, 0.3)',
      colorRealizadoAlpha: 'rgba(0, 205, 164, 0.3)',
      colorCanceladoAlpha: 'rgba(191, 51, 36, 0.3)',
  //*/
  //console.log("State: ", state);
  //console.log("Pedido: ", pedido);
  const color = {
    Solicitado: '#ff7043',
    Reservado: '#24ad49',   //Agendado
    Realizado: '#00cda4',   
    Cancelado: '#bf3324',
    Avaliado:  '#FFD700',
    Ofuscado:  '#adadad',
  }

  const pedidoCriadoPorUser = pedido !== null ? pedido.marketplace                    : false;
  const pedidoRealizado     = pedido !== null ? pedido.status === "REALIZADO"         : false;
  const pedidoAvaliado      = pedido !== null ? pedido.avaliacao_idavaliacao !== null : false;

  const av                  = pedido !== null ? pedido.avaliacao                      : false;
  //console.log("Av: ", av);
  const [aval, setAval]     = React.useState(av === null || av === undefined ? 0 :
    (((av.agendarPeloApp + av.notaGeral + av.qualidadeLocal + av.qualidadeMateriais + av.tempo) / 5).toFixed(0))
  );
  //console.log("Av: Aqui ", aval);
  /*async function BuscarAvaliacao(){
    setLoading(true);
    const reqs = await api.Reservas.buscar_avaliacao_id(token, state.avaliacao_idavaliacao);
    if(reqs.status) return reqs.reqs;
    else return ;
  }

  if(pedidoAvaliado){
    if(pedido.avaliacao){
      //console.log("Avaliação presente no pedido: ", pedido.avaliacao);
    } else {
      //console.log("Avaliação não presente no pedido: ", pedido);
      BuscarAvaliacao().then((reqs) => {

      });
    }
  }*/
  // Atualiza o pedido
  async function AtualizaPedido(idpedido){
    setLoading(true);
    const ress = await PedidosAPI.BuscarPedidoId( token, idpedido );
    //console.log("Reserva Atualiza pedido: ", ress);
    setPedido(ress.req);
    setLoading(false);
  }
  // Atualiza novo valorTotal do pedido
  async function AtualizaValorTotal(newVal){
    setLoading(true);
    const res = await EmpresaAPI.AtualizarValorPedido( token, pedido.idpedido, newVal );
   //console.log("Res: ", res);
    if(res.status){
      await AtualizaPedido(pedido.idpedido);
      Alerts.sucesso("Sucesso", "Valor atualizado com sucesso");
    } else {
      Alerts.aviso("Aviso", res.message);
    }
    setLoading(false);
  }
  //console.log("Pedido: ", pedido);
  // Adicionar pagamento
  async function AdicionarPagamento(pay, obs){
    // Evitar que o usuário adicione um pagamento com valor zerado
    if(valorManual === "0.00" || valorManual === 0) return Alerts.aviso("Aviso", "Você não pode adicionar um pagamento sem um valor informado");
    // Verifica se o valor adicionado estrapola o valor a ser pago...
    
    // Verifica se o valor adicionado estrapola o valor a ser pago...
    //if(parseFloat(valorManual) > pedido.valorPendente) return Alerts.aviso("Aviso", "O valor do pagamento não pode ser maior que o valor pendente do agendamento, que é de R$" + pedido.valorPendente.toFixed(2).toString().replace(".",","));

    if(obs === ""){
      Swal.fire({
        icon    : 'warning',
        title   : 'Aviso',
        text    : 'Tem certeza que deseja adicionar um pagamento sem observação?',
        confirmButtonText   : 'Não',
        cancelButtonText    : 'Sim',
        showCancelButton    : true,
        confirmButtonColor  : '#bf3324',
        cancelButtonColor   : '#00cda4',
      }).then(async (result) => {
        if(result.isConfirmed) return;
        obs = "Sem observações";
        await RodaRotina();
      });
    } else {
      await RodaRotina();
    }

    async function RodaRotina(){
      //console.log("Pay: ", pay, obs);
      setLoadingPay(true);
      const res = await EmpresaAPI.AdicionarPagamento(
        token,
        pedido.idpagamento,
        pedido.usuario.idusuario,
        pedido.idpedido,
        valorManual,
        // Data neste formato : 2021-08-31T00:00:00.000Z
        moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        date.gestor.nome,
        obs,
        (pedido.valorDesconto > 0 ? (pedido.valorTotal-pedido.valorDesconto == valorManual ? "total" : "parcial") : (pedido.valorTotal == valorManual ? "total" : "parcial"))     
      );
      //console.log("Res: ", res);
      if(res.status){
        if(res.req.retorno){
          Alerts.sucesso("Sucesso", "Pagamento adicionado com sucesso");
          setPedido(res.req.registro);
        } else {
          Alerts.aviso("Aviso", "Não foi possível adicionar o pagamento, por favor tente novamente mais tarde");
        }
      }
      // Zera os inputs
      setValorM("0.00");
      setObs("");
      setLoadingPay(false);
    }
  }
  // Remove pagamento
  async function DeletarPagamento(idpagamento){
    setLoadingPay(true);
    const res = await EmpresaAPI.DeletarPagamento( token, idpagamento );
   //console.log("Res Delete pagamento: ", res);
    if(res.status){
      if(res.req.retorno){
        Alerts.sucesso("Sucesso", "Pagamento removido com sucesso");
        setPedido(res.req.registro);
      } else {
        Alerts.aviso("Aviso", "Não foi possível remover o pagamento, por favor tente novamente mais tarde");
      }
    } else {
      Alerts.aviso("Aviso", res.message);
    }
    // Zera os inputs
    setValorM("0.00");
    setObs("");
    setLoadingPay(false);
  }
  //console.log("Date: ", date);
  // Cancela o pedido, caso a regra de cancelamento permita!
  async function CancelarPedido(motivo){
    //console.log("Motivo: ", motivo);
    const res = await PedidosAPI.CancelarPedido(pedido.idpedido, motivo, date.gestor.nome, token);
    if(res.status){
      await AtualizaPedido(pedido.idpedido);
      Alerts.sucesso("Sucesso", "Agendamento cancelado com sucesso");
    } else {
      Alerts.aviso("Aviso", 'Estamos com problemas para cancelar o seu agendamento, por favor tente novamente mais tarde');
    }
  }
  // Cancelar o mensalista
  async function CancelarMensalista(motivo){
   //console.log("Chegou aqui com o Motivo: ", motivo);
    const req = await EmpresaAPI.CancelarMensalistaCliente( token, pedido.mensalista_idmensalista, date.gestor.nome, motivo );// Sem motivo!
   //console.log("Req: ", req);
    if(req.status){
      Alerts.sucesso("Sucesso", "Horário fixo cancelado com sucesso");
      await AtualizaPedido(pedido.idpedido);
      return {status: true};
    } else {
      Alerts.aviso("Aviso!", req.message);
      return {status: false};
    }
  }
  // Função que abre o chat do pedido, caso chat ainda esteja ativo!
  async function AbrirChatPedido(){
    setLoading(true);
    // Verifica se o chat já foi aberto!
    const index = await actions.OpenChatPedido(pedido);
    if(index !== null){ 
      //console.log("Index: ", index);
      serverRouter("chat");
    }
    setLoading(false);
  }
  // Aceita o pedido avulso.
  async function AcepptAgendamento(idagendamento){
    setLoading(true);
    const req = await EmpresaAPI.AprovarPedido( token, idagendamento, date.gestor.nome );
    if(req.status){
      // Remover o pedido do array
      Alerts.sucesso("Sucesso", "Pedido aprovado com sucesso");
      await AtualizaPedido(pedido.idpedido);
      setModal({...modal, viewAP: false});
      setLoading(false);  
      return true;
    } else {
      Alerts.aviso("Aviso", req.message);
      setLoading(false);  
      return false;
    }

  }
  // Rejeita o pedido avulso.
  async function DeclinedAgendamento(idagendamento, mode){
    if(mode){
      const req = await EmpresaAPI.RecusarPedido( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
      if(req.status){
        // Remover o pedido do array
        Alerts.sucesso("Sucesso", "Pedido cancelado com sucesso");
        await AtualizaPedido(pedido.idpedido);
        return {status: true};
      } else {
        Alerts.aviso("Aviso!", req.message);
        return {status: false};
      }
    } else {
      const req = await EmpresaAPI.RecusarMensalistaCliente( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
      if(req.status){
        Alerts.sucesso("Sucesso", "Horário fixo cancelado com sucesso");
        await AtualizaPedido(pedido.idpedido);
        return {status: true};
      } else {
        Alerts.aviso("Aviso!", req.message);
        return {status: false};
      }
    }
  }
  // Verifica se tem avaliação e calcula a média das notas
  function VerificarAvaliacao(){
    if(pedido.avaliacao_idavaliacao !== null){
      //console.log("Aqui entrouy!!!")
      const av = pedido.avaliacao;
      let soma = av.agendarPeloApp + av.notaGeral + av.qualidadeLocal + av.qualidadeMateriais + av.tempo;
      setAval((soma/5).toFixed(0));
    }
  }
  // Sempre que o pedido for atualizado, verifica se tem uma avaliação
  React.useEffect(() => {
    VerificarAvaliacao(pedido.avaliacao);
  }, [pedido]);

  // Start detalhes do cliente
  function GoClient(){
    //console.log("Cliente: ", pedido)
    if(date.perfilG.verClientes){
      LocalStorage.set("cliente-selected", pedido.usuario.idusuario);
      serverRouter("clientes");
    } else {
      Alerts.aviso("Aviso", "Você não possui permissão para acessar os detalhes de clientes da empresa.");
    }
  }

  // Função que seta o novo valor do pedido
  function SetarNewValor(){
    // Verifica se o pedido foi criado pelo usuário
    if(pedidoCriadoPorUser){
     //console.log("Pedido criado pelo usuário!");
      // Só pode diminuir o valor!
      if(valorS < pedido.valorTotal){
        // Atualiza o valor do pedido
        AtualizaValorTotal(valorS);
      } else {
        Alerts.aviso("Aviso", "O valor não pode ser maior que o valor total do agendamento!");  
       //console.log("ValorS: ", valorS);
      }
    } else {
     //console.log("Pedido criado pelo gestor!");
      // Pode alterar o valor para mais ou para menos!
     //console.log("ValorS: ", valorS);
      // Atualiza o valor do pedido
      AtualizaValorTotal(valorS);
    }
    setModal({...modal, newValor: false});
  }
  // Retorna o componente com o tempoq ue falta para o pedido acontecer ou o tempo que passou desde que o pedido foi realizado
  function ReturnFalta(pedido) {
    //falta.label = moment(dataVerify).startOf('minute').fromNow().toUpperCase()
    let falta = moment(pedido.dataInicio).startOf("minute").fromNow().toUpperCase();
    switch(pedido.status){
      case "SOLICITADO" : 
        return (
          <div className = 'contain-detalhes-falta-pedido-collum'>
            <div className = "contain-detalhes-falta-pedido-row">
              <CalClock
                size  = {25}
                color = {"#ff7043"}
                style = {{color:"#ff7043", width: "25px", height: "25px"}}
              />
              <h3 style = {{color : "#ff7043"}}>
                {pedido.status}
              </h3>
            </div>
            <h3 
              className = {"text-states-detalhes-pedido"} 
            >
              {falta}
            </h3>
          </div>
        );
      case "AGENDADO" :
        return (
          <div className = 'contain-detalhes-falta-pedido-collum'>
            <div className = "contain-detalhes-falta-pedido-row">
              <BiCalendarHeart
                size  = {25}
                color = {"#24ad49"}
              />
              <h3 style = {{color : "#24ad49"}}>
                {pedido.status}
              </h3>
            </div>
            <h3
              className = {"text-states-detalhes-pedido"}
            >
              {falta}
            </h3>
          </div>
        );
      case "CANCELADO" : 
        return (
          <div className = 'contain-detalhes-falta-pedido-collum'>
            <div className = "contain-detalhes-falta-pedido-row">
              <BiCalendarX
                size  = {25}
                color = {"#bf3324"}
              />
              <h3 style = {{color : "#bf3324"}}>
                {pedido.status}
              </h3>
            </div>
            {/*<h3
              className = {"text-states-detalhes-pedido"}
            >
              {falta}
            </h3>*/}
          </div>
        );
      case "REALIZADO" : 
        falta = moment(pedido.dataFinal).startOf("minute").fromNow().toUpperCase();
        return (
          <div className = 'contain-detalhes-falta-pedido-collum'>
            <div className = "contain-detalhes-falta-pedido-row">
              <BiCalendarCheck
                size  = {25}
                color = {"#00cda4"}
              />
              <h3 style = {{color : "#00cda4"}}>
                {pedido.status}
              </h3>
            </div>
            <h3
              className = {"text-states-detalhes-pedido"}
            >
              {falta}
            </h3>
          </div>
        );
    }
  }
  function ReturnMetodoPay(metodo){
    switch(metodo){
      case "MAQUINA_CARTAO" : return "Máquina de cartão";
      case "DINHEIRO"       : return "Dinheiro";
      case "PIX"            : return "PIX";
      case "CARTAO_CREDITO" : return "Cartão de crédito";
    }
  }
  // Retorna o componente com o tipo de pagamento e o valor total do pedido
  function CompoInfo({mode}){
    const [urlImg, setUrlImg] = React.useState("" + pedido.quadra.urlFoto + pedido.quadra.foto);

    if(mode === 1){
      return (
        <div 
          className = "componente-de-informacao"
          style     = {{minWidth: "270px"}}
        >
          <h2>PAGAMENTO</h2>
          <div className = "componente-de-informacao-line">
            <p>Forma de pagamento</p>
            <h3>
              {ReturnMetodoPay(pedido.formaPagamento)}
            </h3>
          </div>
          { (pedido.valorDesconto > 0) ?
            <div className = "componente-de-informacao-line">
              <p style = {{textDecoration: "line-through"}}>Valor Total</p>
              <h3>R${pedido.valorDesconto.toFixed(2).toString().replace(".",",")}</h3>
            </div>
              :
            <div className = "componente-de-informacao-line">
              <p>Valor Total</p>
              { (pedido.formaPagamento === "DINHEIRO" || pedido.formaPagamento === "MAQUINA_CARTAO") ?
                <button
                  className = "btt-handle-valor"
                  onClick   = {() => setModal({...modal, newValor: true})}
                >
                  <BiEditAlt
                    className = "icon-edit-valor"
                  />
                  <h3 className = "btt-text-valor">
                    {
                      `R$${pedido.valorTotal.toFixed(2).toString().replace(".",",")}`
                    }
                  </h3>
                </button> 
                  :
                <h3>R${pedido.valorTotal.toFixed(2).toString().replace(".",",")}</h3>
              }
            </div>
          }
          { (pedido.valorDesconto > 0) &&
            <div className = "componente-de-informacao-line">
              <p>Valor Desconto</p>
              <h3>R${pedido.valorDesconto.toFixed(2).toString().replace(".",",")}</h3>
            </div>
          }
          { (pedido.valorDesconto > 0) &&
            <div className = "componente-de-informacao-line">
              <p>Valor Total com Desconto</p>
              <h3>R${(pedido.valorTotal - pedido.valorDesconto).toFixed(2).toString().replace(".",",")}</h3>
            </div>
          }
          { (pedido.valorPendente > 0 && pedido.status !== "CANCELADO") &&
            <div className = "componente-de-informacao-line">
              <p>Valor Pendente</p>
              { pedido.valorDesconto > 0 ?
                <h3 style={{color:"#edb201"}}>R${(pedido.valorPendente).toFixed(2).toString().replace(".",",")}</h3>
                  :
                <h3 style={{color:"#edb201"}}>R${pedido.valorPendente.toFixed(2).toString().replace(".",",")}</h3>
              }
            </div>
          }
          { (pedido.valorRealizado > 0) &&
            <div className = "componente-de-informacao-line">
              <p>Valor Pago</p>
              <h3 style={{color:"#24ad49"}}>R${pedido.valorRealizado.toFixed(2).toString().replace(".",",")}</h3>
            </div>
          }
          { (pedido.valorEstornado > 0) &&
            <div className = "componente-de-informacao-line">
              <p>Valor Estornado</p>
              <h3 style ={{color:"#be3223"}}>R${pedido.valorEstornado.toFixed(2).toString().replace(".",",")}</h3>
            </div>
          }
          { (pedido.valorRealizado > 0 && pedido.status === "CANCELADO") &&
            <div className = "componente-de-informacao-line">
              <p>Multa por cancelamento</p>
              <h3 style ={{color:"#be3223"}}>R${pedido.valorRealizado.toFixed(2).toString().replace(".",",")}</h3>
            </div>
          }
          <div className = "contain-btt-gerenciar-pagamento">
            <button
              className = "btt-gerenciar-pagamento"
              onClick   = {() => {
                setModal({...modal, gerenciarValor: true});
              }}
            >
              Gerenciar pagamento
            </button>
          </div>
        </div>
      );
    } else if(mode === 2){
      return (
        <div 
          className = "componente-de-informacao"
          style     = {{minWidth: "220px", flexDirection: "row", alignItems: "center", cursor: "pointer"}}
          onClick   = {() => {
            GoClient();
          }}
        >
          <div className = "contain-cliente">
            <h3>CLIENTE</h3>
      
            <h4>{pedido.usuario.nome}</h4>
            { pedido.usuario.nome !== pedido.usuario.usuario ?
              <h5>{pedido.usuario.usuario}</h5>
                :
              <div className = "contain-buffer-usuario">
                <h3>
                  {"CRIADO MANUALMENTE POR GESTOR"}
                </h3>  
                <UserSet
                  style = {{marginLeft: "7px", color: "#A8A8A8", minWidth: "20px", minHeight: "20px"}}
                />
              </div>  
            }
            <h5>{pedido.usuario.atleta.telefone}</h5>
          </div>
          <BiChevronRight
            className   = "icon-right-cliente"
            size        = {20}

          />
        </div>
      );
    } else {
      return (
        <div className = "componente-de-informacao">
          <h2>LOCAL</h2>
          <div className = "contain-pedido-local-quadra">
            <img src = {urlImg} onError={()=> setUrlImg(semFoto)}/>
            <div>
              <h2>{pedido.empresa.nome}</h2>
              <h3>{pedido.quadra.descricao}</h3>
              <h4>{pedido.quadra.endereco}</h4>
            </div>
          </div>
        </div>
      );
    }
  }
  // Retorna o componente de avaliação
  function SetaAv(av){
    //console.log("Avaliação: aqui1111 ", av);
    pedido.avaliacao_idavaliacao = av.idavaliacao;
    pedido.avaliacao = av;
    setPedido(pedido);
    VerificarAvaliacao(av);
  }
  //  Função que seta o novo valor do pedido
  function SetaValorTotal(mode, e) {
    // Remove todos os caracteres que não sejam dígitos.
    let value = e.target.value.replace(/[^\d]/g, "");

    // Se o valor não for vazio, converta-o.
    if (value !== "") {
        // Interpretamos o valor como centavos.
        let cents = parseInt(value);
        if (isNaN(cents) || cents === 0) {
            value = "0.00";
        } else {
            // Converte os centavos em uma representação decimal.
            value = (cents / 100).toFixed(2);
        }
    } else {
        value = "0.00";
    }
    if(mode)  setValorS(value);
    else      setValorM(value);
  }
  // Componente para cancelar pedidos
  function CancelarPedidoUI(){
    const [loadingCP, setLoadingCP] = React.useState(false);

    return (
      <>
        <h3 className = "text-title-modal">
          {`Cancelar Agendamento #${pedido.idpedido}`} 
        </h3>
        {
          loadingCP ? 
          <div className="contain-loading" style = {{height: "250px"}}>
            <Loading
              mode    = {true}
              color   = {true}
              text    = {"Cancelando agendamento..."}
            />
          </div>
            :
          <>
            <div className = "contain-cancelar-pedido-hf">
              <h2>
                Atenção
              </h2>
              { pedido.mensalista_idmensalista !== null ?
                <>
                  <h3>
                    Este horário faz parte de uma agendamento fixo, ou seja, outros horários estão ligados à este.
                  </h3>
                </>
                  : 
                <h3>
                  Deseja rejeitar essa solicitação?
                </h3>
              }
            </div>
            <button
              className = "btt-cancelamento-hf yes-cancel"
              onClick   = {() => {
                setModal({...modal, viewCP: false});
              }}
            >
              Não, voltar
            </button>
            <button
              className = "btt-cancelamento-hf now-cancel"
              onClick   = {() => {
                setLoadingCP(true);
                DeclinedAgendamento(pedido.idpedido, true).then((result) => {
                  if(result.status){
                    setModal({...modal, viewCP: false});
                  } 
                  setLoadingCP(false);  
                });
              }}
            >
              {pedido.mensalista_idmensalista === null ? "Sim, rejeitar essa solicitação" : "Rejeitar apenas esse horário"}
            </button>
            {
              pedido.mensalista_idmensalista !== null &&
              <button
                className = "btt-cancelamento-hf now-cancel-hf"
                onClick   = {() => {
                  DeclinedAgendamento(pedido.mensalista_idmensalista, false).then((result) => {
                    if(result.status){
                      setModal({...modal, viewCP: false});
                    } 
                    setLoadingCP(false);  
                  });
                }}
              >
                Rejeitar todos os horários
              </button>
            }
          </>
        }
      </>  
    );
  }
  function AceitarPedidoUI(){
    const [loadingCP, setLoadingCP] = React.useState(false);

    return (
      <>
        <h3 className = "text-title-modal">
          {`Aprovar Agendamento #${pedido.idpedido}`} 
        </h3>
        {
          loadingCP ? 
          <div className="contain-loading" style = {{height: "250px"}}>
            <Loading
              mode    = {true}
              color   = {true}
              text    = {"Aprovando agendamento..."}
            />
          </div>
            :
          <>
            <div className = "contain-cancelar-pedido-hf">
              <h2>
                Atenção
              </h2>
              <h3>
                Deseja aprovar essa solicitação?
              </h3>
            </div>
            <button
              className = "btt-cancelamento-hf now-cancel"
              onClick   = {() => {
                setModal({...modal, viewAP: false});
              }}
            >
              Não, voltar
            </button>
            <button
              className = "btt-cancelamento-hf yes-cancel"
              onClick   = {() => {
                setLoadingCP(true);
                AcepptAgendamento(pedido.idpedido);
              }}
            >
              Sim, aprovar essa solicitação
            </button>
          </>
        }
      </>  
    );
  }
 //console.log("Pedido: ", pedido);
  return (
    <>
      {
        modal.avaliacao &&
        <ModalAv
          modal           = {modal.avaliacao}
          ClosedModal     = {() => setModal({...modal, avaliacao: false})}
          aval            = {pedido.avaliacao}
          idpedido        = {pedido.idpedido}
        />
      }{
        (modal.cancelamento) &&
        <ModalCP
          modal           = {modal.cancelamento}
          ClosedModal     = {() => setModal({...modal, cancelamento: false})}
          onClick         = {async (motivo) => await CancelarPedido(motivo)}
          onClickChat     = {async () => await AbrirChatPedido()}
          onClickMens     = {async (motivo) => await CancelarMensalista(motivo)}
          fixo            = {pedido.mensalista_idmensalista !== null ? true : false}
          pedido          = {pedido}
        />
      }{
        modal.newValor &&
        <ModalInfos
          modal           = {modal.newValor}
          ClosedModal     = {() => setModal({...modal, newValor: false})}
          Component       = {(
            <>
              <h3 className = "text-title-modal">
                Editar Valor
              </h3>
              <div className = "contain-new-value">
                <h3 className = "text-preco-rs">
                  R$
                </h3>
                <input
                  placeholder   = "0,00"
                  type          = "text"
                  value         = {valorS.toString().replace(".",",")}
                  onChange      = {(e) => {
                    SetaValorTotal(true, e);                    
                  }}
                />
              </div>
              <button
                className = "btt-contain-salvar-cliente"
                onClick   = {() => {
                  SetarNewValor();
                }}
              >
                Definir esse valor
              </button>
            </>
          )}
        />
      }{
        modal.viewCP &&
        <ModalInfos
          modal           = {modal.viewCP}
          ClosedModal     = {() => setModal({...modal, viewCP: false})}
          Component       = {<CancelarPedidoUI/>}
        />
      }{
        modal.viewAP &&
        <ModalInfos
          modal           = {modal.viewAP}
          ClosedModal     = {() => setModal({...modal, viewAP: false})}
          Component       = {<AceitarPedidoUI/>}
        />
      }{
        modal.gerenciarValor &&
        <ModalFlex
          modal           = {modal.gerenciarValor}
          ClosedModal     = {() => setModal({...modal, gerenciarValor: false})}
          Component       = {(
            <>
              <h3 className = "text-title-modal">
                Adicionar pagamento manualmente
              </h3>
              <div className = "contain-new-value">
                <h3 className = "text-preco-rs">
                  R$
                </h3>
                <input
                  placeholder   = "0,00"
                  type          = "text"
                  value         = {valorManual.toString().replace(".",",")}
                  onChange      = {(e) => {
                    SetaValorTotal(false, e);                    
                  }}
                />
              </div>
              <div className = "contain-new-value">
                <input
                  placeholder   = "Alguma observação?"
                  type          = "text"
                  value         = {obs}
                  onChange      = {(e) => {setObs(e.target.value)}}
                  style         = {{fontFamily: "jost-light", color: "black"}}
                />
              </div>
              <button
                className = "btt-contain-add-pay"
                onClick   = {() => {
                  AdicionarPagamento(valorManual, obs);
                }}
              >
                Adicionar pagamento
                <BiMoney
                  size  = {25}
                  color = {"white"}
                  style = {{marginLeft: "10px"}}
                />
              </button>
              <div className = "contain-list-pagamentos">
                { loadingPay ?
                  <Loading mode={false}/> :
                  pedido.pagamentos.map((pay, index) => {
                    return (
                      <CompPay
                        key     = {index}
                        pay     = {pay}
                        onClick = {() => {
                          Swal.fire({
                            icon    : 'warning',
                            title   : 'Remover pagamento',
                            text    : "Deseja remover esse pagamento? Essa ação não poderá ser desfeita.",
                            showCancelButton      : true,
                            confirmButtonColor    : '#bf3324',
                            cancelButtonColor     : '#00cda4',
                            confirmButtonText     : 'Cancelar',
                            cancelButtonText      : 'Remover',
                          }).then((result) => {
                            if(result.isConfirmed) return;
                            DeletarPagamento(pay.idpagamento);
                          });
                        }}
                      />
                    );
                  })
                }
              </div>
            </>
          )}
        />
      }
      { 
        loading ? 
        <div className = "contain-loading">
          <Loading
            mode  ={true}
            text  = {"Carregando agendamento..."}
          />
        </div> 
          : 
        <div className = "contain-detalhes-pedido">
          {/* Div superior */}
          <div className = "contain-detalhes-pedido-topo">
            <BiArrowBack
              className = "icon-back-left"
              onClick={() => {
                handleBackButtonEvent();
              }}
            />
            {/* Componente esquerdo da div superior */}
            {ReturnFalta(pedido)}
            {/* Componente central da div superior */}
            <div className = "contain-detalhes-pedido-topo-central">
              <h2>{`${Services.ReturnHoraMin(new Date(pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(pedido.dataFinal))}`}</h2>
              <GmtAviso
                pedido = {pedido}
                local  = "right"
                horaEmpresa = {`${Services.ReturnHoraMin(new Date(pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(pedido.dataFinal))}`}
                horaUsuario = {horaUser}
              />
              <h3>{Services.DataFormat("dia,DD/MM/YY", pedido.dataInicio, null)}</h3>
              { (pedido.mensalista_idmensalista !== null && date.perfilG.gerenciarMensalistas) &&
              <button 
                className = "btt-hora-fixa"
                onClick   = {() => { // Ativa modal que mostra o horário fixo!
                  LocalStorage.set("hora-fixa-selected", pedido.mensalista_idmensalista);
                  serverRouter("hora-fixa");
                }}
              >
                HORÁRIO FIXO
                <BiChevronRight
                  size  = {20}
                />
              </button>
            }
            </div>
            {/* Componente direito da div superior */}
            <div>
              {( pedido.status !== "CANCELADO" && pedido.status !== "REALIZADO" && pedido.status !== "SOLICITADO" && date.perfilG.cancelar ) &&
                <button
                  className = "button-cancelar-pedido"
                  onClick   = {() => {
                    setModal({...modal, cancelamento: true});
                  }}
                >
                  CANCELAR
                </button>
              }
              <h2 className = "numero-pedido-label">Nº #{pedido.idpedido}</h2>
            </div>
          </div>
          {/* Time Line do status do componente */}
          <TLPedido
            pedido = {pedido}
            onClick = {() => {
              // Ativa modal de avaliação
              setModal({...modal, avaliacao: true});
            }}
          />
          {/* Div com 3 componentes de informação Pagamento , Avaliação , Local */}
          <div 
            className = "contain-componentes-informacao"
            style     = {(pedido.status === "CANCELADO" && pedido.motivoCancelamento !== null) ? {marginTop: "10px"} : {}}
          >
            {/* Componente de pagamento */}
              <CompoInfo
                mode = {1}
              />
            {/* Componente de avaliação */}
              {/*
                pedidoRealizado &&
                <div className = "componente-de-informacao">
                  <h2>AVALIAÇÃO</h2>
                  <div className = "componente-de-informacao-line">
                    <p>{pedido.avaliacao === null ? `Como você avalia seu agendamento?` : ``}</p>
                  </div>
                  <div className = "componente-de-informacao-estrelas">
                    {
                      arrayStt.map((index) => {
                        return (
                          <BsStarFill
                            key   = { index}
                            size  = { 25}
                            color = { pedido.avaliacao === null ? color.Ofuscado : (index <= aval ? color.Avaliado : color.Ofuscado)}
                            style = {{marginRight : 5}}
                          />
                        );
                      })
                    }
                  </div>
                  <button
                    className = "btt-avaliar-pedido"
                    onClick   = {() => {
                      //Abrir modal de avaliação
                      if(!pedidoAvaliado){
                        if(pedidoRealizado){
                          setModalAv(true);
                        } else {
                          Swal.fire({
                            icon    : "warning",
                            title   : "Aviso",
                            text    : "Você só pode avaliar o pedido após a realização do mesmo",
                            confirmButtonText   : "Entendi",
                            confirmButtonColor  : "#DA5A34",
                          })
                        }
                      } else setModalAv(true);              
                    }}
                  >
                    {pedidoAvaliado ? "Ver avaliação completa" : "AVALIAR"}
                  </button>
                </div>
                */}
            {/* Componente de cliente */}
              <CompoInfo
                mode = {2}
              />
            {/* Componente de local */}
              <CompoInfo
                mode = {3}
              />
          </div>
          { (pedido.observacoes !== null && pedido.observacoes !== "") ?
            <div 
              className = "componente-de-observacao"
              style     = {pedidoRealizado ? {width: "60%"} : {width: "60%"}}
            >
              <h2>OBSERVAÇÃO</h2>
              <h3>{pedido.observacoes} Aqui temos algumas obssss</h3>
            </div> : <></>
          }
          {
            (pedido.mensalista_idmensalista !== null && pedido.status === "SOLICITADO") &&
            <div>
              <h3 className = "text-aviso">
                Para aprovar esse agendamento acesse o painel de horários fixos
              </h3>
            </div>
          }
          <div className = "contain-inferior-btts">
            { (pedido.mensalista_idmensalista !== null && date.perfilG.gerenciarMensalistas && pedido.status === "SOLICITADO") ?            
              <button
                className = "btt-inferior-rejeitar"
                onClick   = {() => {
                  setModal({...modal, viewCP: true});
                }}
              >
                <ClosedX
                  className = "icon-closed-x"
                />
                <h2>REJEITAR</h2>
              </button> :
              pedido.status === "SOLICITADO" && (pedido.formaPagamento === "MAQUINA_CARTAO" || pedido.formaPagamento === "DINHEIRO") && date.perfilG.cancelar && pedido.mensalista_idmensalista === null ?
              <button
                className = "btt-inferior-rejeitar"
                onClick   = {() => {
                  setModal({...modal, viewCP: true});
                }}
              >
                <ClosedX
                  className = "icon-closed-x"
                />
                <h2>REJEITAR</h2>
              </button> : <></>
            }
            { (pedido.usuario.nome !== pedido.usuario.usuario && date.perfilG.chat) && 
              <button
                className = "btt-inferior-chat"
                onClick   = {() => {AbrirChatPedido();}}
              >
                <IoMdChatbubbles
                  size  = {20}
                  style = {{ marginRight : 7}}
                />
                <h2>ABRIR CHAT</h2>
              </button>
            }
            { (pedido.mensalista_idmensalista === null && pedido.status === "SOLICITADO" && (pedido.formaPagamento === "MAQUINA_CARTAO" || pedido.formaPagamento === "DINHEIRO") && date.perfilG.aprovar) &&
              <button
                className = "btt-inferior-aprovar"
                onClick   = {() => {
                  setModal({...modal, viewAP: true});
                }}
              >
                <FaCheck
                  style = {{ marginRight : 7}}
                  size  = {20}
                />
                <h2>APROVAR</h2>
              </button>
            }
          </div>
        </div>
      }
    </>
  );
}
