import React          from "react";

import "./stylesBI.css";

export default function BttIcon({ Icon, text, onClick, selected, index, novo, payAss}) {

  return(
    <button
      className = { selected ? "contain-btt-sb-selected" : "contain-btt-sb" }
      style     = {index === 0 ? {borderTop: "1px solid #302D2A"} : {}}
      onClick   = { onClick }
    >
      <div className = "contain-btt-icon-sb">
        <Icon
          className   = { selected ? "icon-btt-sb-selected" : "icon-btt-sb" }
          size        = {25}
        />
        <h3 className = { selected ? "text-btt-sb-selected" : "text-btt-sb" }>
          {text}
        </h3>
      </div>
      {//*
        novo ?
        payAss ?     
        <h2 className = "text-new-function">
          Pagar
        </h2>
          :
        <h2 className = "text-new-function">
          Novo
        </h2>
          :
        <></>
      //*/
      }
    </button>
  );
}