import React            from "react";
import Switch           from "react-switch";
import { EmpresaAPI }   from "../../../servicesAPI";
import { AuthContext }  from "../../../contexts/AuthContext";

import "./stylesCDU.css";
import Swal from "sweetalert2";
import { Alerts, Loading } from "../../Basics";
import { Check } from "../../../assets";
import { BiCircle } from "react-icons/bi";

export default function CompDayUse({ diaria, onClick, checked }) {

  const { token, date }             = React.useContext(AuthContext);

  //console.log("sttDiaria: ", sttDiaria);
  

  return(
    <button
      className = "btt-comp-day-use"
      onClick   = {() => {
        onClick();
      }}
    >
      <div className = "btt-contain-labels">
        <h3 className = "txt-label-du">
          {diaria.titulo}
        </h3>
        <h3 
          className = "txt-status-du"
          style     = {{
            color: diaria.status ? "#23A820" : "#CC3939"
          }}
        >
          {diaria.status ? "ATIVA" : "INATIVA"}
        </h3>
      </div>
      <div className = "contain-dias-switch">
        <div className = "contain-array-dias">
          {
            JSON.parse(diaria.diaSemana).map((dia, index) => {
              return(
                <h3
                  key       = {index}
                  className = "text-dia-semana"
                >
                  {RetornaDiaSemana(dia)}
                </h3>
              )
            })
          }
        </div>
        {
          checked ?
          <Check
            className = "icon-check-select"
            style     = {{marginLeft: "3px"}}
          />
            :
          <BiCircle
            className = "icon-check-circle"
            style     = {{marginLeft: "3px"}}
          />
        }
      </div>
    </button>  

  );

  function RetornaDiaSemana(dia){
    switch(dia){
      case 1: return "Domingos";
      case 2: return "Segundas";
      case 3: return "Terças";
      case 4: return "Quartas";
      case 5: return "Quintas";
      case 6: return "Sextas";
      case 7: return "Sábados";
      default: return "Erro";
    }
  }
}