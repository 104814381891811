import React              from "react";
import { FaUser }         from "react-icons/fa";
import { AuthContext }    from "../../../contexts/AuthContext";
import moment             from "moment";
import { EmpresaAPI, PedidosAPI }     from "../../../servicesAPI";
import { 
  Alerts, Loading, SuperAv 
} from "../../Basics";
import { 
  BiChevronRight, BiLeftArrowAlt 
} from "react-icons/bi";
import { MdOutlineStar }  from "react-icons/md";
import RouterHook         from "../../../contexts/RouterHook";
import { LocalStorage }   from "../../../services";

import "./stylesAv.css";
import { notAgend } from "../../../assets";

export default function Avaliacoes() {

  const listAvsRef      = React.useRef(null); 
  const serverRouter    = RouterHook();
  const { token, date, modalOpen, handleMOP } = React.useContext(AuthContext);
  const base = {
    mediaQualidadeLocal       : 1.0,
    mediaQualidadaMateriais   : 1.0,
    mediaTempo                : 1.0,
  };
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : null,
    scroll      : 0,
    countAll    : true,
  };
  const [pag, setPag]           = React.useState(pagBuffer);
  const [loading, setLoading]   = React.useState(true);
  const [loadingC, setLoadingC] = React.useState(false);

  const [sttPage, setSttPage] = React.useState({
    erroComunic : false,
    medias      : base,
    listAvs     : [],
    modal       : null,
  }); 

  async function BuscaAvDaEmpresa(){
    const reqs = await EmpresaAPI.BuscarAvsDaEmpresa(token, date.empresa.idempresa, pag.limit, 0, true);
   //console.log("Reqs: Avaliacoes ", reqs);
    if(reqs.status){
      setPag({
        ...pag,
        offSet    : pag.limit,
        maxScroll : reqs.req.avaliacao.avaliacoes.count,
      });
      setSttPage({
        ...sttPage,
        medias  : reqs.req.avaliacao.medias,
        listAvs : reqs.req.avaliacao.avaliacoes.rows,
      })
      setLoading(false);
    } else {
      Alerts("Aviso", reqs.message);
    }
  }

  async function BuscaMaisAvsDaEmpresa(){
    const reqs = await EmpresaAPI.BuscarAvsDaEmpresa(token, date.empresa.idempresa, pag.limit, pag.offSet, true);
   //console.log("Reqs: Avaliacoes ", reqs);
    if(reqs.status){
      setPag({
        ...pag,
        offSet    : pag.offSet + pag.limit,
      });
      setSttPage({
        ...sttPage,
        listAvs : [...sttPage.listAvs ,...reqs.req.avaliacao.avaliacoes.rows],
      })
      setLoading(false);
    } else {
      Alerts("Aviso", reqs.message);
    }
  }
  function ReturnSttPage(){
    if(modalOpen){
      handleMOP(false);
    } else {
      serverRouter("back");
    }
  }
  React.useEffect(() => {
    ////console.log("ModalOpen: ", modalOpen);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);    
  }, [modalOpen]);
  React.useEffect(() => {
    BuscaAvDaEmpresa();
  }, []);

  // Controla o scroll da lista de clientes (Paginação)
  let flag1 = false;
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop = listAvsRef.current.scrollTop;
      const scrollHeight = listAvsRef.current.scrollHeight;
      const clientHeight = listAvsRef.current.clientHeight;
  
     //console.log("AS");
      const pg = {
          ...pag,
          scroll: scrollTop,
      }
      if (
          scrollHeight > clientHeight &&
          (scrollTop + clientHeight >= scrollHeight) &&
          !flag1 &&
          pag.maxScroll > pag.offSet
      ) {
          flag1 = true;
         //console.log("Scroll", pag);
          setLoadingC(true);
          BuscaMaisAvsDaEmpresa().then(() => {
              flag1 = false;
              setLoadingC(false);
          });
      }
    }
  
    if (listAvsRef.current) listAvsRef.current.addEventListener("scroll", handleScroll);
    return () => {
      if (listAvsRef.current) listAvsRef.current.removeEventListener("scroll", handleScroll);
    }
  }, [sttPage.listAvs, pag]);
  async function StartGoPedido(idpedido){
    setLoading(true);
    const ress = await PedidosAPI.BuscarPedidoId(token, idpedido);
    LocalStorage.set("pedidoDetails", {pedido: ress.req, page: "avaliacao"});
    serverRouter("details-appoint", idpedido);
    setLoading(false);
  }
  //console.log("Avaliacoes: ", listAvs);
  function CompAvUser({ item, idx }){
    //console.log("Item: ", item);
    return(
      <button 
        className = "contain-comp-av-user"
        style     = {idx === 0 ? {marginTop: "5px"}: {}}
        onClick   = {() => {
          StartGoPedido(item.pedido.idpedido);
          
        }}  
      >
        <div className = "contain-icon-user">
          <FaUser
            size  = {25}
            color = "#DA5A34"
          />
        </div>
        <div className = "contain-data-avaliacao-collum-intermed">
          <h3>{item.nome === null ? "Anonimo" : item.nome}</h3>
          <p>{item.texto}</p>
        </div>
        <div className = "contain-data-avaliacao-collum">
          <p>{`#${item.pedido.idpedido}`}</p>
          <h3>{moment(item.createdAt).format("DD/MM/YYYY")}</h3>
          <h4>
            <MdOutlineStar
              className = "icon-star-modal"
              style     = {{marginRight: "5px"}}
              size      = {20}
            />
            {parseFloat(item.notaGeral).toFixed(1).toString().replace(".",",")}
          </h4>
        </div>
        <BiChevronRight
          style = {{minWidth: "25px", minHeight: "25px"}}
          color = "#DA5A34"
        />
      </button>
    );
  }
 //console.log("SttPage: ", sttPage);
 //console.log("Date Empresa: ", date.empresa);
  return (
    <div className = "contain-agenda-page">
      
      <div className = "contain-filtro-agenda-hf">
        <BiLeftArrowAlt
          className = "icon-arrow-back-hf"
          onClick={() => {
            ReturnSttPage();
          }}
        />
        <h2 className = "title-me-agenda"> Avaliações </h2>
        {
          loading ? 
            <div className = "contain-loading" style = {{height: "400px"}}>
              <Loading 
                mode = {true}
                text = {"Carregando avaliações..."}
              />
            </div>
              :
            <div className = "contain-view-hora-fixa">
              <div className = "contain-filtra-hf">
                <div 
                  className = "contain-list-clientes"
                  style = {{minWidth: "340px"}}
                >
                  { (date.empresa.mediaAvaliacoes >= 4.5 && date.empresa.quantidadeAvaliacoes >= 20) &&
                    <>
                      <div className = "contain-selo-super-av">
                        <SuperAv />
                        <h3>
                          Parabéns, você conquistou o selo "Super Avaliado" para o 
                          perfil do seu Centro Esportivo no Agendei Quadras! 
                        </h3>
                      </div>
                      <div className = "line-border-bottom"/>
                    </>
                  }
                  <div className = "contain-resumo-avaliacoes-empresa">
                    <div className = "contain-nota-geral">
                      <h3>NOTA GERAL</h3>
                      <MdOutlineStar
                        className = "icon-star-modal"
                        size      = {25}
                      />
                      <h4>{date.empresa.mediaAvaliacoes.toFixed(1).toString().replace(".",",")}</h4>
                    </div>
                    <div className = "contain-line-dark"/>
                    <div className = "contain-parametros-avaliacao">
                      <div className = "contain-parametros-avaliacao-line">
                        <h3>Qualidade da quadra e ambiente</h3>
                        <>
                          <MdOutlineStar
                            className = "icon-star-modal"
                            size      = {20}
                          />
                          <h4>{sttPage.medias.mediaQualidadeLocal === null ? 0 : parseFloat(sttPage.medias.mediaQualidadeLocal).toFixed(2).toString().replace(".",",")}</h4>
                        </>
                      </div>
                      <div className = "contain-parametros-avaliacao-line">
                        <h3>Qualidade dos materiais</h3>
                        <>
                          <MdOutlineStar
                            className = "icon-star-modal"
                            size      = {20}
                          />
                          <h4>{sttPage.medias.mediaQualidadaMateriais === null ? 0 : parseFloat(sttPage.medias.mediaQualidadaMateriais).toFixed(2).toString().replace(".",",")}</h4>
                        </>
                      </div>
                      <div className = "contain-parametros-avaliacao-line">
                        <h3>Tempo de resposta</h3>
                        <>
                          <MdOutlineStar
                            className = "icon-star-modal"
                            size      = {20}
                          />
                          <h4>{sttPage.medias.mediaTempo === null ? 0 : parseFloat(sttPage.medias.mediaTempo).toFixed(2).toString().replace(".",",")}</h4>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className = "contain-list-avaliacoes">  
                  { sttPage.listAvs.length > 0 &&
                    <h2 className = "text-list-avs"> 
                      {`${pag.maxScroll} avaliações`}   
                    </h2>
                  }
                  <div 
                    ref       = {listAvsRef}
                    className = "contain-list-avaliacoes-overflow"
                    style     = {{height: `calc(${window.innerHeight}px - 290px)`}}
                  >
                    { sttPage.listAvs.length > 0 ?
                      sttPage.listAvs.map((av, idx) => {
                        return(
                          <>
                            <CompAvUser
                              key   = {idx}
                              idx   = {idx}
                              item  = {av}
                            />
                            {
                              (idx === sttPage.listAvs.length - 1 && loadingC) &&
                              <Loading mode={false}/>
                            }
                          </>
                        )
                      })
                        :
                      <div className = "contain-not-agend">
                        <img
                          style = {{height: "250px"}}
                          title = "Sem avaliações"
                          src   = {notAgend}
                          alt   = "Not avaliations"
                        />
                        <h2>
                          {"Este centro esportivo ainda não recebeu nenhuma avaliação."}
                        </h2>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  );
}
             