import React          from "react";
import { Services }   from "../../../services";
import moment         from "moment";

import "./stylesDia.css";

export default function Diaria({day, diaria, onClick, onClickA}) {
  //console.log("diaria", diaria);

  function ReturnMetodPay(){
    if(diaria.criadoPorGestor) return "Pagamento no local";
    const metod = diaria.formaPagamento;

    if(metod === "PIX")                 return "PIX";
    else if(metod === "CARTAO_CREDITO") return "Cartão de crédito";
    else if(metod === "DINHEIRO")       return "Dinheiro";
    else if(metod === "MAQUINA_CARTAO") return "Máquina de cartão";
    else return "Não definido";
  }
  //console.log("Aqui entrou!", diaria)
  return (
    <div className = "contain-diaria">
      <div className = "contain-diaria-superior border-botton-diaria-2">
        <div className="contain-diaria-interior">
          <h2>{diaria.dayuse.titulo}</h2>
          <h3 className = "text-status-cd" style={{color: RetornaColorSttDiaria(diaria.status)}}>
            {diaria.status}
          </h3>
        </div>
        <h3 className = "rotul-diaria-data-2">
          {`${Services.ReturnDiaSemana(3, day)}, ${Services.DataFormat("DD/MM/YY", diaria.data)}`}      
        </h3>
      </div>
      <div className = "contain-diaria-intermed-pays-2 border-botton-diaria-2">
        <div className = "contain-diaria-intermed-pays-2-line">
          <h3 className = "text-label-pagamento-di">
            PAGAMENTO
          </h3>
        </div>
        <div className = "contain-diaria-intermed-pays-2-line">
          <h3 className = "text-label-di">
            Forma de pagamento
          </h3>
          <h3 className="text-label-di">
            {`${ReturnMetodPay(diaria.formaPagamento)}`}
          </h3>
        </div>
        <div className = "contain-diaria-intermed-pays-2-line">
          <h3 className = "text-label-di">
            Valor Total
          </h3>
          <h3 className="text-label-di" style = {{fontFamily : "jost-mediun"}}>
            {`R$${diaria.valorTotal.toFixed(2).toString().replace(".",",")}`}
          </h3>
        </div>
        { 
          ((diaria.status === "AGENDADA" || diaria.status === "REALIZADA") && diaria.pagamento !== null) &&
          <div className = "contain-diaria-intermed-pays-2-line">
            <h3 className = "text-label-di">
              Taxa de processamento
            </h3>
            <h3 className="text-label-di" style = {{fontFamily : "jost-mediun", color: "red"}}>
              {`R$${diaria.pagamento.custoTotal.toFixed(2).toString().replace(".",",")}`}
            </h3>
          </div>
        }{
          (diaria.status === "REALIZADA") &&
          <div className = "contain-diaria-intermed-pays-2-line">
            <h3 className = "text-label-di" style = {{fontWeight: "bold", fontFamily: "jost-mediun"}}>
              Valor Líquido
            </h3>
            <h3 className="text-label-di" style = {{fontFamily : "jost-mediun", color: "#2cbb76", scale: "1.1", marginRight: "2px"}}>
              { diaria.pagamento !== null ?
                `R$${diaria.pagamento.valorLiquido.toFixed(2).toString().replace(".",",")}` :
                `R$${diaria.valorTotal.toFixed(2).toString().replace(".",",")}`
              }
            </h3>
          </div>
        }{
          (diaria.status === "CANCELADA" && diaria.valorEstornado > 0) &&
          <div className = "contain-diaria-intermed-pays-2-line">
            <h3 className = "text-label-di">
              Valor Estornado
            </h3>
            <h3 className="text-label-di" style = {{fontFamily : "jost-mediun", color: "#be3223"}}>
              {`R$${diaria.valorEstornado.toFixed(2).toString().replace(".",",")}`}
            </h3>
          </div>
        }{
          ((diaria.status === "SOLICITADA" || diaria.criadoPorGestor) && diaria.valorPendente > 0) &&
          <div className = "contain-diaria-intermed-pays-2-line">
            <h3 className = "text-label-di">
              Valor Pendente
            </h3>
            <h3 className="text-label-di" style = {{fontFamily : "jost-mediun", color: "#FFD700"}}>
              {`R$${diaria.valorPendente.toFixed(2).toString().replace(".",",")}`}
            </h3>
          </div>
        }{ diaria.valorRealizado > 0 &&
          <div className = "contain-diaria-intermed-pays-2-line">
            <h3 className = "text-label-di">
              Valor Pago
            </h3>
            <h3 className="text-label-di" style = {{fontFamily : "jost-mediun", color: "#2cbb76", scale: "1.1", marginRight: "2px"}}>
              {`R$${diaria.valorRealizado.toFixed(2).toString().replace(".",",")}`}
            </h3>
          </div>
        }
      </div>
      { 
        ((diaria.status === "AGENDADA" || diaria.status === "REALIZADA") && !diaria.criadoPorGestor) ?
        <div className = "contain-aviso-recebimento">
          <h3>
            { diaria.pagamento !== null ?
              `Previsão de recebimento do valor líquido na sua conta digital em ${moment(diaria.pagamento.dataRecebivel).format("DD/MM/YY HH:mm")}`
              :
              `Previsão de recebimento do valor em ${moment(diaria.data).add(1, "days").format("DD/MM/YY HH:mm")}`
            }
          </h3>
        </div> :
        <div className = "contain-aviso-recebimento" style = {{alignItems: 'flex-end'}}>
          <button 
            className = "btt-definir-valor-diaria"
            onClick = {() => {
              onClickA();
            }}
          >
            {
              diaria.valorRealizado > 0 ? "Alterar valor +" : "Definir valor +"
            }  
          </button>
        </div>
      }
      <div className = "contain-diaria-inferior">
        <h2>REGRAS DA DIÁRIA</h2>
        { diaria.criadoPorGestor ?
          <></>:
          <h3>Forma de pagamento: {(diaria.dayuse.formaPagamento.includes("PIX") && diaria.formaPagamento.includes("CARTAO_CREDITO")) ? "Pix ou Cartão de crédito" : (diaria.dayuse.formaPagamento.includes("PIX") ? "Pix" : "Cartão de crédito")}</h3>
        }
        <h3>Duração: {diaria.dayuse.duracao}</h3>
        { diaria.criadoPorGestor ?
          <></>:
          <h3>Cancelamento: {diaria.dayuse.usuarioPodeCancelar === "DIA" ? "Até o dia anterior" : (diaria.dayuse.usuarioPodeCancelar === "SEMPRE" ? "A qualquer momento" : "Não poderá cancelar")}</h3>
        }
        <h3>Válida para: {diaria.dayuse.quadra}</h3>
      </div>
      { diaria.status === "AGENDADA" &&
        <button
          className = "btt-cancel-diaria"
          onClick   = {() => {
            onClick();
          }}
        >
          Cancelar Diária
        </button>
      }
    </div>
  );

  function RetornaColorSttDiaria(stt){
    switch(stt){
      case "SOLICITADA" : return "#ff7043";
      case "AGENDADA"   : return "#22ac4a";
      case "CANCELADA"  : return "#bf3324";
      case "REALIZADA"  : return "#00cda4";
    }
  }
}