import React                from 'react'
import { 
  BiMap, BiCalendarCheck,
  BiCalendarHeart, BiCalendarX, BiX, BiCheck
} from "react-icons/bi";
import { CalClock }         from "../../../assets";
import { Services }         from '../../../services';
import moment               from 'moment';

import 'moment/locale/pt-br';
import "./stylesHC.css";
import { ChatContext } from '../../../contexts/ChatContext';

moment.locale('pt-br');

export default function HeaderChat({onClick}) {

  /*
    Gradiente do Header deve seguir o status do pedido:
    Cores dos status dos horários:
      colorSolicitado: '#ff7043',
      colorReservado: '#24ad49', -- Agendado
      colorRealizado: '#00cda4',
      colorCancelado: '#bf3324',
      colorSolicitadoAlpha: 'rgba(255, 112, 67, 0.3)',
      colorReservadoAlpha: 'rgba(36, 173, 73, 0.3)',
      colorRealizadoAlpha: 'rgba(0, 205, 164, 0.3)',
      colorCanceladoAlpha: 'rgba(191, 51, 36, 0.3)', 
  */

  const { states } = React.useContext(ChatContext);

  function ReturnContainHeader(status) {
    switch(status){
      case "SOLICITADO" : return "contain-header-chat btt-gradient-solicitado";
      case "AGENDADO"   : return "contain-header-chat btt-gradient-agendado";
      case "CANCELADO"  : return "contain-header-chat btt-gradient-cancelado";
      case "REALIZADO"  : return "contain-header-chat btt-gradient-realizado";
    }
  }

  //console.log("Chat: ", chat);
  if(states.modeCE){
    function ReturnFalta() {
      const falta = moment(states.chat_Sel.pedido.dataInicio).startOf("minute").fromNow().toUpperCase();
      switch(states.chat_Sel.pedido.status){
        case "SOLICITADO" : return (
          <div className = 'contain-header-chat-infos-line'>
            <CalClock
              size  = {25}
              color = "#ff7043"
              style = {{color:"#ff7043", width: "25px", height: "25px"}}
            />
            <h3 
              className = {"text-states-header"}
              style     = {{color: "#ff7043"}}  
            >
              {falta}
            </h3>
          </div>
        );
        case "AGENDADO" : return (
          <div className = 'contain-header-chat-infos-line'>
            <BiCalendarHeart
              size  = {25}
              color = "#24ad49"
            />
            <h3
              className = {"text-states-header"}
              style     = {{color: "#24ad49"}}
            >
              {falta}
            </h3>
          </div>
        );
        case "CANCELADO" : return (
          <div className = 'contain-header-chat-infos-line'>
            <BiCalendarX
              size  = {25}
              color = "#bf3324"
            />
            <h3
              className = {"text-states-header"}
              style     = {{color: "#bf3324"}}
            >
              {falta}
            </h3>
          </div>
        );
        case "REALIZADO" : return (
          <div className = 'contain-header-chat-infos-line'>
            <BiCalendarCheck
              size  = {25}
              color = "#00cda4"
            />
            <h3
              className = {"text-states-header"}
              style     = {{color: "#00cda4"}}
            >
              {falta}
            </h3>
          </div>
        );
  
  
      }
    }
    function ReturnColorStatus(status) {
      switch(status){
        case "SOLICITADO" : return <h3 style={{color: "#ff7043"}}>{status}</h3>;
        case "AGENDADO"   : return <h3 style={{color: "#22ac4a"}}>{status}</h3>;
        case "CANCELADO"  : return <h3 style={{color: "#bf3324"}}>{status}</h3>;
        case "REALIZADO"  : return <h3 style={{color: "#00cda4"}}>{status}</h3>;
      }
    }
    function ReturnSolicitou(pedido) {
      const falta = moment(pedido.createdAt).startOf("minute").fromNow().toLowerCase();
      return `Solicitou ${falta} por`;
    }
    function ReturnFormPay(metod) {
      switch(metod) {
        case "DINHEIRO":
          return "Dinheiro";
        case "MAQUINA_CARTAO":
          return "Máquina de cartão";
        case "PIX":
          return "PIX";
        case "CARTAO_CREDITO":
          return "Cartão de crédito";
        case "LOCAL":
          return "Local";
        case "Pagamento online":
          return "Pagamento Online";
        default:
          return "Outro";
      }
    }
    return (
      <div className = {ReturnContainHeader(states.chat_Sel.pedido.status)}>
        {
          /*!show ?
            <div className = 'contain-header-chat-zip'>
              <h3>{`Agendamento: #${states.chat_Sel.pedido.idpedido}`}</h3>
              <h3>{`${states.chat_Sel.pedido.empresa.nome}`}</h3>
              {ReturnColorStatus(states.chat_Sel.pedido.status)}
            </div>
              :*/
            <div className = 'contain-header-chat-ext'>
              <div className = 'contain-header-chat-infos left-header'>
                {ReturnFalta()}
                <h4>{`${Services.ReturnHoraMin(new Date(states.chat_Sel.pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(states.chat_Sel.pedido.dataFinal))}`}</h4>
                <h4>{`${Services.ReturnDay(new Date(states.chat_Sel.pedido.dataInicio).getDay())}, ${Services.ReturnToStringDate(new Date(states.chat_Sel.pedido.dataInicio))}`}</h4>
                <h4>{`${states.chat_Sel.pedido.quadra.descricao}`}</h4>
                { (states.chat_Sel.pedido.mensalista_idmensalista !== null) &&
                  <h2>FIXO</h2>
                }
                {ReturnColorStatus(states.chat_Sel.pedido.status)}
              </div>
              <div className = 'contain-header-chat-infos right-header'>
                
                <h3>{`${states.chat_Sel.pedido.usuario.nome}`}</h3>
                <h4>
                  {ReturnSolicitou(states.chat_Sel.pedido)}
                </h4>
                <h4>{`Nº #${states.chat_Sel.pedido.idpedido}`}</h4>
                <h4>{ReturnFormPay(states.chat_Sel.pedido.formaPagamento)}</h4>
                <p>{`${states.chat_Sel.pedido.valorTotal !== null ? (states.chat_Sel.pedido.valorDesconto > 0 ? (`R$${(states.chat_Sel.pedido.valorTotal - states.chat_Sel.pedido.valorDesconto).toFixed(2).toString().replace(".",",")}`) : (`R$${states.chat_Sel.pedido.valorTotal.toFixed(2).toString().replace(".",",")}`)) : "R$0,00"}`}</p>
              </div>
              { (states.chat_Sel.pedido.status === "SOLICITADO" && (states.chat_Sel.pedido.formaPagamento === "DINHEIRO" || states.chat_Sel.pedido.formaPagamento === "MAQUINA_CARTAO")) &&
                <div className = "contain-btt-acept-declined">
                <button
                  className   = "btt-declined"
                  onClick     = {() => {
                    onClick("DECLINED");
                  }}
                >
                  <BiX
                    size  = {30}
                    color = "white"
                  />
                </button>
                <button
                  className   = "btt-aceppt"
                  onClick     = {() => {
                    onClick("ACEPPT");
                  }}
                >
                  <BiCheck
                    size  = {30}
                    color = "white"
                  />
                </button>
                </div>
              }
            </div>
        }
        {/*<button
          className = 'btt-header-zip-ext-chat'
          onClick   = {() => setShow(!show)}
        >
          {
            !show ?
              <BiCaretDown
                size = {20}
              />
                :
              <BiCaretUp
                size = {20}
              />
          }
        </button>*/}
      </div>
    );
  } else {
    //console.log("Chat: ", states.chat_Sel);
    const usuario = states.chat_Sel.chat_empresa_usuario.usuario;
    return (
      <div className = {ReturnContainHeader("AGENDADO")}>          
          <div className = 'contain-header-chat-cliente'>
            <div className = 'contain-header-chat-cliente-title'>
              <h2>Cliente: </h2>
              <h3>{usuario.nome}</h3>
            </div>
            <h3>
              {usuario.username}
            </h3>
            <h4> 
              {// Colocar () no telefone 55999999999 -> (55) 99999-9999
                usuario.atleta ?
                usuario.atleta.telefone !== null &&
                usuario.atleta.telefone.length === 11 ?
                  `(${usuario.atleta.telefone.substring(0,2)}) ${usuario.atleta.telefone.substring(2,7)}-${usuario.atleta.telefone.substring(7,11)}`
                    :
                  usuario.atleta.telefone
                      :
                  "-- -- -- -- --" 
              }
            </h4>

          </div>
          <div className = 'contain-header-chat-'>

          </div>
      </div>
    );
  }
}
