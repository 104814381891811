import React        from "react";
import "./stylesSR.css";

export default function SeletoRadio({textA, textB, select, onClick}) {
  return (
    <button
      className   = "btt-seletor-div-radio"
      onClick     = {onClick}
    >
      <input
        className = "input-seletor-check-radio"
        type      = "radio"
        onChange  = {()=> {}}
        checked   = {select}
      />
      <div className = "contain-infos-select-radio">
        <label className = "select-text-radio-label"
        >{textA}</label>
        <h3 className = "select-text-radio-h3"
        >{textB}</h3>
      </div>
    </button>
  );
}