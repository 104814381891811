import React                          from "react";
import { BiChevronRight }             from "react-icons/bi";
import moment                         from "moment";

import "./stylesCDS.css";

export default function CompDiaSol({diaria, onClick}) {
  //  transforma JSON em objeto
  // //console.log("CompDiaSol.js - diariaPIX: ", diaria);
  // //console.log("diaria", diaria);
  // //console.log("diaria", diaria);

  function RetornaColorSttDiaria(stt){
    switch(stt){
      case "SOLICITADA" : return "#ff7043";
      case "AGENDADA"   : return "#22ac4a";
      case "CANCELADA"  : return "#bf3324";
      case "REALIZADA"  : return "#00cda4";
    }
  }

  function FormaPay(status){
    switch(status){
      case "CARTAO_CREDITO" : return "CARTÃO DE CRÉDITO";
      case "PIX"            : return "PIX";
      case "DINHEIRO"       : return "DINHEIRO";
      case "MAQUINA_CARTAO" : return "MÁQUINA DE CARTÃO";
      default               : return status;
    }
  }
  const falta = moment(diaria.createdAt).startOf("minute").fromNow();
  //console.log("diaria", diaria)
  return (
    <div className = "contain-topo-dia-sol">

      <button
        className = "btt-diaria-comp-sol"
        onClick   = {() => {
         //console.log("CompDiaSol.js - onClick: ");
          onClick()
        }}
      >
        <div className = "contain-primar-diaria">
          <p className = "text-solicitou-cd-sol">
            Diária - Solicitada {falta} 
          </p>
          <h3 className = "text-titulo-cd">
            {diaria.dayuse.titulo}
          </h3>
          <h3 
            className = "text-status-cd"
            style     = {{color: RetornaColorSttDiaria(diaria.status)}}
          >
            {diaria.status}
          </h3>
        </div>
        <div className = "contain-secundar-diaria-row">
          <div className = "contain-secundar-diaria-col">
            <h3 className = "text-nome-cd">
              {diaria.usuario.nome}
            </h3>
            <h3 className = "text-forma-pay-cd">
              {FormaPay(diaria.formaPagamento)}
              {diaria.status  === "SOLICITADA" && " (aguarde)"} 
            </h3>
            <h3 className = "text-pay-cd">
              {`R$${diaria.valorTotal.toFixed(2).toString().replace(".", ",")}`}
            </h3>
          </div>
        </div>
      </button>
    </div>
  );
}