import React                      from "react";
import { CompLink, WhatsPerson }  from "../../../assets";

import "./stylesCSB.css";

export default function CompCSB({ title, text, onClick, mode }) {

  return (
    <button
      className = {mode ? "contain-btt-csb-compartilha" : "contain-btt-csb-ajuda"}
      onClick   = {onClick}
    >
      {mode ? 
        <CompLink
          className = "icon-csb"
        />
          :
        <WhatsPerson
          className = "icon-csb"
        />
      }
      <div className = "contain-title-text-csb">
        <h2>{title}</h2>
        <label>{text}</label>
      </div>
    </button>
  );
}