import React              from "react";
import { 
  Alerts, ErroComunic, Loading 
} from "../../Basics";
import { 
  BiCircle, BiLeftArrowAlt
} from "react-icons/bi";
import { 
  Check,
  IconCupon,
  notAgend 
} from "../../../assets";
import { AuthContext }        from "../../../contexts/AuthContext";
import RouterHook             from "../../../contexts/RouterHook";
import { EmpresaAPI }         from "../../../servicesAPI";
import moment                 from "moment";

import "./stylesC.css";
import Swal from "sweetalert2";
import { LocalStorage } from "../../../services";

export default function Cupons() {

  const serverRouter                    = RouterHook();
  const { 
    date, token, modalOpen, handleMOP 
  } = React.useContext(AuthContext);
  
  const [load, setLoad]                 = React.useState({
    page      : true,
    arrayStt  : false,
  });

  const [sttPage, setSttPage]           = React.useState({
    erroComunic       : false,
    cuponsAtivos      : true,
    cupomSelect       : null,
    listCupons        : true, // meusCupons = true, disponiveis = false
    cupomSelect       : null,
    cupomSelectPermit : false,
  });

  async function BuscarCupons(){
    
    let req = null;
    if(sttPage.cuponsAtivos){
      req = await EmpresaAPI.BuscarCuponsVinculadosEmpresa( token, date.empresa.idempresa );
    } else {
      req = await EmpresaAPI.BuscarCuponsDisponiveisIngrsso( token, date.empresa.idempresa);
    }
    //console.log("Busca Cupons: ", sttPage.cuponsAtivos, req);

    if( req.status ){
      if(req.req.length > 0){
        const cpp = LocalStorage.get("cupomAtivo");
        if(cpp !== null){
          setSttPage({
            ...sttPage,
            // ordenar pelo updateAt do cupom
            cuponsEmpresa       : req.req.sort((a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)) ),
            cupomSelect         : req.req.filter(cupom => cupom.idcupomDesconto === cpp)[0],
            cupomSelectPermit   : moment(req.req.filter(cupom => cupom.idcupomDesconto === cpp)[0].dataFim).isAfter(moment()),
          });
          // Remove o cupom ativo do localStorage
          LocalStorage.remove("cupomAtivo");
        } else {
          setSttPage({
            ...sttPage,
            cuponsEmpresa       : req.req.sort((a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)) ),
            cupomSelect         : req.req[0],
            cupomSelectPermit   : moment(req.req[0].dataFim).isAfter(moment()),
          });
        }
      } else {
        setSttPage({
          ...sttPage,
          cuponsEmpresa       : [],
          cupomSelect         : null,
          cupomSelectPermit   : false,
        });
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: true,
      });
    }
    
    setLoad({
      ...load,
      page      : false,
      arrayStt  : false,
    });
  }

  React.useEffect(() => {
    BuscarCupons();
    document.title = "Cupons de Desconto | Gestor Agendei";
    //console.log("Modal Open: ", modalOpen)
  }, [sttPage.cuponsAtivos]);

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
  }, [sttPage.cuponsAtivos, modalOpen]);

  //console.log("Cupons: ", sttPage);
  
  async function DisponibilizarCupom(){
    setLoad({
      ...load,
      page      : true,
    });

    const req = await EmpresaAPI.AderirCupomEmpresa( token, date.empresa.idempresa, sttPage.cupomSelect.idcupomDesconto, date.gestor.nome );
    //console.log("Disponibilizar Cupom: ", req);
    if( req.status ){
      Alerts.sucesso( "Sucesso", "Cupom disponibilizado com sucesso!" );
      LocalStorage.set("cupomAtivo", sttPage.cupomSelect.idcupomDesconto);
      setSttPage({
        ...sttPage,
        cuponsAtivos: true,
      });
    } else {
      Alerts.aviso( "Aviso", "Não foi possível disponibilizar o cupom. Por favor, tente novamente mais tarde." );
    }
  }

  async function AtivarCupom(){
    setLoad({
      ...load,
      page      : true,
    });
    const req = await EmpresaAPI.ReativarCupomEmpresa( token, date.empresa.idempresa, sttPage.cupomSelect.idcupomDesconto );
    //console.log("Ativar Cupom: ", req);
    if( req.status ){
      Alerts.sucesso( "Sucesso", "Cupom ativado com sucesso!" );
      LocalStorage.set("cupomAtivo", sttPage.cupomSelect.idcupomDesconto);
      await BuscarCupons();
    } else {
      Alerts.aviso( "Aviso", "Não foi possível ativar o cupom. Por favor, tente novamente mais tarde." );
    }
  }

  async function DesativarCupom(){
    setLoad({
      ...load,
      page      : true,
    });
    const req = await EmpresaAPI.DesativarCupomEmpresa( token, date.empresa.idempresa, sttPage.cupomSelect.idcupomDesconto );    
    //console.log("Desativar Cupom: ", req);
    if( req.status ){
      Alerts.sucesso( "Sucesso", "Cupom desativado com sucesso!" );
      LocalStorage.set("cupomAtivo", sttPage.cupomSelect.idcupomDesconto);
      await BuscarCupons();
    } else {
      Alerts.aviso( "Aviso", "Não foi possível desativar o cupom. Por favor, tente novamente mais tarde." );
    }
  }

  function ReturnSttPage(){
    if(modalOpen){
      handleMOP(false);
    } else if(!sttPage.cuponsAtivos){
      setSttPage({
        ...sttPage,
        cuponsAtivos: true,
      });
    } else {
      serverRouter("back");
    }
  }

  return (
      <div className = "contain-agenda-page">
        <div className = "contain-filtro-agenda-hf">
          <BiLeftArrowAlt
            className = "icon-arrow-back-hf"
            onClick={() => {
              ReturnSttPage();
            }}
          />
          <h2 className = "title-me-agenda"> Cupons de Desconto </h2>
          { load.page ? 
            <div className = "contain-loading" style = {{height: "300px"}}>
              <Loading 
                mode  = {true}
                text  = { "Buscando cupons..." }  
              />
            </div>
              :
            sttPage.erroComunic ?
            <ErroComunic
              onClick = {() => {
                setSttPage({
                  ...sttPage,
                  erroComunic: false,
                });
              }}
            />
              :
            <div className = "contain-view-hora-fixa">
              <div className = "contain-filtra-hf" style = {{justifyContent: 'center'}}>
                <div className = "contain-list-clientes" style = {{minWidth: "450px", maxWidth: "500px"}}>
                  <div className = "contain-handle-peds-diars">
                    <button
                      className = { sttPage.cuponsAtivos ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
                      onClick   = {() => {
                        if(load.arrayStt || sttPage.cuponsAtivos) return;
                        setLoad({
                          ...load,
                          arrayStt  : true,
                        });
                        setSttPage({
                          ...sttPage,
                          cuponsAtivos  : true,
                        });
                      }}
                    >
                      MEUS CUPONS
                    </button>
                    <button
                      className = {!sttPage.cuponsAtivos ? "btt-select-ped-dia-select" : "btt-select-ped-dia"}
                      onClick   = {() => {
                        if(load.arrayStt || !sttPage.cuponsAtivos) return;
                        setLoad({
                          ...load,
                          arrayStt  : true,
                        });
                        setSttPage({
                          ...sttPage,
                          cuponsAtivos: false,
                        });
                      }}
                    >
                      DISPONÍVEIS
                    </button>
                  </div>
                  <h3 className = "text-label-cupons">
                    {sttPage.cuponsAtivos ? 
                      "Estes são os cupons da sua empresa. Você pode ativar ou desativar os cupons que desejar."
                      :
                      "Estes são os cupons disponíveis para você ativar."
                    }
                  </h3>
                  <div className = "line-border-bottom"/>
                  <div className = "contain-list-cupons">
                    {
                      load.arrayStt ? 
                      <div className = "contain-loading">
                        <Loading mode = {false}/>
                      </div>
                        :
                      sttPage.cuponsEmpresa.length === 0 ?
                      <div className = "contain-not-cupons">
                        <img
                          src   = {notAgend}
                          alt   = "Sem Cupons"
                          title = "Sem Cupons"
                        />
                        <h3 className = "text-not-cupons">
                          {sttPage.cuponsAtivos ? 
                            "Você ainda não possui cupons disponíveis em sua empresa. Disponibilize um cupom na aba 'DISPONÍVEIS' para que seus clientes possam aproveitá-lo."
                              :
                            "Não há cupons de desconto disponíveis no momento."
                          }
                        </h3>
                      </div> 
                        :
                      sttPage.cuponsAtivos ?
                      sttPage.cuponsEmpresa.map((cupom, index) => {
                        return (
                          <CuponsEmpresa
                            key         = {index}
                            cupom       = {cupom}
                            onClick     = {() => {
                              if(sttPage.cupomSelect === cupom) return;
                              setSttPage(prevStt => ({
                                ...prevStt,
                                cupomSelect           : cupom,
                                cupomSelectPermit     : moment(cupom.dataFim).isAfter(moment()),
                              }));
                            }}
                          />
                        )
                      }) 
                        :
                      sttPage.cuponsEmpresa.map((cupom, index) => {
                        return (
                          <CuponsEmpresaDisponiveis
                            key         = {index}
                            cupom       = {cupom}
                            onClick     = {() => {
                              if(sttPage.cupomSelect === cupom) return;
                              setSttPage(prevStt => ({
                                ...prevStt,
                                cupomSelect           : cupom,
                                cupomSelectPermit     : moment(cupom.dataFim).isAfter(moment()),
                              }));
                            }}
                          />
                        )
                      })
                    }
                  </div>
                </div>
                { sttPage.cupomSelect === null ?
                  <></>
                    :
                  sttPage.cuponsAtivos ? 
                  <div className = "contain-list-array-state">
                    { load.arrayStt ?
                      <div className = "contain-loading" style = {{height: "300px"}}>
                        <Loading
                          mode  = {false}
                        />
                      </div>
                        :
                      
                      <>
                        <h2 className = "text-title-cupom">
                          {`Cupom "${sttPage.cupomSelect.codigo}"`}
                        </h2>
                        <div className="line-border-bottom" style = {{maxWidth: '650px', margin: "var(--margin-low) 0px var(--margin-mid) 0px"}}/>
                        <div className = "contain-details-cupom">
                          <h2 
                            className = "text-cupom-aviso"
                            style = {sttPage.cupomSelect.isAtivo ? {color: VerificaAtivo(sttPage.cupomSelect) ? "#23A820" : "#FF7043", textAlign: 'center'} : {color: "#FF7043", textAlign: 'center'}}
                          >
                            {sttPage.cupomSelect.isAtivo ? 
                              VerificaAtivo(sttPage.cupomSelect) ?
                                "ATIVO"
                                  :
                                "INATIVO"
                              : "ENCERRADO"
                            }
                          </h2>
                          <div className = "contain-icon-text" style = {{width: 'auto', marginBottom: '3px'}}>
                            <IconCupon 
                              className = "icon-cupom"
                            />
                            <h2 style = {{width: 'auto'}}>
                              "{sttPage.cupomSelect.codigo}"
                            </h2>
                          </div>  
                          {MontaStrDesc(sttPage.cupomSelect.descricao, true)}
                          <h3 className = "text-aviso-date">
                            {`Válido de ${moment(sttPage.cupomSelect.dataInicio).format("DD/MM/YY")} às ${moment(sttPage.cupomSelect.dataInicio).format("HH:mm")} até ${moment(sttPage.cupomSelect.dataFim).format("DD/MM/YY")} às ${moment(sttPage.cupomSelect.dataFim).format("HH:mm")}`}
                          </h3>
                          <div className = "contain-regras-cupom">
                            <h2 className = "text-regras-cp">
                              REGRAS DO CUPOM
                            </h2>
                            <h3>
                              {`Valor de desconto: R$${sttPage.cupomSelect.valorDesconto.toFixed(2).replace(".", ",")}`}
                            </h3>
                            <h3>
                              {`Valor mínimo de agendamento: R$${sttPage.cupomSelect.valorMinimoPedido.toFixed(2).replace(".", ",")}`}
                            </h3>
                            <h3>
                              {`Limite de uso por cliente: ${sttPage.cupomSelect.limiteUsoPorUsuario === 1 ? "Uma única vez" : `${sttPage.cupomSelect.limiteUsoPorUsuario} vezes`}`}
                            </h3>
                            <h3>
                              {`Válido em: ${
                                sttPage.cupomSelect.habilitadoPagamentoLocal && sttPage.cupomSelect.habilitadoPagamentoOnline ? 
                                  "Pagamento no local e online" :
                                sttPage.cupomSelect.habilitadoPagamentoLocal ?
                                  "Pagamento no local" :
                                  "Pagamento online"                            
                              }`}
                            </h3>
                            <h3>
                              {`Válido para: ${
                                sttPage.cupomSelect.aplicarAvulso && sttPage.cupomSelect.aplicarMensalista ? 
                                  "Agendamentos avulsos e mensalistas" :
                                sttPage.cupomSelect.aplicarAvulso ?
                                  "Agendamentos avulsos" :
                                  "Agendamentos mensalistas"
                              }`}
                            </h3>
                          </div>
                        </div>
                        <div className="line-border-bottom" style = {{maxWidth: '650px', margin: "var(--margin-mid) 0px"}}/>
                        <div className = "contain-details-cupom">
                          { sttPage.cupomSelectPermit ?
                            <>
                              <h2 className = "text-title-resumo">
                                {
                                  VerificaAtivo(sttPage.cupomSelect) ?
                                    "ATIVO"
                                      :
                                    "INATIVO"
                                }
                              </h2>
                              <h2 className = "text-title-resumo" style = {{color: "var(--color-text-hig)"}}>
                                {date.empresa.nome}
                              </h2>
                              <h3 className = "text-aviso-resumo" style={{fontSize: "var(--text-size-h3)", margin: '0px'}}>
                                {QuantidadeUtilizada(sttPage.cupomSelect)}
                              </h3>
                              <h3 className = "text-aviso-resumo">
                                {AtivadoPor(sttPage.cupomSelect)}
                              </h3>
                              { VerificaAtivo(sttPage.cupomSelect) ?
                                <button
                                  className = "btt-action-cupom btt-desativar-cupom"
                                  onClick   = {() => {
                                    DesativarCupom();
                                  }}
                                >
                                  Desativar Cupom
                                </button>
                                  :
                                <button
                                  className = "btt-action-cupom btt-ativar-cupom"
                                  onClick   = {() => {
                                    AtivarCupom();
                                  }}
                                >
                                  Ativar Cupom
                                </button>
                              }
                            </>
                              :
                            <>
                              <h3 className = "text-aviso-resumo" style={{fontSize: "var(--text-size-h3)", marginTop: '10px'}}>
                                {QuantidadeUtilizada(sttPage.cupomSelect)}
                              </h3>
                              <h3 className = "text-aviso" style = {{marginBottom: "10px"}}>
                                Cupom com o prazo de validade expirado
                              </h3>
                            </>
                          }
                        </div>
                      </>
                    }
                  </div>
                    :
                  <div className = "contain-list-array-state">
                    { load.arrayStt ?
                      <div className = "contain-loading" style = {{height: "300px"}}>
                        <Loading
                          mode  = {false}
                        />
                      </div>
                        :
                      <>
                        <h2 className = "text-title-cupom">
                          {`Cupom "${sttPage.cupomSelect.codigo}"`}
                        </h2>
                        <div className="line-border-bottom" style = {{maxWidth: '650px', margin: "var(--margin-low) 0px var(--margin-mid) 0px"}}/>
                        <div className = "contain-details-cupom">
                          
                          <div className = "contain-icon-text" style = {{width: 'auto', marginBottom: '3px'}}>
                            <IconCupon 
                              className = "icon-cupom"
                            />
                            <h2 style = {{width: 'auto'}}>
                              "{sttPage.cupomSelect.codigo}"
                            </h2>
                          </div>  
                          {MontaStrDesc(sttPage.cupomSelect.descricao, true)}
                          <h3 className = "text-aviso-date">
                            {`Válido de ${moment(sttPage.cupomSelect.dataInicio).format("DD/MM/YY")} às ${moment(sttPage.cupomSelect.dataInicio).format("HH:mm")} até ${moment(sttPage.cupomSelect.dataFim).format("DD/MM/YY")} às ${moment(sttPage.cupomSelect.dataFim).format("HH:mm")}`}
                          </h3>
                          <div className = "contain-regras-cupom">
                            <h2 className = "text-regras-cp">
                              REGRAS DO CUPOM
                            </h2>
                            <h3>
                              {`Valor de desconto: R$${sttPage.cupomSelect.valorDesconto.toFixed(2).replace(".", ",")}`}
                            </h3>
                            <h3>
                              {`Valor mínimo de agendamento: R$${sttPage.cupomSelect.valorMinimoPedido.toFixed(2).replace(".", ",")}`}
                            </h3>
                            <h3>
                              {`Limite de uso por cliente: ${sttPage.cupomSelect.limiteUsoPorUsuario === 1 ? "Uma única vez" : `${sttPage.cupomSelect.limiteUsoPorUsuario} vezes`}`}
                            </h3>
                            <h3>
                              {`Válido em: ${
                                sttPage.cupomSelect.habilitadoPagamentoLocal && sttPage.cupomSelect.habilitadoPagamentoOnline ? 
                                  "Pagamento no local e online" :
                                sttPage.cupomSelect.habilitadoPagamentoLocal ?
                                  "Pagamento no local" :
                                  "Pagamento online"                            
                              }`}
                            </h3>
                            <h3>
                              {`Válido para: ${
                                sttPage.cupomSelect.aplicarAvulso && sttPage.cupomSelect.aplicarMensalista ? 
                                  "Agendamentos avulsos e mensalistas" :
                                sttPage.cupomSelect.aplicarAvulso ?
                                  "Agendamentos avulsos" :
                                  "Agendamentos mensalistas"
                              }`}
                            </h3>
                          </div>
                        </div>
                        <div className="line-border-bottom" style = {{maxWidth: '650px', margin: "var(--margin-mid) 0px"}}/>
                        <div className = "contain-details-cupom">
                          <h2 className = "text-title-resumo">
                            Resumo
                          </h2>
                          <h3 className = "text-aviso-resumo">
                            {ReturnResumoCupom(sttPage.cupomSelect)}
                          </h3>
                          <button
                            className = "btt-action-cupom btt-ativar-cupom"
                            style     = {{width: 'auto', padding: '10px 25px', fontSize: '16px'}}
                            onClick   = {() => {
                              DisponibilizarCupom();
                            }}
                          >
                            Disponibilizar Cupom
                          </button>    
                        </div>
                      </>
                    }
                  </div>
                }
                
              </div>
            </div>
          }
        </div>
      </div>
  );

  function CuponsEmpresa({cupom, onClick}){

    return (
      <button 
        className = {sttPage.cupomSelect.idcupomDesconto === cupom.idcupomDesconto ? "btt-contain-cupom border-select" : "btt-contain-cupom"}
        onClick   = {onClick}
      >
        { !cupom.isAtivo &&
          <div className = "contain-cupom-off-fx"/>
        }
        <div className = "contain-infos-btt">
          <h2 
            className = "text-cupom-aviso"
            style = {cupom.isAtivo ? {color: VerificaAtivo(cupom) ? "#23A820" : "#FF7043"} : {color: "#FF7043"}}
          >
            {cupom.isAtivo ?  
              VerificaAtivo(cupom) ?
                "ATIVO" 
                  :
                "INATIVO"
                : 
              "ENCERRADO"
            }
          </h2>
          <div className = "contain-icon-text">
            <IconCupon 
              className = "icon-cupom"
            />
            <h2>
              "{cupom.codigo}"
            </h2>
          </div>
          {MontaStrDesc(cupom.descricao)}
          <h2 className = "text-ver-regras">
            {QuantidadeUtilizada(cupom)}
          </h2>
        </div>
        <div className = "line-height"/>
        <div className = "contain-cupom-valores">
          <div className = "contain-cupom-valores-inter">
            <h2>
              R${cupom.valorDesconto.toFixed(2).replace(".", ",")}
            </h2>
            <h2>OFF</h2>
          </div>
          <div className = "contain-cupom-valores-inter">
            <h3>
              Válido até
            </h3>
            <h3>
              {moment(cupom.dataFim).format("DD/MM/YY")}
            </h3>
          </div>
        </div>
        <div className = "contain-cupom-fixed-select">
          { sttPage.cupomSelect.idcupomDesconto === cupom.idcupomDesconto ?
            <Check
              className = "icon-check-select"
            />
              :
            <BiCircle
              className = "icon-check-circle"
            />
          }
        </div>
      </button>
    );
  }

  function CuponsEmpresaDisponiveis({cupom, onClick}){

    return (
      <button 
        className = {sttPage.cupomSelect.idcupomDesconto === cupom.idcupomDesconto ? "btt-contain-cupom border-select" : "btt-contain-cupom"}
        onClick   = {onClick}
      >
       
        <div className = "contain-infos-btt">
          <h2 
            className = "text-cupom-aviso"
            style = {{color: "#6693E4", fontSize: '14px'}}
          >
            {'DISPONÍVEL PARA ATIVAÇÃO'}
          </h2>
          <div className = "contain-icon-text">
            <IconCupon 
              className = "icon-cupom"
            />
            <h2>
              "{cupom.codigo}"
            </h2>
          </div>
          {MontaStrDesc(cupom.descricao)}
          <h2 className = "text-ver-regras">
            Quantidade disponibilizada: {cupom.quantidadeDisponibilizada}
          </h2>
        </div>
        <div className = "line-height"/>
        <div className = "contain-cupom-valores">
          <div className = "contain-cupom-valores-inter">
            <h2>
              R${cupom.valorDesconto.toFixed(2).replace(".", ",")}
            </h2>
            <h2>OFF</h2>
          </div>
          <div className = "contain-cupom-valores-inter">
            <h3>
              Válido até
            </h3>
            <h3>
              {moment(cupom.dataFim).format("DD/MM/YY")}
            </h3>
          </div>
        </div>
        <div className = "contain-cupom-fixed-select">
          { sttPage.cupomSelect.idcupomDesconto === cupom.idcupomDesconto ?
            <Check
              className = "icon-check-select"
            />
              :
            <BiCircle
              className = "icon-check-circle"
            />
          }
        </div>
      </button>
    );
  }

  function AtivadoPor(cupom){
    const hasEmpresa = cupom.empresas.filter(empresa => empresa.idempresa === date.empresa.idempresa);
    //console.log("Ativado Por: ", hasEmpresa);
    const str = `Ativado por: ${hasEmpresa[0].cupomDesconto_has_empresa.ativadoPor} em ${moment(hasEmpresa[0].cupomDesconto_has_empresa.createdAt).format("dddd, DD [de] MMMM [de] YYYY [às] HH:mm")}`;

    return str;
  }

  function ReturnResumoCupom(cupom){
    const dataInicio = new Date(cupom.dataInicio).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    const dataFim = new Date(cupom.dataFim).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    const str =  `Ao clicar no botão, serão disponibilizados ${cupom.quantidadeDisponibilizada} cupons 
                  de desconto no valor de R$${cupom.valorDesconto},00 para utilizar em 
                  todas as quadras de sua empresa, com validade de ${dataInicio} até ${dataFim}. 
                  Ao disponibilizar este cupom você concorda em assumir esse desconto em seus horários.`;
  
    return  str;

  }

  function MontaStrDesc(str, mode){
    const text = str.split("\r\n");
    if(mode) {
      return text.map((txt, index) => {
        return (
          <p key = {index} className = "text-cupom-desc" style = {mode ? {width: 'auto', textAlign: 'center', marginBottom: index === 0 ? "15px" : "0px"} : {}}>
            {txt}
          </p>
        )
      });
    } else {
      return (
        <p className = "text-cupom-desc">
          {text[0]}
        </p>
      );
    }
  }

  function VerificaAtivo(cupom){
    if(!cupom.isAtivo) return false;
    const hasEmpresa = cupom.empresas.filter(empresa => empresa.idempresa === date.empresa.idempresa);
    return hasEmpresa[0].cupomDesconto_has_empresa.status
  }

  function QuantidadeUtilizada(cupom){
    const hasEmpresa = cupom.empresas.filter(empresa => empresa.idempresa === date.empresa.idempresa);
    const str = `Quantidade utilizada: ${hasEmpresa[0].cupomDesconto_has_empresa.quantidadeUtilizada}/${cupom.quantidadeDisponibilizada}`;
    return str;
  }
}
