
const ChatAPI = {
  AtualizarChatUsuario  : async ( token, idempresa ) => {
    const ress = await atualizar_caixa_entrada( token, idempresa );
    //console.log("RESS", ress);
    if(ress.status) return {status : true, req : ress.res};
    else return {status : false, req : ress};
  },
  AtualizarChatEmpresa  : async ( token, idempresa ) => {
    const ress = await atualizar_caixa_entrada( token, idempresa );
    //console.log("RESS", ress);
    if(ress.status) return {status : true, req : ress.res};
    else return {status : false, req : ress};
  },
  AtualizarStsMsgLida   : async ( token, idchat ) => {
    const ress = await mensagem_lida( token, idchat );
    if(ress){
      return {status : true, ress};
    } else {
      //console("Não há mensagens");
      return {status : false, ress};
    }
  },
  BuscarCaixaEntrada    : async ( token, idempresa, limit, offset, countAll ) => {
    const req = await buscar_caixa_entrada(token, idempresa, limit, offset, countAll);
    if(req.status){
      return { status: true, req: req.req }
    } else {
      return { status: false, message: req.message, req: req.req }
    }
  },
  BuscarChatId          : async ( token, idchat ) => {
    const ress = await buscar_chat_id( token, idchat );
    if(ress){
      return {status : true, ress};
    } else {
      //console.log("Não há mensagens");
      return {status : false, ress};
    }
  },
  SendMensChat          : async ( token, id, msg, name, modeCE ) => {
    let ress = null;
    if(modeCE)  ress = await enviar_mensagem( token, id, msg, name );
    else        ress = await enviar_mensagem_ceu( token, id, msg, name );
    if(ress){
      return { status : true, ress };
    } else {
      //console("Não há mensagens");
      return { status : false, ress };
    }
  },
  BuscarChatEmpresaUsuario : async ( token, idempresa, idusuario ) => {
    const ress = await buscar_chat_empresa_usuario( token, idempresa, idusuario );
    if(ress.status){
      return {status : true, req: ress.retorno};
    } else {
      //console.log("Não há mensagens");
      return {status : false, ress};
    }
  },
  BuscarCaixaEntradaEmpresaUsuario : async ( token, idempresa, limit, offset, countAll ) => {
    const ress = await buscar_ce_empresa_usuario( token, idempresa, limit, offset, countAll );
    //console.log("RESS", ress);
    if(ress){
      return {status : true, rows: ress.chat.rows, count : ress.chat.count};
    } else {
      //console.log("Não há mensagens");
      return {status : false, ress};
    }
  },
  BuscarChatIdEmpresaUsuario : async ( token, idchat, idempresa ) => {
    const ress = await buscar_chat_id_empresa_usuario( token, idchat, idempresa );
    if(ress){
      return {status : true, chat: ress.chat};
    } else {
      //console.log("Não há mensagens");
      return {status : false, ress};
    }
  },
}


export default ChatAPI;

// Atualizar Caixa de Entrada
const atualizar_caixa_entrada = async ( token, idempresa ) => {
  try{
    //console.log("id: ", id);
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarChatNaoLidoEmpresa/?id=${idempresa}&newVersion=${true}&isGestor=${true}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("atualizar_caixa_entrada: ", res);
    return {status : true, res: res}
  } catch(e){
    return {status : false, res: e}
  }
};

// Buscar Caixa de Entrada
const buscar_caixa_entrada    = async ( token, idempresa, limit, offset, countAll ) => {
  try{
    /*idusuario, limit, offset, countAll
      //console.log("Chamada na api: ");
      //console.log("idusuario: ", idusuario);
      //console.log("limit: ", limit);
      //console.log("offset: ", offset); 
      //console.log("countAll: ", countAll);
    //*/
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarPorEmpresaCaixaEntrada/?id=${idempresa}&limit=${limit}&offset=${offset}&countAll=${countAll}&versaoApp=${38}&isGestor=${true}`;
    const req = await fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Application": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const res = await req.json();
    return { status: true, req: res }
  } catch (er) {
    return { states: false, message: "Erro ao buscar caixa de entrada", req: er }
  }
};

// Buscar Chat por id
const buscar_chat_id          = async ( token, idchat ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarPorId/?id=${idchat}&isGestor=${true}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    return await req.json();
  } catch(error){
    return {states : false, message : "Erro ao buscar chat", error}
  }
};

// Enviar mensagem
const enviar_mensagem         = async ( token, id, msg, name ) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/chat/novaMensagem/?isGestor=${true}`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify({
        idchat : id,
        mensagem : {
          texto           : msg,
          enviadoPor      : name,
          criadaPorAdmin  : true,
          chat_idchat     : id 
        }
      })
    });
    return await req.json();
  } catch (error) {
    return {states : false, message : "Erro ao enviar mensagem", error}
  }
};

// Enviar mensagem entre empresa e usuario
const enviar_mensagem_ceu     = async ( token, id, msg, name ) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/chat/novaMensagemChatEmpresaUsuario/?isGestor=${true}`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify({
        idchat : id,
        mensagem : {
          chat_idchat     : id,
          texto           : msg,
          enviadoPor      : name,
          criadaPorAdmin  : true,
        }
      })
    });
    return await req.json();
  } catch (error) {
    return {states : false, message : "Erro ao enviar mensagem", error}
  }
};

// Atualizar status de mensagem lida
const mensagem_lida           = async ( token, idchat ) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/chat/mensagensLidas/?isGestor=${true}`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify({
        idchat          : idchat,
        lidaPeloGestor  : true
      })
    });
    if(req.status === 200) return true;
    else return false;
  } catch (error) {
    return false;
  }
};

// Buscar Chat entre Empresa e Usuario
const buscar_chat_empresa_usuario = async ( token, idempresa, idusuario ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarChatEmpresaUsuario/?isGestor=${true}`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify({
        idempresa   : idempresa,
        idusuario   : idusuario,
        abertoPor   : "gestor"
      })
    });
    return await req.json();
  } catch(error){
    return {states : false, message : "Erro ao buscar chat", error}
  }
};

// buscar caixa de entrada dos chats entre empresa e usuario
const buscar_ce_empresa_usuario = async ( token, idempresa, limit, offset, countAll ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarPorEmpresaCaixaEntradaClientes/?idempresa=${idempresa}&limit=${limit}&offset=${offset}&countAll=${countAll}&isGestor=${true}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    return await req.json();
  } catch(error){
    return {states : false, message : "Erro ao buscar caixa de entrada", error}
  }
};

// buscar chat por id entre empresa e usuario
const buscar_chat_id_empresa_usuario = async ( token, idchat, idempresa ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/chat/buscarChatIdEmpresaUsuario/?idchat=${idchat}&idempresa=${idempresa}&isGestor=${true}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    return await req.json();
  } catch(error){
    return {states : false, message : "Erro ao buscar chat", error}
  }
};