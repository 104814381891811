import React    from "react";
import { BiX }  from "react-icons/bi";
import "./stylesCX.css";

export default function ClosedX({ onClick, color }) {
  return (
    <button
      className = "contain-closedX"
      onClick   = {onClick}
    >
      <BiX
        size = {25}
      />
    </button>
  );
}