import React            from "react";
import { BsStarFill }   from "react-icons/bs";

import "./stylesSAv.css";

export default function SuperAv() {

  return(
    <div 
      className = "contain-super-av"
    >
      <BsStarFill
        size  = {20}
      />
      <h3>Super avaliado</h3>
    </div>
  );
}
