import React                              from "react";
import { 
  SeletorPay, Loading, InputIcon, Alerts
} from "../../Basics";
import { ListClients }                    from "../../Complext";
import { RiUserSearchLine }               from "react-icons/ri";
import { BiMoneyWithdraw, BiUserCheck }   from "react-icons/bi";
import Swal                               from "sweetalert2";
import { iconsPayment }                   from "../../../assets";
import { AuthContext }                    from "../../../contexts/AuthContext";
import { EmpresaAPI }                     from "../../../servicesAPI";
import { LocalStorage, Services }         from "../../../services";
import moment                             from "moment";

import "./stylesFCD.css";

export default function FormCheckoutDiaria({diaria, handleStt, dataSel}) {
  // Contextos
  const { 
    token, date, modalOpen, handleMOP 
  } = React.useContext(AuthContext);
  // Variáveis de controle  
  const [modalCC, setModalCC]             = React.useState(false);  // Modal Cartão de Crédito!
  const [stt, setState]                   = React.useState(true);  // false = não pode realizar o agendamento, true = pode realizar o agendamento
  const [cupomStt, setCupomStt]           = React.useState(null);   // null = não tem cupom aplicado, string = tem cupom aplicado

  const [loading, setLoading]             = React.useState(false);  // false = não está carregando, true = está carregando
  const [cupom, setCupom]                 = React.useState(null);   // Cupom de desconto 
  
  // Valores do pedido  
  const [valorT, setValorT]               = React.useState(diaria.valor); // Valor total
  const [valorS, setValorS]               = React.useState(diaria.valor); // Valor de sinal
  
  // Modos de pagamento         
  const [selTotal, setSelTotal]           = React.useState(true);
  // Formas de pagamento          
  const [selPix, setSelPix]               = React.useState(false);
  const [selCredCard, setSelCredCard]     = React.useState(false);
  const [selCedulas, setSelCedulas]       = React.useState(true);
  const [selMaqCard, setSelMaqCard]       = React.useState(false);
  const [metodPay, setMetodPay]           = React.useState(null);
  const [addClient, setAddCliente]        = React.useState(false);   // Cliente criado
  const client = {
    nome      : "",
    telefone  : "",
  }
  const [newCliente, setNewCliente]       = React.useState(client); // Cliente criado

  const [sttPage, setSttPage]             = React.useState({
    modalNC : false,
    filter  : "",
  });
  const [cliente, setCliente]             = React.useState(null);
  
  // Função que escuta modalOpen
  React.useEffect(() => {
    if(!modalOpen) {
      if(modalCC) setModalCC(false);
    }
  }, [modalOpen]);

  // Função que escuta modalCC
  React.useEffect(() => {
    if(modalCC) handleMOP(true);
    else        handleMOP(false);
  }, [modalCC]);


  //console.log("Diaria", diaria);
  async function ConfirmaCheckinDiaria(){
    // Chamar a rota da diária para realizar o checkin
    setLoading(true);
    //console.log("Confirma Diária...");
    const formaPay = {
      metodPay : "LOCAL",
      transacao: metodPay,  //metodPay,
    }
    //*
    const reqs = await EmpresaAPI.SolicitarDiaria(token, cliente.idusuario, date.gestor.nome, moment(dataSel), formaPay, diaria);
    //console.log("Diaria Reqs", reqs);
    if(reqs.status){  // Diária solicitada com sucesso!
      if(reqs.req.retorno){
        Alerts.sucesso("Sucesso", "Diária marcada com sucesso");
        handleStt(reqs.req.registro);
      } else {
        Alerts.aviso("Aviso", "Não foi possível marcar a diária, tente novamente mais tarde");
      }
    } else {          // Erro ao solicitar diária!
      Alerts.aviso("Aviso", "Não foi possível marcar a diária, tente novamente mais tarde");
    }
    //*/
    setLoading(false);
  }

  //console.log("HorarioFix", hrFixo);
  //console.log("diaria", diaria);
  //console.log("Empresa", empresa);
  
  // Função que valida o cupom de desconto
  async function ValidarCupom(codigo){ 
    // Verificar se o fluxo para cupom na diária é o mesmo da marcação de horarios

  }
  // Função que retorna a regra de cancelamento
  function Regras(){
    return Swal.fire({
      icon  : 'info',
      title : 'Regra de Cancelamento',
      text: diaria.usuarioPodeCancelar === "DIA" ? "Até o dia anterior" : (diaria.usuarioPodeCancelar === "SEMPRE" ? "Você pode cancelar a qualquer momento" : "Não poderá cancelar"),
      timer : 6000,
      confirmButtonText: 'Entendi',
      confirmButtonColor: '#DA5A34',
    })
  }
  //console.log("Diaria", diaria);
  // Função que remove o cupom de desconto
  function RemoveCupom(){
    setCupomStt(null);
    setCupom(null);
    setValorT(diaria.valor);
    setValorS(diaria.valor);
  }
  // Métodos de verificação de tipo de pagamento e tipo de pedido
  // Verifica os parametros para solicitar um agendamento!!!
   function VerificaAgendamentoOk(){
    if((selCedulas || selMaqCard) && cliente){
      if( stt ) return "btt-contain-agendar";
    }
    return "btt-contain-agendar-disable";
  }

  // Define o método de pagamento e o tipo de pagamento, criando o objeto forma de pagamento
  function SelectFormaPagamento(valor, id, ctk){
    switch(valor){
      case 0: // Pix
        setMetodPay({
          tipo                : "PIX",
          payment_method_id   : 6,
        })
        break;
      case 1: // Cartão de Crédito
        setMetodPay({
          tipo                : "CARTAO_CREDITO",
          payment_method_id   : id,
          card_token          : ctk,
        });
        setSelCredCard(true);
        setState(true);
        break;
      case 2: // Cedulas
        setMetodPay({
          tipo                : "DINHEIRO",
        });
        break;
      case 3: // Maquina de cartão
        setMetodPay({
          tipo                : "MAQUINA_CARTAO",
        });
        break;
        default: 
          return Swal.fire({
            title               : "Atenção!",
            text                : "Selecione uma forma de pagamento!",
            icon                : "warning",
            confirmButtonText   :  "Entendi",
            confirmButtonColor  : "#DA5A34",
          });
    }
  }

  // Função que seleciona a forma de pagamento
  function SelectionFormPay(value){
    /* Apenas uma forma de pagamento pode estar selecionada! */
    if(cupomStt !== null){
      Swal.fire({
        title               : "Atenção!",
        text                : "O cupom de descconto aplicado será removido, tente aplicar novamente após alterar a forma de pagamento. Deseja prosseguir?",
        icon                : "warning",
        confirmButtonText   : "Sim",
        confirmButtonColor  : "#DA5A34",
        showCancelButton    : true,
        cancelButtonText    : "Não",
        cancelButtonColor   : "gray",
      }).then((result) => {
        if (result.isConfirmed) {
          setMetodPay(null);
          RemoveCupom();
        } else {
          return;
        }
      });
    }

    switch(value){
      case 1:
        if(selPix){ 
          setSelPix(false);
          setState(false);
        } else {
          if(selCredCard) setSelCredCard(false);
          if(selCedulas)  setSelCedulas(false);
          if(selMaqCard)  setSelMaqCard(false);
          setSelPix(true);
          SelectFormaPagamento(0);
          setState(true);
        }
        break;
      case 2:
        if(selCredCard){ 
          setSelCredCard(false);
          setState(false);
        } else {
          if(selPix)      setSelPix(false);
          if(selCedulas)  setSelCedulas(false);
          if(selMaqCard)  setSelMaqCard(false);
          // Ambiente Produção!
          setModalCC(true);
          /* Ambiente teste!
          setSelCredCard(true);
          setState(true);
          //*/
        }
        break;
      case 3:
        if(selCedulas){ 
          setSelCedulas(false);
          setState(false);
        } else {
          if(selPix)      setSelPix(false);
          if(selCredCard) setSelCredCard(false);
          if(selMaqCard)  setSelMaqCard(false);
          setSelCedulas(true);
          SelectFormaPagamento(2);
          setState(true);
        }
        break;
      case 4:
        if(selMaqCard){
          setSelMaqCard(false);
          setMetodPay(null);
          setState(false);
        } else {
          if(selPix)      setSelPix(false);
          if(selCredCard) setSelCredCard(false);
          if(selCedulas)  setSelCedulas(false);
          setSelMaqCard(true);
          SelectFormaPagamento(3);
          setState(true);
        }
        break;
        default:
          //console.log("Erro ao selecionar forma de pagamento! //console default do switch SelectionFormPay!")
          break;
    }
  } 

  // Funções organizadoras de código, renderiza forma de pagamento!
  function RenderFormasPagamento(){
    // Quando o horarios é avulso!
    return(
      <>          
        { //diaria.formaPagamento.includes("DINHEIRO") &&
          <SeletorPay
            Icon      = {iconsPayment.payColor}
            textA     = "Dinheiro"
            textB     = "Pague no local"
            mode      = {false}
            select    = {selCedulas}
            onClick   = {() => SelectionFormPay(3)}
          />
        }
        { //diaria.formaPagamento.includes("MAQUINA_CARTAO") &&
          <SeletorPay
            Icon      = {iconsPayment.payTerminal}
            textA     = "Maquina de Cartão"
            textB     = "Pague no local"
            mode      = {false}
            select    = {selMaqCard}
            onClick   = {() => SelectionFormPay(4)}
          />
        }
      </>
    ); 
  
  }

  return(
    <div className = "contain-form-checkout-diaria" style = {{maxHeight: '600px'}}>
      {/*
        sttPage.modalNC &&
        <ModalInfos
          modal             = { sttPage.modalNC }
          ClosedModal       = { () => setSttPage({...sttPage, modalNC: false}) }
          Component         = {(
            <>
              <h3 className = "text-title-modal">
                Criar Cliente
              </h3>
              <h4 className = "text-subtitle-modal">
                Defina Nome e Contato para o novo cliente
              </h4>
              <input
                className     = "input-new-client"
                placeholder   = "Nome do cliente"
                value         = {newCliente.nome}
                onChange      = {(e) => setNewCliente({...newCliente, nome: e.target.value})}
              />
              <input
                className     = "input-new-client"
                placeholder   = "Telefone do cliente (opcional)"
                value         = {newCliente.telefone}
                onChange      = {(e) => {
                  if(e.target.value.length > 15) return;
                  setNewCliente({...newCliente, telefone: RetornaPhone(e.target.value)});
                }}
              />
              <div className = "contain-alert-modal">
                <TbAlertCircleFilled
                  size      = {30}
                  color     = "#DA5A34"
                />
                <h3 className = "text-alert-modal">
                  Atenção
                </h3>
                <h4 className = "text-info-modal">
                  Ao confirmar o agendamento você estará criando um novo
                  novo cliente manualmente. Nos próximos agendamento
                  você poderá reutilizá-los, basta buscá-los pelo NOME.
                </h4>
              </div>
              <button
                className = "btt-contain-salvar-cliente"
                onClick   = {() => {
                  SalvarCliente();
                }}
              >
                Salvar cliente
              </button>
            </>
          )}
        />
      */}
      <div className = "contain-divs-forms-diaria" >
        {
          loading ? 
          <div className="contain-loading">
            <Loading 
              mode =  {true} 
              text =  {"Não feche essa tela, aguarde por gentileza, sua diária esta sendo marcada"}
            />
          </div>
            : 
          (
            <>
              
              {/* Container Centro -- Seleciona cliente */}
              <div className = "contain-div-infos" style = {{maxHeight: '500px'}}>
                <div className = "contain-title-div-infos">
                  <div className = "icon-div-infos">
                    <BiUserCheck
                      color     = "white"
                      size      = {30}
                    />
                  </div>
                  Clientes
                </div>
                
                <div className = "contain-div-infos-inf" style = {{height: '100%'}}>
                  <h3 
                    className = "text-subtitle-low"
                    style    = {{marginBottom: "5px"}}
                  >
                    Selecione o cliente
                  </h3>  
                  
                  {/*
                    <button 
                      className = { "btt-seletor-modal" }
                      onClick   = {() => {
                        //console.log(`${modalHF ? "Ativou" : "Desativou"} Modal`);
                        setSttPage({...sttPage, modalNC: true});
                      }}
                    >
                      { "Criar cliente manualmente" }
                      <BiChevronRight
                        size      = {30}
                        color     = "#DA5A34"
                      />
                    </button>
                    //*/
                  }
                  <InputIcon
                    placeHolder  = {"Buscar cliente..."}
                    type         = {"text"}
                    value        = {sttPage.filter}
                    onChange     = {(e) => setSttPage({...sttPage, filter: e.target.value})}
                    Icon         = {RiUserSearchLine}
                  />
                  <div className = "contain-lista-clientes-cg">
                    <ListClients
                      type          = {"list-select"}
                      newClient     = {false}
                      filter        = {sttPage.filter}
                      selected      = {cliente}
                      addClient     = {addClient}
                      handleClient  = {(user)=>{
                        //console.log("User: ", user);
                        if(cliente !== null){
                          if(cliente.idusuario === user.idusuario){
                            setCliente(null);
                            return;
                          } else {
                            setCliente(user);
                            return;
                          }
                        } else setCliente(user);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Container Resumo */}
              <div 
                className = "contain-div-infos borders-confirm" 
                style = {{padding : "var(--padding-low)", maxHeight: "500px"}}
              >
                <div className = "contain-title-div-infos"> 
                  {"Resumo"}
                </div>
                <div className = "contain-div-infos-inf">
                  <div className  = "contain-div-resumo-line">
                    <h3 className = "text-align-left">
                      Diária
                    </h3>
                    <h3 className = "text-align-right">
                      {diaria.titulo}
                    </h3>
                  </div>
                  <div className  = "contain-div-resumo-line">
                    <h3 className = "text-align-left">
                      Data
                    </h3>
                    <h3 className = "text-align-right">
                      {Services.DataFormat("dia,DD/MM/YY", dataSel)}
                    </h3>
                  </div>
                  <div className  = "contain-div-resumo-line">
                    <h3 className = "text-align-left">Local</h3>
                    <div className = "contain-div-resumo-line-3">
                      <h3>
                        {date.empresa.nome}
                      </h3>
                      <h3>
                        {date.empresa.endereco}
                      </h3>
                    </div>
                  </div>
                  {/*<button 
                    className = {cupomStt === null ? "btt-seletor-modal" : "btt-seletor-modal-select"}
                    onClick   = {() => {
                      setModalCD(!modalCD)
                      //console.log(`${modalCD ? "Ativou" : "Desativou"} Modal Cupom de Desconto`);
                    }}
                  >
                    { cupomStt === null ? "Possui cupom de desconto?" : "Cupom Aplicado!"}
                  </button>*/}
                  { cliente !== null &&
                    <>
                      <div className  = "contain-divisao-line"/>
                      <div className  = "contain-div-resumo-line">
                        <h3 className = "text-align-left">
                          Cliente
                        </h3>
                        <div className = "contain-div-resumo-line-2">
                          <h3 className = "text-align-right">
                            {cliente.nome}  
                          </h3>
                        </div>  
                      </div> 
                    </>
                  }
                  {/* Linha de divisão!!! */}
                  <div className  = "contain-divisao-line"/>
                    <div className  = "contain-div-resumo-line">
                      <h3 className = "text-align-left">
                        Valor Total
                      </h3>
                      { stt ?
                          <h2 className = "text-align-right text-hig">
                            R${valorS.toFixed(2).toString().replace(".", ",")}
                          </h2>                          
                          :
                        <h3 className = "text-align-right">
                          - - -
                        </h3>
                      }
                    </div>                  
                  <button 
                    className = {VerificaAgendamentoOk()}
                    onClick   = {() => {
                      if(valorS < 1 || valorT < 1) {
                        Swal.fire({
                          title               : "Atenção",
                          text                : "Valor mínimo de R$ 1,00. Caso seu agendamento possua um sinal menor que esse valor, ele será ajustado automaticamente para R$ 1,00.",
                          icon                : "warning",
                          confirmButtonText   : "Entendi",
                          confirmButtonColor  : "#DA5A34",
                        }).then((result) => { 
                          if(result.isConfirmed){
                            setValorS(1);
                            ConfirmaCheckinDiaria();
                          }
                        });
                      } else ConfirmaCheckinDiaria();
                      //console.log("Metodo de pagamento: ", metodPay);
                    }}
                  >
                    {stt ? "Marcar Diária" : "Selecione a forma de pagamento"}
                  </button>
                </div>
              </div>
            </>
          )
        }

      </div>
    </div>
  );
  /*
  function SalvarCliente(){
    // Verifica se nome e telefone do cliente estão preenchidos
    if(newCliente.nome !== ""){
      const LC = LocalStorage.get("clientes-buffer");
      const cliente = {
        buffer      : true,
        nome        : newCliente.nome,
        idusuario   : newCliente.nome,
        atleta:{
          telefone  : newCliente.telefone,
        }
      }
      if(LC !== null){
        //Verificar se o cliente já existe
        const index = LC.findIndex((cli) => cli.nome === cliente.nome && cli.telefone === cliente.telefone);
        if(index !== -1){
          Alerts.aviso("Aviso", "Cliente já cadastrado!");
          return;
        } 
        LC.push(cliente);
        LocalStorage.set("clientes-buffer", LC);
      } else {
        LocalStorage.set("clientes-buffer", [cliente]);
      }
      setCliente(cliente);
      setNewCliente(client);
      setSttPage({...sttPage, modalNC: false});
      setAddCliente(!addClient);
    } else {
      Alerts.aviso("Aviso", "Por favor preencha o nome do cliente.");
    }

  }
  function RetornaPhone(phone){
    // (99) 99999-9999
    let data = phone;
    data = data.replace(/\D/g, ''); // remove any non-numeric characters
    // apply the mask
    data = data.replace(/^(\d{2})(\d)/g,"($1) $2");
    data = data.replace(/(\d)(\d{4})$/,"$1-$2");
    return data;
  }
  //*/
}