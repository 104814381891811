import React                    from "react";
import { 
  Alerts, ErroComunic, Loading 
} from "../../Basics";
import {
  CompPedido, GerClient, CompDiaria
} from "../../Complext/";
import { 
  BiBlock, BiCalendarPlus, BiCircle, BiEdit, BiLeftArrowAlt
} from "react-icons/bi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { 
  RiUserSearchLine, RiUserSettingsLine 
} from "react-icons/ri";
import { 
  notAgend, Check, UserCircle, logoAgendei, UserSet 
} from "../../../assets";
import { EmpresaAPI }           from "../../../servicesAPI";
import { LocalStorage }         from "../../../services";
import { AuthContext }          from "../../../contexts/AuthContext";
import { ChatContext }          from "../../../contexts/ChatContext";
import { ModalInfos }           from "../../Modais";
import RouterHook               from "../../../contexts/RouterHook";
import { MdEdit }               from "react-icons/md";
import moment                   from "moment";
import Swal                     from "sweetalert2";

import "./stylesClient.css";

export default function Clientes() {

  const serverRouter    = RouterHook();
  const { date, token, modalOpen, handleMOP } = React.useContext(AuthContext);
  const { actions, states }                   = React.useContext(ChatContext);
  const pagBuffer = {
    offSet      : 0,
    limit       : 10,
    maxScroll   : null,
    scroll      : 0,
    countAll    : true,
  }
  const clientRef   = React.useRef(null);
  const arraySttRef = React.useRef(null);
  const [pgClients, setPgClients]       = React.useState(pagBuffer);
  const [pgArrayStt, setPgArrayStt]     = React.useState(pagBuffer);
  // Index do botão para api
  // null -> todos
  // SOLICITADO -> 1
  // AGENDADO   -> 2
  // CANCELADO  -> 3
  // REALIZADO  -> 4
  const [load, setLoad]                 = React.useState({
    page      : true,
    arrayStt  : false,
    compA     : false,
  });

  const [sttPage, setSttPage]           = React.useState({
    title             : "Gerenciar Clientes",
    titleDi           : "Gereciar Autorizações",
    mode              : "init",     // init, block, client
    modeCl            : "pedidos",  // E diarias
    clienteSelect     : null,
    filter            : "",
    filterAS          : "",
    erroComunic       : false,
    modalNC           : false,
    render            : true,
  });
 
  //console.log("sttPage", sttPage);
  //Listas de clientes e um buffer para controle de filtro
  const [clientes, setClientes]                     = React.useState([]); // array de clientes
  const [clientesBuffer, setClientesBuffer]         = React.useState([]); // array de clientes
  // Listas de autorizações e um buffer para controle de filtro
  const [arrayState, setArrayState]                 = React.useState([]); // array de pedidos
  const [arrayStateBuffer, setArrayStateBuffer]     = React.useState([]); // array de pedidos
  // Clientes bloqueados e um buffer para controle de filtro
  const [clientesBlk, setClientesBlk]               = React.useState([]); // array de clientes
  const [clientesBlkBuffer, setClientesBlkBuffer]   = React.useState([]); // array de clientes
  //console.log("Clientes", clientes);
  //console.log("ClientesBuffer", clientesBuffer);
  //console.log("ArrayState", arrayState);
  // Busca os clientes da empresa
  async function BuscaClientes(){
    const req = await EmpresaAPI.BuscarClientsEmpresa(
      token, 
      date.empresa.idempresa,
      pgClients.limit,
      0,
      true
    );
    //console.log("BuscaClientes ::", req);
    
    if(req.status){
      if(req.req.retorno){
        setPgClients({
          ...pgClients,
          offSet      : req.req.usuario.rows.length,
          maxScroll   : req.req.usuario.count,
          countAll    : false,
        });
        /*setPgClients({
          ...pgClients,
          maxScroll   : req.req.usuario.rows.length,
          countAll    : false,
        }); //
        */
       //console.log("BuscaClientes", pgClients);

        setClientes(req.req.usuario.rows);
        setClientesBuffer(req.req.usuario.rows);

      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
        Alerts.aviso("Aviso", req.req.message);
      }
    } else {
      // Mapear quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
  }
  // Busca mais clientes da empresa rota paginada
  async function BuscaMaisClientes(){
    setLoad({
      ...load,
      compC: true,
    });
   //console.log("BuscaMaisClientes paginados", pgClients);
    const req = await EmpresaAPI.BuscarClientsEmpresa(
      token,
      date.empresa.idempresa,
      pgClients.limit,
      pgClients.offSet,
      true,
    );
   //console.log("BuscaClientes", req);
    if(req.status){
      if(req.req.retorno){
        setPgClients({
          ...pgClients,
          offSet      : pgClients.offSet + req.req.usuario.rows.length, 
        }); // limpa o buffer
        setClientes([
          ...clientes,
          ...req.req.usuario.rows
        ]);
        setClientesBuffer([
          ...clientesBuffer,
          ...req.req.usuario.rows
        ]);
      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
        Alerts.aviso("Aviso", req.req.message);
      }
    } else {
      // Mapear quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      compC: false,
    });
  }
  // Busca os clientes da empresa por filtro de nome
  async function BuscaClientesFiltro(){
    setLoad({
      ...load,
      filter: true,
    });
    const req = await EmpresaAPI.BuscarClientesPorFiltro( token, date.empresa.idempresa, sttPage.filter);
   //console.log("BuscaClientesFiltro", req);
    if(req.status){
      const array = req.req.map((cliente) => {
        return cliente.usuario;
      });
      setClientes(array);
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      filter: false,
    });
  }
  // Busca as autorizações dos clientes caso empresa seja privada!
  async function BuscaAutorizacoes(){
    const req = await EmpresaAPI.BuscarSolicitacaoAcess(
      token,
      date.empresa.idempresa,
      null
    );
    //console.log("BuscaAutorizacoes", req);
    if(req.status){
      // Ordena lista pelo createdAt
      const array = req.req.sort((a, b) => {
        return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1;
      });
      if(array.length === 0 && sttPage.render){
       //console.log("BuscaAutorizacoes", clientes);
        const req = await EmpresaAPI.BuscarClientsEmpresa(
          token, 
          date.empresa.idempresa,
          pgClients.limit,
          0,
          true
        );

        if(req.status && req.req.retorno){
          setLoad({
            ...load,
            arrayStt: true,
          });
          setSttPage({
            ...sttPage,
            clienteSelect   : req.req.usuario.rows[0],
            mode            : "client",
            render          : false,
          });
        }
      } 
      //console.log("BuscaAutorizacoes ordenado", array);
      setArrayState(array);
      setArrayStateBuffer(array);
      
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
  }
  // Abrir chat entre gestor e cliente
  async function AbrirChatEmpresaUsuario(cliente){
    setLoad({
      ...load,
      page: true,
    });
    const req = await actions.OpenChatEmpresa(cliente);
    actions.handleLoad(3, false);
    actions.handleLoad(0, false);
    if(req !== null){
      serverRouter('chat', date.empresa.username);
    }
    setLoad({
      ...load,
      page: false,
    });
  }
  //console.log("ArrayState", arrayState);
  // Busca black list da empresa
  async function BuscaBlackList(){
   //console.log("BuscaBlackList");
    const req = await EmpresaAPI.BuscarBlackListEmpresa(token, date.empresa.idempresa);
   //console.log("BuscaBlackList", req);
    if(req.status){
      if(req.req.retorno){
        setClientesBlk(req.req.registro.rows);
        setClientesBlkBuffer(req.req.registro.rows);
      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    } 
  }
  // Busca os pedidos do cliente
  async function BuscarPedidosCliente(mode, idusuario){ // mode é inicio da PG ou mais da PG (PG é paginação)
    const req = await EmpresaAPI.BuscarReservasCliente(
      token,
      idusuario ? idusuario : sttPage.clienteSelect.idusuario,
      date.empresa.idempresa,
      mode ? 20 : pgArrayStt.limit,
      mode ? true : false,
      mode ? 0 : pgArrayStt.offSet,
    );
   //console.log("BuscarPedidosCliente", req);
    if(req.status){
      if(mode){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : req.req.rows.length,
          maxScroll   : req.req.count,
          countAll    : false,
        });
        setArrayState(req.req.rows);
        setArrayStateBuffer(req.req.rows);
      } else {
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : pgArrayStt.offSet + req.req.rows.length,
        });
        setArrayState([
          ...arrayState,
          ...req.req.rows
        ]);
        setArrayStateBuffer([
          ...arrayStateBuffer,
          ...req.req.rows
        ]);
        return true;
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
      return false;
    }
  }
  // Busca as diarias do cliente
  async function BuscarDiariasCliente(mode){
    const req = await EmpresaAPI.BuscarDayUseVendidosCliente(
      token,
      sttPage.clienteSelect.idusuario,
      date.empresa.idempresa,
      mode ? 20 : pgArrayStt.limit,
      mode ? 0 : pgArrayStt.offSet,
      mode ? true : false
    );
   //console.log("BuscarDiariasCliente", req);
    if(req.status){
      if(mode){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : req.req.dayUse.rows.length,
          maxScroll   : req.req.dayUse.count,
          countAll    : false,
        });
        setArrayState(req.req.dayUse.rows);
        setArrayStateBuffer(req.req.dayUse.rows);
      } else {
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : pgArrayStt.offSet + req.req.dayUse.rows.length,
        });
        setArrayState([
          ...arrayState,
          ...req.req.dayUse.rows
        ]);
        setArrayStateBuffer([
          ...arrayStateBuffer,
          ...req.req.dayUse.rows
        ]);
        return true;
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
      return false;
    }
  }
  // Busca o cliente selecionado
  async function BuscarClienteSelect(idusuario){
    const req = await EmpresaAPI.BuscarClientsEmpresa(
      token, 
      date.empresa.idempresa,
      null,
      null,
      null
    );
   //console.log("BuscaClientes", req);
    
    if(req.status){
      if(req.req.retorno){
        /*setPgClients({
          ...pgClients,
          offSet      : req.req.usuario.rows.length,
          maxScroll   : req.req.usuario.count,
          countAll    : false,
        }); */
        setPgClients({
          ...pgClients,
          maxScroll   : req.req.usuario.rows.length,
          countAll    : false,
        }); //
        // Busca o index do cliente
        const index = req.req.usuario.rows.findIndex((cliente) => {
          return cliente.idusuario === idusuario;
        }); 
        setSttPage({
          ...sttPage,
          mode          : "client",
          clienteSelect : req.req.usuario.rows[index],
        });
        
       //console.log("Index", index);
        // Jogar o cliente para o topo da lista
        const array = req.req.usuario.rows;
        const user  = array[index];
        array.splice(index, 1);
        array.unshift(user);

        //console.log("Index", index);
        
       //console.log("BuscaClientes", pgClients);

        setClientes(array);
        setClientesBuffer(array);

        const reqA = await EmpresaAPI.BuscarReservasCliente(
          token,
          idusuario,
          date.empresa.idempresa,
          20,
          true,
          0
        );
        if(req.status){
          setPgArrayStt({
            ...pgArrayStt,
            offSet      : reqA.req.rows.length,
            maxScroll   : reqA.req.count,
            countAll    : false,
          });
          setArrayState(reqA.req.rows);
          setArrayStateBuffer(reqA.req.rows);
        } else {
          setSttPage({
            ...sttPage,
            erroComunic: false,
          });
        }
      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
        Alerts.aviso("Aviso", req.req.message);
      }
    } else {
      // Mapear quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
  }
  // Gerencia autorizações
  async function GerenciarAutorizacao(action, cliente){
    if(action === "autorizar"){
      const req = await EmpresaAPI.AprovarCliente(token, cliente.idcliente, date.gestor.nome);
     //console.log("GerenciarAutorizacao", req);
      if(req.status){
        Alerts.sucesso("Sucesso", "Cliente autorizado com sucesso");
        await BuscaClientes();
        await BuscaAutorizacoes();
      } else {
        Alerts.aviso("Aviso", "Não foi possível autorizar o cliente, tente novamente mais tarde!");
      }
    } else if(action === "desautorizar"){
      const req = await EmpresaAPI.RecusarCliente(token, cliente.idcliente, date.gestor.nome);
     //console.log("GerenciarAutorizacao", req);
      if(req.status){
        Alerts.sucesso("Sucesso", "Cliente desautorizado com sucesso");
        await BuscaAutorizacoes();
      } else {
        Alerts.aviso("Aviso", "Não foi possível desautorizar o cliente, tente novamente mais tarde!");
      }   
    } else if(action === "revogar"){
      const req = await EmpresaAPI.RevogarCliente(token, cliente.idcliente, date.gestor.nome);
     //console.log("GerenciarAutorizacao", req);
      if(req.status){
        Alerts.sucesso("Sucesso", "Cliente revogado com sucesso");
        await BuscaAutorizacoes();
      } else {
        Alerts.aviso("Aviso", "Não foi possível revogar o cliente, tente novamente mais tarde!");
      }
    }
  }
  // Bloquear cliente lista negra
  function BloquearCliente(cliente){
   //console.log("BloquearCliente", cliente);
    Swal.fire({
      icon          : "warning",
      title         : "Atenção",
      text          : `Deseja bloquear o acesso de ${cliente.nome}?`,
      showCancelButton  : true,
      confirmButtonColor: "#bf3324",
      cancelButtonColor : "#00cda4",
      confirmButtonText : "Não",
      cancelButtonText  : "Sim",
    }).then(async (result) => {
      //console.log("Result: ", result);
      if (result.isDismissed && result.dismiss === "cancel"){
        setLoad({
          ...load,
          arrayStt: true,
        });
        const req = await EmpresaAPI.AdicionarUserInBlackList(token, date.empresa.idempresa, cliente.idusuario, date.gestor.idusuario);
       //console.log("BloquearCliente", req);
        if(req.status){
          Alerts.sucesso("Sucesso", "Cliente bloqueado com sucesso");
          setSttPage({
            ...sttPage,
            mode: "block",
          });
        } else {
          Alerts.aviso("Aviso", "Não foi possível bloquear o cliente, tente novamente mais tarde!");
        }
        setLoad({
          ...load,
          arrayStt: false,
        });
      }
    });
  }

  // Desbloquear cliente lista negra
  function DesbloquearCliente(cliente){
   //console.log("BloquearCliente", cliente);
    Swal.fire({
      icon          : "warning",
      title         : "Atenção",
      text          : `Deseja desbloquear o acesso de ${cliente.usuario.nome}?`,
      showCancelButton  : true,
      confirmButtonColor: "#bf3324",
      cancelButtonColor : "#00cda4",
      confirmButtonText : "Não",
      cancelButtonText  : "Sim",
    }).then(async (result) => {
      //console.log("Result: ", result);
      if (result.isDismissed && result.dismiss === "cancel"){
        setLoad({
          ...load,
          arrayStt: true,
        });
        const req = await EmpresaAPI.RemoverUserBlackList(token, date.empresa.idempresa, cliente.usuario.idusuario, date.gestor.idusuario);
       //console.log("DesbloquearCliente", req);
        if(req.status){
          Alerts.sucesso("Sucesso", "Cliente desbloqueado com sucesso");
          await BuscaBlackList();
        } else {
          Alerts.aviso("Aviso", "Não foi possível desbloquear o cliente, por favor, tente novamente mais tarde");
        }
        setLoad({
          ...load,
          arrayStt: false,
        });
      }
    });
  }

  // Quando renderiza
  React.useEffect(() => {
    async function SetDataExcept(idusuario){
      setLoad({
        ...load,
        page: true,
      });
      //console.log("SetDataExcept", idusuario);
      await BuscarClienteSelect(idusuario);
      setLoad({
        ...load,
        page: false,
      });
    }
    async function SetDataA(){
      setLoad({
        ...load,
        page: true,
      });
      await BuscaClientes();
      await BuscaAutorizacoes();
      setLoad({
        ...load,
        page: false,
      });
    }
    const clt = LocalStorage.get("cliente-selected");
    if(clt){
     //console.log("Cliente selecionado: ", clt);
      SetDataExcept(clt).then(() => {
        LocalStorage.remove("cliente-selected");
      });
    } else {
     //console.log("Cliente não selecionado");
      SetDataA();
    }
  }, []);
  // Aciona a busca da lista ao trocar mode
  React.useEffect(() => {
    
    async function SetDataB(){

      if(load.page) return;
     //console.log("SetDataB");
      setLoad({
        ...load,
        arrayStt: true,
      });
      if(sttPage.mode === "init"){
        await BuscaAutorizacoes();
      } else if(sttPage.mode === "block"){
        await BuscaBlackList();
      } else if(sttPage.mode === "client"){
        sttPage.modeCl === "pedidos" && await BuscarPedidosCliente(true);
        sttPage.modeCl === "diarias" && await BuscarDiariasCliente(true);
      }
      setLoad({
        ...load,
        arrayStt: false,
      });
    }

    if(clientes.length > 0 || sttPage.mode !== "init") SetDataB();
    
  }, [sttPage.mode, sttPage.clienteSelect, sttPage.modeCl]);

  // Controla retorno nos states
  function ReturnSttPage(){   
    if(modalOpen){
      handleMOP(false)
    } else if(sttPage.mode === "init"){
      serverRouter("back");
    } else if(sttPage.mode === "block"){
      setSttPage({
        ...sttPage,
        mode: "init",
        titleDi: "Gerenciar Autorizações",
      });
    } else if(sttPage.mode === "client"){
      if(sttPage.modeCl === "pedidos"){
        setLoad({
          ...load,
          arrayStt: true,
        });
        BuscaAutorizacoes().then(() => {
          if(arrayState.length === 0){
           //console.log("ReturnSttPageAqui", arrayState);
            return serverRouter("back");
          }
          setSttPage({
            ...sttPage,
            clienteSelect: null,
            mode: "init",
            titleDi: "Gerenciar Autorizações",
          });
          setLoad({
            ...load,
            arrayStt: false,
          });
        });
      } else if(sttPage.modeCl === "diarias"){
        setLoad({
          ...load,
          arrayStt: true,
        });
        BuscarPedidosCliente(true).then(() => {
          setSttPage({
            ...sttPage,
            modeCl: "pedidos",
          });
        });
      }
    }
  }
  
  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    document.title = "Clientes | Gestor Agendei";
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
   }, [sttPage.mode, sttPage.clienteSelect, sttPage.modeCl, modalOpen]);

  // Retorna o componente cliente
  function Cliente({usuario, onClick, select}){
    const empresaNome = date.empresa.nome;
    //console.log('Select: ', select);
    return (
      <button 
        className = {select ? "btt-contain-client-select" : "btt-contain-client"}
        style     = {{width: "100%"}}
        onClick   = {() => {
          onClick();
        }}
      >
        <div className = "contain-infos-client">
          <h2>
            {usuario.nome}
          </h2>
          { usuario.atleta &&
            <label>
              {usuario.atleta.telefone}
            </label>
          }
          { usuario.nome === usuario.usuario ?
            <div className = "contain-buffer-client-hf">
              <h3>
                {"CRIADO MANUALMENTE POR GESTOR"}
              </h3>  
              <RiUserSettingsLine
                color = "#A8A8A8"
                size  = {20}
                style = {{marginLeft: "5px"}}
              />
            </div>
              :
            <div className = "contain-buffer-client-hf">
              <h2 className = "text-cliente-empresa">
                {("Cliente " + empresaNome).toUpperCase()}
              </h2>
              <img
                className = "img-buffer-empresa"
                src       = {logoAgendei}
              />
            </div>
          }
        </div>
        { select ?
          <Check
            className = "icon-check-select"
          />
            :
          <BiCircle
            className = "icon-check-circle"
          />
        }
      </button>
    );
  }

  // Retorna o componente de balcklist
  function CompBlackList({cliente, onClick}){
    function ReturnLabelStatus(){
      switch(cliente.status) {
        case "APROVADO": return `Aprovado por ${cliente.aprovadoPor}`;
        
        case "RECUSADO" : return `Recusado por ${cliente.recusadoPor}`;
      
        case "PENDENTE" : return `Solicitado em ${moment(cliente.createdAt).format("DD/MM/YY")}`;
  
        case "BLOQUEADO" : return `Bloqueado por ${cliente.recusadoPor} em ${moment(cliente.updatedAt).format("DD/MM/YY")}`;
      
        case "REVOGADO"  : return `Revogado por ${cliente.recusadoPor}`;
        
        default: return "Cliente pendente";
      } 
    }
  
    //console.log("GerClient.js - cliente: ", cliente)
    return (
      <div 
        className = "contain-gerenciar-cliente"
        style     = {cliente.status === "APROVADO" ? {borderColor: "#23A820"} : {borderColor: "#F05546"}} 
      >
        <h2 
          className = "text-status-cliente"
          style     = {cliente.status === "APROVADO" ? {color: "#23A820"} : {color: "#F05546"}}
        >
          {cliente.status === "APROVADO" ? "AUTORIZADO" : cliente.status}
        </h2>
        <h2 className = "text-nome-cliente">
          {cliente.usuario.nome}
        </h2>
        <div className = "contain-message-cliente">
          <MdEdit
            size  = {20}
            color = "#F05546"
          />
          <p className = "text-message-cliente">
            {cliente.mensagemCliente !== null && cliente.mensagemCliente !== "" ? cliente.mensagemCliente : "Sem mensagem para o gestor"}
          </p>
        </div>
          <button
            className = "btt-des-blokis"
            onClick   = {() => {
              onClick();
            }}
          >
            Desbloquear
          </button>
        {
          <p className = "text-cliente-status-fez">
            {ReturnLabelStatus()}
          </p>
        }
      </div>
    );
  }


  // Controla o scroll da lista de clientes (Paginação)
  let flag1 = false;
  React.useEffect(() => {
    function handleScrollA() {
      const scrollTop     = clientRef.current.scrollTop;
      const scrollHeight  = clientRef.current.scrollHeight;
      const clientHeight  = clientRef.current.clientHeight;
     //console.log("AS")
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag1 && pgClients.maxScroll > pgClients.offSet && sttPage.filter.length <= 0) {
        flag1 = true;
        //console.log("Scroll", pgClients);
        BuscaMaisClientes().then(() => {
          flag1 = false;
        });
      }
    }
    if(clientRef.current) clientRef.current.addEventListener("scroll", handleScrollA);
    return () => {if(clientRef.current) clientRef.current.removeEventListener("scroll", handleScrollA);}
  }, [sttPage, load, clientes, pgClients, clientesBuffer]);
  // Controla o scroll do arrayState (Paginação)*/
  let flag2 = false;
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop     = arraySttRef.current.scrollTop;
      const scrollHeight  = arraySttRef.current.scrollHeight;
      const clientHeight  = arraySttRef.current.clientHeight;
      //console.log("Paginação", pgArrayStt);
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag2 && pgArrayStt.maxScroll > pgArrayStt.offSet) {
        flag2 = true;
        //console.log("Scroll", pgArrayStt);
        if(sttPage.mode === "client"){
          if(sttPage.modeCl === "pedidos"){
            setLoad({
              ...load,
              compA : true,
            });
            BuscarPedidosCliente(false).then((result) => {
              if(result){
                flag2 = false;
                setLoad({
                  ...load,
                  compA : false,
                });
              } else {
               //console.log("Erro de comunicação");
              }
            });
          }
          if(sttPage.modeCl === "diarias"){
            setLoad({
              ...load,
              compA : true,
            });
            BuscarDiariasCliente(false).then((result) => {
              if(result){
                flag2 = false;
                setLoad({
                  ...load,
                  compA : false,
                });
              } else {
               //console.log("Erro de comunicação");
              }
            });
          }
        }
      }
    }
    //console.log("pgArrayStt", pgArrayStt);
    if(arraySttRef.current) arraySttRef.current.addEventListener("scroll", handleScroll);
    return () => {if(arraySttRef.current) arraySttRef.current.removeEventListener("scroll", handleScroll);}
  }, [sttPage, load, arrayState, pgArrayStt]);
  
  // Controla o filtro de clientes
  React.useEffect(() => {
    const buscar_clientes = debounce(async () => {
      if(sttPage.filter.length > 3){
        await BuscaClientesFiltro();
      } 
    }, 500);
    buscar_clientes();
  }, [sttPage.filter]);
  
  React.useEffect(() => {
    if(sttPage.mode === "block"){
      if(sttPage.filterAS.length > 0){
        setClientesBlk(clientesBlkBuffer.filter((cliente) => {
          return cliente.usuario.nome.toLowerCase().includes(sttPage.filterAS.toLowerCase());
        }));
      } else {
        setClientesBlk(clientesBlkBuffer);
      }
    } else {
      if(sttPage.filterAS.length > 0){
        setArrayState(arrayStateBuffer.filter((cliente) => {
          return cliente.usuario.nome.toLowerCase().includes(sttPage.filterAS.toLowerCase());
        }));
      } else {
        setArrayState(arrayStateBuffer);
      }
    }
  }, [sttPage.filterAS]);
  //console.log("Clientes", clientes);
  function debounce(fn, delay) {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  function EditClientComp(){
    const [newCliente, setNewCliente]     = React.useState({
      nome      : sttPage.clienteSelect.nome,
      telefone  : sttPage.clienteSelect.atleta.telefone,
    });
    const [loadCliente, setLoadCliente]   = React.useState(false);
    async function AlterarCliente(){
     //console.log("AlterarCliente", sttPage.clienteSelect);
      if(sttPage.clienteSelect.nome !== newCliente.nome || sttPage.clienteSelect.atleta.telefone !== newCliente.telefone){
        setLoadCliente(true);
        const cliente = {
          idusuario : sttPage.clienteSelect.idusuario,
          nome      : newCliente.nome,
          usuario   : newCliente.nome,
          atleta    : {
            ...sttPage.clienteSelect.atleta,
            telefone : newCliente.telefone,
          }
        }
        const reqs = await EmpresaAPI.AtualizarClienteManual( token, cliente );
       //console.log("Resposta da atualização", reqs);
        if(reqs.status){
          Alerts.sucesso("Sucesso", "Cliente alterado com sucesso");
          let buffer = {};
          clientes.filter((clt) => {
            if(clt.idusuario === sttPage.clienteSelect.idusuario){
              clt.nome = newCliente.nome;
              clt.usuario = newCliente.nome;
              clt.atleta.telefone = newCliente.telefone;
             //console.log("Setou cliente");
              buffer = clt;
            }
          });
          setClientes(clientes);
          setSttPage({
            ...sttPage,
            modalNC: false,
            clienteSelect: buffer,
          });
        }
      } else {
        Alerts.aviso("Aviso", "Para alterar o cliente é necessário alterar pelo menos um campo!");
      }
    }

    return (
      <div className = "contain-alterar-cliente-modal">
        { loadCliente ? 
          <div>
            <Loading mode={true} text="Alterando cliente..." color={true}/>
          </div>
            :
          <>
            <h3 className = "text-title-modal" style = {{marginBottom: "10px"}}>
              {`Editando cliente #${sttPage.clienteSelect.idusuario}`}
            </h3>
            <h4 className = "text-subtitle-modal">
              Aqui você pode editar o cliente que foi criado manualmente por algum gestor.
            </h4>
            <input
              className     = "input-new-client"
              placeholder   = "Nome do cliente"
              value         = {newCliente.nome}
              onChange      = {(e) => {
                if(e.target.value.length > 35) return;
                setNewCliente({...newCliente, nome: e.target.value});
              }}
            />
            <input
              className     = "input-new-client"
              placeholder   = "Telefone do cliente (opcional)"
              value         = {newCliente.telefone}
              onChange      = {(e) => {
                if(e.target.value.length > 15) return;
                setNewCliente({...newCliente, telefone: RetornaPhone(e.target.value)});
              }}
            />
            <button
              className = "btt-contain-salvar-cliente"
              onClick   = {() => {
                AlterarCliente();
              }}
            >
              Salvar alteração
            </button>
          </>
        }
      </div>
    );
    function RetornaPhone(phone){
      // (99) 99999-9999
      let data = phone;
      data = data.replace(/\D/g, ''); // remove any non-numeric characters
      // apply the mask
      data = data.replace(/^(\d{2})(\d)/g,"($1) $2");
      data = data.replace(/(\d)(\d{4})$/,"$1-$2");
      return data;
    }
  }
  //console.log("ArrayState", arrayState);
  //console.log("ClientesBlackList", clientesBlk);
  // Page de Filtrar Pedidos
  return (
      <>
        { sttPage.modalNC &&
          <ModalInfos
            modal             = { sttPage.modalNC }
            ClosedModal       = { () => setSttPage({...sttPage, modalNC: false}) }
            Component         = {<EditClientComp/>}
          />
        }
        <div className = "contain-agenda-page">
          <div className = "contain-filtro-agenda-hf">
            <BiLeftArrowAlt
              className = "icon-arrow-back-hf"
              onClick={() => {
                ReturnSttPage();
              }}
            />
            <h2 className = "title-me-agenda"> {sttPage.title} </h2>
            { load.page ? 
              <div className = "contain-loading" style = {{height: "300px"}}>
                <Loading 
                  mode  = {true}
                  text  = { "Buscando clientes..." }  
                />
              </div>
                :
              sttPage.erroComunic ?
              <ErroComunic
                onClick = {() => {
                  setSttPage({
                    ...sttPage,
                    erroComunic: false,
                  });
                }}
              />
                :
              <div className = "contain-view-hora-fixa">
                <div className = "contain-filtra-hf">

                  <div className = "contain-list-clientes">
                    <button 
                      className = {sttPage.mode === "init" ? "btt-contain-client-select" : "btt-contain-client"}
                      style     = {{minWidth: "300px"}}
                      onClick = {() => {
                        if(sttPage.mode !== "init" && !load.arrayStt) { 
                          setLoad({
                            ...load,
                            arrayStt: true,
                          });
                          setSttPage({
                            ...sttPage,
                            clienteSelect : null,
                            mode          : "init",
                            titleDi       : "Gerenciar Autorizações"
                          });
                        }
                      }}
                    >
                      <div className = "contain-infos-client">
                        <h2 style = {{fontSize: "16px"}}>
                          Gerenciar autorizações
                        </h2>
                      </div>
                      {
                        (sttPage.mode === "init") ?
                        <Check
                          className = "icon-check-select"
                        />
                          :
                        <BiCircle
                          className = "icon-check-circle"
                        />
                      }
                    </button>
                    <button 
                      className = {sttPage.mode === "block" ? "btt-contain-client-select" : "btt-contain-client"}
                      style     = {{minWidth: "300px"}}
                      onClick = {() => {
                        if(sttPage.mode !== "block" && !load.arrayStt) { 
                          setLoad({
                            ...load,
                            arrayStt: true,
                          });
                          setSttPage({
                            ...sttPage,
                            clienteSelect : null,
                            mode          : "block",
                            titleDi       : "Gerenciar Clientes Bloqueados"
                          });
                        }
                      }}
                    >
                      <div className = "contain-infos-client">
                        <h2 style = {{fontSize: "16px"}}>
                          Ver clientes bloqueados
                        </h2>
                      </div>
                      {
                        sttPage.mode === "block" ?
                        <Check
                          className = "icon-check-select"
                        />
                          :
                        <BiCircle
                          className = "icon-check-circle"
                        />
                      }
                    </button>
                    <div className = "line-border-bottom"/>
                    <h3 className = "title-list-clientes"> 
                      {`${pgClients.maxScroll} Clientes`} 
                    </h3>
                    <div 
                      className = "contain-comp-input-forms"
                      style     = {{width: "100%", marginBottom: "7px"}}
                    >
                      <input
                        className     = "input-comp"
                        placeholder   = {"Buscar Clientes..."}
                        type          = {"text"}
                        value         = {sttPage.filter}
                        onChange      = {(e) => {
                          if(e.target.value.length === 0) setClientes(clientesBuffer);
                          setSttPage({
                            ...sttPage,
                            filter: e.target.value
                          });
                        }}
                        onKeyDown={async (e) => {
                          if (e.key === 'Enter') { 
                            // Verificar tamanho do texto
                            if(sttPage.filter.length > 3){
                             //console.log("Buscar Clientes");
                              await BuscaClientesFiltro();
                            } else {
                              Alerts.aviso("Aviso", "Por favor digite mais de 3 caracteres para filtrar os clientes!");
                            }
                          }
                        }}
                      /> 
                      <RiUserSearchLine
                        size      = {25}
                        color     = "#A8A8A8"
                      />
                    </div>
                    <div
                      ref       = {clientRef} 
                      className = "flat-list-clientes">
                      { load.filter ?
                        <div>
                          <Loading mode = {true} text={"Buscando Clientes..."}/>
                        </div>
                          :
                        clientes.length > 0 ?
                        clientes.map((cliente, index) => {
                          return (
                            <>
                              <Cliente
                                key       = {index}
                                type      = {"select"}
                                usuario   = {cliente}
                                select    = {(sttPage.clienteSelect !== null) ? sttPage.clienteSelect.idusuario === cliente.idusuario : false}
                                onClick   = {() => {
                                  
                                  if(sttPage.clienteSelect !== null) {
                                    if(sttPage.clienteSelect.idusuario !== cliente.idusuario && !load.arrayStt) { 
                                    //console.loglog("Cliente", cliente);
                                      setLoad({
                                        ...load,
                                        arrayStt: true,
                                      });
                                      setSttPage({
                                        ...sttPage,
                                        mode          : "client",
                                        clienteSelect : cliente
                                      });
                                    } 
                                  } else if(!load.arrayStt){
                                  //console.loglog("Cliente", cliente);
                                    setLoad({
                                      ...load,
                                      arrayStt: true,
                                    });
                                    setSttPage({
                                      ...sttPage,
                                      mode          : "client",
                                      clienteSelect : cliente
                                    });
                                  }
                                }}
                              />
                              {
                                clientes.length - 1 === index && load.compC &&
                                <Loading mode = {false} />
                              }
                            </>
                          )
                        })
                          :
                        <div className = "contain-not-agend">
                          <img
                            title = "Sem Clientes"
                            src   = {notAgend}
                            alt   = "Not Agend"
                          />
                          <h2>
                            { sttPage.filter.length > 0 ?
                              "Não foi encontrado nenhum cliente com este nome"
                                :
                              "Esta empresa não possui clientes com agendamentos realizados"
                            }
                          </h2>
                        </div>
                      }
                    </div>
                  </div>
                  
                  { sttPage.mode === "init" ?
                    <div className = "contain-list-array-state">
                      <div className = "contain-clientes-internal-as">
                        <h3 className = "text-title-array-stt" style = {{margin: "0px"}}>
                          {sttPage.titleDi}
                        </h3>
                        <div className = "line-border-bottom"/>
                        <div 
                          className = "contain-comp-input-forms"
                          style     = {{width: "100%", margin: "7px 0px", maxWidth: "340px"}}
                        >
                          <input
                            className     = "input-comp"
                            placeholder   = {"Buscar Clientes..."}
                            type          = {"text"}
                            value         = {sttPage.filterAS}
                            onChange      = {(e) => {
                              if(e.target.value.length === 0) setArrayState(arrayStateBuffer);
                              setSttPage({
                                ...sttPage,
                                filterAS: e.target.value
                              });
                            }}
                          /> 
                          <RiUserSearchLine
                            size      = {25}
                            color     = "#A8A8A8"
                          />
                        </div>
                        <div 
                          ref       = {arraySttRef}
                          className = "contain-flat-list-array-stt"
                        >
                          {
                            load.arrayStt ?
                            <div className = "contain-loading" style = {{height: "150px"}}>
                              <Loading mode = {true} text={"Buscando Clientes..."}/>
                            </div>
                              :
                            arrayState.length > 0 ? 
                            arrayState.map((cliente, index) => {
                              return (
                                <GerClient
                                  key       = {index}
                                  cliente   = {cliente}
                                  onClick   = {async (action) => {
                                    await GerenciarAutorizacao(action, cliente);
                                  }}
                                />
                              );
                            })
                              :
                            <div className = "contain-not-agend">
                              <img
                                title = "Sem Clientes"
                                src   = {notAgend}
                                alt   = "Not Agend"
                              />
                              <h2>
                                { sttPage.filterAS.length > 0 ?
                                  "Não foi encontrado nenhum cliente com esse nome"
                                    :
                                  "Esta empresa não possui clientes com agendamentos realizados"
                                }
                              </h2>
                            </div>
                          }
                        </div>
                      </div>
                    </div> 
                      :
                    sttPage.mode === "block" ?
                    <div className = "contain-list-array-state">
                      <div className = "contain-clientes-internal-as">
                        <h3 className = "text-title-array-stt" style = {{margin: "0px"}}>
                          {sttPage.titleDi}
                        </h3>
                        <div className = "line-border-bottom"/>
                        <div 
                          className = "contain-comp-input-forms"
                          style     = {{width: "100%", margin: "7px 0px", maxWidth: "340px"}}
                        >
                          <input
                            className     = "input-comp"
                            placeholder   = {"Buscar Clientes..."}
                            type          = {"text"}
                            value         = {sttPage.filterAS}
                            onChange      = {(e) => {
                              if(e.target.value.length === 0) setArrayState(arrayStateBuffer);
                              setSttPage({
                                ...sttPage,
                                filterAS: e.target.value
                              });
                            }}
                          /> 
                          <RiUserSearchLine
                            size      = {25}
                            color     = "#A8A8A8"
                          />
                        </div>
                        { load.arrayStt ?
                          <div className = "contain-loading" style = {{height: "150px"}}>
                            <Loading mode = {true} text={"Buscando Clientes Bloqueados..."}/>
                          </div>
                              :
                          <div 
                            ref       = {arraySttRef}
                            className = "contain-flat-list-array-stt"
                            style     = {{flexDirection: "column"}}
                          >
                            {
                              clientesBlk.length > 0 ? 
                              clientesBlk.map((cliente, index) => {
                                return (
                                  <CompBlackList
                                    key       = {index}
                                    cliente   = {cliente}
                                    onClick   = {() => {
                                      DesbloquearCliente(cliente);
                                    }}
                                  />
                                );
                              })
                                :
                              <div className = "contain-not-agend">
                                <img
                                  title = "Sem Clientes"
                                  src   = {notAgend}
                                  alt   = "Not Agend"
                                />
                                <h2>
                                  { sttPage.filterAS.length > 0 ?
                                    "Não foi encontrado nenhum cliente com este nome"
                                      :
                                    "Esta empresa não possui clientes bloqueados"
                                  }
                                </h2>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div> 
                      : // mode === "client"
                    <div className = "contain-list-array-state">
                      <div 
                        className = "contain-resumo-cliente"
                        style     = {{maxWidth: "550px"}}
                      >
                        <UserCircle
                          className = "icon-user-circle-hf"
                        />
                        <h2>{sttPage.clienteSelect.nome}</h2>
                        { sttPage.clienteSelect.usuario !== sttPage.clienteSelect.nome ?
                          <h3>{sttPage.clienteSelect.usuario}</h3>
                            :
                          <div 
                            className = "contain-buffer-client"
                            style = {{justifyContent: "center", alignItems: "center", width: "100%"}}
                          >
                            <h3>
                              {"CRIADO MANUALMENTE POR GESTOR"}
                            </h3>  
                            <UserSet
                              style = {{marginLeft: "7px", color: "#A8A8A8", minWidth: "20px", minHeight: "20px"}}
                            />
                          </div>
                        }
                        { sttPage.clienteSelect.atleta &&
                          <p>
                            {sttPage.clienteSelect.atleta.telefone}
                          </p>
                        }
                        <div className = "contain-btts-ger-cliente">
                          <button
                            className = "btt-text-icon-cliente"
                            onClick   = {() => {
                              //console.loglog("HoraFx", horaFx);
                              // Salva o idusuario do cliente e salva no localStorage!
                              LocalStorage.set("clienteSelect", sttPage.clienteSelect);
                              // Manda o gestor para tela inicial de criar novo horário fixo
                              serverRouter("menu");
                            }}
                          >
                            Agendar horário
                            <BiCalendarPlus 
                              className = "icon-btt-text-icon"
                            />
                          </button>
                          { sttPage.clienteSelect.nome !== sttPage.clienteSelect.usuario ?
                            <button
                              className = "btt-text-icon-cliente"
                              onClick   = {() => {
                                AbrirChatEmpresaUsuario(sttPage.clienteSelect);
                              }}
                            >
                              Abrir conversa
                              <IoChatbubblesOutline
                                className = "icon-btt-text-icon"
                              />
                            </button> : <></>
                          }{ 
                            sttPage.clienteSelect.nome !== sttPage.clienteSelect.usuario ?
                            <button
                              className = "btt-text-icon-cliente-block"
                              onClick   = {() => {
                                BloquearCliente(sttPage.clienteSelect);
                              }}
                            >
                              Bloquear cliente
                              <BiBlock
                                className = "icon-btt-text-icon"
                              />
                            </button>
                              :
                            <button
                              className = "btt-text-icon-cliente-edit"
                              onClick   = {() => {
                                setSttPage({
                                  ...sttPage,
                                  modalNC: true,
                                });
                              }}
                            >
                              Editar cliente
                              <BiEdit
                                className = "icon-btt-text-icon"
                              />
                            </button>
                          }
                        </div>
                        <div className = "line-border-bottom"/>
                        <div className = "contain-handle-peds-diars">
                          <button
                            className = {sttPage.modeCl === "pedidos" ? "btt-select-ped-dia-select" : "btt-select-ped-dia"}
                            onClick   = {() => {
                              if(load.arrayStt) return;
                              setLoad({
                                ...load,
                                arrayStt: true,
                              });
                              setSttPage({
                                ...sttPage,
                                modeCl: "pedidos"
                              });
                            }}
                          >
                            Agendamentos
                          </button>
                          <button
                            className = {sttPage.modeCl === "diarias" ? "btt-select-ped-dia-select" : "btt-select-ped-dia"}
                            onClick   = {() => {
                              if(load.arrayStt) return;
                              setLoad({
                                ...load,
                                arrayStt: true,
                              });
                              setSttPage({
                                ...sttPage,
                                modeCl: "diarias"
                              });
                            }}
                          >
                            Diárias
                          </button>
                        </div>
                        <h3 className = "title-list-clientes"> 
                          {`${pgArrayStt.maxScroll !== null ? pgArrayStt.maxScroll : ""} ${sttPage.modeCl === "pedidos" ? "Agendamentos" : "Diárias"}`} 
                        </h3>
                        <div 
                          ref       = {arraySttRef}
                          className = "contain-flat-list-array-stt"
                          style     = {{maxHeight: "calc(600px - 230px)"}}
                        >
                          {
                            load.arrayStt ?
                              <div className = "contain-loading">
                                <Loading 
                                  mode  = {true}
                                  text  = {sttPage.modeCl === "pedidos" ? "Buscando Agendamentos..." : "Buscando Diárias..."}  
                                />
                              </div> 
                                :
                              arrayState.length > 0 ? 
                                arrayState.map((item, index) => {
                                  //console.log("Item", item);
                                  return (
                                    <>
                                      { sttPage.modeCl === "pedidos" ?
                                        <CompPedido
                                          key       = {index}
                                          pedido    = {item}
                                          nome      = {item.usuario.nome}
                                          maxW      = {true}  
                                        />
                                          :
                                        <CompDiaria
                                          key       = {index}
                                          diaria    = {item}
                                          onClick   = {() => {
                                           //console.log("GoDiarias btt");
                                           //console.log("GoDiarias", item);
                                            LocalStorage.set("diaria-select", item);
                                            serverRouter("diaria");
                                          }}
                                        />
                                      }{
                                        arrayState.length - 1 === index && load.compA &&
                                        <Loading mode = {false} />
                                      }
                                    </>
                                  );
                                })
                                  :
                                <div className = "contain-not-agend">
                                  <img 
                                    src   = {notAgend}
                                    alt   = "Not Agend"
                                  />
                                  <h2>
                                    {sttPage.modeCl === "pedidos" ? "Não possui agendamentos cadastrados" : "Não possui diárias cadastradas"}
                                  </h2>
                                </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </>
  );
}
