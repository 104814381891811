import React        from "react";

import "./stylesII.css";


export default function InputIcon({placeHolder, type, value, onChange, Icon, minW}) {

  return (
    <div 
      className = "contain-comp-input-forms"
      style     = {minW ? {width: "100%", marginBottom: "3px"} : {}}
    >
      <input
        className     = "input-comp"
        placeholder   = {placeHolder}
        type          = {type}
        value         = {value}
        onChange      = {onChange}
        /> 
        <Icon
          size      = {25}
          color     = "#A8A8A8"
        />
    </div>
  );
};