import React              from "react";
import Swal               from "sweetalert2";
import { AuthContext }    from "./AuthContext";
import { LocalStorage }   from '../services';
import { Alerts }         from "../components/Basics";
import ChatAPI            from "../servicesAPI/Chat";
import { set } from "lodash";
//import { alertMP3 }       from "../assets";  
//import { io }             from "socket.io-client";

export const ChatContext = React.createContext({
  states     : {
    loading       : true,
    loadingCE     : false,
    loadingCEPg   : false,
    loadingCS     : false,
    loadSend      : false,
    
    modeCE        : true,
    chatOn        : false,
    chatMNLP      : false,
    chatMNLE      : false,
    chatEmpyP     : false,
    chatEmpyE     : false,
    chat_CEP      : [],
    chat_CEE      : [],
    chat_Sel      : null,
    paginaP       : {
      limit       : 10,
      offset      : 0,
      maxScroll   : 0,
    },
    paginaE       : {
      limit       : 10,
      offset      : 0,
      maxScroll   : 0,
    },
  },
  actions : {
    setChat_CEPContext            : (chats)=>{},
    setChat_CEEContext            : (chats)=>{},
    setChat_SelContext            : (chat)=>{},
    handleModeCE                  : (mode)=>{},
    handleLoad                    : (idx, val)=>{},
    handleChatOn                  : (val)=>{},
    OpenChatPedido                : async (pedido)=>{},
    OpenChatEmpresa               : async (usuario)=>{},
    ReturnChatMsg                 : async (input, id)=>{},
    SendMsgChat                   : async (input, idchat)=>{},
    BuscarCaixaEntradaPaginado    : async ()=>{},
  }
});

export default function ChatContextProvider({children}){

  const {
    date, token, isAuth
  } = React.useContext(AuthContext);
  // paginação
  const [paginaP, setPaginaP]       = React.useState({
    limit       : 10,
    offset      : 0,
    maxScroll   : 0,
  });
  const [paginaE, setPaginaE]       = React.useState({
    limit       : 10,
    offset      : 0,
    maxScroll   : 0,
  });
  const [sttCtx, setSttCtx]       = React.useState({
    modeCE      : true,         // true = pedidos, false = clientes
    chatEmpyP   : false,        // chat vazio pedido
    chatEmpyE   : false,        // chat vazio empresa
    chatOn      : false,        // chat aberto, -> modal ou page do chat!
    chatMNLP    : false,        // chat mensagem nova pedido
    chatMNLE    : false,        // chat mensagem nova empresa
  });

  
  const [chat_CEP, setChat_CEP]   = React.useState([]);
  const [chat_CEE, setChat_CEE]   = React.useState([]);
  const [chat_Sel, setChat_Sel]   = React.useState(null);
  //const [socket, setSocket]       = React.useState(null);
  //console.log("ChatContext: ", sttCtx);
  const [load, setLoad]           = React.useState({
    loading     : true,        // loading page
    loadingCE   : false,        // loading chat caixa de entrada
    loadingCEPg : false,        // loading chat caixa de entrada paginação
    loadingCS   : false,        // loading chat selecionado
    loadingSend : false,        // loading send message
  });
  //console.log("ChatContext: ", chat_CE);
  //console.log("ChatContext: ", chat_CEP, chat_CEE, chat_Sel);
  // Função que atualiza a caixa de entrada do usuário ao abrir contexto autenticado
  // Função que busca a caixa de entrada do usuário
  async function BuscarCaixaEntrada() {
    let reqs = null;
    if(sttCtx.modeCE){ 
      reqs = await ChatAPI.BuscarCaixaEntrada( token, date.empresa.idempresa, paginaP.limit, 0, true );
      if(reqs.status){
        if(reqs.req.retorno){
          setPaginaP({...paginaP, maxScroll: reqs.req.chat.count, offset: paginaP.offset + paginaP.limit});
          //console.log("MaxScroll: ", maxScroll);
          if(reqs.req.chat.rows.length){ // Tem chats!
            if(chat_Sel !== null && chat_Sel.status === "ABERTO"){
              const idx = reqs.req.chat.rows.findIndex(chat => chat.idchat === chat_Sel.idchat);
              if(idx !== -1){
                // Verifica se tem novas mensagens
                if(reqs.req.chat.rows[idx].ultimaMensagem !== null){
                  if(reqs.req.chat.rows[idx].ultimaMensagem.status === "ENVIADA" && !reqs.req.chat.rows[idx].ultimaMensagem.criadaPorAdmin){
                    const resp = await ChatAPI.AtualizarStsMsgLida( token, chat_Sel.idchat );
                    //console.log("Atualiza msg: ", resp);
                    if(resp.status){
                      const reqC = await ChatAPI.BuscarChatId( token, chat_Sel.idchat );  
                      if(reqC.status){
                        if(reqC.ress.mensagens.length > 0){
                          const index = reqC.ress.mensagens.length - 1;
                          reqC.ress.mensagens[index].status   = "LIDA";
                          reqC.ress.ultimaMensagem            = reqC.ress.mensagens[reqC.ress.mensagens.length - 1];
                          reqC.ress.nomeUsuario               = reqC.ress.pedido.usuario.nome;
                          reqs.req.chat.rows[idx]             = reqC.ress;
                          setChat_SelContext(reqC.ress);
                        } 
                      }
                    }
                  } 
                }
              }
            }
            setChat_CEPContext(reqs.req.chat.rows);
            if(sttCtx.chatEmpyP)                              setSttCtx({...sttCtx, chatEmpyP: false});
          } else { // Não tem chats!
            if(sttCtx.chatEmpyP) setSttCtx({...sttCtx, chatEmpyP: true});
            setChat_CEPContext([]);
          }
        } else {
          if(!sttCtx.chatEmpyP) setSttCtx({...sttCtx, chatEmpyP: true});
          setChat_CEPContext([]);
        }
      } else {
        Alerts.aviso("Aviso", "Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde");
      }
    } else {
      reqs = await ChatAPI.BuscarCaixaEntradaEmpresaUsuario( token, date.empresa.idempresa, paginaE.limit, 0, true );
      //console.log("ChatContext -- BuscarCaixaEntrada: ", reqs);
      setPaginaE({...paginaE, maxScroll: reqs.count, offset: paginaE.offset + paginaE.limit});
      if(reqs.status){
        if(reqs.rows.length){ // Tem chats!
          if(chat_Sel !== null && chat_Sel.status === "ABERTO"){
            const idx = reqs.rows.findIndex(chat => chat.idchat === chat_Sel.idchat);
            if(idx !== -1){
              // Verifica se tem novas mensagens
              if(reqs.rows[idx].ultimaMensagem !== null){
                if(reqs.rows[idx].ultimaMensagem.status === "ENVIADA" && !reqs.rows[idx].ultimaMensagem.criadaPorAdmin){
                  const resp = await ChatAPI.AtualizarStsMsgLida( token, chat_Sel.idchat );
                  //console.log("Atualiza msg: ", resp);
                  if(resp.status){
                    const reqC = await ChatAPI.BuscarChatIdEmpresaUsuario( token, chat_Sel.idchat, date.empresa.idempresa );  
                    if(reqC.status){
                      if(reqC.chat.mensagens.length > 0){
                        const index = reqC.chat.mensagens.length - 1;
                        reqC.chat.mensagens[index].status   = "LIDA";
                        reqC.chat.ultimaMensagem            = reqC.chat.mensagens[reqC.chat.mensagens.length - 1];
                        reqC.chat.nomeUsuario               = reqC.chat.chat_empresa_usuario.usuario.nome;
                        reqs.rows[idx]                      = reqC.chat;
                        setChat_SelContext(reqC.chat);
                      } 
                    }
                  }
                } 
              }
            }
          }
          setChat_CEEContext(reqs.rows);
          if(sttCtx.chatEmpyE)                  setSttCtx({...sttCtx, chatEmpyE: false});
        } else { // Não tem chats!
          if(!sttCtx.chatEmpyE) setSttCtx({...sttCtx, chatEmpyE: true});
          setChat_CEEContext([]);
        }
      } else {
        Alerts.aviso("Aviso", "Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde");
      }
    } 
    //console.log("Resposta da caixa de entrada: ", reqs);
    setLoad({
      ...load, 
      loading   : false,
      loadingCE : false
    });
  }
  //console.log("Paginação P: ", paginaP);
  async function BuscarCaixaEntradaPaginado(){
    let reqs = null;
    setLoad({ ...load, loadingCEPg: true });
    if(sttCtx.modeCE){
      reqs = await ChatAPI.BuscarCaixaEntrada( token, date.empresa.idempresa, paginaP.limit, paginaP.offset, false );
      //console.log("ChatContext -- BuscarCaixaEntradaPaginado: chat_CEP ", reqs);
      if(reqs.status){
        if(reqs.req.retorno){
          //console.log("MaxScroll: ", maxScroll);
          if(reqs.req.chat.rows.length){ // Tem chats!
            setPaginaP({...paginaP, offset: paginaP.offset + reqs.req.chat.rows.length});
            setChat_CEPContext([...chat_CEP, ...reqs.req.chat.rows]);
          } else {
            //console.log("ChatContext -- BuscarCaixaEntradaPaginado: ", reqs);
          }
        }
      } else {
        Alerts.aviso("Aviso", "Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde");
      }
    } else {
      reqs = await ChatAPI.BuscarCaixaEntradaEmpresaUsuario( token, date.empresa.idempresa, paginaE.limit, paginaE.offset, true );
      //console.log("ChatContext -- BuscarCaixaEntrada: chat_CEE :", reqs);
      setPaginaE({...paginaE, maxScroll: reqs.count, offset: paginaE.offset + paginaE.limit});
      if(reqs.status){
        if(reqs.rows.length){ // Tem chats!
          //CheckMsgs(reqs.req.rows);
          setChat_CEEContext([...chat_CEE, ...reqs.rows]);
        } else { 
          //console.log("ChatContext -- BuscarCaixaEntradaPaginado: ", reqs);
        }
      } else {
        Alerts.aviso("Aviso", "Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde");
      }
    }
    setLoad({ ...load, loadingCEPg: false });
  }

  // Função que verifica se há novas mensagens na caixa de entrada do usuário
  async function VerifyChatMNL(){
    //console.log("VerifyChatMNL : ");
    const res = await ChatAPI.AtualizarChatEmpresa( token, date.empresa.idempresa );
    //console.log("ChatContext -- Verify: ", res);
    if(res.status){
      //console.log("Ress", res);
      //console.log("Chat_MNL: ", chat_MNL);
      if(res.req.error){
        return {
          error   : res.req.error,
          status  : true, 
        };
      }
      if(res.req.status){
        
        if(sttCtx.chatMNLP !== res.req.retorno.chatPedido || sttCtx.chatMNLE !== res.req.retorno.chatUsuario){
          setSttCtx({ 
            ...sttCtx,
            chatMNLP: res.req.retorno.chatPedido,
            chatMNLE: res.req.retorno.chatUsuario,
          });
          
          return { status: true };
        }
        return { status: true };
        //console.log("ChatContext -- VerifyChatMNL: ", res);
        //await UpdateChatUser();
      } 
    } else {
      return {status: true, error: "else"};
    }

    
    return { status : false };
  }
  // Função que atualiza o status da mensagem para lida
  async function ConfirmarLeitura(idchat){
    const res = await ChatAPI.AtualizarStsMsgLida( token, idchat );
    if(res.status){
      return { status: true };
    } else {
      return { status: false };
    }
  }
  //console.log("States: ", sttCtx, "Chat: ", chat_CEP, "ChatSel: ", chat_Sel, "Load: ", load);
  // Conecta-se com o socket para chats do servidor!
  //const litarlsSocket = React.useRef(true);
  //console.log("ChatContext: ", sttCtx);
  const literalsA = React.useRef(true);
  React.useEffect(() => {
    if(isAuth && literalsA.current) {
      VerifyChatMNL();
      literalsA.current = false;
    }
  }, [isAuth, chat_Sel, sttCtx.modeCE, sttCtx.chatMNLP, sttCtx.chatMNLE]);

  const atuaRef  = React.useRef(true);
  React.useEffect(() => {
    //*
    //console.log("ChatContext: ", isAuth);
    if(isAuth && date.perfilG.chat && atuaRef.current) { // Se logado, carrega a caixa de entrada do usuário e inicia o timer de 15 segundos 
      if(chat_Sel !== null) { // Para um chat selecionado, inicia o timer de 10 segundos
        atuaRef.current = false;
        //console.log("Cria timer 10 segundos!");
        const interval = setInterval(async () => {
          //console.log("Verifica ChatMNL: 10s");
          // Todo :: Implementar filtro, que tranca a requisição caso o chat esteja fechado e caso não esteja recebendo respostas, aumentar o tempo de atualização!
          const valid = await VerifyChatMNL();
          if(!valid.status) {
            //console.log("ChatContext Verifica ChatMNL: ", valid);
            clearInterval(interval);
          }
        }, 10000);
        return () => {
          clearInterval(interval);
          atuaRef.current = true;
        }
      } else { // Para a caixa de entrada, inicia o timer de 45 segundos
        atuaRef.current = false;
        //console.log("Cria timer 45 segundos!");
        const interval = setInterval(async () => {
          //console.log("Verifica ChatMNL: 45s");
          // Todo :: Implementar filtro, que tranca a requisição caso o chat esteja fechado e caso não esteja recebendo respostas, aumentar o tempo de atualização!
          const valid = await VerifyChatMNL();
          if(!valid.status) {
            //console.log("ChatContext Verifica ChatMNL: ", valid);
            clearInterval(interval);
          }
        }, 45000);
        return () => {
          clearInterval(interval);
          atuaRef.current = true;
        }
      }
    }
    //*/
  }, [isAuth, sttCtx.chatMNLP, sttCtx.chatMNLE, chat_CEP, chat_CEE, sttCtx.modeCE, chat_Sel]);
  //}, [ isAuth, sttCtx.chatMNLP, sttCtx.chatMNLE, sttCtx.modeCE, chat_Sel]);

  // Busca a caixa de entrada do usuário
  React.useEffect(() => {
    //console.log("ChatContext -- Busca Chat Empresa", sttCtx.chatOn, isAuth, sttCtx.modeCE);
    if(sttCtx.chatOn && isAuth){
      if(sttCtx.modeCE) {
        // Verifica se a caixa de entrada de pedidos esta vazia
        if((chat_CEP.length === 0 && !sttCtx.chatEmpyP) || sttCtx.chatMNLP){
          BuscarCaixaEntrada();
        } else {
          // Desativa o loading da caixa de entrada
          setLoad({...load, loadingCE: false});
        }
      } else {
        // Verifica se a caixa de entrada de clientes esta vazia
        if((chat_CEE.length === 0 && !sttCtx.chatEmpyE) || sttCtx.chatMNLE){
          BuscarCaixaEntrada();
        } else {
          // Desativa o loading da caixa de entrada
          setLoad({...load, loadingCE: false});
        }
      }
      // Busca a caixa de entrada da empresa
      //console.log("ChatContext -- Busca Chat Empresa");
    }
    //if(load.loadingCE) setLoad({...load, loadingCE: false});

  }, [ sttCtx.chatOn, sttCtx.modeCE, sttCtx.chatMNLP, sttCtx.chatMNLE, isAuth]);
  // Função que retorna o chat selecionado
  async function ReturnChatMsg(idchat, idxCE) {
    setLoad({...load, loadingCS: true});
    let ress = null;
    if(sttCtx.modeCE){
      ress = await ChatAPI.BuscarChatId( token, idchat );
      if(ress.status){
        /* Verifica Leitura!!! */
        if(chat_CEP[idxCE].ultimaMensagem !== null){
          const index = ress.ress.mensagens.length - 1;
          if(ress.ress.mensagens[index].status === "ENVIADA" && !ress.ress.mensagens[index].criadaPorAdmin){
            const resp = await ChatAPI.AtualizarStsMsgLida( token, idchat );
            //console.log("Atualiza msg: ", resp);
            if(resp.status){
              //console.log("Entrou aqui! Atualiza msg: ");
              ress.ress.mensagens[index].status   = "LIDA";
              ress.ress.ultimaMensagem            = ress.ress.mensagens[index];
              ress.ress.nomeUsuario               = ress.ress.pedido.usuario.nome;
              chat_CEP[idxCE]                     = ress.ress;
              setChat_CEPContext(chat_CEP);
              await VerifyChatMNL();
            } else {
              Swal.fire({
                icon  : "warning",
                title : "Aviso",
                text  : "Não foi possível atualizar o status da mensagem, por favor tente novamente mais tarde",
                timer : 6000,
                confirmButtonText : "Entendi",
                confirmButtonColor: "#DA5A34",
              });
            }
          } else {
            ress.ress.ultimaMensagem = ress.ress.mensagens[index];
            ress.ress.nomeUsuario    = ress.ress.pedido.usuario.nome;
            chat_CEP[idxCE]          = ress.ress;
            setChat_CEPContext(chat_CEP);
          }
        } else {
          ress.ress.ultimaMensagem = ress.ress.mensagens[ress.ress.mensagens.length - 1];
          ress.ress.nomeUsuario    = ress.ress.pedido.usuario.nome;
          chat_CEP[idxCE]          = ress.ress;
        }
        //console.log("ChatContext -- ReturnChatMsg: ", ress.ress);
        setChat_SelContext(ress.ress);
      } else setChat_SelContext(null);
    } else {
      ress = await ChatAPI.BuscarChatIdEmpresaUsuario( token, idchat, date.empresa.idempresa );
      //console.log("ChatContext -- ReturnChatMsg: ", ress);
      if(ress.status){
        if(ress.chat.mensagens.length > 0){
          const index = ress.chat.mensagens.length - 1;
          if(ress.chat.mensagens[index].status === "ENVIADA" && !ress.chat.mensagens[index].criadaPorAdmin){
            const resp = await ChatAPI.AtualizarStsMsgLida( token, idchat );
            //console.log("Atualiza msg: ", resp);
            if(resp.status){
              ress.chat.mensagens[index].status   = "LIDA";
              ress.chat.ultimaMensagem            = ress.chat.mensagens[index];
              ress.chat.nomeUsuario               = ress.chat.chat_empresa_usuario.usuario.nome;
              chat_CEE[idxCE]                     = ress.chat;
              setChat_CEEContext(chat_CEE);
              await VerifyChatMNL();
            } else {
              Swal.fire({
                icon                : "warning",
                title               : "Aviso",
                text                : "Não foi possível atualizar o status da mensagem, por favor tente novamente mais tarde",
                timer               : 6000,
                confirmButtonText   : "Entendi",
                confirmButtonColor  : "#DA5A34",
              });
            }
          }
        }
        setChat_SelContext(ress.chat);
      } else {
        setChat_SelContext(null);
      }

    }
    if(load.loadingCE) setLoad({...load, loadingCS: false, loadingCE: false});
    else setLoad({...load, loadingCS: false});
  }
  //Envia a msg para o chat selecionado!
  async function SendMsgChat(input, idchat){
    if(input.trim() !== ""){
      setLoad({...load, loadingSend: true});
      // Usa a função de enviar mensagem do ChatAPI
      const ress = await ChatAPI.SendMensChat( token, idchat, input, date.gestor.nome, sttCtx.modeCE );
      //console.log("ChatContext -- SendMsgChat: ", ress);
      if(ress.status){
        // Atualiza o chat selecionado
        let idx = null;
        if(sttCtx.modeCE){
          idx = chat_CEP.findIndex(chat => chat.idchat === idchat);
          ress.ress.nomeUsuario         = chat_CEP[idx].nomeUsuario;
          ress.ress.ultimaMensagem      = ress.ress.mensagens[ress.ress.mensagens.length - 1];
          const array = chat_CEP;
          array[idx].ultimaMensagem     = ress.ress.ultimaMensagem;
          array[idx].nomeUsuario        = ress.ress.nomeUsuario;
          if(idx !== 0){
            const item = array[idx];
            array.splice(idx, 1);
            array.unshift(item);
          } else {
            array[idx] = ress.ress;
          }
          setChat_CEPContext(array);
          chat_Sel.mensagens.push(ress.ress.ultimaMensagem);
        } else {
          idx = chat_CEE.findIndex(chat => chat.idchat === idchat);
          chat_CEE[idx].ultimaMensagem = ress.ress;
          if(idx !== 0){ // Regra de negócio, um chat enviado sempre esta na caixa de entrada!!! não verifico -1 por isso!
            const item = chat_CEE[idx];
            chat_CEE.splice(idx, 1);
            chat_CEE.unshift(item);
          } 
          //console.log("ChatContext -- SendMsgChat: ", array, idx, chat_CEE);
          setChat_CEEContext(chat_CEE);
          chat_Sel.mensagens.push(ress.ress);
        }
        setChat_SelContext(chat_Sel);
      } else {
        Alerts.aviso("Aviso", "Não foi possível enviar a mensagem, por favor tente novamente mais tarde!");
      }
      setLoad({...load, loadingSend: false});
      // Verifica se o socket esta conectado
      /*if(false){//socket && socket.connected){
        setLoad({...load, loadingSend: true});
        //console.log("Socket Conectado!");
        /*socket.emit('enviarMensagem', {
          modo_caixa_entrada : sttCtx.modeCE ? "modo_pedido" : "modo_empresa",
          idchat : idchat,
          mensagem : {
            texto           : input,
            enviadoPor      : date.gestor.nome,
            criadaPorAdmin  : true,
            chat_idchat     : idchat,
          }
        });
      } else {
        
      }*/
      // Emite a mensagem para o servidor
    }
  }
  // Abre um chat pedido
  async function OpenChatPedido( pedido ){
    //console.log("OpenChatPedido: 123 ", pedido);
    const ress = await ChatAPI.BuscarChatId( token, pedido.chat_idchat );
    //console.log("OpenChatPedido: ", ress);
    if(ress.status){
      // Pedido > então seta modoCE como true se estiver em false
      if(!sttCtx.modeCE) setSttCtx({...sttCtx, modeCE: true});
      // Procura o chat na caixa de entrada!
      if(ress.ress.mensagens.length > 0){ // Se for maior que zero, ele deve estar na caixa de entrada! Se não encontrar é por que, muito provavelmente, não foi carregada a caixa de entrada!
        let idx = chat_CEP.findIndex(chat => chat.idchat === pedido.chat_idchat);
        if(idx === -1){ // Adiciona o chat na caixa de entrada (Não deveria abrir esse if!!!)
          //console.log("Pela lógica não deveria não achar, se mensagens > 0, mas vamos adicionar!");
          ress.ress.ultimaMensagem = ress.ress.mensagens[ress.ress.mensagens.length - 1];
          ress.ress.nomeUsuario    = ress.ress.pedido.usuario.nome;
          setChat_CEPContext([ress.ress, ...chat_CEP]);
          setChat_SelContext(ress.ress);
          setLoad({...load, loading: false, loadingCE: false, loadingCS: false});
          return 0;
        } else {
          // Atualiza o chat selecionado
          const index = ress.ress.mensagens.length - 1;
          if(ress.ress.mensagens[index].status === "ENVIADA" && !ress.ress.mensagens[index].criadaPorAdmin){
            const resp = await ChatAPI.AtualizarStsMsgLida( token, pedido.chat_idchat );
            //console.log("Atualiza msg: ", resp);
            if(resp.status){
              ress.ress.mensagens[index].status = "LIDA";
              ress.ress.ultimaMensagem  = ress.ress.mensagens[index];
              ress.ress.nomeUsuario     = ress.ress.pedido.usuario.nome;
              chat_CEP[idx]             = ress.ress;
              setChat_CEPContext(chat_CEP);
            } else {
              Alerts.aviso("Aviso", "Não foi possível atualizar o status da mensagem. Por favor, tente novamente mais tarde");
              return null;
            }
          } else {
            ress.ress.ultimaMensagem = ress.ress.mensagens[index];
            ress.ress.nomeUsuario    = ress.ress.pedido.usuario.nome;
            chat_CEP[idx]            = ress.ress;
            setChat_CEPContext(chat_CEP);
          }
          setChat_SelContext(ress.ress);
          setLoad({...load, loading: false, loadingCE: false, loadingCS: false});
          return idx;
        }
      } else {
        //console.log("Chat não encontrado!", ress, sttCtx.modeCE);
        if(ress.ress.status === "ABERTO") {
          // monta e adiciona o chat na caixa de entrada
          ress.ress.nomeUsuario = ress.ress.pedido.usuario.nome;
          setChat_CEPContext([ress.ress, ...chat_CEP]);
          setChat_SelContext(ress.ress);
          setLoad({...load, loading: false, loadingCE: false, loadingCS: false});
          return 0;
        } else {
          Swal.fire({
            icon  : "warning",
            title : "Aviso",
            text  : "Este agendamento não possibilita abrir um novo chat. Por favor, vá para a página da empresa e abra um chat por lá.",
            confirmButtonText   : "Entendi",
            confirmButtonColor  : "#DA5A34"
          });
        } 
      }
    } return null;
  }
  // Abre um chat empresa
  async function OpenChatEmpresa( usuario ){
    const ress = await ChatAPI.BuscarChatEmpresaUsuario( token, date.empresa.idempresa, usuario.idusuario);
    //console.log("OpenChatEmpresa: ", ress);
    if(ress.status){
      // Procura o chat na caixa de entrada!
      let idx = chat_CEE.findIndex(chat => chat.idchat === ress.req.chat.idchat);
      if(ress.req.chat.mensagens.length > 0){  // Se for maior que zero, ele deve estar na caixa de entrada! Se não encontrar é por que, muito provavelmente, não foi carregada a caixa de entrada!
        // Se não encontrar na caixa de entrada, buscar a caixa de entrada!
        // Inverte a ordem das mensagens!
        ress.req.chat.mensagens = ress.req.chat.mensagens.reverse();
        if(idx === -1){
          // Busca caixa de entrada da empresa
          const reqs = await ChatAPI.BuscarCaixaEntradaEmpresaUsuario( token, date.empresa.idempresa, paginaE.limit, 0, true );
          //console.log("ChatContext -- BuscarCaixaEntrada: ", reqs);
          setPaginaE({...paginaE, maxScroll: reqs.count, offset: paginaE.offset + paginaE.limit});
          if(reqs.status){
            if(reqs.rows.length){ // Tem chats!
              // Adiciona o chat na caixa de entrada
              // Procura o chat na caixa de entrada!
              let idx = reqs.rows.findIndex(chat => chat.idchat === ress.req.chat.idchat);
              if(idx === -1){
                // Adiciona o chat na caixa de entrada
                ress.req.chat.ultimaMensagem        = ress.req.chat.mensagens[ress.req.chat.mensagens.length - 1];
                ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
                ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
                setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
                setChat_CEEContext([ress.req.chat, ...reqs.rows]);
                setChat_SelContext(ress.req.chat);
                setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
                return 0;
              } else {
                // Atualiza o chat selecionado
                const index = ress.req.chat.mensagens.length - 1;
                if(ress.req.chat.mensagens[index].status === "ENVIADA" && !ress.req.chat.mensagens[index].criadaPorAdmin){
                  const resp = await ChatAPI.AtualizarStsMsgLida( token, ress.req.chat.idchat );
                  //console.log("Atualiza msg: ", resp);
                  if(resp.status){
                    ress.req.chat.mensagens[index].status = "LIDA";
                    ress.req.chat.ultimaMensagem        = ress.req.chat.mensagens[index];
                    ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
                    ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
                    reqs.rows[idx]                      = ress.req.chat;
                    setChat_CEEContext(reqs.rows);
                    setChat_SelContext(ress.req.chat);
                    return idx;
                  } else {
                    Alerts.aviso("Aviso", "Não foi possível atualizar o status da mensagem. Por favor, tente novamente mais tarde");
                    return null;
                  }
                } else {
                  ress.req.chat.ultimaMensagem        = ress.req.chat.mensagens[index];
                  ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
                  ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
                  reqs.rows[idx]                      = ress.req.chat;
                }
              }
              setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
              setChat_SelContext(ress.req.chat);
              setChat_CEEContext(reqs.rows);
              return idx;
            } else { // Não tem chats!
              setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
              ress.req.chat.ultimaMensagem        = null;
              ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
              ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
              setChat_CEEContext([ress.req.chat]);
              setChat_SelContext(ress.req.chat);
              return 0;
            }
          } else {
            Alerts.aviso("Aviso", "Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde");
            return null;
          }
        } else {
          // Atualiza o chat selecionado
          const index = ress.req.chat.mensagens.length - 1;
          if(ress.req.chat.mensagens[index].status === "ENVIADA" && !ress.req.chat.mensagens[index].criadaPorAdmin){
            const resp = await ChatAPI.AtualizarStsMsgLida( token, ress.req.chat.idchat );
            //console.log("Atualiza msg: ", resp);
            if(resp.status){
              ress.req.chat.mensagens[index].status = "LIDA";
              ress.req.chat.ultimaMensagem          = ress.req.chat.mensagens[index];
              ress.req.chat.nomeUsuario             = ress.req.usuario.nome;
              ress.req.chat.chat_empresa_usuario    = { usuario: ress.req.usuario };
              chat_CEE[idx]                         = ress.req.chat;
            } else {
              Swal.fire({
                icon  : "warning",
                title : "Aviso",
                text  : "Não foi possível atualizar o status da mensagem. Por favor, tente novamente mais tarde",
                timer : 6000,
                confirmButtonText   : "Entendi",
                confirmButtonColor  : "#DA5A34",
              });
              return null;
            }
          } else {
            ress.req.chat.ultimaMensagem        = ress.req.chat.mensagens[index];
            ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
            ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
            chat_CEE[idx]                       = ress.req.chat;
          }
          setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
          setChat_SelContext(ress.req.chat);
          setChat_CEEContext(chat_CEE);
          return idx;
        }
      } else {
        // Se caixa de entrada estiver vazia, e chatEmpyE for false, busca a caixa de entrada!
        if(chat_CEE.length === 0 && !sttCtx.chatEmpyE){
          const reqs = await ChatAPI.BuscarCaixaEntradaEmpresaUsuario( token, date.empresa.idempresa, paginaE.limit, 0, true );
          //console.log("ChatContext -- BuscarCaixaEntrada: ", reqs);
          setPaginaE({...paginaE, maxScroll: reqs.count, offset: paginaE.offset + paginaE.limit});
          if(reqs.status){
            if(reqs.rows.length){ // Tem chats!
              // Adiciona o chat na caixa de entrada
              // Procura o chat na caixa de entrada!
              ress.req.chat.ultimaMensagem        = null;
              ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
              ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };

              setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
              setChat_SelContext(ress.req.chat);
              setChat_CEEContext([ ress.req.chat, ...reqs.rows]);
              return idx;
            } else { // Não tem chats!
              ress.req.chat.ultimaMensagem        = null;
              ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
              ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
              setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
              setChat_CEEContext([ress.req.chat]);
              setChat_SelContext(ress.req.chat);
              return 0;
            }
          } else {
            Swal.fire({
              icon    : 'warning',
              title   : 'Aviso',
              text    : 'Estamos com problemas para carregar sua caixa de entrada. Por favor, tente novamente mais tarde',
              timer   : 6000,
              confirmButtonText   : 'Entendi',
              confirmButtonColor  : '#DA5A34',
            });
            return null;
          }
        } else if(chat_CEE.length) {
          // Adiciona o chat na caixa de entrada
          ress.req.chat.ultimaMensagem        = null;
          ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
          ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
          setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
          if(chat_CEE.findIndex(chat => chat.idchat === ress.req.chat.idchat) === -1) setChat_CEEContext([ress.req.chat, ...chat_CEE]);
          setChat_SelContext(ress.req.chat);
          return idx;
        } else { // Se caixa de entrada estiver vazia, e chatEmpyE for true, não busca a caixa de entrada! Só adiciona o chat na caixa de entrada!
          ress.req.chat.ultimaMensagem        = null;
          ress.req.chat.nomeUsuario           = ress.req.usuario.nome;
          ress.req.chat.chat_empresa_usuario  = { usuario: ress.req.usuario };
          setSttCtx({...sttCtx, chatEmpyE: false, modeCE: false});
          setChat_CEEContext([ress.req.chat]);
          setChat_SelContext(ress.req.chat);
          return 0;
        }
      }
    } else {
      Alerts.aviso("Aviso", "Não foi possível abrir o chat, por favor, tente novamente mais tarde!");
      return null;
    }
  }
  // Função que atualiza o chat aberto ou fechado, cada vez que abre, atualiza caixa de entrada pedido ou cliente, depende do modeCE = true(pedido) ou false(cliente)
  function handleChatOn(val){
    setSttCtx(stt => ({...stt, chatOn: val}));
  }
  //console.log("ChatContext -- ChatContext: sttCtx: ", sttCtx, 'Chat_CEE: ', chat_CEE);
  // Função que altera o modo da caixa de entrada, entre pedidos e cliente
  function handleModeCE(mode){
    
    //litarlsSocket.current = true;
    // Desconecta o socket, se estiver conectado
    /*if(socket) {
      //console.log("SairChat: ", chat_Sel.idchat);
      socket.emit('sairChat', { idchat: chat_Sel.idchat });
      socket.close();
      setSocket(null);
    }*/
    atuaRef.current = true;
    setChat_SelContext(null);
    setSttCtx({...sttCtx, modeCE: mode});
  }
  // Função que atualiza a caixa de entrada, pedido ou cliente
  function setChat_CEPContext(chats){
    setChat_CEP(chats);
    LocalStorage.set('caixa-entrada-pedidos-gestor', JSON.stringify(chats));
  }
  // Função que atualiza a caixa de entrada, pedido ou cliente
  function setChat_CEEContext(chats){
    setChat_CEE(chats);
    LocalStorage.set('caixa-entrada-pedidos-gestor', JSON.stringify(chats));
  }
  // Função que atualiza o chat selecionado
  function setChat_SelContext(chat){
    /*if(chat === null){
      if(chat_Sel !== null && socket){
        //console.log("SairChat: ", chat_Sel.idchat);
        socket.emit('sairChat', { idchat: chat_Sel.idchat });
        socket.close();
        setSocket(null);
      }
    }*/
    setChat_Sel(chat);
    LocalStorage.set('chat-selecionado', chat);
  }
  // Função que atualiza o estado de carregamento da tela
  function handleLoad(idx, val){  
    switch(idx) {
      case 0  : setLoad({...load, loading: val});       break;
      case 1  : setLoad({...load, loadingCE: val});     break;
      case 2  : setLoad({...load, loadingCEPg: val});   break;
      case 3  : setLoad({...load, loadingCS: val});     break;
      case 4  : setLoad({...load, loadingSend: val});   break;

      default : return null;
    }
  }
  
  return (
    <ChatContext.Provider 
      value = {{
        states : {
          chat_CEP, chat_CEE, chat_Sel,
          paginaP, paginaE,
          modeCE        : sttCtx.modeCE,
          chatOn        : sttCtx.chatOn,
          chatMNLP      : sttCtx.chatMNLP,
          chatMNLE      : sttCtx.chatMNLE,
          chatEmpyP     : sttCtx.chatEmpyP,
          chatEmpyE     : sttCtx.chatEmpyE,

          loading       : load.loading, 
          loadingCE     : load.loadingCE,
          loadingCEPg   : load.loadingCEPg,
          loadingCS     : load.loadingCS,
          loadSend      : load.loadingSend, 
        },
        actions : {
          setChat_CEPContext,
          setChat_CEEContext, 
          setChat_SelContext, 
          handleModeCE,
          handleChatOn,
          handleLoad,
          ReturnChatMsg, 
          SendMsgChat, 
          OpenChatPedido, 
          OpenChatEmpresa,
          BuscarCaixaEntradaPaginado,
        }
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}