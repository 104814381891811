import React                        from "react";
import { BiCalendar, BiCalendarEdit, BiCircle, BiLeftArrowAlt, BiSolidMessageSquareError } from "react-icons/bi";
import { EmpresaAPI, PedidosAPI }               from "../../../servicesAPI";
import { 
  ErroComunic, Loading, CompICEx, Alerts 
} from "../../Basics";
import { 
  MdOutlineMarkEmailRead, MdOutlineSimCardDownload 
} from "react-icons/md";
import { CgFileDocument }           from "react-icons/cg";
import { BsBookmarkStar }           from "react-icons/bs";
import { GiReceiveMoney }           from "react-icons/gi";
import { TbFileSpreadsheet }        from "react-icons/tb";
import { LuCopy }                   from "react-icons/lu";
import { RiFileUserFill }           from "react-icons/ri";
import { AuthContext }              from "../../../contexts/AuthContext";
import RouterHook                   from "../../../contexts/RouterHook";
import { Check, logo, notAgend }          from "../../../assets";
import { LocalStorage, Services }                 from "../../../services";
import { ModalInfos }               from "../../Modais";
import moment                       from "moment";

import "./stylesAss.css";
import { FaCheck } from "react-icons/fa";

export default function Assinatura() {

  const serverRouter    = RouterHook();
  const { token, date } = React.useContext(AuthContext);
  const [load, setLoad] = React.useState({
    page      : true,
    arrayStt  : false,
    faturaSel : false,
  });
  
  const [sttPage, setSttPage] = React.useState({
    erroComunic   : false,
    modeViewPage  : true,    //true = Histórico de Faturas, false = Meu Plano
    faturas       : [],
    faturaSel     : null,
    plano         : date.empresa.licenca,
    modalPgto     : false,
  });

  //console.log("sttPage: ", sttPage);

  async function BuscarFaturamento() {
    const req = await EmpresaAPI.BuscarCicloFaturas( token, date.empresa.idempresa );
    //console.log("BuscaFaturas: ",req);
    if(req.status){
      // Percorre o array req.req e joga para cima os pagamentos pendentes atributo statusPagamento = "pending"
      for(let i = 0; i < req.req.length; i++){
        if(req.req[i].statusPagamento === "pending"){
          req.req.unshift(req.req.splice(i, 1)[0]);
        }
      }
      const fatSel = await BuscarFaturaId( req.req[0].idcicloFaturamento );
      setSttPage({
        ...sttPage,
        faturas     : req.req,
        faturaSel   : fatSel,
      });
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: true,
      });
    }
    setLoad({
      ...load,
      page      : false,
      arrayStt  : false,
    });
  }

  async function BuscarFaturaId(idcicloFaturamento) {
    const req = await EmpresaAPI.BuscarFaturaId( token, idcicloFaturamento );
    //console.log("BuscaFaturaId: ",req);
    if(req.status){
      return req.req;
    } else return null;
  }

  async function EnviarFaturaEmail() {
    const req = await EmpresaAPI.EnviarFaturaPorEmail( token, sttPage.faturaSel.idcicloFaturamento);
    //console.log("EnviarFaturaEmail: ",req);
    if(req.status){
      Alerts.sucesso("Sucesso", "Sua fatura foi enviada para o e-mail cadastrado: " + sttPage.faturaSel.empresa.licenca.emailResponsavel);
    } else {
      Alerts.aviso("Aviso", "Não foi possível enviar a fatura para o e-mail cadastrado: " + sttPage.faturaSel.empresa.licenca.emailResponsavel);
    }
  }

  async function handleFaturaSel(fatura) {
    const req = await BuscarFaturaId( fatura.idcicloFaturamento );
    setSttPage({
      ...sttPage,
      faturaSel: req,
    });
    setLoad({
      ...load,
      faturaSel: false,
    });
  }

  function ExportBoletoPDF(fatura) {
    // Exportar boleto em formato PDF
    window.open(fatura.urlBoletoPdf, "_blank");
  }

  //console.log("sttPage.FaturaSel: ", sttPage.faturaSel);

  React.useEffect(() => {
    //console.log("sttPage.modeViewPage: ", sttPage.modeViewPage);
    if(sttPage.modeViewPage) BuscarFaturamento();

  }, [sttPage.modeViewPage]);

  function ReturnSttPage() {

  }

  function ModalPayFatura({fatura}){

    return (
      <div className = "contain-modal-pay-fatura">
        <h2 className = "text-title-mpf">
          BOLETO GERADO
        </h2>
        <h3 className = "text-info-mpf">
          Copie o código de barra abaixo e cole em seu aplicativo bancário. O tempo de compensação é de 3 dias úteis.
        </h3>
        <div className = "contain-codigo-mpf">
          <h3>
            {fatura.codigoBarra}
          </h3>
        </div>
        <button
          className = "btt-modal-fatura-pay"
          onClick   = {() => {
            // Copiar para área de transferencia o código de barra
            navigator.clipboard.writeText(fatura.codigoBarra);
          }}
        >
          <LuCopy
            size  = {25}
            color = {"#fff"}
            style = {{marginRight: "10px"}}
          />
          Copiar código do boleto
        </button>
        <button
          className = "btt-modal-fatura-pay"
          onClick   = {() => {
            // Baixar boleto em formato PDF
            ExportBoletoPDF(fatura);
          }}
        >
          <MdOutlineSimCardDownload
            size  = {25}
            color = {"#fff"}
            style = {{marginRight: "10px"}}
          />
          Baixar boleto em formato PDF
        </button>
        <button
          className = "btt-modal-fatura-pay"
          onClick   = {() => {
            EnviarFaturaEmail();
          }}
        >
          <MdOutlineMarkEmailRead
            size  = {25}
            color = {"#fff"}
            style = {{marginRight: "10px"}}
          />
          Enviar por E-mail
        </button>
      </div>
    );
  }

  return (
    <div className = "contain-agenda-page">
      {
        <ModalInfos
          modal       = {sttPage.modalPgto}
          ClosedModal = {() => {
            setSttPage({
              ...sttPage,
              modalPgto : false,
            })
          }}
          Component   = {<ModalPayFatura fatura = {sttPage.faturaSel}/>}
        />
      }
      <div className = "contain-filtro-agenda-hf">
        <BiLeftArrowAlt
          className = "icon-arrow-back-hf"
          onClick={() => {
            ReturnSttPage();
          }}
        />
        <h2 className = "title-me-agenda"> Assinatura </h2>
        {
          load.page ? (
            <div className = "contain-loading" style={{height: "350px"}}> 
              <Loading 
              mode  = {true}
              text  = {"Carregando Assinatura..."}
              />
            </div>
          ) : 
          sttPage.erroComunic ? (
            <ErroComunic
              onClick={() => {setSttPage({...sttPage, erroComunic: false})}}
            />
          ) : (
            <div className = "contain-view-hora-fixa">
              <div className = "contain-filtra-hf" style = {{justifyContent: 'center'}}>
                
                <div className = "contain-list-clientes" style = {{minWidth: "450px", maxWidth: "500px"}}>
                  <div className = "contain-handle-peds-diars">
                    <button
                      className = { sttPage.modeViewPage ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
                      onClick   = {() => {
                        if(load.arrayStt || sttPage.modeViewPage) return;
                        setLoad({
                          ...load,
                          arrayStt  : true,
                        });
                        setSttPage({
                          ...sttPage,
                          modeViewPage  : true,
                        });
                      }}
                    >
                      Histórico de Faturas
                    </button>
                    <button
                      className = {!sttPage.modeViewPage ? "btt-select-ped-dia-select" : "btt-select-ped-dia"}
                      onClick   = {() => {
                        if(load.arrayStt || !sttPage.modeViewPage) return;
                        setSttPage({
                          ...sttPage,
                          modeViewPage: false,
                        });
                      }}
                    >
                      Meu Plano
                    </button>
                  </div>
                  <div className = "contain-list-cupons">
                    {
                      load.arrayStt ? 
                      <div className = "contain-loading">
                        <Loading mode = {false}/>
                      </div>
                        :
                      sttPage.modeViewPage ?
                        sttPage.faturas.length === 0 ?
                        <div className = "contain-not-cupons">
                          <img
                            src   = {notAgend}
                            alt   = "Sem Faturas"
                            title = "Sem Faturas"
                          />
                          <h3 className = "text-not-cupons">
                            Não há faturas para serem exibidas
                          </h3>
                        </div> 
                          :
                        <div>
                          {
                            sttPage.faturas.map((fatura, index) => {
                              return (
                                <CompFatura
                                key     = {index}
                                fatura  = {fatura}
                                index   = {index}
                                onClick = {() => {
                                  if(load.arrayStt && fatura.idcicloFaturamento === sttPage.faturaSel.idcicloFaturamento) return;
                                  setLoad({
                                    ...load,
                                    faturaSel: true,
                                  });
                                  handleFaturaSel(fatura);
                                }}
                                />
                                )
                              }
                            ) 
                          }
                        </div>
                          :
                        <div className = "contain-my-plano-assinatura">
                          <div className = "contain-my-plano">
                            <div className = "icon-plano-star">
                              <BsBookmarkStar
                                size      = {30}
                                color     = {"#FF7043"}
                              />
                            </div>
                            <h2 className = "text-titulo-my-plano">
                              {sttPage.plano.tituloPlano}
                            </h2>
                            <div className = "contain-valor-my-plano">
                              <h3>{`R$ ${sttPage.plano.valor.toFixed(2).replace(".", ",")}`}</h3>
                              <p> /mês </p>
                            </div> 
                            { sttPage.plano.diasRestanteGratuito > 0 ?
                              <h3 className = "text-dias-gratuitos">
                                {`Restam ${sttPage.plano.diasRestanteGratuito} dia${sttPage.plano.diasRestanteGratuito > 1 ? "s gratuitos!" : " gratuito!"}`}
                              </h3> : <></>
                            }
                            
                            <div className = "line-bar-plano"/>
                            
                            <div className = "contain-line-details-my-plano">
                              <div className = "contain-line-details-icon-label-mp">
                                <TbFileSpreadsheet
                                  size      = {25}
                                  color     = {"#FF7043"}
                                />
                                <h2>Forma de pagamento</h2>
                              </div>
                              <p>
                                {sttPage.plano.formaPagamento}
                              </p>
                            </div>
                            <div className = "contain-line-details-my-plano">
                              <div className = "contain-line-details-icon-label-mp">
                                <BiCalendar
                                  size      = {25}
                                  color     = {"#FF7043"}
                                />
                                <h2>Dia de faturamento</h2>
                              </div>
                              <p>
                                {sttPage.plano.dataVencimento}
                              </p>
                            </div>
                            <div className = "contain-line-details-my-plano">
                              <div className = "contain-line-details-icon-label-mp">
                                <BiCalendarEdit 
                                  size      = {25}
                                  color     = {"#FF7043"}
                                />
                                <h2>Data de contratação</h2>
                              </div>
                              <p>
                                {moment(sttPage.plano.dataInicialFree ? sttPage.plano.dataInicialFree : sttPage.plano.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <div className = "contain-line-details-my-plano">
                              <div className = "contain-line-details-icon-label-mp">
                                <RiFileUserFill 
                                  size      = {25}
                                  color     = {"#FF7043"}
                                />
                                <h2>Assinante</h2>
                              </div>
                              <p>
                                {sttPage.plano.nomeResponsavel}
                              </p>
                              <p>
                                {sttPage.plano.emailResponsavel}
                              </p>
                              <p>
                                CPF/CNPJ: {sttPage.plano.cpfCnpj}
                              </p>
                            </div>
                           
                          </div>
                        </div>

                    }
                  </div>
                </div>
                { sttPage.modeViewPage ?
                  <div className = "contain-list-array-state">
                    {
                      load.faturaSel ?
                      <div className = "contain-loading">
                        <Loading mode = {false}/>
                      </div>
                        :
                      <div className = 'contain-fatura-selecionada'>
                        <div className = "contain-fatura-header">
                          <CgFileDocument
                            className = "icon-comp-faturas"
                          />
                          <h2 className = "text-title-fatura-sel">
                            Resumo da Fatura
                          </h2>
                          <h3 className = "text-data-fatura-sel">
                            {`${Services.RetornaMes(sttPage.faturaSel.mes+1)} de ${sttPage.faturaSel.ano}`}
                          </h3>
                          <div 
                            className = "fatura-rotulo-status-pay"
                            style     = {{backgroundColor: ReturnColorStatusPay(sttPage.faturaSel.statusPagamento), minWidth: "150px"}}
                          >
                            <h3>{ReturnStatusPay(sttPage.faturaSel.statusPagamento)}</h3>
                          </div>
                          <h3 className = "text-fatura-vencimento">
                            {Services.RetornaTempoVencimento(sttPage.faturaSel)}
                          </h3>
                        </div>
                        <div className = "contain-valores-fatura">
                          { sttPage.faturaSel.mensalidade > 0 ?
                            <div className = "contain-line-valores-fatura">                          
                              <h3 className = "text-rotulo-valor-fatura">
                                Valor da Mensalidade
                              </h3>
                              <h3 className = "text-valor-fatura-sel">
                                {`R$${sttPage.faturaSel.mensalidade.toFixed(2).replace(".", ",")}`}
                              </h3>
                            </div> : <></>
                          }{sttPage.faturaSel.totalComissao > 0 ? 
                            <div className = "contain-line-valores-fatura">                          
                              <h3 className = "text-rotulo-valor-fatura">
                                Valor em Taxas
                              </h3>
                              <h3 className = "text-valor-fatura-sel">
                                {`R$${sttPage.faturaSel.totalComissao.toFixed(2).replace(".", ",")}`}
                              </h3>
                            </div> : <></>
                          }
                          <div className = "contain-line-valores-fatura">                          
                            <h3 className = "text-rotulo-valor-fatura">
                              TOTAL DA FATURA
                            </h3>
                            <h3 className = "text-valor-fatura-sel">
                              {`R$${sttPage.faturaSel.totalAPagar.toFixed(2).replace(".", ",")}`}
                            </h3>
                          </div>
                        </div>
                        { sttPage.faturaSel.statusPagamento === "pending" ?
                          <button
                            className = "btt-pagar-fatura"
                            onClick   = {() => {
                              setSttPage({
                                ...sttPage,
                                modalPgto : true,
                              })
                            }}
                          >
                            Pagar Fatura
                            <GiReceiveMoney
                              size      = {22}
                              style     = {{marginLeft: "15px"}}
                            />
                          </button> : <></>
                        }
                        <div className = "contain-extrato-fatura">
                          <h2 className = "text-title-extrato-fatura">
                            Extrato da Fatura
                          </h2>
                          <div className = "contain-fl-extrato-fatura">
                            {
                              sttPage.faturaSel.itensCiclo.length === 0 ?
                              <div className = "contain-not-itens-ciclo-fatura">
                                <BiSolidMessageSquareError
                                  className = "icon-not-itens-ciclo-fatura"
                                />
                                <h2 className = "text-not-itens-ciclo-fatura">
                                  Nenhum item no extrato da fatura
                                </h2>
                              </div>
                                :
                              sttPage.faturaSel.itensCiclo.map((item, index) => (
                                <CompICEx
                                  key        = {index}
                                  itemC      = {item}
                                  index      = {index}
                                  onClick    = {() => {
                                    //console.log("ItemC: ", item);
                                  }}
                                />
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                    :
                  <div className = "contain-list-array-state">
                    { sttPage.plano.tipoPlano !== 9 ? 
                      <h2 className = "text-title-fatura-sel" style = {{color: "white", fontSize: "var(--text-size-h2)", margin: "0px"}}>
                        Migre de plano e aproveite todos os benefícios
                      </h2> : <></>
                    }
                    <div className = "contain-details-plano-master">
                      <div className = "icon-plano-star" style = {{backgroundColor: "#FF7043"}}>
                        <FaCheck 
                          size      = {30}
                          color     = {"white"}
                        />
                      </div>
                      <h2 className = "text-title-details-plano">
                        Plano | Gestão + Marketplace + Diárias
                      </h2>
                      <h3 className = "text-rotulo-details-pa">
                        Acesso completo aos benefícios de Gerenciamento + Agendamentos + Diárias + Taxas e prazos atualizados
                      </h3>
                      {VerificaPlanoAtual()}
                      <div className = "contain-valor-my-plano" style = {{marginBottom: "var(--margin-low)"}}>
                        <h3>{`R$ 97,90`}</h3>
                        <p> /mês </p>
                      </div> 
                      <div className = "line-bar-plano" style = {{maxWidth: "600px", margin: "var(--margin-low) 0px"}}/>
                      <div className = "contain-line-details-pa">
                        <FaCheck className = "icon-check-pa"/>
                        <h3>Todos os recursos atuais +</h3>
                      </div>
                      <div className = "contain-line-details-pa">
                        <FaCheck className = "icon-check-pa"/>
                        <h3>{`NOVIDADE: Recebe Diárias (Day Use) pelo App e site Agendei`}  </h3>
                      </div>
                      <div className = "contain-line-details-pa">
                        <FaCheck className = "icon-check-pa"/>
                        <h3>{`NOVIDADE: Taxa no crédito em 30 dias: 3,69%`} </h3>
                      </div>
                      <div className = "contain-line-details-pa">
                        <FaCheck className = "icon-check-pa"/>
                        <h3>{`NOVIDADE: Taxa no PIX em 24 horas após o horário do agendamento: 1%`} </h3>
                      </div>
                      <div className = "contain-line-details-pa">
                        <FaCheck className = "icon-check-pa"/>
                        <h3>{`Em caso de Pagamento Online do Agendamento, haverá também a taxa de R$0,99 pela transação financeira`} </h3>
                      </div>
                      {date.empresa.licenca.tipoPlano === 9 ?
                        <div className = "contain-rotulo-plano-contemple">
                          <FaCheck className = "icon-check-pa"/>
                          <h3>Seu plano atual já contempla todos os benefícios</h3>
                        </div> : 
                        <div className = "contain-rotulo-plano-contemple">
                          <FaCheck className = "icon-check-pa"/>
                          <h3>Seu plano atual não contempla todos os benefícios, para migrar de plano, acesse o aplicativo e solicite a mudança na área de assinatura</h3>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );

  function CompFatura({fatura, index, onClick}){
    return (
      <button
        className = {fatura.idcicloFaturamento === sttPage.faturaSel.idcicloFaturamento ? "btt-comp-faturas-sel" : "btt-comp-faturas"}
        onClick   = {onClick}
      >
        <CgFileDocument
          className = "icon-comp-faturas"
        />
        <h2 className = "text-data-fatura">
          {`${Services.RetornaMes(fatura.mes+1)} de ${fatura.ano}`}
        </h2>
        <div className = "contain-details-fatura">
          <h3>
            {`R$${fatura.totalAPagar.toFixed(2).replace(".", ",")}`}
          </h3>
          <div 
            className = "fatura-rotulo-status-pay"
            style     = {{backgroundColor: ReturnColorStatusPay(fatura.statusPagamento)}}
          >
            <h3>{ReturnStatusPay(fatura.statusPagamento)}</h3>
          </div>
        </div>
        <div className = "contain-cupom-fixed-select">
          { sttPage.faturaSel.idcicloFaturamento === fatura.idcicloFaturamento ?
            <Check
              className = "icon-check-select"
            />
              :
            <BiCircle
              className = "icon-check-circle"
            />
          }
        </div>
      </button>
    );
  }

  function ReturnStatusPay(status){
    switch(status){
      case "aguardando" : return "FATURA ABERTA";
      case "paid"       : return "FATURA PAGA";
      case "completed"  : return "FATURA PAGA";
      case "canceled"   : return "PGTO. PENDENTE";
      case "pending"    : return "PGTO. PENDENTE";
      default           : return "";
    }
  }

  function ReturnColorStatusPay(status){
    switch(status){
      case "aguardando" : return "#12BF6D";
      case "paid"       : return "#00CEA5";
      case "completed"  : return "#00CEA5";
      case "canceled"   : return "#F25640";
      case "pending"    : return "#F25640";
      default           : return "";
    }
  }

  function VerificaPlanoAtual(){
    if(date.empresa.licenca.tipoPlano === 9){
      return (
        <button
          className = "btt-mudar-plano-assinatura" style = {{cursor: 'default'}}
          onClick   = {() => {
            //Alerts.aviso("Você já está no plano Completo!");
          }}
        >
          <FaCheck size={20} className = "icon-check-btt"/>
          Seu Plano Atual
        </button>
      )
    } else {
      return (
        <button
          className = "btt-mudar-plano-assinatura"
          onClick   = {() => {
            Alerts.aviso("Aviso", "Para mudar o seu plano atual, acesse o aplicativo Gestor Agendei e altere o seu plano na área de Assinatura!");
          }}
        >
          Mudar de Plano
        </button>
      )
    }
  }

}