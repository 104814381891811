import React              from 'react';
import { ResponsiveBar }  from '@nivo/bar'

import "./stylesGraphic.css";

export default function GraficoBarras({ data }) {
  //console.log("Data", data);
  
  const opConfig = {
    keys              : ['valor'],
    groupMode         : 'grouped',
    layout            : 'vertical',
    indexBy           : "dataG",
    margin            : { top: 70, right: 0, bottom: 100, left: 55 },
    padding           : 0.1,
    // aumentar o espaço entre os grupos
    
    valueScale        : { type: 'linear' },
    indexScale        : { type: 'band', round: true },
    colors            : { scheme: 'nivo' },
    axisTop           : null,
    axisRight         : null,
    labelSkipWidth    : 12,
    labelSkipHeight   : 12,
    axisBottom: {
      tickSize        : 5,
      tickPadding     : 5,
      tickRotation    : -45,
      legend          : '',
      legendPosition  : 'middle',
      legendOffset    : 35,
      tickColor       : 'white', // Cor dos ticks
      legendTextColor : 'white', // Cor do texto da legenda
      format          : v => {
        return v.split(";").join(" até ");
      },
    },
    tooltip           : TooltipFunction,
    axisLeft: {
      tickSize        : 5,
      tickPadding     : 5,
      tickRotation    : 0,
      legend          : 'valor',
      legendPosition  : 'middle',
      legendOffset    : -40,
      tickColor       : 'white', // Cor dos ticks
      legendTextColor : 'white', // Cor do texto da legenda
    },
    // textos em branco
    theme: {
      axis: {
        ticks : {
          text : { fill: 'white' } // Isso define a cor do texto dos ticks para branco
        },
        legend : {
          text : { fill: 'white' } // Isso define a cor do texto da legenda para branco
        }
      },
    },
    enableLabel: true,
    label: d => {
      return `R$${d.value.toFixed(2).toString().replace(".",",")}`;
    },
    // cor preta para o texto das fatias
  }

  function TooltipFunction(dt){
    //console.log("TooltipFunction", dt);
    return(
      <div className = 'contain-tooltip'>
       <div className = 'contain-tooltip-texts'>
          <h2>{`${dt.data.dataG.replace(";"," até ")}`}</h2>
          <h3>{`R$${dt.data.valor.toFixed(2).toString().replace(".",",")}`}</h3>
        </div>
      </div>
    );
  }

 

  return(
    <div className = 'contain-graphic-bar'>
      <ResponsiveBar
        data={data}
        {...opConfig}
      />
    </div>
  );
};
