import React      from 'react';
import AppRouter  from './routes';

import "./components/Primar/texts.css";
import "./components/Primar/inputs.css";
import "./components/Primar/buttons.css";
import { LocalStorage } from './services';

//LocalStorage.clear();

export default function App() {
  
  return (<AppRouter/>);
}