import React                  from "react";
import { 
  Alerts, CompPay, Diaria, ErroComunic, Loading, VenDiaria 
} from "../../Basics";
import { 
  CompDiaria, DiasRoler, CompDayUse 
} from "../../Complext/";
import { 
  BiCircle, BiLeftArrowAlt, BiMoney
} from "react-icons/bi";
import { 
  notAgend, Check, UserCircle 
} from "../../../assets";
import { FormCheckoutDiaria } from "../../Forms/";
import { LocalStorage }       from "../../../services";
import { AuthContext }        from "../../../contexts/AuthContext";
import { EmpresaAPI }         from "../../../servicesAPI";
import { ModalInfos }         from "../../Modais/";
import { Services }           from "../../../services";
import RouterHook             from "../../../contexts/RouterHook";
import Switch                 from "react-switch";
import moment                 from "moment";
import Swal                   from "sweetalert2";

import "./stylesD.css";

export default function Diarias({show, clickDay}) {

  function ReturnAmplitude(){
    const width = window.innerWidth;
   //console.log("width", width)
    if(width > 1490)                        return 3;
    else if(width <= 1490 && width > 1320)  return 2;
    else if(width <= 1320)                  return 1; 
    // Aqui para baixo vai para mobile e diminui o tamanho dos dayCircle!
  }

  const serverRouter                = RouterHook();
  const refDiaria                   = React.useRef(null);
  const { 
    date, token, modalOpen, handleMOP, handleDate
  } = React.useContext(AuthContext);
  const stateBuffer                 = LocalStorage.get("home-diarias-stt");
  const [amplitude, setAmplitude]   = React.useState(ReturnAmplitude());
  const dataDay                     = new Date();
  const [daySel, setDaySel]         = React.useState(stateBuffer !== null ? new Date(stateBuffer.daySel) : dataDay);
  const [dataMoment, setDataMoment] = React.useState(stateBuffer !== null ? new Date(stateBuffer.dataMoment) : dataDay);
  const [arrayDias, setArrayDias]   = React.useState(Services.OrganizarDias((stateBuffer !== null ? new Date(stateBuffer.daySel) : dataDay), amplitude));
 //console.log("Date", date);
  function DefineAmplitude(){
    const width = window.innerWidth;
    if(show){
      if(width > 1490){
        setArrayDias(Services.OrganizarDias(daySel, 3))
        setAmplitude(3);
      } else if(width <= 1490 && width > 1320){
        setArrayDias(Services.OrganizarDias(daySel, 2));
        setAmplitude(2);
      } else if(width <= 1320){
        setArrayDias(Services.OrganizarDias(daySel, 1));
        setAmplitude(1);
      } 
    } else {
      if(width > 1490){
        setArrayDias(Services.OrganizarDias(daySel, 4));
        setAmplitude(4);
      } else if(width <= 1490 && width > 1320){
        setArrayDias(Services.OrganizarDias(daySel, 3));
        setAmplitude(3);
      } else if(width <= 1320){
        setArrayDias(Services.OrganizarDias(daySel, 2));
        setAmplitude(2);
      } 
    }
  }

  React.useEffect(() => {
    DefineAmplitude();
  }, [show]);
  
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : null,
    scroll      : 0,
    countAll    : true,
  }
  const [pagina, setPagina]                 = React.useState(pagBuffer);
  const [loading, setLoading]               = React.useState(true);
  const [loadingD, setLoadingD]             = React.useState(false);
  const [loadingDU, setLoadingDU]           = React.useState(false);
  const [loadingCD, setLoadingCD]           = React.useState(false);
  
  const [sttPage, setSttPage]               = React.useState({
    mode              : "init",          // init, diaria-selected, dayuse-selected, marcar-diaria, checkout-diaria
    title             : "Diárias",
    titleDi           : "Ver todas Diárias vendidas",
    diariaSel         : null,
    dayUseSel         : null,
    diriasIsOn        : date.empresa.licenca.moduloDiaria === 1 ? true : false,
    erroComunicacao   : false,
    modalCD           : false,
    modalAV           : false,
    motivoCancel      : "",
    valorManual       : 0,
  });
  const [diariasOff, setDiariasOff]         = React.useState(false);
  
  React.useEffect(() => {
    document.title = "Diárias | Gestor Agendei";
    BuscarDiariasEmpresa();
  }, []);

  //console.log("sttPage", sttPage);
  React.useEffect(() => {
    const DD = LocalStorage.get("diaria-select");
   //console.log("DD", DD);
    if(DD !== null){
      BuscarCompraDayUsePorID(DD.idcompraDayuse);
      LocalStorage.remove("diaria-select");        
    }
  }, [clickDay]);
  
  //console.loglog("sttPage", sttPage);
  const [diarias, setDiarias]               = React.useState([]);
  const [arrayState, setArrayState]         = React.useState([]);
  //console.log("daySel", daySel);
  // Função que retorna os horarios da empresa ou as diarias.
  async function GetData(){
   // Diárias
    setLoadingD(true);  
    const ress = await EmpresaAPI.BuscarDayUseVendidasPorData( token, date.empresa.idempresa, RetornaData(daySel) );//"2023-8-6" );
   //console.log("Resposta das diárias 1: ", ress);
    if(ress.status) { // Comunicação bem sucedida
     //console.log("Diárias: ", ress);
      //* Verifica se existem diárias para o dia selecionado
      if(ress.req.length) { // Existem diárias para o dia selecionado
        setArrayState(ress.req);
      } else { // Não existem diárias para o dia selecionado, vetor vazio
        setArrayState([]);
      }//*/
      const DD = LocalStorage.get("diaria-select");
     //console.log("DD", DD);
      if(DD !== null){
        await BuscarCompraDayUsePorID(DD.idcompraDayuse);
        LocalStorage.remove("diaria-select");        
      }
    } else { // Comunicação mal sucedida
      Swal.fire({
        icon      : "warning",        
        title     : "Aviso",
        text      : "Pedimos desculpas, não foi possível carregar as diárias da empresa. Por favor, tente novamente mais tarde",
        confirmButtonText   : "Entendi",
        confirmButtonColor  : "#DA5A34",
        showConfirmButton   : true,
      }); 
    }
    setLoadingD(false);
  }

  React.useEffect(() => {
    let interval = null;
    if(sttPage.diariaSel !== null){
      if(sttPage.diariaSel.status === "AGENDADA" || sttPage.diariaSel.status === "SOLICITADA"){
      // inicia um timer para atualizar a diaria selecionada
       //console.log("AtualizaDiariaSel Status value = true", sttPage.diariaSel);
        interval = setInterval(async () => {
          const req = await EmpresaAPI.BuscarCompraDayUsePorID( token, sttPage.diariaSel.idcompraDayuse );
          //console.log("AtualizaDiariaSel req: ", req);
          if(req.status){
            if(req.req.status !== sttPage.diariaSel.status){
              //console.log("AtualizaDiariaSel Status value = true", req.req);
              setSttPage({
                ...sttPage,
                diariaSel: req.req
              });
              clearInterval(interval);
            }
            if(req.req.status === "CANCELADA" || req.req.status === "REALIZADA"){
              //console.log("AtualizaDiariaSel Status value = false", req.req);
              clearInterval(interval);
            }
          }//*/
        }, 10000);
      }
    } else {
      // se interval não for null, limpa o interval    
      clearInterval(interval);
     //console.log("ClearInterval");
    }
  }, [sttPage.diariaSel]);
  //console.log("Diarias", diarias);
  //console.log("sttPage", sttPage);
  //console.log("Date", date);
  // Função que retorna os horarios da empresa ou as diarias.
  async function BuscarDiariasEmpresa() {
    //console.log("BuscarDiariasEmpresa");
    if(date.empresa.licenca.moduloDiaria === 0){
      setDiariasOff(true);
    } else {
      const req = await EmpresaAPI.BuscarTodasDayUse( token, date.empresa.idempresa, pagina.limit, pagina.offSet, pagina.countAll );
      if(req.status){
        if(req.req.retorno){
          setDiarias(req.req.dayUse.rows);
          setPagina({
            ...pagina,
            maxScroll: req.req.dayUse.count,
          });
          await GetData()
        }
      }
    }
    setLoading(false);
  }
  async function BuscarVendaDayUse(){
    setLoadingD(true);
    const req = await EmpresaAPI.BuscarDiariasEmpresa( token, date.empresa.idempresa, (daySel.getDay()+1), daySel.toString() );
    //console.log("BuscarVendaDayUse req: ", req);

    if(req.status){
      setArrayState(req.req);
    } else {
      Alerts.aviso("Aviso", req.message);
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
    setLoadingD(false);
  }
  async function BuscarCompraDayUsePorID(idcdu){
    setLoading(true);
    const req = await EmpresaAPI.BuscarCompraDayUsePorID( token, idcdu );
    //console.log("BuscarCompraDayUsePorID req: ", req);
    if(req.status){
      setSttPage({
        ...sttPage,
        mode: "diaria-selected",
        title: `Diária #${idcdu}`,
        diariaSel: req.req
      });
    } else {
      Alerts.aviso("Aviso", req.message);
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
    setLoading(false);
  }
  // Função que ativa ou desativa a compra de diárias na empresa
  async function AtualizarModuloDiarias(){
    const req = await EmpresaAPI.AtivarDesativarDiarias(token, date.empresa.licenca.idlicenca, (sttPage.diriasIsOn ? 0 : 1));
   //console.log("AtualizarDiarias -- req", req);
    if(req.status){
      if(req.req.retorno) {
        setSttPage({
          ...sttPage,
          diriasIsOn: !sttPage.diriasIsOn
        });
        handleDate({
          ...date,
          empresa: {
            ...date.empresa,
            licenca: {
              ...date.empresa.licenca,
              moduloDiaria: (sttPage.diriasIsOn ? 0 : 1)
            }
          }
        })
        Swal.fire({
          icon      : "success",        
          title     : "Sucesso",
          text      : `As diárias foram ${!sttPage.diriasIsOn ? "ativadas" : "desativadas"} com sucesso`,
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
          showConfirmButton   : true,
        }); 
      } else {
        Alerts.aviso("Aviso", req.req.messagem)
      }
    } else {
      Alerts.aviso("Aviso", req.message);
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
  }

  async function AtivarDayUse(){
    setLoadingDU(true);
    const req = await EmpresaAPI.AtivarDayUse(token, sttPage.dayUseSel.iddayuse);
   //console.log("Ativar Diaria req: ", req);
    if(req.status){
      if(req.req.retorno){
        Alerts.sucesso("Sucesso", "Diária ativada com sucesso");
        await BuscarDiariasEmpresa();
        setSttPage({
          ...sttPage,
          dayUseSel: {
            ...sttPage.dayUseSel,
            status: true
          }
        });
      } else {
        Alerts.aviso("Aviso", req.req.messagem);
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
    setLoadingDU(false);
  }

  async function DesativarDayUse(){
    setLoadingDU(true);
    const req = await EmpresaAPI.DesativarDayUse(token, sttPage.dayUseSel.iddayuse, date.gestor.nome);
   //console.log("Desativar Diaria req: ", req);
    if(req.status){
      if(req.req.retorno){
        Alerts.sucesso("Sucesso", "Diária desativada com sucesso");
        await BuscarDiariasEmpresa();
        setSttPage({
          ...sttPage,
          dayUseSel: {
            ...sttPage.dayUseSel,
            status: false
          }
        });
      } else {
        Alerts.aviso("Aviso", req.req.messagem);
      }
    } else {
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
    setLoadingDU(false);
  }

  async function CancelarDiaria(motivo){
   //console.log("CancelarDiaria");
    const req = await EmpresaAPI.CancelarCompraDayUse( token, sttPage.diariaSel.idcompraDayuse, date.gestor.nome, motivo );
    //console.log("CancelarDiaria req: ", req);
    if(req.status){
      if(req.req.retorno){
        const req = await EmpresaAPI.BuscarCompraDayUsePorID( token, sttPage.diariaSel.idcompraDayuse );
        //console.log("BuscarCompraDayUsePorID req: ", req);
        if(req.status){
          setSttPage({
            ...sttPage,
            mode        : "diaria-selected",
            title       : `Diária #${sttPage.diariaSel.idcompraDayuse}`,
            diariaSel   : req.req,
            modalCD     : false
          });
          //console.log("Setou como false a modal!", sttPage.modalCD);
        }
        Alerts.sucesso("Sucesso", "Diária cancelada com sucesso");
        //Fecha modal
      } else {
        Alerts.aviso("Aviso", req.req.messagem);
      }
    } else {
      Alerts.aviso("Aviso", req.message);
      setSttPage({
        ...sttPage,
        modalCD: false,
        erroComunicacao: true
      });
    }

  }
  
  function MarcarDiariaCliente(diaria){
    setSttPage(
      sttPage => ({
        ...sttPage,
        mode      : "checkout-diaria",
        diariaSel : diaria,
      })
    );
  }
  //console.log("SttPage", sttPage);
  async function VenderDiaria(dayuse){
    // Abre a diária marcada
    //console.log("VenderDiaria ::", dayuse);
    setLoadingD(true);
    // Busca a dayuse por ID
    const req = await EmpresaAPI.BuscarCompraDayUsePorID( token, dayuse.idcompraDayuse );
    //console.log("BuscarCompraDayUsePorID req: ", req);
    if(req.status){
      setSttPage({
        ...sttPage,
        mode      : "diaria-selected",
        title     : `Diária #${dayuse.idcompraDayuse}`,
        diariaSel : req.req
      });
      //console.log("Setou como false a modal!", sttPage.modalCD);
    } else {
      Alerts.aviso("Aviso", req.message);
      setSttPage({
        ...sttPage,
        erroComunicacao: true
      });
    }
    setLoadingD(false);
  }

  React.useEffect(() => {
    LocalStorage.set("home-diarias-stt", {
      dataMoment : daySel,
      daySel,
    });
    if(sttPage.mode === 'init') GetData();
    else if(sttPage.mode === 'marcar-diaria') BuscarVendaDayUse();
  }, [daySel, dataMoment, sttPage.mode]);

  // Função que navega entre os dias e os seleciona!
  function ChangeDay(type, day) {
    if(type === "next-left") {
      // Bloquear o botão caso o daySel seja o dia de hoje!
      //if(dataMoment.getDate() === dataDay.getDate() && dataMoment.getMonth() === dataDay.getMonth() && daySel.getFullYear() === dataDay.getFullYear()) return; // Verifica se o dia é hoje!
      //if((arrayDias[0].getTime() - dataDay.getTime()) < 0) return; // Verifica se o index == 0 é menos que hoje!
      // O arrayDias deve ser deslocado para a esquerda
      // O primeiro elemento do arrayDias deve ser o dia anterior ao primeiro elemento do arrayDias
      // O último elemento do arrayDias deve ser o dia anterior ao primeiro elemento do arrayDias
      // O arrayDias deve ser atualizado
      const diaAux = new Date(arrayDias[amplitude]);
      diaAux.setDate(diaAux.getDate() - (amplitude * 2));
      const array = Services.OrganizarDias(diaAux, amplitude);
      setArrayDias(array);
      setDataMoment(array[amplitude]);
    } else if(type === "next-right") {
      // O arrayDias deve ser deslocado para a direita
      // O primeiro elemento do arrayDias deve ser o dia posterior ao último elemento do arrayDias
      // O último elemento do arrayDias deve ser o dia posterior ao último elemento do arrayDias
      // O arrayDias deve ser atualizado

      // Verifica se tem regra de agendamento limite!
      const diaMax = new Date();
      // Sempre verifica se não passou 1 ano!
      diaMax.setDate(diaMax.getDate() + 365);
      if(arrayDias[arrayDias.length - 1].getDate() >= diaMax.getDate() && arrayDias[arrayDias.length - 1].getMonth() >= diaMax.getMonth() && arrayDias[arrayDias.length - 1].getFullYear() >= diaMax.getFullYear())
        return Swal.fire({
          icon                : "warning",
          title               : "Aviso",
          text                : "Só é possível agendar até um ano a partir de hoje",
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
          allowEnterKey       : true,
          timer               : 6000,
        });
      const diaAux = new Date(arrayDias[amplitude]);
      diaAux.setDate(diaAux.getDate() + (amplitude * 2));
      const array = Services.OrganizarDias(diaAux, amplitude);
      setArrayDias(array);
      setDataMoment(array[amplitude]);
    } else if(type === "selected") { // tratei para não selecionar dias anteriores a hoje!
      // O arrayDias deve ser atualizado com o dia selecionado no centro
      const array = Services.OrganizarDias(day, amplitude);
      setArrayDias(array);
      setDaySel(day);  
    }
  }
  function ReturnStatePg(){
    //console.log("ReturnStatePg", sttPage.mode);
    if(modalOpen) {
      handleMOP(false);
    } else if(sttPage.mode === "diaria-selected"){
      GetData();
      setSttPage({
        ...sttPage,
        mode      : "init",
        title     : "Diárias",
        diariaSel : null,
      });
    } else if(sttPage.mode === "dayuse-selected"){
      GetData();
      setSttPage({
        ...sttPage,
        title     : "Diárias",
        mode      : "init",
        dayUseSel : null,
      });
    } else if(sttPage.mode === "marcar-diaria"){
      GetData();
      setSttPage({
        ...sttPage,
        title     : "Diárias",
        mode      : "init",
        dayUseSel : null,
      });
    } else if(sttPage.mode === "checkout-diaria"){
      BuscarVendaDayUse();
      setSttPage({
        ...sttPage,
        title     : "Diárias",
        mode      : "marcar-diaria",
        dayUseSel : null,
      });
    } else {
      serverRouter("back");
    }
  }
  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnStatePg);
    return () => window.removeEventListener("popstate", ReturnStatePg);
  }, [sttPage.mode, sttPage.diariaSel, sttPage.dayUseSel, modalOpen]);
  React.useEffect(() => {
    if(!modalOpen && (sttPage.modalAV || sttPage.modalCD)){
      setSttPage(sttPage => ({
        ...sttPage,
        modalCD: false,
        modalAV: false,
      }));
    }
  }, [modalOpen]);
  function RetornaDiaSemana(dia){
    switch(dia){
      case 1: return "Domingos";
      case 2: return "Segundas";
      case 3: return "Terças";
      case 4: return "Quartas";
      case 5: return "Quintas";
      case 6: return "Sextas";
      case 7: return "Sábados";
      default: return "Erro";
    }
  }
  //dayuse/buscarCompraPorId/?id=
  function CancelDayUse(){
    const [motivo, setMotivo]       = React.useState("");
    const [okCancel, setOkCancel]   = React.useState(false);
    const [loadingCP, setLoadingCP] = React.useState(false);

    React.useEffect(() => {
      if(motivo.length > 4){
        setOkCancel(true);
      } else {
        setOkCancel(false);
      }
    }, [motivo]);

    return (  
      <>
        { loadingCP ?
          <div className = "contain-loading">
            <Loading
              mode  = {true}
              text  = { "Cancelando Diária..." }
              color = {true}
            />
          </div>
            :
          <div className = "contain-cancel-compra-dayuse">
            <h3 className = "text-title-modal">
              Cancelamento
            </h3>
            { sttPage.diariaSel.criadoPorGestor ?
              <></> :
              <h4 className = "text-aviso-can-day">
                Essa diária foi paga pelo Agendei Quadras, se você cancelar o estorno será total ao cliente.
              </h4>
            }
            <h3 className = "text-motivo-whats">
              Qual o motivo do cancelamento?
            </h3>
            <div className = "contain-input-cancel-du">
              <input
                placeholder   = "Digite aqui"
                type          = "text"
                value         = {motivo}
                onChange      = {(e) => {
                  setMotivo(e.target.value);  
                }}
              />
            </div>
            <button
              className = {okCancel ? "btt-cancelar-day-use" : "btt-cancelar-day-use-not"}
              onClick   = {() => {
                Swal.fire({
                  title: 'Aviso',
                  text: "Deseja cancelar esta diária? Esta ação não poderá ser desfeita!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor    : '#bf3324',
                  cancelButtonColor     : '#00cda4',
                  confirmButtonText     : 'Não',
                  cancelButtonText      : 'Sim'
                }).then(async (result) => {
                  if(!result.isConfirmed && result.dismiss === "cancel"){
                    setLoadingCP(true);
                    await CancelarDiaria(motivo);
                    
                    setLoadingCP(false);

                  }
                });
              }}
            >
              Cancelar Diária
            </button>
          </div>
        }
      </>
    );    
  }
  // componente para adicionar um novo valor a diária
  function CompAddNewValor(){
    
    const [valorM, setValorM]         = React.useState(sttPage.diariaSel.valorRealizado);
    const [loadingPay, setLoadingPay] = React.useState(false);
    
    async function AdicionarPagamento(){
      // Verifica se o valor é maior que 0 e se é diferente do valor atual
      if(valorM === sttPage.diariaSel.valorRealizado) return Alerts.aviso("Aviso", "O valor da diária deve ser diferente do valor atual, porém maior que zero e menor que o valor da diária");
      if(valorM <= 0) return Alerts.aviso("Aviso", "O valor da diária deve ser maior que zero e menor que o valor da diária");

      setLoadingPay(true);
      const req = await EmpresaAPI.EditarValorDiaria( token, sttPage.diariaSel.idcompraDayuse, parseFloat(valorM) );
      //console.log("EditarValorDiaria req: ", req);
      if(req.status){
        if(req.req.retorno){
          setSttPage({
            ...sttPage,
            modalAV   : false,
            diariaSel : req.req.registro
          });
          Alerts.sucesso("Sucesso", "Valor da diária alterado com sucesso");
        } else {
          Alerts.aviso("Aviso", "Não foi possível alterar o valor da diária. Por favor, tente novamente mais tarde");
        }
      } else {
        Alerts.aviso("Aviso", req.message);
      }
      setLoadingPay(false);
    }
    
    return (
      <>
        {
          loadingPay ?
          <div className = "contain-loading">
            <Loading
              mode  = {true}
              text  = {sttPage.diariaSel.valorRealizado > 0 ? "Alterando valor..." : "Criando pagamento..." }
              color = {true}
            /> 
          </div> :
          <>
            <h3 className = "text-title-modal">
              { sttPage.diariaSel.valorRealizado > 0 ?
                "Alterar valor da diária" :
                "Adicionar pagamento manualmente"
              }
            </h3>
            <div className = "contain-new-value">
              <h3 className = "text-preco-rs">
                R$
              </h3>
              <input
                placeholder   = "0,00"
                type          = "text"
                value         = {valorM.toString().replace(".",",")}
                onChange      = {(e) => {
                  SetaValorTotal(e);                    
                }}
              />
            </div>
            
            <button
              className = "btt-contain-add-pay"
              onClick   = {() => {
                AdicionarPagamento();
              }}
            >
              {
                sttPage.diariaSel.valorRealizado > 0 ?
                "Alterar valor" :
                "Adicionar pagamento"
              }
              <BiMoney
                size  = {25}
                color = {"white"}
                style = {{marginLeft: "10px"}}
              />
            </button>
          </> 
        }
      </>
    );
    function SetaValorTotal(e) {
      // Remove todos os caracteres que não sejam dígitos.
      let value = e.target.value.replace(/[^\d]/g, "");
  
      // Se o valor não for vazio, converta-o.
      if (value !== "") {
          // Interpretamos o valor como centavos.
          let cents = parseInt(value);
          if (isNaN(cents) || cents === 0) {
              value = "0.00";
          } else {
              // Converte os centavos em uma representação decimal.
              value = (cents / 100).toFixed(2);
          }
      } else {
          value = "0.00";
      }
      
      setValorM(value);
    }
  }
  //console.log("sttPage", sttPage);
  // Page de Filtrar Pedidos
  return (
    <>
      {
        sttPage.modalCD &&
        <ModalInfos
          modal           = {sttPage.modalCD}
          ClosedModal     = {() => { 
            setSttPage({...sttPage, modalCD: false});
            handleMOP(false);
          }}
          Component       = {<CancelDayUse/>}
        />
      }{
        <ModalInfos
          modal           = {sttPage.modalAV}
          Component       = {<CompAddNewValor/>}
          ClosedModal     = {() => {
            setSttPage({...sttPage, modalAV: false});
            handleMOP(false);
          }}
        />
      }{ 
        diariasOff ?
        <div className = "contain-agenda-page">
          <div className = "contain-filtro-agenda-hf">
            <BiLeftArrowAlt
              className = "icon-arrow-back-hf"
              onClick={() => {
                ReturnStatePg();
              }}
            />
            <h2 className = "title-me-agenda"> {sttPage.title} </h2>
            {
              loading ? 
                <div className = "contain-loading" style = {{height: "300px"}}>
                  <Loading 
                    mode  = {true}
                    text  = { "Buscando Diárias..." }  
                  />
                </div>
                  :
                <div className = "contain-not-agend">
                  <img
                    title = "Sem diárias"
                    src   = {notAgend}
                    alt   = "Not diarias"
                  />
                  <h2>
                    Para ativar as diárias, entre em contato com o suporte e solicite a ativação do módulo de diárias.
                  </h2>
                </div>
            }
          </div>
        </div>
          :
        <>
          
          <div className = "contain-agenda-page">
            <div className = "contain-filtro-agenda-hf">
              <BiLeftArrowAlt
                className = "icon-arrow-back-hf"
                onClick={() => {
                  ReturnStatePg();
                }}
              />
              <h2 className = "title-me-agenda"> {sttPage.title} </h2>
              { sttPage.erroComunicacao ?
                <ErroComunic
                  onClick = {() => setSttPage({
                    ...sttPage,
                    erroComunicacao: false
                  })}
                />
                  :
                loading ? 
                <div className = "contain-loading" style = {{height: "300px"}}>
                  <Loading 
                    mode  = {true}
                    text  = { "Buscando Diárias..." }  
                  />
                </div>
                  :
                (sttPage.mode === "init" || sttPage.mode === "dayuse-selected" || sttPage.mode === "marcar-diaria" || sttPage.mode === "checkout-diaria") ?
                <div className = "contain-view-hora-fixa">
                  {
                    sttPage.mode !== "checkout-diaria" ?
                    <div className = "contain-filtra-hf">
                      <div className = "contain-list-clientes">
                        <button 
                          className = {sttPage.mode === "init" ? "btt-contain-client-select" : "btt-contain-client"}
                          style     = {{minWidth      : "350px"}}
                          onClick = {() => {
                            if(sttPage.mode !== "init") {
                              GetData();
                              setSttPage(sttPage => ({ 
                                ...sttPage,
                                mode        : "init",
                                titleDi     : "Ver todas Diárias vendidas",
                                diariaSel   : null,
                                dayUseSel   : null,
                              }));
                            }
                          }}
                        >
                          <div className = "contain-infos-client">
                            <h2 style = {{fontSize: "15px"}}>
                              Ver todas as diárias vendidas
                            </h2>
                          </div>
                          {
                            sttPage.mode === "init" ?
                            <Check
                              className = "icon-check-select"
                            />
                              :
                            <BiCircle
                              className = "icon-check-circle"
                            />
                          }
                        </button>
                        
                        <button 
                          className = {sttPage.mode === 'marcar-diaria' ? "btt-contain-client-select" : "btt-contain-client"}
                          style     = {{minWidth      : "350px"}}
                          onClick = {() => {
                            if(sttPage.mode !== 'marcar-diaria') { 
                              BuscarVendaDayUse();
                              setSttPage(
                                sttPage => ({
                                ...sttPage,
                                mode        : "marcar-diaria",
                                titleDi     : "Marcar diária para um cliente",
                                diariaSel   : null,
                                dayUseSel   : null,
                              }));
                            }
                          }}
                        >
                          <div className = "contain-infos-client">
                            <h2 style = {{fontSize: "15px"}}>
                              Marcar diária
                            </h2>
                          </div>
                          {
                            (sttPage.mode === 'marcar-diaria') ?
                            <Check
                              className = "icon-check-select"
                            />
                              :
                            <BiCircle
                              className = "icon-check-circle"
                            />
                          }
                        </button>

                        <button 
                          className = {"btt-contain-client-not"}
                          style     = {{minWidth: "350px"}}
                          onClick = {() => {
                            // Ativar ou desativar diárias
                            const text = sttPage.diriasIsOn ? 
                            "Você deseja desativar as diárias? Os clientes não poderão mais comprar diárias na sua empresa pelo site ou aplicativo" 
                              :
                            "Você deseja ativar as diárias? Os clientes poderão comprar diárias na sua empresa pelo site ou aplicativo";
                            Swal.fire({
                              icon                : "info",
                              title               : "Aviso",
                              text                : text,
                              confirmButtonText   : 'Não',
                              cancelButtonText    : sttPage.diriasIsOn ? 'Desativar' : 'Ativar',
                              showCancelButton    : true,
                              confirmButtonColor  : '#bf3324',
                              cancelButtonColor   : '#00cda4',
                            }).then(async (result) => {
                              if(result.isConfirmed) return ;
                              
                              await AtualizarModuloDiarias();
                            });
                          }}
                        >
                          <div className = "contain-infos-client">
                            <h2 style = {{fontSize: "15px"}}>
                              Deseja vender diárias?
                            </h2>
                          </div>
                          <Switch
                            title    = {sttPage.diriasIsOn ? "Desativar" : "Ativar"}
                            checked  = {sttPage.diriasIsOn}
                            height   = {20}
                            width    = {40}
                            onChange = {(val) => {
                              
                            }}
                          />
                        </button>
                        <div className = "line-border-bottom"/>
                        <div 
                          className = "contain-list-title-btt" 
                          style = {{
                            padding       : "0px"
                          }}
                        >
                          <h2>Diárias configuradas</h2>
                        </div>
                        <div
                          ref       = {refDiaria} 
                          className = "flat-list-clientes"
                        >
                          {
                            diarias.map((item, index) => {
                              return (
                                <CompDayUse
                                  key     = {index}
                                  diaria  = {item}
                                  checked = {sttPage.dayUseSel !== null ? (item.iddayuse === sttPage.dayUseSel.iddayuse ? true : false) : false}
                                  onClick = {() => {
                                    setSttPage({
                                      ...sttPage,
                                      mode      : "dayuse-selected",
                                      titleDi   : `Diária #${item.iddayuse}`,
                                      dayUseSel : item
                                    });
                                  }}
                                />
                              );
                            })
                          }
                        </div>
                      </div>
      
                      <div className = "contain-list-array-state">
                        <h3 className = "text-title-array-stt">
                          {sttPage.titleDi}
                        </h3>
                        { sttPage.mode === "init" || sttPage.mode === "marcar-diaria" ?
                          <>
                            <h2 className = "title-list-agend" style = {{color: "var(--color-text-hig)"}}>
                              {`${Services.RetornaMes(dataMoment.getMonth() + 1)} ${dataMoment.getFullYear()}`}
                            </h2>
                            <DiasRoler
                              arrayDias   = {arrayDias}
                              daySel      = {daySel}
                              ChangeDay   = {(cmd, dia) => ChangeDay(cmd, dia)}
                            />
                            { sttPage.mode === 'init' ?
                              <div className = "flat-list-diarias-colum">
                                { 
                                  loadingD ?
                                  <div className = "contain-loading" style = {{height: "300px"}}>
                                    <Loading 
                                      mode  = {true}
                                      text  = { "Buscando Diárias..." }  
                                    />
                                  </div> :
                                  arrayState.length ?
                                  arrayState.map((item, index) => {
                                    return(
                                      <CompDiaria
                                        key       = {index}
                                        diaria    = {item}
                                        loading   = {loadingCD}
                                        onClick   = {() => {
                                          BuscarCompraDayUsePorID(item.idcompraDayuse);
                                        }}
                                      />
                                    )
                                  })                                       
                                    :
                                  <div className = "contain-aviso-not-diarias">
                                    <img
                                      className = "img-list-vazia"
                                      src       = {notAgend}
                                    />
                                    <h2 className = "text-rotulo-gray">
                                      {'Você não possui diárias vendidas para este dia'}
                                    </h2>
                                  </div>
                                }
                              </div>
                                :
                              <div className = "contain-flat-list-row-diarias">
                                <div className = "flat-list-diarias-row">
                                  { 
                                    loadingD ?
                                    <div className = "contain-loading" style = {{height: "300px"}}>
                                      <Loading 
                                        mode  = {true}
                                        text  = { "Buscando Diárias..." }  
                                      />
                                    </div> :
                                    arrayState.length ?
                                    arrayState.map((item, index) => {
                                      return (
                                        <VenDiaria
                                          key     = {index}
                                          day     = {daySel}
                                          diaria  = {item}
                                          onClick = {() => {
                                            MarcarDiariaCliente(item);
                                          }}
                                        />
                                      )
                                    })                                  
                                      :
                                    <div className = "contain-aviso-not-diarias">
                                      <img
                                        className = "img-list-vazia"
                                        src       = {notAgend}
                                      />
                                      <h2 className = "text-rotulo-gray">
                                        {'Você não possui diárias disponíveis para venda neste dia!'}
                                      </h2>
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                          </>
                            : // dayuse-selected
                          <>
                            <div className = "contain-dayuse-selected">                
                              <div className = "contain-dayuse-superior">
                                <div className="contain-dayuse-interior">
                                  <h2>{sttPage.dayUseSel.titulo}</h2>
                                  <h3>R${sttPage.dayUseSel.valor.toFixed(2).toString().replace(".", ",")}</h3>
                                  <h3 style = {sttPage.dayUseSel.status ? {color: "#23A820"} : {color: "rgb(204, 57, 57)"}}>
                                    {sttPage.dayUseSel.status ? "ATIVA" : "INATIVA"}
                                  </h3>
                                </div>
                                <div className = "contain-array-dias">
                                {
                                  JSON.parse(sttPage.dayUseSel.diaSemana).map((dia, index) => {
                                    return(
                                      <h3
                                        key       = {index}
                                        className = "text-dia-semana"
                                      >
                                        {RetornaDiaSemana(dia)}
                                      </h3>
                                    )
                                  })
                                }
                                </div>
                              </div>
                              <p className = "text-label-dayuse">
                                {`Diária criada por ${sttPage.dayUseSel.criadoPor} em ${moment(sttPage.dayUseSel.createdAt).format("DD/MM/YY")} às ${moment(sttPage.dayUseSel.createdAt).format("HH:mm")}`}
                              </p>
                              <div className = "contain-line-dayuse"/>
                              <div className = "contain-dayuse-inferior">
                                <h2>REGRAS DA DIÁRIA</h2>
                                <h3>Pagamento: {ReturnPayMetod(sttPage.dayUseSel.formaPagamento)}</h3>
                                <h3>Cancelamento: {ReturnRegraCancelamento(sttPage.dayUseSel)}</h3>
                                <h3>Quantidade máxima de vendas por dia: {sttPage.dayUseSel.quantidadePorDia}</h3>
                                <h3>Duração: {sttPage.dayUseSel.duracao}</h3>
                                <h3>Válida para: {sttPage.dayUseSel.quadra}</h3>
                              </div>
                            </div>
                            { loadingDU ?
                              <div>
                                <Loading
                                  mode  = {false}
                                />
                              </div>
                                :
                              <button
                                className = "btt-ativar-desativar-dayuse"
                                style     = {sttPage.dayUseSel.status ? {backgroundColor: "#CC3939"} : {backgroundColor: "#23A820"}}
                                onClick   = {() => {
                                  if(sttPage.dayUseSel.status)  DesativarDayUse();
                                  else                          AtivarDayUse();
                                }}
                              >
                                {sttPage.dayUseSel.status ? "Desativar Diária" : "Ativar Diária"}
                              </button>
                            }
                          </>
                        }
                      </div>
                    </div>
                      :
                    <div className = "contain-form-chekout-diarias-gestor">
                      <FormCheckoutDiaria
                        dataSel     = {daySel}
                        title       = {sttPage.titleDi}
                        diaria      = {sttPage.diariaSel}
                        handleStt   = {(dayuse) => {
                          VenderDiaria(dayuse);
                        }}
                      />
                    </div> 
                  }
                </div>
                  : // diaria-selected
                <div className = "contain-view-hora-fixa">
                  <div className = "contain-filtra-hf">
                    <div className = "contain-view-diaria-sel">
                      <div className = "contain-view-status-diaria">
                        { sttPage.diariaSel.criadoPorGestor ?
                          <h2 className = "text-diaria-infos-du">
                            Criada pelo gestor {`${sttPage.diariaSel.criadoPor}`} em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", sttPage.diariaSel.createdAt)} às ${Services.ReturnHoraMin(new Date(sttPage.diariaSel.createdAt))}`}</a>
                          </h2>
                            :
                          <h2 className = "text-diaria-infos-du">
                            Solicitada em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", sttPage.diariaSel.createdAt)} às ${Services.ReturnHoraMin(new Date(sttPage.diariaSel.createdAt))}, por ${sttPage.diariaSel.usuario.nome}`}</a>
                          </h2>
                        }
                        { (sttPage.diariaSel.dataAprovacao !== null) && 
                          <h2 className = "text-diaria-infos-du">
                            Aprovada em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", sttPage.diariaSel.dataAprovacao)} às ${Services.ReturnHoraMin(new Date(sttPage.diariaSel.dataAprovacao))}, por: ${sttPage.diariaSel.aprovadoPor}`}</a>
                          </h2>
                        }
                        { (sttPage.diariaSel.dataCancelamento !== null && sttPage.diariaSel.canceladoPor !== null) &&
                          <>
                            <h2 className = "text-diaria-infos-du">
                              Cancelada em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", sttPage.diariaSel.dataCancelamento)} às ${Services.ReturnHoraMin(new Date(sttPage.diariaSel.dataCancelamento))}, por: ${sttPage.diariaSel.canceladoPor}`}</a>
                            </h2>
                            { (sttPage.diariaSel.motivoCancelamento !== null && sttPage.diariaSel.motivoCancelamento !== "") &&
                              <h2 className = "text-diaria-infos-du">
                                Motivo do cancelamento: <a style={{color: "#DA5A34"}}>{`${sttPage.diariaSel.motivoCancelamento}`}</a>
                              </h2>
                            }
                          </>
                        }
                      </div>
                      <div className = "line-border-bottom"/>
                      <Diaria
                        day       = {daySel}
                        diaria    = {sttPage.diariaSel}
                        onClick   = {() => {
                          setSttPage({
                            ...sttPage,
                            modalCD: true
                          });
                          handleMOP(true);
                        }}
                        onClickA = {() => {
                          setSttPage({
                            ...sttPage,
                            modalAV: true
                          });
                          handleMOP(true);
                        }}
                      />
                    </div>
                    <div className = "contain-view-usuario">
                    <div className = "contain-resumo-cliente">
                      <UserCircle
                        className = "icon-user-circle-hf"
                      />
                      <h2>{sttPage.diariaSel.usuario.nome}</h2>
                      <h3>{sttPage.diariaSel.usuario.usuario}</h3>
                      <p>{""}</p>
                      <button
                        className = "btt-text-icon-cliente"
                        onClick   = {() => {
                          //console.loglog("HoraFx", horaFx);
                          // Salva o idusuario do cliente e salva no localStorage!
                          LocalStorage.set("cliente-selected", sttPage.diariaSel.usuario.idusuario);
                          serverRouter("clientes");
                        }}
                      >
                        Ver histórico do cliente
                      </button>
                    </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      }
    </>
  );

  function RetornaData(day){
    // Recebe um objeto tipo Date e retorna uma string no formato YYYY-MM-DD
    // Retorna TIMESTAMP "2023-10-15T15:09:33.000Z"
    const timesT = moment(day).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const ano = day.getFullYear();
    const mes = day.getMonth() + 1;
    const dia = day.getDate();
    return `${timesT}`;
  }

  function ReturnPayMetod(str){
    const hasPix = /PIX/.test(str);
    const hasCartaoCredito = /CARTAO_CREDITO/.test(str);

    if (hasPix && hasCartaoCredito) {
        return "Pix ou Cartão de crédito";
    } else if (hasPix) {
        return "Pix";
    } else if (hasCartaoCredito) {
        return "Cartão de crédito";
    } else {
        return "Forma de pagamento não reconhecida";
    }
  }

  function ReturnRegraCancelamento(regra){
    //console.log("regra", regra);
    return regra.usuarioPodeCancelar === "DIA" ? "Até o dia anterior" : (regra.usuarioPodeCancelar === "SEMPRE" ? "A qualquer momento" : "Não poderá cancelar")
  }
}
