import React                        from "react";
import { LocalStorage, Services }   from "../../../services";
import { BiCalendarCheck }          from "react-icons/bi";
import { BsBookmarkStar }           from "react-icons/bs";
import { AuthContext }              from "../../../contexts/AuthContext";
import { PedidosAPI }               from "../../../servicesAPI";
import Loading                      from "../Loading/Loading";
import Alerts                       from "../AlertsSwall";
import moment                       from "moment";

import "./stylesICEx.css"
import RouterHook from "../../../contexts/RouterHook";

export default function CompICEx({itemC, onClick}){

  const { token }             = React.useContext(AuthContext);
  const serverRouter          = RouterHook();
  const [loading, setLoading] = React.useState(false);

  function GoPedido() {
    //console.log("item: ", itemC);
    if(itemC.pedido_idpedido === null) return Alerts.aviso("Aviso", "Não foi possível encontrar o pedido relacionado a este item, por favor contate nosso suporte!");
    setLoading(true);
    PedidosAPI.BuscarPedidoId(token, itemC.pedido.idpedido).then((ress) => {
      //console.log("Pedido: ", ress);
      LocalStorage.set("pedidoDetails", {pedido: ress.req, page: "assinatura"});
      serverRouter("details-appoint", itemC.pedido.idpedido);
      setLoading(false);
    });
    
  }

  //console.log("CompICEx.js - item: ", itemC)
  if(itemC.pedido_idpedido !== null){
    return (
      <>
        {
          loading ?
          <Loading mode = {false} /> :
          <button
            className = "btt-comp-item-ciclo-ex"
            onClick   = {() => {
              GoPedido();
            }}
          >
            <BiCalendarCheck
              className = "color-pedido-realizado"
              size = {27}
            />
            <div className = "contain-details-pedido-ic-ex">
              <h3 className = "text-id-agendamento">
                {`Agendamento #${itemC.pedido_idpedido}`}
              </h3>
              <h3 className = 'text-status-pedido' style={{color: "#00cda4"}}>
                REALIZADO
              </h3>
              <h1 className = "text-infos-negrito" style={{fontSize: "var(--font-size-h4)"}}>
                {`${Services.ReturnHoraMin(new Date(itemC.pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(itemC.pedido.dataFinal))}`}
              </h1>
              <p className = "text-data-ic-ex">
                {`${moment(itemC.dataFaturado).format("DD/MM/YYYY")} às ${moment(itemC.dataFaturado).format("HH:mm")}`}
              </p>
            </div>
            <div className = "contain-valor-ic-ex">
              <p className = "text-title-valor-ic-ex">
                Taxa
              </p>
              <h3 className = "text-valor-ic-ex">
                {`R$${itemC.valor.toFixed(2).toString().replace(".",",")}`}
              </h3>
            </div>
          </button>
        }
      </>
    );
  } else {
    return (
      <button
        className = "btt-comp-item-ciclo-ex"
        onClick = {() => onClick(itemC)}
      >
        <BsBookmarkStar
          className = "icon-plano-ic-ex"
        />
        <h3 className = "text-label-plano-ic-ex">
          {itemC.descricao}
        </h3>
        <div className = "contain-valor-ic-ex">
          <p className = "text-title-valor-ic-ex">
            Plano
          </p>
          <h3 className = "text-valor-ic-ex">
            {`R$${itemC.valor.toFixed(2).toString().replace(".",",")}`}
          </h3>
        </div>
      </button>
    );
  }
}