import React                  from 'react';
import { FiMessageSquare }    from 'react-icons/fi';
import { MdMessage }          from 'react-icons/md';
import { ChatContext }        from '../../../contexts/ChatContext';

import "./stylesIC.css";

export default function IconChat({mode, refChat, onClick, count, mobile}) {
  //console.log("IconChat: ", mode, count);
  const { states } = React.useContext(ChatContext);
  if(states.chatMNLP || states.chatMNLE){ // mode = true -> Chat não lido
    return (
      <div 
        className = 'contain-icon-chat'
        onClick   = {onClick}
        ref       = {refChat}
      >
         <div className = 'icon-count-message'>
          {count}
        </div>
        <MdMessage
          className = 'icon-chat-read'
          size      = {33}
        />
      </div>
    );
  } else { // mode = false -> Chat lido
    return (
      <div 
        className = 'contain-icon-chat'
        onClick   = {onClick}
        ref       = {refChat}  
      >
        <FiMessageSquare
          size    = {33}
          className = 'icon-chat-not-message'
        />
      </div>
    );

  }
}