import React        from "react";

import "./stylesCP.css";
import { DollarNote } from "../../../assets";
import moment from "moment";

export default function CompPay({ pay, onClick}) {
  //console.log("pay", pay);
  return (
    <div className = "contain-comp-pay">
      <div className = "contain-interior-pay">
        <div className = "contain-mid-left-pay" style = {{maxWidth: "250px"}}>
          <h3 className = "title-contain">
            {`Pagamento ${pay.tipo}`}
          </h3>
          <h4 className = "text-info-pay" style = {{marginBottom: "0px"}}>
            {`Criado ${pay.criadoPor === "sistema" ? "automaticamente" : "por " + pay.criadoPor} em: ${moment(pay.createdAt).format("DD/MM/YY")} - ${moment(pay.createdAt).format("HH:mm")}`}
          </h4>
          <h4 
            className = "text-info-pay"
          >
            {``}
          </h4>
          { pay.observacao !== null &&
            <h4 className = "text-info-pay">
              {`${pay.observacao}`}
            </h4>
          }
        </div>
        <div className = "contain-mid-right-pay">
          <h3 className = "title-contain">
            {`${RetornaMetodPay(pay.formaPagamento)}`}
          </h3>
          <h4 className = "text-info-dollar">
            {`${pay.valor !== null ? ("R$" + pay.valor.toFixed(2).toString().replace(".", ",")) : "Aguardando pagamento"}`}
          </h4>
        </div>
      </div>
      { (pay.formaPagamento === "DINHEIRO" || pay.formaPagamento === "CARTAO_CREDITO")  &&
        <button
          className = "btt-remove-pay"
          onClick   = {onClick}
        >
          Remover esse pagamento
          <DollarNote
            className = "icon-btt-pay"
          />
        </button>
      }
    </div>
  );

  function RetornaMetodPay(metod) {
    switch(metod) {
      case "DINHEIRO":
        return "Dinheiro";
      case "MAQUINA_CARTAO":
        return "Máquina de cartão";
      case "PIX":
        return "PIX";
      case "CARTAO_CREDITO":
        return "Cartão de crédito";
      case "LOCAL":
        return "Local";
      case "Pagamento online":
        return "Pagamento Online";
      default:
        return "Outro";
    }
  }
}