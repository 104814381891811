import React                        from "react";
import { Loading }                  from "../../Basics";
import Modal                        from "react-modal";
import { BsStarFill }               from "react-icons/bs";
import { AuthContext }              from "../../../contexts/AuthContext";
import { Services, LocalStorage}    from "../../../services";
import Swal                         from "sweetalert2";
import { BiX }                      from "react-icons/bi";
import RouterHook                   from "../../../contexts/RouterHook";
import { PedidosAPI }               from "../../../servicesAPI";

import "./stylesMAv.css";

Modal.setAppElement('#root');

export default function ModalAv({modal, ClosedModal, aval, idpedido}) {

  //console.log("Avaliação: ", aval);
  const { date, token }       = React.useContext(AuthContext);
  const nome = date.empresa.nome;

  // States para input da avaliação
  const [sttM, setSttM]           = React.useState({
    qualitCampo     : aval.qualidadeLocal,
    qualitMateriais : aval.qualidadeMateriais,
    tempoResp       : aval.tempo,
    notaGeral       : aval.notaGeral,
    appAgendei      : aval.agendarPeloApp,
    writeOpn        : "",
  });

  //console.log("sttM: ", sttM);

  const formulario = [
    {
      mode  : "star",
      title : "Qualidade da quadra e ambiente",
      text  : "O local da prática esportiva estava em boas condições?",
      value : sttM.qualitCampo,
      onchange : (idx) => {setSttM({...sttM, qualitCampo: idx})}
    },{
      mode  : "star",
      title : "Qualidade dos materiais esportivos",
      text  : "Ex: Bola, raquete, coletes estavam em boas condições?",
      value : sttM.qualitMateriais,
      onchange : (idx) => {setSttM({...sttM, qualitMateriais: idx})}
    },{
      mode  : "star",
      title : "Tempo de resposta",
      text  : "Seu agendamento foi confirmado rapidamente?",
      value : sttM.tempoResp,
      onchange : (idx) => {setSttM({...sttM, tempoResp: idx})}
    },{
      mode  : "star",
      title : `Nota geral para a empresa ${nome}`,
      text  : "No geral, o que você achou da experiência?",
      value : sttM.notaGeral,
      onchange : (idx) => {setSttM({...sttM, notaGeral: idx})}
    },{
      mode  : "star",
      title : "Avalie a plataforma Agendei Quadras",
      text  : "Sua experiência em agendar pela plataforma foi boa?",
      value : sttM.appAgendei,
      onchange : (idx) => {setSttM({...sttM, appAgendei: idx})}
    },{
      mode  : "text",
      title : "Comentário sobre a experiência?",
      text  : "Sua opinião é muito importante para nós.",
      value : sttM.writeOpn,
      onChange : (e) => {setSttM({...sttM, writeOpn: e.target.value})}
    }
  ]
  const styles = {
    // Definir css para a estrela, interromper o hover
    pointerEvents  : "none",
  }

  //console.log("Nome: ", date.user.nome);
  return (
    <Modal
      isOpen              = {modal}
      className           = "contain-content-modal-avaliacao"
      overlayClassName    = "contain-overlay-modal-avaliacao"
      onRequestClose      = {ClosedModal}
    >
      <div className = "contain-modal-avaliacao-center">
        <BiX
          className = "icon-closed-modal-avaliacao"
          onClick={() => {ClosedModal()}}
        />
        <h2>{`Avaliação #${idpedido}`}</h2>
        
        <div className = "contain-modal-flat-list">
          {
            formulario.map((item, index) => {
              if(item.mode === "star"){
                return (
                  <div 
                    key       = {index}
                    className = "contain-comp-formulario"
                  >
                    <h2>{item.title}</h2>
                    <h3>{item.text}</h3>
                    <div className = "contain-row-stars">
                      {
                        [1,2,3,4,5].map((idx) => {
                          return(
                            <BsStarFill
                              key       = {idx}
                              onClick   = {() => {item.onchange(idx)}}
                              size      = {25}
                              className = {idx <= item.value ? "icon-star-select" : "icon-star-ofuscado"}
                              style     = {aval != null ? styles : {}}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
                );
              } else {
                return (
                  <div 
                    key       = {index}
                    className = "contain-comp-formulario"
                  >
                    <h2>{item.title}</h2>
                    <h3>{(aval.texto === "" || aval.text === null) ? "Cliente não comentou sobre a experiência." : item.text}</h3>
                  </div>
                );
              }
            })
          }
        </div>
      </div>
    </Modal>
  );
}