import React             from "react";
import {
  Routes, Route
} from "react-router-dom";
import { 
  ContainMax, ContainMid 
} from "../../components/Primar";
import { 
  AlertSolicit,
  Header, SideBar 
} from "../../components/Complext";
import { AuthContext }    from "../../contexts/AuthContext";
import RouterHook         from "../../contexts/RouterHook";
import { 
  Avaliacoes, Chat, Clientes, DetailsAppoint, Diarias, GestorAgenda, Help,
  HoraFixa, MinhaAgend, Settings, AspectsLegais, FluxoCaixa, EstatEmpresa, 
  Cupons, Assinatura
} from "../../components/Pages";
import { ModalCompLink }  from "../../components/Modais";
import { LocalStorage }   from "../../services";

import "./stylesMenu.css";
import ChatContextProvider from "../../contexts/ChatContext";
import { EmpresaAPI } from "../../servicesAPI";
import { Loading } from "../../components/Basics";

export default function Menu() {
  const serverRouter    = RouterHook();
  const { date, token, handleDate, handleMOP, modalOpen }        = React.useContext(AuthContext);
  //console.log("date", date);
  /*
  
  const acessId = [
    96830, // WillianS
    60719, // agendei@agendeiquadras.com.br
    61842, // luiz@agendeiquadras.com.br
    158196,// mari@agendeiquadras.com.br
    161657,// thali@agendeiquadras.com.br
  ];
  const perfilAg = {
    "idperfilAcesso"            : 1026,
    "chat"                      : true,
    "aprovar"                   : true,
    "cancelar"                  : true,
    "agendarAvulso"             : true,
    "verAgendaCalendario"       : true,
    "verListaAgendamentos"      : true,
    "gerenciarMensalistas"      : true,
    "configurarValoresAvulsos"  : true,
    "configurarValoresFixos"    : true,
    "gerenciarLicenca"          : true,
    "editarQuadra"              : true,
    "editarEmpresa"             : true,
    "verClientes"               : false,
    "verEstatisticas"           : true,
    "desativarQuadra"           : true,
    "ativarQuadra"              : true,
    "rankingEstados"            : false,
    "rankingCidades"            : false,
    "rankingFaturamento"        : false,
    "gestaoAulas"               : false,
    "fluxoCaixa"                : true,
    "diarias"                   : true
  };
  //*/
  //console.log("date", date.gestor.idusuario);
  const [stt, setStt]   = React.useState({
    sideBar   : true,
    handleHF  : false,
  });
  const [clickDay, setClickDay] = React.useState(false);
  const [inputId, setInputId]   = React.useState("");
  const [modal, setModal]       = React.useState(false);
  const [recurso, setRecurso]   = React.useState({
    isAtive     : true,
    loading     : false,
  });
  /*
  //console.log("date", date);
  async function AlterarEmpresa(){

    if(!recurso.isAtive)  return;
    if(inputId === "")    return;

    let id = parseInt(inputId);
    if(isNaN(id))         return;
    if(id === date.gestor.idempresa) return;
    
    setRecurso({
      ...recurso,
      loading: true,
    });
    const req = await EmpresaAPI.BuscarEmpresaPorID(token, id);
    //console.log("Req: ", req);
    const req2 = await EmpresaAPI.BuscaQuadrasEmpresa(token, date.empresa.idempresa);
    //console.log("Req2: ", req2);
    const empresa = {
      ...req.req,
      quadras: req2.req
    }
    //console.log("Empresa: ", empresa);
    handleDate({
      ...date,
      perfilG: perfilAg,
      empresa: empresa
    });
    setRecurso({
      ...recurso,
      loading: false,
    });
  }
  //*/
  function handleHorFixa(){
    setStt({...stt, handleHF: !stt.handleHF});
  }
  const lb = {
    home          : "",
    ajuda         : "ajuda",
    chat          : "conversas",
    setgs         : "configuracoes/:idusuario",
    detl          : "agendamento/:idpedido",
    buscar        : "buscar-agendamentos",
    horaFx        : "horarios-fixos",
    diaria        : "diarias",
    clientes      : "clientes",
    avaliacoes    : "avaliacoes",
    aspects       : "aspectos-legais/:idusuario",
    fluxoCaixa    : "fluxo-caixa",
    cupons        : "cupons",
    //estatEmpresa  : "retrospectiva-empresa-2023",
    assinatura    : "assinatura",
  }
  const [stt2, setStt2] = React.useState(false);
  React.useEffect(() => {
    setStt2(!stt2);
  }, [date.empresa.quadras]);
  
  React.useEffect(() => {
    if(modal){
      handleMOP(true);
    } else {
      handleMOP(false);
    }
  }, [modal]);
  
  React.useEffect(() => {
    if(!modalOpen){
      setModal(false);
    } 
  }, [modalOpen]);


  function handleBttSel(val){
   //console.log("SideBar Select", val);
    if(val === "sideBar")             setStt({...stt, sideBar: !stt.sideBar});
    else if(val === "home")           serverRouter("menu", date.empresa.username);
    else if(val === "buscar")         serverRouter("buscar");
    else if(val === "hora-fixa"){
      LocalStorage.remove("stt-page-hf");
      LocalStorage.remove("hora-fixa-selected");
      LocalStorage.remove("pg-client-buffer");
      LocalStorage.remove("pg-array-stt-buffer");
      serverRouter("hora-fixa");
    } 
    else if(val === "ajuda")          serverRouter("ajuda");
    else if(val === "diaria")         serverRouter("diaria");
    else if(val === "clientes")       serverRouter("clientes");
    else if(val === "avaliacoes")     serverRouter("avaliacoes");
    else if(val === "fluxo-caixa")    serverRouter("fluxo-caixa");
    else if(val === "assinatura")     serverRouter("assinatura");
    else if(val === "cupons")         serverRouter("cupons");
  }

  function handleKeyDown(e){
    if(e.key === "Enter"){
      //AlterarEmpresa();
    }
  }

  return (
    <ContainMax>
        {/* 
          (recurso.isAtive && acessId.includes(date.gestor.idusuario)) &&
          <div className = "contain-acessar-empresas">
            <h2>Acessar empresa pelo idEmpresa:</h2>
            <div className = "contain-acessar-empresas-inter">
              <input
                id        = "input-acessar-empresas"
                type      = "number"
                value     = {inputId}
                onChange  = {(e) => setInputId(e.target.value)}
                onKeyDown = {(e) => handleKeyDown(e)}
              />
              <button 
                onClick = {() => {
                  AlterarEmpresa();
                }}
              >
                Acessar
              </button>
            </div>
          </div>
        //*/
      }{
        modal &&
        <ModalCompLink
          modal       = {modal}
          ClosedModal = {() => setModal(false)}
        />
      }
      <ChatContextProvider>
        <Header/>
        {
          recurso.loading ?
          <div className = "contain-loading">
            <Loading 
              mode={true} 
              text={"Alterando empresa..."}
            />
          </div>
            :
          <ContainMid>
            <SideBar
              show          = {stt.sideBar}
              handleBttSel  = {(val) => handleBttSel(val)}
              onClick       = {() => {setModal(!modal)}}
            />
            <div 
              className = "contain-menu-show"
              style     = {{width: (stt.sideBar ? "calc(100% - 250px)" : "calc(100% - 70px)")}}
            >
              { ( stt.bttSel !== "checkout" && stt.bttSel !== "chat" && date.perfilG.aprovar ) &&
                <AlertSolicit 
                  onClick       = {() => handleHorFixa()}
                  handleClkDay  = {() => setClickDay(!clickDay)}
                />
              }
              <Routes>
                <Route path = {lb.home}             element={<MinhaAgend/>}         />
                <Route path = {lb.ajuda}            element={<Help/>}               />
                <Route path = {lb.chat}             element={<Chat/>}               />
                <Route path = {lb.setgs}            element={<Settings/>}           />
                <Route path = {lb.detl}             element={<DetailsAppoint/>}     />
                <Route path = {lb.buscar}           element={<GestorAgenda/>}       />
                <Route path = {lb.clientes}         element={<Clientes/>}           />
                <Route path = {lb.avaliacoes}       element={<Avaliacoes/>}         />
                <Route path = {lb.aspects}          element={<AspectsLegais/>}      />
                <Route path = {lb.fluxoCaixa}       element={<FluxoCaixa/>}         />
                <Route path = {lb.diaria}           element={<Diarias    show   = {stt.sideBar} clickDay={clickDay}/>}  />
                <Route path = {lb.horaFx}           element={<HoraFixa   handle = {stt.handleHF}/>}                     />
                <Route path = {lb.cupons}           element={<Cupons/>}             />
                <Route path = {lb.assinatura}       element={<Assinatura/>}         />
              </Routes>
            </div>
          </ContainMid>
        }
      </ChatContextProvider>
    </ContainMax>
  );
  
 
}; // Fim Menu