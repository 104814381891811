//Mensalistas
const message = "Parece que estamos com problemas de comunicação. Por favor, tente novamente mais tarde.";
// Buscar estatisticas
const buscar_estatistica_empresa      = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/buscarEstatisticaEmpresa/?idempresa=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Resposta das estatisticas: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
const criar_mensalista                = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/criarPorGestor/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da criação do mensalista: ", res);
    return { status: true, req: res };
  } catch(e) {
    return { status: false, message, req: e };
  }
};
// Calcular agendamento fixo
const calcular_reserva_hf             = async ( token, data ) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/mensalista/realizarPreAgendamento`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data),
    });
    return {status : true,  reqs : await req.json()};
  } catch(err) {
    return {status : false, reqs : err, message};
  }
};
// Buscar reservas da empresa
const buscar_mensalista_id            = async ( token, id ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/buscarPorId/?id=${id}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Resposta Mensalista ID: ", ress);
    return {status : true, reqs : ress};
  } catch(e){
    return {status : false, reqs : e, message};
  }
};
// Buscar mensalistas da empresa
const buscar_mensalista_empresa_id    = async ( token, buf ) => {
  try{
    //console.log("Buffer: ", buf);
    const url = `${process.env.REACT_APP_URL_API}/mensalista/buscarTodosUsuariosPorEmpresa/?idempresa=${buf.idempresa}&limit=${buf.limit}&offset=${buf.offset}&countAll=${buf.countAll}`;
    const req = await fetch(url, {
      method    : "GET",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Buscar Mensalista User ID: ", ress);
    return { status : true, reqs : ress};
  } catch(e){
    return { status : false, reqs : e, message};
  }
};
// Buscar mensalistas do cliente
const buscar_mensalista_cliente_id    = async ( token, buf ) => {
  try{
    //console.log("Buffer: ", buf);
    const url = `${process.env.REACT_APP_URL_API}/mensalista/buscarTodosPorUsuarioPaginado/?idusuario=${buf.idusuario}&idempresa=${buf.idempresa}&limit=${buf.limit}&offset=${buf.offset}&countAll=${buf.countAll}`;
    const req = await fetch(url, {
      method    : "GET",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Buscar Mensalista User ID: ", ress);
    return { status : true, reqs : ress};
  } catch(e){
    return { status : false, reqs : e, message};
  }
};
// Aprovar pedido fixo
const aprovar_pedido_fixo             = async ( token, data ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/aprovarSolicitacaoMensalista/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data),
    });
    const ress = await req.json();
    //console.log("Resposta Aprovar Pedido Fixo: ", ress);
    return {status : true, reqs : ress};
  } catch(err){
    return {status : false, reqs : err, message};
  }
};
// Recusar pedido fixo
const recusar_pedido_fixo             = async ( token, data ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/rejeitarSolicitacaoMensalista/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data),
    });
    const ress = await req.json();
    //console.log("Resposta Recusar Pedido Fixo: ", ress);
    return {status : true, reqs : ress};
  } catch(e){
    return {status : false, reqs : e, message}; 
  }
};
// Cancelar pedido fixo (encerrar mensalidade)
const cancelar_pedido_fixo            = async ( token, data ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/encerrarMensalista/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta Cancelar Pedido Fixo: ", ress);
    return {status : true, reqs : ress};
  } catch(err){
    return {status : false, reqs : err, message};
  }
};
// Buscar todos horarios fixos por idempresa
const buscar_horarios_fixos           = async ( token, data ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/buscarTodosPorEmpresaPaginado/?idempresa=${data.idempresa}&limit=${data.limit}&offset=${data.offset}&countAll=${data.countAll}`;
    const req = await fetch(url, {
      method    : "GET",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      }
    });
    const ress = await req.json();
   //console.loglog("Resposta Buscar Horarios Fixos: ", ress);
    return {status : true, reqs : ress};
  } catch(e){
    return {status : false, reqs : e, message};
  }
};

//---------------------
// Pedidos!!
// Realizar pedido 
const realizar_pedido                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/criarPedidoAdmin/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta Pedido: ", ress);
    return { status : true,  reqs : ress}
  } catch(err){
    return { status : false, reqs : err, message};
  }
};
// Pedido_Fixo
const realizar_pedido_fixo            = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/criarPorGestor/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    });
    
    const ress = await req.json();
    //console.log("Resposta Pedido Fixo: ", ress);
    return { status : true,  reqs : ress}
  } catch(e){
    return { status : false, reqs : e, message};
  }
};
// Buscar agenda da quadra
const buscar_agenda_quadra            = async (token, idquadra, data) => {
  try{
    //console.log("Token", token);
    const url = `${process.env.REACT_APP_URL_API}/grades/horarios/?quadra_idquadra=${idquadra}&data=${data}&siteGestor=${true}`; // Modificar rota! Esta rota é do gestor!
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"   : "application/json",
        "accept"        : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta da busca de agenda da quadra: ", res);
    return {status: true, req: res};
  } catch(e) {
    //console.log("Erro na busca de agenda da quadra: ", e);
    return {status: false, req: e, message};
  }
};
// Buscar reservas da empresa
const buscar_reservas_empresa_id      = async (token, buf) => {
  try{
    //console.log("Buffer: ", buf);
    const url = `${process.env.REACT_APP_URL_API}/pedidos/filtrarPedidos/`;
    const reqs = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(buf),        
    });
    const ress = await reqs.json();
   //console.loglog("Resposta Reservas: ", ress);
    return {status : true, reqs : ress};
  } catch(err){
    return { status : false, reqs : err, message};
  }
};
// Buscar reservas solicitadas
const buscar_reservas_solicitadas     = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/buscarExistenciaPedidosSolicitadas/?idEmpresa=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta das reservas solicitadas: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Buscar Mensalistas Solicitados
const buscar_mensalistas_solicitados  = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/mensalista/buscarSolicitacoesMensalistas/?idempresa=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta das reservas solicitadas: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Aprovar solicitação de reserva
const aprovar_pedido                  = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/aprovar/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da aprovação da reserva: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Rejeitar solicitação de reserva
const cancelar_pedido                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/cancelar/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da rejeição da reserva: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Atualizar valor total do pedido
const atualizar_valor_pedido          = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/atualizarValorPedido/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
   //console.log("Resposta da atualização do valor do pedido: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Adicionar pagamento ao pedido
const adicionar_pagamento_pedido      = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/criarPagamentoManual/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
   //console.log("Resposta da adição de pagamento ao pedido: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Deletar um pagamento
const deletar_pagamento_pedido        = async (token, idpagamento) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/deletarPagamentoManual/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body    : JSON.stringify({idpagamento}),
    });
    const res = await req.json();
   //console.log("Resposta da deleção de pagamento ao pedido: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Buscar pedidos do cliente
const buscar_pedidos_cliente          = async (token, data) => {
  try{
    //console.log("Buffer: ", buf);
    const url = `${process.env.REACT_APP_URL_API}/pedidos/buscarPedidosPorUsuarioPorEmpresa/?id=${data.idusuario}&idEmpresa=${data.idEmpresa}&limit=${data.limit}&offset=${data.offset}&countAll=${data.countAll}&status=${false}`;
    
    const reqs = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
    });
    const ress = await reqs.json();
   //console.log("Resposta Reservas: ", ress);
    if(ress.retorno){
      return {status : true, reqs : ress.pedido};
    } else {
      return {status : false, reqs : "Pedimos desculpas, estamos enfrentando problemas com nosso servidor. Por favor, tente novamente mais tarde."};
    }
  } catch(err){
    return { status : false, reqs : "Pedimos desculpas, estamos enfrentando problemas com nosso servidor. Por favor, tente novamente mais tarde."};
  }
};

//---------------------
// DayUse!!
// Buscar diárias com filtro de data
const buscar_diarias_vendidas         = async (token, data) => {
  try {
    /* 
      body : {
        idempresa : int,
        data      : YYYY-MM-DD,
      }
    */
    const url = `${process.env.REACT_APP_URL_API}/dayuse/buscarDayUseVendidosPorData/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta Buscar Diarias Vendidas: ", res);
    return {status : true, reqs : res};
  } catch(err) {
    return {status : false, reqs : err, message};
  }
};
// Solicitar diária
const marcar_diaria                   = async (token, data) => {
  try{
    //console.log("Dados da solicitação de diaria: ", data);
    const url = `${process.env.REACT_APP_URL_API}/dayuse/aderirDayUse`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"   : "application/json",
        "accept"        : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const ress = await req.json();
    //console.log("Resposta da solicitação de diaria: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    // Comunicação falhou!
    //console.log("Erro na solicitação de diaria: ", e);
    return {status: false, req: e, message};
  }
};
// Cancelar diária
const cancelar_diaria                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/cancelarCompraDayUse/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Cancelar Compra: API!", ress);
    return { status: true,  req: ress }
  } catch (e) {
    return { status : false, req : e, message};
  }
};
// Buscar diárias disponíveis para venda!
const buscar_diarias                  = async (token, data) => {
  try{
    /* 

      body : {
        idempresa : int,
        diaSemana : 1 - 7, //onde 1 é domingo
        data      : date.toString(),
      }
    */
    const url = `${process.env.REACT_APP_URL_API}/dayuse/buscarDayUseComprar`;
    const res = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const ress = await res.json();
    //console.log("Resposta das diarias: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// buscar Diaria por ID
const buscar_diaria_id                = async (token, id) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/buscarCompraPorId/?id=${id}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da diaria: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Buscar Diarias solicitadas ALERTA
const buscar_diarias_solicitadas      = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/filtrarDiariasVendidasPorEmpresa?status=SOLICITADA&idempresa=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta das diarias solicitadas: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Aprovar solicitação de diária
const aprovar_solicitacao_diaria      = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/aprovarSolicitacao/?idcompraDayUse=${data.idcompraDayUse}&aprovadoPor=${data.aprovadoPor}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Resposta da aprovação da diaria: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Rejeitar solicitação de diária
const rejeitar_solicitacao_diaria     = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/rejeitarSolicitacao/?idcompraDayUse=${data.idcompraDayUse}&rejeitadoPor=${data.rejeitadoPor}&motivoCancelamento=${data.motivo}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta da rejeição da diaria: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Cancelar solicitação de diária
const cancelar_diaria_solicitada      = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/cancelarVendaDiaria/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta do cancelamento da diaria: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Buscar diarias disponiveis da empresa
const buscar_diarias_da_empresa       = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/buscarTodosDayUsePorEmpresa/?idempresa=${data.idempresa}&limit=${data.limit}&offset=${data.offset}&countAll=${data.countAll}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    });
    const ress = await req.json();
   //console.log("Resposta das diarias disponiveis da empresa: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, req: e, message};
  }
};
// Ativar ou desativar a venda de diarias na empresa
const ativar_desativar_diarias        = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/licencas/atualizar/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta da ativação ou desativação da diaria: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Ativar DayUse
const ativar_dayuse                   = async (token, idDayUse) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/ativarDayUse/?id=${idDayUse}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    });
    const ress = await req.json();
    //console.log("Resposta da ativação do DayUse: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Desativar DayUse
const desativar_dayuse                = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/inativarDayUse/?id=${data.iddayuse}&inativadoPor=${data.inativadoPor}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    });
    const ress = await req.json();
    //console.log("Resposta da desativação do DayUse: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Buscar DayUse de um usuario com filtro de idempresa!
const buscar_dayuse_usuario           = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/buscarDayUseVendidosPorUsuario`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta do DayUse do usuario: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
const editar_valor_diaria             = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/dayuse/atualizarValorRealizadoCompraDayUse/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta do DayUse do usuario: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};

//---------------------
// Empresas!!
// Buscar empresa por ID
const buscar_solicitacoes_empresa     = async (token, idempresa) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/empresas/buscarNovasSolicitacoesEmpresa/?idEmpresa=${idempresa}`;	
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      }
    });
    const ress = await req.json();
    //console.log("Resposta da busca de solicitações de empresa: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
const buscar_empresa_id               = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/buscarPorId/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`,
      }
    });
    const ress = await req.json();
    //console.log("Resposta da busca da empresa: ", ress);
    return {status: true, req: ress};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Aprovar cliente
const aprovar_cliente                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/aprovarCliente/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da aprovação do cliente: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};    
// Recusar cliente    
const recusar_cliente                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/recusarCliente/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da recusa do cliente: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};    
// Revogar cliente    
const revogar_cliente                 = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/revogarCliente/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da revogação do cliente: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
}   
// Buscar solicitações de acesso    
const buscar_solicitacao_acess        = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/buscarClientesPorFiltro/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da busca de solicitações de acesso: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Buscar regra de cancelamento
const buscar_regra_cancelamento       = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/public/consultarRegraCancelamentoAtiva/?idempresa=${idempresa}`;
    const res = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`
      }
    });
    const ress = await res.json();
    //console.log("Resposta da regra de cancelamento: ", ress);
    return {status: true, req: ress};
  } catch(err) {
    return {status: false, req: err};
  }
};
// buscar clientes da empresa por filtro de string
const buscar_clientes_empresa_filtro  = async (token, data) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/usuarios/clientesPorEmpresaFiltro/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
   //console.log("Resposta da busca de clientes da empresa por filtro de string: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
}
// Buscar BlackList da empresa
const buscar_blacklist_empresa        = async ( token, idEmpresa ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/${idEmpresa}/blocks/`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta da busca de blacklist da empresa: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Adiciona um usuário na blacklist da empresa
const adicionar_user_blacklst_emp     = async (token, date) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/${date.idEmpresa}/blocks/`;
   //console.log("Data: ", date);
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(date),
    });
    const res = await req.json();
    //console.log("Resposta da adição de usuário na blacklist da empresa: ", res);
    return { status: true, req: res };
  } catch(e) {
    return { status: false, message, req: e };
  }
};
// Remove um usuário da blacklist da empresa
const remover_user_blacklst_emp       = async (token, date) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/empresas/${date.idEmpresa}/blocks/${date.idUsuario}?idGestor=${date.idGestor}`;
    const req = await fetch(url, {
      method  : "DELETE",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta da adição de usuário na blacklist da empresa: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};
// Fluxo de caixa
const fluxo_caixa                     = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/fluxoCaixa/filtrar/`; 
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "content-type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta do fluxo de caixa: ", ress);
    return { status: true,  req: ress }
  } catch (e){
    return { status : false, req : e, message };
  }
};
// Calcular valor total do fluxo de caixa
const calcular_fluxo_caixa            = async (token, data) => {
  try{
    //console.log("Data: ", data);
    const url = `${process.env.REACT_APP_URL_API}/fluxoCaixa/somaFiltrar/`; 
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "content-type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Resposta do calculo do fluxo de caixa: ", ress);
    return { status: true,  req: ress }
  } catch (e){
    return { status : false, req : e, message };
  }
};
// Criar pagamento no fluxo de caixa
const criar_pagamento_fluxo_caixa     = async (token, data) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/fluxoCaixa/criarPagamentoManual/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta Criar Pagamento: ", res);
    return {status : true,  reqs : res};
  } catch(e) {
    return {status: false, message, req: e};
      
  }
};
// Deletar um pagamento
const deletar_pagamento_fluxo_caixa   = async (token, idpagamento) => {
  try{
    //console.log("Id do pagamento: ", idpagamento);
    const url = `${process.env.REACT_APP_URL_API}/fluxoCaixa/deletarPagamentoManual/`;
    const req = await fetch(url, {
      method  : "DELETE",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify({idpagamento}),
    });
    const res = await req.json();
   //console.log("Res de delete user: ", res);
    return {status: true, req : res}
  } catch(err){
    return {status : false, req: err, message};
  }
};

//---------------------
// Usuarios!!
// Buscar clientes da empresa
const buscar_clients_empresa          = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/usuarios/clientesPorEmpresa/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da busca de clientes da empresa: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};    
// Atualizar usuário    
const atualizar_usuario               = async (token, gestor) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/usuarios/atualizar/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(gestor)
    });
    const res = await req.json();
    //console.log("Res de delete user: ", res);
    return {status: true, req : res}
  } catch(err){
    return {status : false, req: err, message};
  }
};
//Buscar Perfil usuario
const buscar_perfil_acesso            = async (token, idusuario, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/usuarios/buscarPerfilAcesso/${idusuario}?idEmpresa=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de perfil de acesso do usuario: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};

//---------------------
// Quadras!!
// Buscar preços avulsos por dia da semana
const precos_avulsos_dia_semana       = async (token, idquadra, dia) => {
  try { 
    const url = `${process.env.REACT_APP_URL_API}/quadras/precosAvulsosPorDiaSemana/?id=${idquadra}&diaSemana=${dia}`;
    const ress = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"   : "application/json",
        "accept"        : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const res = await ress.json();
    //console.log("Resposta dos preços avulsos por dia da semana: ", res);
    return {status : true, req: res};
  } catch(err){
    //console.log("Erro no precos_avulsos_dia_semana",err);
    return {status : false, req: err, message};
  }
};    
// Buscar quadras da empresa    
const buscar_quadras_empresa          = async (token, idempresa) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/quadras/buscarQuadrasPorEmpresa/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta da busca de quadras da empresa: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};

//---------------------   
// Reservas!!   
// Calcular agendamento   
const calcular_reserva                = async (token, data) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/reservas/calcularValoresReservas/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify({
        idquadra    : data.idquadra,    // Id da quadra
        diaSemana   : data.diaSemana,   // Dia da semana (1 - 7) onde 1 é domingo
        valorTotal  : data.valorTotal,  // Valor total da reserva
        agenda      : data.agenda,      // Array de horarios selecionados!
      })
    });
    return {status : true,  reqs : await req.json()};
  } catch(err) {
    return {status : false, reqs : err, message};
  }
}

//---------------------
// Autenticação!!
// Validar senha atual
const validar_senha_atual             = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/auth/validarCredenciais`; 
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "aplication"    : "application/json",
        "content-type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body : JSON.stringify(data)
    });
    const ress = await req.json();
    return { status: true,  req: ress }
  } catch (err){
    return { status : false, req : err, message };
  }
};    
// Verificar email    
const verificar_email                 = async (token, email) => {
    try{
      const url = `${process.env.REACT_APP_URL_API}/auth/validarCredenciais`; 
      const req = await fetch(url, {
        method  : "POST",
        headers : {
          "aplication"    : "application/json",
          "content-type"  : "application/json",
          "Authorization" : `Bearer ${token}`
        },
        body : JSON.stringify(email)
      });
      const ress = await req.json();
      return { status: true,  req: ress }
    } catch (err){
      return { status : false, req : err, message };
    }
};














//---------------------
// Avaliações!!
// Buscar avaliações da empresa
const buscar_aval_empresa             = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/avaliacao/buscarPorEmpresa/?id=${data.idempresa}&limit=${data.limit}&offset=${data.offset}&countAll=${data.countAll}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"   : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta da busca de avaliações da empresa: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, req: e, message};
  }
};















// Cupons
// Buscar cupons disponiveis para ingresso na empresa
const buscar_cupons_disponiveis_ingresso = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cupom/buscarCuponsDisponiveisIngressoEmpresa/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify({idempresa}),
    });
    const res = await req.json();
    //console.log("Resposta da busca de cupons disponiveis para ingresso na empresa: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Buscar cupons vinculados a empresa
const buscar_cupons_vinculados_empresa = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cupom/buscarCuponsAtivosDaEmpresa/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify({idempresa}),
    });
    const res = await req.json();
    //console.log("Resposta da busca de cupons vinculados a empresa: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Aderir cupom
const aderir_cupom = async (token, data) => {
  try{
    /* data = {idcupomDesconto, idempresa, ativadoPor: nome_do_gestor} */
    const url = `${process.env.REACT_APP_URL_API}/cupom/adesaoEmpresa/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da adesão de cupom: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Desativar cupom vinculado à empresa - cupom/inativarEmpresaCupom/  
const desativar_cupom = async (token, data) => {
  try{
    /* data = { idcupomDesconto, idempresa } */
    const url = `${process.env.REACT_APP_URL_API}/cupom/inativarEmpresaCupom/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da desativação de cupom: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Reativar cupom vinculado à empresa cupom/ativarEmpresaCupom/
const reativar_cupom = async (token, data) => {
  try{
    /* data = { idcupomDesconto, idempresa } */
    const url = `${process.env.REACT_APP_URL_API}/cupom/ativarEmpresaCupom/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data),
    });
    const res = await req.json();
    //console.log("Resposta da reativação de cupom: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Ciclo de faturamento (Assinatura)
// Buscar todas as faturas
const buscar_faturas = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/buscarTodosPorEmpresa/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de faturas: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Buscar ciclo de faturamento aberto
const buscar_ciclo_faturamento_aberto = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/buscarCicloFaturamentoAbertoPorEmpresa/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de ciclo de faturamento aberto: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Buscar ciclo de faturamento fechado
const buscar_ciclo_faturamento_fechado = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/buscarCicloFaturamentoFechadoPorEmpresa/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de ciclo de faturamento fechado: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};
// Buscar Fatura por ID
const buscar_fatura_id = async (token, idfatura) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/buscarPorId/?id=${idfatura}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de fatura por id: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};

// Enviar fatura por email
const enviar_fatura_email = async (token, idfatura) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/reenviarEmailFaturaPorCicloFaturamento/?id=${idfatura}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "accept"        : "application/json",
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "authorization" : `Bearer ${token}`
      }
    });
    const res = await req.json();
    //console.log("Resposta do envio de fatura por email: ", res);
    return {status : true, req : res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};

// Verificar boletos pendentes
const verificar_boletos_pendentes = async (token, idempresa) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/cicloFaturamento/buscarCicloFaturamentoFechado/?id=${idempresa}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json"
      },
    });
    const res = await req.json();
    //console.log("Resposta da busca de boletos pendentes: ", res);
    return {status: true, req: res};
  } catch (e) {
    return {status: false, message, req: e};
  }
};

const simular_mensalista = async (token, data) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/mensalista/simular/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(data)
    });
    const res = await req.json();
    //console.log("Resposta da simulação de mensalista: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }

}

// Criar pedido versão gestor para cliente real
const criar_pedido_client_real = async ( token, buffer ) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/pedidos/criarParaClienteCadastrado/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(buffer)
    });
    const res = await req.json();
    //console.log("Resposta da criação de mensalista: ", res);
    return {status: true, req: res};
  } catch(e) {
    return {status: false, message, req: e};
  }
};

// Criar mensalista versão gestor para cliente ficticio
const criar_pedido_client_fict = async ( token, buffer ) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/criarParaClienteSemCadastro/`;
    const req = await fetch(url, {
      method  : "POST",
      headers : {
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body    : JSON.stringify(buffer)
    });
    const res = await req.json();
    //console.log("Resposta da criação de mensalista: ", res);
    return {status: true, req: res};
  
  } catch(e) {
    return {status: false, message, req: e};
  }
};

const api = {
  // Estatisitcas
  buscar_estatistica_empresa,
  // Mensalistas
  criar_mensalista,
  calcular_reserva_hf,
  buscar_mensalista_id,
  buscar_mensalista_empresa_id,
  buscar_mensalista_cliente_id,
  aprovar_pedido_fixo,
  recusar_pedido_fixo,
  cancelar_pedido_fixo,
  buscar_horarios_fixos,
  simular_mensalista,
  // Pedidos
  realizar_pedido,
  realizar_pedido_fixo,
  buscar_agenda_quadra,
  buscar_reservas_empresa_id,
  buscar_reservas_solicitadas,
  buscar_mensalistas_solicitados,
  aprovar_pedido,
  cancelar_pedido,
  atualizar_valor_pedido,
  adicionar_pagamento_pedido,
  deletar_pagamento_pedido,
  buscar_pedidos_cliente,
  criar_pedido_client_real,
  criar_pedido_client_fict,
  // DayUse
  marcar_diaria,
  cancelar_diaria,
  buscar_diarias,
  buscar_diarias_vendidas,
  buscar_diaria_id,
  aprovar_solicitacao_diaria,
  rejeitar_solicitacao_diaria,
  cancelar_diaria_solicitada,
  buscar_diarias_solicitadas,
  buscar_diarias_da_empresa,
  ativar_desativar_diarias,
  ativar_dayuse,
  desativar_dayuse,
  buscar_dayuse_usuario,
  editar_valor_diaria,

  // Empresas
  buscar_solicitacoes_empresa,
  buscar_empresa_id,
  aprovar_cliente,
  recusar_cliente,
  revogar_cliente,
  buscar_solicitacao_acess,
  buscar_regra_cancelamento,
  buscar_clientes_empresa_filtro,
  buscar_blacklist_empresa,
  adicionar_user_blacklst_emp,
  remover_user_blacklst_emp,
  fluxo_caixa,
  calcular_fluxo_caixa,
  criar_pagamento_fluxo_caixa,
  deletar_pagamento_fluxo_caixa,

  // Usuarios
  buscar_clients_empresa,
  atualizar_usuario,
  buscar_perfil_acesso,

  // Quadras
  precos_avulsos_dia_semana,
  buscar_quadras_empresa,

  // Reservas
  calcular_reserva,

  // Autenticação
  validar_senha_atual,
  verificar_email,
  
  // Avaliações
  buscar_aval_empresa,
  // Cupons
  buscar_cupons_disponiveis_ingresso,
  buscar_cupons_vinculados_empresa,
  aderir_cupom,
  desativar_cupom,
  reativar_cupom,
  // Ciclo de faturamento
  buscar_faturas,
  buscar_ciclo_faturamento_aberto,
  buscar_ciclo_faturamento_fechado,
  buscar_fatura_id,
  enviar_fatura_email,
  verificar_boletos_pendentes,
};

export default api;