import LocalStorage   from './LocalStorage';
import zxcvbn         from 'zxcvbn';
import moment         from 'moment';

const Services = {
  /*
    Services.ReturnData("init") -> retorna a data inicial do mês atual no formato YYYY-MM-DD
    Services.ReturnData("end")  -> retorna a data final do mês atual no formato YYYY-MM-DD
    Services.ReturnData("now") ->  retorna a data atual no formato YYYY-MM-DD
  */ 
  ReturnDataGraphic                 : (mode, sttCalc, arrayReg, dataInicial, dataFinal)=> {
    //console.log("Entrada ReturnDataGraphic: ", {mode, sttCalc, arrayReg, dataInicial, dataFinal});
    let array = [];
    let tamanho = [];
    arrayReg.map((item) => {
      if(tamanho.length === 0) tamanho.push(moment(item.data).format("DD/MM/YY"));
      else {
        if(!tamanho.includes(moment(item.data).format("DD/MM/YY"))){
          tamanho.push(moment(item.data).format("DD/MM/YY"));
        }
      }        
    });
    if(!tamanho.length < 8){ // monta o array de datas
      const dataInit  = moment(dataInicial);
      const dataFim   = moment(dataFinal);
      const diff      = dataFim.diff(dataInit, 'days');
      //console.log("diff: ", diff);
      const per       = Math.round(diff / 7);
      //console.log("per: ", per);
      let initPer = dataInit;
      //adiciona o período
      let fimPer = moment(initPer);
      fimPer.add(per, 'days');

      for(let x = 0 ; x < 7 ; x++){
        if(x === 0){
          const base = {
            dataG: `${initPer.format("DD/MM/YY")};${fimPer.format("DD/MM/YY")}`,
            valor: 0,
          };
          array.push(base);
        } else {
          initPer = moment(fimPer);
            if(x === 6) fimPer = moment(dataFim);
            else        fimPer.add(per, 'days');
          const base = {
            dataG: `${initPer.format("DD/MM/YY")};${fimPer.format("DD/MM/YY")}`,
            valor: 0,
          };
          array.push(base);
        }
      }
    }

    //console.log("array Montado zerado: ", array);

    switch (mode) {
      case "pizza-receit" : 
        const rawData1 = [
          {"id": "Agendamentos",        "label" : "Agendamentos",       "value": parseFloat(sttCalc.somaTotalAgendamentos).toFixed(2)},
          {"id": "Pagamentos Manuais",  "label": "Pagamentos Manuais",  "value": parseFloat(sttCalc.somaTotalReceitasManuais).toFixed(2)},
          {"id": "Diárias",             "label": "Diárias",             "value": parseFloat(sttCalc.somaTotalDiarias).toFixed(2)},
        ];
      
        // Filtra os dados, removendo objetos onde o valor é 0
        const filteredData1 = rawData1.filter(item => item.value != 0);
        
        return filteredData1;
      case "pizza-pays" :
        const rawData2 = [
          {"id": "Pix",                 "label" : "Pix",                "value": parseFloat(sttCalc.somaTotalPix).toFixed(2)},
          {"id": "Cartão de Crédito",   "label": "Cartão de Crédito",   "value": parseFloat(sttCalc.somaTotalCartaoCredito).toFixed(2)},
          {"id": "Pagamentos no local", "label": "Pagamentos no local", "value": parseFloat(sttCalc.somaTotalLocal).toFixed(2)},
        ];
      
        // Filtra os dados, removendo objetos onde o valor é 0
        const filteredData2 = rawData2.filter(item => item.value != 0);
        
        return filteredData2;
      case "line-receit" : {
        // Verifica se o array é menor que 7 
        
        if(tamanho.length < 8){
          //console.log("Entrou no tamanho menor que 8");
          const array1 = [];
          for(let x = 0 ; x < arrayReg.length ; x++){
            if(!array1.length){
              const base = {
                dataG: moment(arrayReg[x].data).format("DD/MM/YY"),
                valor: arrayReg[x].valor,
              }
              array1.push(base);
              //console.log("Array zero", array1);
            } else {
              if(array1[array1.length - 1].dataG === moment(arrayReg[x].data).format("DD/MM/YY")){
                array1[array1.length - 1].valor += arrayReg[x].valor;
                //console.log("Array Somou ", array1);
              } else {
                const base = {
                  dataG: moment(arrayReg[x].data).format("DD/MM/YY"),
                  valor: arrayReg[x].valor,
                } 
                array1.push(base);
                //console.log("Array novo: ", array1);
              }
            }
          }
          //console.log("array: ", array1);
          // retorna o array invertido
          return array1.reverse();
        } else {
          //console.log("Entrou no tamanho maior que 8");
          for(let x = 0 ; x < arrayReg.length ; x++){
            for(let y = 0 ; y < array.length ; y++){
              // verifica se a data está dentro do período
              if(moment(arrayReg[x].data).isBetween(moment(array[y].dataG.split(";")[0], "DD/MM/YY"), moment(array[y].dataG.split(";")[1], "DD/MM/YY"), null, '[]')){
                array[y].valor += arrayReg[x].valor;
                break;
              }
            }
          }
          //console.log("array: montado ", array);
          return array;
        };
      }
      case "line-pays" : {
        if(tamanho.length < 8){
          const array = [];
          for(let x = 0 ; x < arrayReg.length ; x++){
            if(!array.length){
              //console.log("Array zero");
              const base = {
                dataG: moment(arrayReg[x].dataRecebivel).format("DD/MM/YY"),
                valor: arrayReg[x].valor,
              }
              array.push(base);
            } else {
              //console.log("Array indo: ");
              if(array[array.length - 1].dataG === moment(arrayReg[x].dataRecebivel).format("DD/MM/YY")){
                array[array.length - 1].valor += arrayReg[x].valor;
              } else {
                const base = {
                  dataG: moment(arrayReg[x].dataRecebivel).format("DD/MM/YY"),
                  valor: arrayReg[x].valor,
                } 
                array.push(base);
              }
            }
          }
          //console.log("array: ", array);
          // retorna o array invertido
          return array.reverse();
        } else {
          
          for(let x = 0 ; x < arrayReg.length ; x++){
            for(let y = 0 ; y < array.length ; y++){
              // verifica se a data está dentro do período
              if(moment(arrayReg[x].dataRecebivel).isBetween(moment(array[y].dataG.split(";")[0], "DD/MM/YY"), moment(array[y].dataG.split(";")[1], "DD/MM/YY"), null, '[]')){
                array[y].valor += arrayReg[x].valor;
                break;
              }
            }
          }
          //console.log("array: ", array);

          return array;
        }
      }
      case "line-faturas" : {
        if(tamanho.length < 8){
          const array2 = [];
          for(let x = 0 ; x < arrayReg.length ; x++){
            if(!array2.length){
              //console.log("Array zero");
              const base = {
                dataG: moment(arrayReg[x].dataRecebivel).format("DD/MM/YY"),
                valor: arrayReg[x].valor,
              }
              array2.push(base);
            } else {
              //console.log("Array indo: ");
              if(array2[array2.length - 1].dataG === moment(arrayReg[x].dataRecebivel).format("DD/MM/YY")){
                array2[array2.length - 1].valor += arrayReg[x].valor;
              } else {
                const base = {
                  dataG: moment(arrayReg[x].dataRecebivel).format("DD/MM/YY"),
                  valor: arrayReg[x].valor,
                } 
                array2.push(base);
              }
            }
          }
          //console.log("array: ", array2);
          // retorna o array invertido
          return array2.reverse();
        } else {
          for(let x = 0 ; x < arrayReg.length ; x++){
            for(let y = 0 ; y < array.length ; y++){
              // verifica se a data está dentro do período
              if(moment(arrayReg[x].dataRecebivel).isBetween(moment(array[y].dataG.split(";")[0], "DD/MM/YY"), moment(array[y].dataG.split(";")[1], "DD/MM/YY"), null, '[]')){
                array[y].valor += arrayReg[x].valor;
                break;
              }
            }
          }
          //console.log("array: ", array);

          return array;
        }
      }
    }
  },
  ReturnData                        : (cmd) => {
    const data = new Date();

    // Função auxiliar para formatar a data no formato YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Os meses são indexados a partir de 0
      const day = date.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
    }
    let diff = 0;
    switch (cmd){
      case "init" :
        diff = data.getDay(); // 0 (domingo) - 6 (sábado)
        data.setDate(data.getDate() - diff); 
        return formatDate(data);
      case "end"  :
        // Para obter o final da semana (sábado)
        diff = 6 - data.getDay(); // 6 (sábado) - dia atual da semana
        data.setDate(data.getDate() + diff); 
        return formatDate(data);
      case "now"  :
        return formatDate(data);
      case "init-periodo" :
        let ano2 = data.getFullYear();
        let mes2 = data.getMonth();
        let dia2 = data.getDate();
        //console.log("dia2 = ", dia2);
        if (dia2 > 29) {
            // Se já passaram 10 dias do início do mês, retorna o período de 01 a 10 do mês atual
            let inicioPeriodo = formatDate(new Date(ano2, mes2, 1));
            //console.log("inicioPeriodo = ", inicioPeriodo);
            return inicioPeriodo;
        } else {
            // Se é antes do dia 10, retorna o período de 01 ao fim do mês anterior
            let inicioMesAnterior = formatDate(new Date(ano2, mes2 - 1, 1));
            //console.log("inicioMesAnterior = ", inicioMesAnterior);
            return inicioMesAnterior;
        }
      case "end-periodo" :
        let ano = data.getFullYear();
        let mes = data.getMonth();
        let dia = data.getDate();

        if (dia > 29) {
            // Se já passaram 10 dias do início do mês, retorna o período de 01 a 10 do mês atual
            //let inicioPeriodo = new Date(ano, mes, 1);
            let fimPeriodo = formatDate(data);
            //console.log("fimPeriodo = ", fimPeriodo);
            return fimPeriodo;
        } else {
            //// Se é antes do dia 10, retorna o período de 01 ao fim do mês anterior
            //let inicioMesAnterior = new Date(ano, mes - 1, 1);
            let fimMesAnterior = formatDate(new Date(ano, mes, 0));
            //console.log("fimMesAnterior = ", fimMesAnterior);
            return fimMesAnterior;
        }
      default    : return formatDate(data);
    }
  },
  DataFormat                        : (format, date, agenda) => {
    const data = new Date(date);
    switch (format) {
      case "DD/MM/YYYY"   : 
        const dia = data.getDate() < 10 ? "0"+data.getDate() : data.getDate();
        const mes = data.getMonth()+1 < 10 ? "0"+(data.getMonth()+1) : (data.getMonth()+1);
        return `${dia}/${mes}/${data.getFullYear()}`;
      case "dia,DD/MM/YY" : return `${Services.ReturnDiaSemana(3, data)}, ${data.getDate() < 10 ? "0"+data.getDate() : data.getDate()}/${data.getMonth()+1 < 10 ? "0"+(data.getMonth()+1) : (data.getMonth()+1)}/${data.getFullYear().toString().substr(2,2)}`;
      
      case "DD/MM/YY"     :
        const dia2 = data.getDate() < 10 ? "0"+data.getDate() : data.getDate();
        const mes2 = data.getMonth()+1 < 10 ? "0"+(data.getMonth()+1) : (data.getMonth()+1);
        return `${dia2}/${mes2}/${data.getFullYear().toString().slice(-2)}`;
      
      // Preciso retornar a seguinte string: "Todas(os) as(os) {dia da semana} das HH:MM às HH:MM"
      case "label-hf"     : return `${ReturnLabelHF(data.getDay())} ${Services.ReturnDiaSemana(2, data)} das ${Services.ReturnPeriodoHorarioArray(agenda)} `;
      // Preciso retornar a seguinte string: "A partir de {dia da semana}, {número do mês} de {mês} de {ano} (Durante {parâmetro} mês)"
      case "label-hf-2"   : return `A partir de ${Services.ReturnDiaSemana(1, data)}, ${data.getDate()} de ${ReturnMes(data.getMonth()+1)} de ${data.getFullYear()}`;
    }
    
    function ReturnLabelHF(dia){
      if(dia === 0 || dia === 6)  return "Todos os";
      else                        return "Todas as";
    }
    function ReturnMes(mes){
      //verificar o número do mês e retornar o nome
      switch (mes) {
        case 1  : return "Janeiro";
        case 2  : return "Fevereiro";
        case 3  : return "Março";
        case 4  : return "Abril";
        case 5  : return "Maio";
        case 6  : return "Junho";
        case 7  : return "Julho";
        case 8  : return "Agosto";
        case 9  : return "Setembro";
        case 10 : return "Outubro";
        case 11 : return "Novembro";
        case 12 : return "Dezembro";
        default : return "Erro";
      }
    }
  },
  ArrumaEndereco                    : (str) => {
    const lowerCaseWords = ['de', 'do', 'da', 'dos', 'das']; // Lista das palavras que devem ficar em minúscula
    return str.toLowerCase().split(' ').map(function(word, index) {
        if (index !== 0 && lowerCaseWords.includes(word)) {
            return word;
        }
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  },
  ReturnDiaSemana                   : (mode, data) => {
    // Array de dias da semana
    const dia1 = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    const dia2 = ["Domingos", "Segundas", "Terças", "Quartas", "Quintas", "Sextas", "Sábados"];
    const dia3 = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const dia4 = ["Domingos", "Segundas-feiras", "Terças-feiras", "Quartas-feiras", "Quintas-feiras", "Sextas-feiras", "Sábados"];
    const dia5 = ["Domingos", "Segundas - feiras", "Terças - feiras", "Quartas - feiras", "Quintas - feiras", "Sextas - feiras", "Sábados"];
    
    //console.log("data = ", data);
    switch (mode) {
      case 1 : return dia1[data.getDay()];
      case 2 : return dia2[data.getDay()];
      case 3 : return dia3[data.getDay()];
      case 4 : return dia4[data.getDay()];
      case 5 : return dia5[data.getDay()];
    }
  },
  RetornaDiaSemana                  : (data) => {
    const diaSemana   = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const dia         = new Date(data);
    //console.log("dia = ", data);
    //console.log("diaSemana[dia.getDay()] = ", diaSemana[dia.getDay()]);
    return diaSemana[dia.getDay()];
  },
  ConverteData                      : (mode, data) => {
    // Converte dd/mm/yy para yyyy-mm-dd -> mode = true
    if(mode){
      const dataSplit = data.split("/");
      return ""+dataSplit[2]+"-"+dataSplit[1]+"-"+dataSplit[0];
    } else { 
      // Converte yyyy-mm-dd para dd/mm/yy -> mode = false
      const dataSplit = data.split("-");
      return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
    }
  },
  FormatDate                        : (input) => { // Recebe DD/MM/YYYY e retorna "Dia, DD/MM/YY"
    // Validar se o input esta completo
    //console.log("input = ", input);
    if(input.length < 8) return "---,";
    
    const weekdays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
    const [day, month, year] = input.split("/");
  
    // Construa um objeto Date usando ano, mês e dia.
    // Lembre-se: Os meses são indexados a partir de 0 em JavaScript, então subtraímos 1 do mês.
    const date = new Date(`20${year}`, month - 1, day);
  
    // Obtenha a abreviação do dia da semana usando o objeto Date e o array weekdays.
    const weekday = weekdays[date.getUTCDay()];
  
    // Construa a string de data formatada.
    return `${weekday},`;
  }, 
  DiminuiDate                       : (data) => { // Recebe DD/MM/YYYY e retorna "DD/MM/YY"
    //console.log("data = ", data);
    const dataSplit = data.split("/");
    return `${dataSplit[0]}/${dataSplit[1]}/${dataSplit[2].toString().slice(-2)}`;
  },
  RetornaEstados                    : (estado) => {
    switch (estado) {
      // Verificar estado e retornar a sigla
      case "Acre"               : return "AC";
      case "Alagoas"            : return "AL";
      case "Amapá"              : return "AP";
      case "Amazonas"           : return "AM";
      case "Bahia"              : return "BA";
      case "Ceará"              : return "CE";
      case "Distrito Federal"   : return "DF";
      case "Espírito Santo"     : return "ES";
      case "Goiás"              : return "GO";
      case "Maranhão"           : return "MA";
      case "Mato Grosso"        : return "MT";
      case "Mato Grosso do Sul" : return "MS";
      case "Minas Gerais"       : return "MG";
      case "Pará"               : return "PA";
      case "Paraíba"            : return "PB";
      case "Paraná"             : return "PR";
      case "Pernambuco"         : return "PE";
      case "Piauí"              : return "PI";
      case "Rio de Janeiro"     : return "RJ";
      case "Rio Grande do Norte": return "RN";
      case "Rio Grande do Sul"  : return "RS";
      case "Rondônia"           : return "RO";
      case "Roraima"            : return "RR";
      case "Santa Catarina"     : return "SC";
      case "São Paulo"          : return "SP";
      case "Sergipe"            : return "SE";
      case "Tocantins"          : return "TO";
      default                   : return "";
    }
  },
  RetornaEstadoSigla                : (sigla) => {
    switch (sigla.toLowerCase()) {
      case "ac": return "Acre";
      case "al": return "Alagoas";
      case "ap": return "Amapá";
      case "am": return "Amazonas";
      case "ba": return "Bahia";
      case "ce": return "Ceará";
      case "df": return "Distrito Federal";
      case "es": return "Espírito Santo";
      case "go": return "Goiás";
      case "ma": return "Maranhão";
      case "mt": return "Mato Grosso";
      case "ms": return "Mato Grosso do Sul";
      case "mg": return "Minas Gerais";
      case "pa": return "Pará";
      case "pb": return "Paraíba";
      case "pr": return "Paraná";
      case "pe": return "Pernambuco";
      case "pi": return "Piauí";
      case "rj": return "Rio de Janeiro";
      case "rn": return "Rio Grande do Norte";
      case "rs": return "Rio Grande do Sul";
      case "ro": return "Rondônia";
      case "rr": return "Roraima";
      case "sc": return "Santa Catarina";
      case "sp": return "São Paulo";
      case "se": return "Sergipe";
      case "to": return "Tocantins";
      default: return "";
    }
  },      
  RetornaMes                        : (mes) => {
    //verificar o número do mês e retornar o nome
    switch (mes) {
      case 1  : return "Janeiro";
      case 2  : return "Fevereiro";
      case 3  : return "Março";
      case 4  : return "Abril";
      case 5  : return "Maio";
      case 6  : return "Junho";
      case 7  : return "Julho";
      case 8  : return "Agosto";
      case 9  : return "Setembro";
      case 10 : return "Outubro";
      case 11 : return "Novembro";
      case 12 : return "Dezembro";
      case 13 : return "Janeiro";
      default : return "Erro";
    }
  },      
  OrganizarDias                     : (data, range) => {
    // Array de dias
    const days = [];
  
    // Loop para adicionar os dias
    for (let i = (range*-1); i <= (range); i++) {
      const newDate = new Date(data);
      newDate.setDate(data.getDate() + i);
      days.push(newDate);
    }
    //console.log("DiasArray: ", days)
    return days;
  },
  ReturnLabelURL                    : (estad, city) => {
    const ct  = `${city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-')}`;
    const label = `${Services.RetornaEstados(estad).toLowerCase()}-${ct}`;
    return label;
  },    
  ReturnToStringDate                : (data) => {
    const dia = data.getDate() < 10 ? ("0" + data.getDate()) : data.getDate();
    const mes = (data.getMonth() + 1) < 10 ? ("0" + (data.getMonth()+1)) : (data.getMonth()+1);
    const ano = data.getFullYear();
    //console.log(`Data Service strinDate: ${dia}/${mes}/${ano}`)
    return `${dia}/${mes}/${ano}`;
  },    
  ReturnStringDay                   : (data) => {
    // Verificar o dia da semana e retornar o dia em string
    switch (data.getDay()) {
      case 0 : return "DOM";
      case 1 : return "SEG";
      case 2 : return "TER";
      case 3 : return "QUA";
      case 4 : return "QUI";
      case 5 : return "SEX";
      case 6 : return "SÁB";
      default: return "Erro";
    }
  },      
  ReturnDay                         : (day) => {
    switch (day) {
      case 0 : return "Domingo";
      case 1 : return "Segunda";
      case 2 : return "Terça";
      case 3 : return "Quarta";
      case 4 : return "Quinta";
      case 5 : return "Sexta";
      case 6 : return "Sábado";
      default: return "Erro no Services.ReturnDay()";
    }
  },      
  ReturnDataMsg                     : (date) => {
    return `${Services.ReturnDay(date.getDay())}, ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} às ${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
  },  
  ReturnHorarioString               : (pedido) => {
    let horarioInicial = pedido.agenda[0].horario.horarioInicial.split(":");
    let horarioFinal   = pedido.agenda[(pedido.agenda.length - 1)].horario.horarioFinal.split(":");  
    return `${horarioInicial[0]}:${horarioInicial[1]} às ${horarioFinal[0]}:${horarioFinal[1]}`;
  },
  RetornaDuracaoFixa                : (hrFix) => {
    const horaInit = new Date(hrFix.prePedidos[0].dataInicio);
    const horaFim  = new Date(hrFix.prePedidos[0].dataFinal);
    // Retorna o seguinte formato: "HH:MM às HH:MM" sem 9:0 e sim 09:00
    return `${horaInit.getHours() >= 10 ? horaInit.getHours() : "0"+horaInit.getHours()}:${horaInit.getMinutes() < 10 ? "0" + horaInit.getMinutes() : horaInit.getMinutes()} às ${horaFim.getHours() >= 10 ? horaFim.getHours() : "0"+horaFim.getHours()}:${horaFim.getMinutes() < 10 ? "0" + horaFim.getMinutes() : horaFim.getMinutes()}`;
  },
  ReturnHorarioLabel                : (init, end) => {
    const dataI = new Date(init);
    const dataF = new Date(end);
    return `${dataI.getHours() >= 10 ? dataI.getHours() : "0"+dataI.getHours()}:${dataI.getMinutes() < 10 ? "0" + dataI.getMinutes() : dataI.getMinutes()} às ${dataF.getHours() >= 10 ? dataF.getHours() : "0"+dataF.getHours()}:${dataF.getMinutes() < 10 ? "0" + dataF.getMinutes() : dataF.getMinutes()}`;
  },
  ReturnDataStringCompleta          : (pedido) => {
    //console.log("pedido = ", pedido);
    const data = new Date(pedido.agenda[0].data);
    return `${Services.ReturnDay(data.getDay())}, ${data.getDate()} de ${Services.RetornaMes(data.getMonth() + 1)} de ${data.getFullYear()}`;
  },
  ReturnDataStringCompletaResumo    : (data) => {
    return `${Services.ReturnDay(data.getDay())}, ${data.getDate()} de ${Services.RetornaMes(data.getMonth() + 1)} de ${data.getFullYear()}`;
  },
  RetornaForcaSenha                 : (senha) => {
    if(senha.length){
      const { score } = zxcvbn(senha);
      // Verifica a força da senha
      switch (score) {
        case 0:
          return "Senha muito fraca";
        
        case 1:
          return "Senha fraca";
        
        case 2:
          return "Senha razoável";
        
        case 3:
          return "Senha forte";
        
        case 4:
          return "Senha muito forte";
        
        default:
          return "";
        
      } 
    } else return "";
  },  
  RemoveHorariosPassados            : (horarios, agora) => {
    const horariosFuturos = [];
    horarios.forEach((horario) => {
      const dataHorario = new Date(horario.data);
      if (dataHorario > agora) {
        horariosFuturos.push(horario);
      }
    });
    return horariosFuturos;  
  },  
  RetornFaltaHora                   : (hor) => {
    function VerificaDiferença(mode, horas){
      if(mode){
        if(horas < 24){
          return {
            state : true, 
            label :`EM ${horas} HORA${horas == 1 ? "" : "S"}`,
          };
        } else {
          const dias = Math.floor(horas / 24);
          if(dias < 7) {
            return {
              state : false,
              label : `EM ${dias} DIA${dias == 1 ? "" : "S"}`
            };
          }
          else if(dias < 30) {
            const semanas = Math.floor(dias / 7);
            return {
              state : false,
              label : `EM ${semanas} SEMANA${semanas == 1 ? "" : "S"}`
            };
          } else if(dias < 365){
            const meses = Math.floor(dias / 30);
            return {
              state : false,
              label : `EM ${meses} ${meses === 1 ? "MÊS" : "MESES"}`
            };
          } else {
            const anos = Math.floor(dias / 365);
            if(anos == 1){
              return {
                state : false,
                label : `EM ${anos} ANO`
              };
            }
            else {
              return {
                state : false,
                label : `EM ${anos} ANOS`
              };
            }
          }
        }
      } else {
        if(horas < 23){
          return {
            state : false,
            label : `HÁ ${horas} HORA${horas > 1 ? "S" : ""}`
          };
        } else { 
          const dias = Math.floor(horas / 24);
          if(dias < 7) {
            return {
              state : false,
              label : `HÁ ${dias} DIA${dias == 1 ? "" : "S"}`
            };
          }
          else if(dias < 30) {
            const semanas = Math.floor(dias / 7);
            return {
              state : false,
              label : `HÁ ${semanas} SEMANA${semanas == 1 ? "" : "S"}`
            };
            
          } else if(dias < 365){
            const meses = Math.floor(dias / 30);
            return {
              state : false,
              label : `HÁ ${meses} ${meses === 1 ? "MÊS" : "MESES"}`
            };
          } else {
            const anos = Math.floor(dias / 365);
            return {
              state : false,
              label : `HÁ ${anos} ANO${anos == 1 ? "" : "S"}`
            };
          }
        }
      }
    }
    // Recebe um objeto Date e define quanto tempo em horas falta para o horário, ou se já passou
    // Retorna uma tag "Realizado".
    const agora   = new Date();
    const falta   = hor - agora;
    // Converte para minutos
    let minutos   = Math.floor(falta / 60000);
    let horas     = Math.floor(falta / 3600000);
    if (horas > 0) {
      if(minutos < 60){
        return {
          state : true,
          label : `EM ${minutos} MINUTOS`
        }
      } else {
        return VerificaDiferença(true, horas);
      }
    } else{
      horas = horas * (-1);
      return VerificaDiferença(false, horas);
    } 
    
  },
  ReturnHoraMin                     : (hor) => {
    // Recebe um objeto Date e retorna a hora e minutos
    const horas   = hor.getHours()    < 10 ? "0" + hor.getHours()   : hor.getHours();
    const minutos = hor.getMinutes()  < 10 ? "0" + hor.getMinutes() : hor.getMinutes();
    return `${horas}:${minutos}`;
  },  
  ReturnValorPorcentagem            : (porcentagem, valor) => {
    return ((valor * porcentagem) / 100).toFixed(2);
  },
  OrganizaArrayCalendar             : (daySel) => {
    function VeryfyMes(day){
      switch (day.getMonth()) {
        case 0: return 31;
        case 1: return 28;
        case 2: return 31;
        case 3: return 30;
        case 4: return 31;
        case 5: return 30;
        case 6: return 31;
        case 7: return 31;
        case 8: return 30;
        case 9: return 31;
        case 10: return 30;
        case 11: return 31;
        default: return 0;
      }
    }
    const array = [];
    const mes = VeryfyMes(daySel);
    const ano = daySel.getFullYear();
    for (let i = 1; i <= mes; i++) array.push(new Date(ano, daySel.getMonth(), i));
    return array;
  },
  ReturnDayComplet                  : (day) => {
    switch (day) {
      case 0 : return "Domingo";
      case 1 : return "Segunda-feira";
      case 2 : return "Terça-feira";
      case 3 : return "Quarta-feira";
      case 4 : return "Quinta-feira";
      case 5 : return "Sexta-feira";
      case 6 : return "Sábado";
      default: return "Erro no Services.ReturnDay()";
    }
  },
  RetornaRegraCancelamento          : (regra) => {
    if (regra.minutosAntecedencia === 0) return <p>{`Cancelamento GRATUITO até o horário do agendamento.`}</p>;
    else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta === 0 && regra.permitirCancelamentoPosHorario === 1) {
      return <p>{`'Você pode cancelar até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>;
    } else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta > 0 && regra.permitirCancelamentoPosHorario === 1) {
      return(
        <>
          <p>{`Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>
          <p>{`Se você cancelar após o horário limite, será cobrada uma taxa de ${regra.porcentagemMulta}% do valor total do agendamento.`}</p>
          <p>{`Se o pagamento for um sinal, ele será a multa.`}</p>
        </>
      );
    } else if (regra.minutosAntecedencia !== 0 && regra.permitirCancelamentoPosHorario === 0) {
      return (
        <>
          <p>{`Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>
          <p>{`Após o horário limite NÃO será possível cancelar e você deverá entrar em contato com o Gestor pelo chat.`}</p>
        </>
      );
    } else return <p>{regra.permitirCancelamentoPosHorario}</p>
  },
  RetornaRegraCancelamentoSwal      : (regra) => {
    if (regra.minutosAntecedencia === 0) return `Cancelamento GRATUITO até o horário do agendamento.`;
    else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta === 0 && regra.permitirCancelamentoPosHorario === 1) {
      return `Você pode cancelar até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`;
    } else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta > 0 && regra.permitirCancelamentoPosHorario === 1) {
      return `Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário. Se você cancelar após o horário limite, será cobrada uma taxa de ${regra.porcentagemMulta}% do valor total do agendamento. Se o pagamento for um sinal, ele será a multa.`;
    } else if (regra.minutosAntecedencia !== 0 && regra.permitirCancelamentoPosHorario === 0) {
      return `Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário. Após o horário limite NÃO será possível cancelar e você deverá entrar em contato com o Gestor pelo chat.`;
    } else return regra.permitirCancelamentoPosHorario;
  },
  RetornaLabelUrl                   : (cidade, username) => {
    const sigla = username.split("-")[0];
    //console.log("sigla = ", sigla);
    const cidadeFormat = cidade.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-').toLowerCase();
    //console.log("cidadeFormat = ", cidadeFormat);
    return `${sigla}-${cidadeFormat}`;
  },
  ModuloChatAbertaNaoEnviado        : {
    /*
      Salva o chat aberto , porém sem mensagens, após a primeira mensagem enviada,
      o chat é removido do localStorage e da tabela.
      A tabela salva os keys do chat, indicado pelo seu idchat e o modoCE, se true é pedidos se false é cliente [..., {idchat, modeCE}]!
      tabela = [idchat, ... ]
    */
    SalveChat     : (chat, idchat)  => LocalStorage.set(idchat, chat),
    ReturnChat    : (idchat)        => LocalStorage.get(idchat),
    DeleteChat    : (idchat)        => LocalStorage.remove(idchat),
    SalveTabela   : (tabela)        => LocalStorage.set('tabela-chat-gestor', tabela),
    ReturnTabela  : ()              => LocalStorage.get('tabela-chat-gestor'),
    DeleteTabela  : ()              => LocalStorage.remove('tabela-chat-gestor'), // caso tabela sem chats abertos = null!!!
  },
  
  RetornaTempoVencimento            : (fatura) => {
    if(fatura.statusPagamento === "canceled"){
      const dataAtual       = moment();
      const dataVencimento  = moment(fatura.dataVencimento || fatura.dataFinal);
      const diferenca       = dataVencimento.diff(dataAtual, 'days');
      if(diferenca < 0) return `Vencida há ${diferenca * -1 === 1 ? diferenca * -1 + ' dia' : diferenca * -1 + ' dias'}`;
      else if(diferenca === 0) return `Venceu hoje`;
      else return `Fatura Cancelada`;
    } else if(fatura.statusPagamento === "paid"){
      const dataAtual       = moment();
      const dataPagamento   = moment(fatura.dataPagamento);
      const diferenca       = dataAtual.diff(dataPagamento, 'days');

      if(diferenca < 0) return `Pago há ${diferenca * -1 === 1 ? diferenca * -1 + ' dia' : diferenca * -1 + ' dias'}`;
      else if(diferenca === 0) return `Pago hoje`;
      else return `Pago há ${diferenca === 1 ? diferenca + ' dia' : diferenca + ' dias'}`;

    } else if(fatura.statusPagamento === "aguardando"){
      const dataAtual       = moment();
      const dataVencimento  = moment(fatura.dataFinal);
      const diferenca       = dataAtual.diff(dataVencimento, 'days');
      if(diferenca < 0) return `Pagamento em ${diferenca * -1 === 1 ? diferenca * -1 + ' dia' : diferenca * -1 + ' dias'}`;
      else if(diferenca === 0) return `Pagamento hoje`;
      else return `Pagamento há ${diferenca === 1 ? diferenca + ' dia' : diferenca + ' dias'}`;
    } else if(fatura.statusPagamento === "pending"){
      const dataAtual       = moment();
      const dataVencimento  = moment(fatura.dataVencimento);
      const diferenca       = dataAtual.diff(dataVencimento, 'days');
      if(diferenca < 0) return `Vence em ${diferenca * -1 === 1 ? diferenca * -1 + ' dia' : diferenca * -1 + ' dias'}`;
      else if(diferenca === 0) return `Vence hoje`;
      else return `Venceu há ${diferenca === 1 ? diferenca + ' dia' : diferenca + ' dias'}`;
    }
  },
  RetornaPeriodoHorario             : (horario) => {
    // Recebe item.dataInicio e item.dataFinal no formato "2024-03-21T17:30:00.000Z" e retorna o seguinte formato "HH:MM às HH:MM"
    const dataI = new Date(horario.dataInicio);
    const dataF = new Date(horario.dataFinal);
    return `${dataI.getHours() >= 10 ? dataI.getHours() : "0"+dataI.getHours()}:${dataI.getMinutes() < 10 ? "0" + dataI.getMinutes() : dataI.getMinutes()} às ${dataF.getHours() >= 10 ? dataF.getHours() : "0"+dataF.getHours()}:${dataF.getMinutes() < 10 ? "0" + dataF.getMinutes() : dataF.getMinutes()}`;
  },
  ReturnPeriodoHorarioArray          : (array) => {
    // Recebe um array de horários, ordena pelo horário incial e retorna um período desses horários
    array.sort((a, b) => new Date(a.dataInicio) - new Date(b.dataInicio));
    const dataI = new Date(array[0].dataInicio);
    const dataF = new Date(array[array.length - 1].dataFinal);
    return `${dataI.getHours() >= 10 ? dataI.getHours() : "0"+dataI.getHours()}:${dataI.getMinutes() < 10 ? "0" + dataI.getMinutes() : dataI.getMinutes()} às ${dataF.getHours() >= 10 ? dataF.getHours() : "0"+dataF.getHours()}:${dataF.getMinutes() < 10 ? "0" + dataF.getMinutes() : dataF.getMinutes()}`;
  },
  RetornaPeriodoDataSelect     : (array) => {
    // Recebe um array de horarios que possui dentro de cada objeto um horarioInicio e um horarioFinal, deve retornar
    // uma string com o horarioInicio do primeiro objeto e o horarioFinal do último objeto do array. O formato do dataInicio e dataFinal é '2024-03-16T12:00:00.000Z'
    const horarioInicial = new Date(array[0].dataInicio);
    const horarioFinal   = new Date(array[array.length - 1].dataFinal);

    // Retorna a string no formato 'dia, de mês de ano
    return `${Services.ReturnDiaSemana(3, horarioInicial)}, ${horarioInicial.getDate()} de ${Services.RetornaMes(horarioInicial.getMonth() + 1)} de ${horarioInicial.getFullYear()}`;
  },
} ; // Fim de Services
export default Services;

function retornaMinutagem(minutos) {
  if (minutos >= 60 && minutos < 1440) {
    let minutagem = minutos / 60 + ''
    let retorno = minutagem.split('.')          
    return retorno[0] + ' hora(s)';
  } else if (minutos < 60) {
      return minutos + ' minuto(s)';
  } else {
    let minutagem = minutos / 1440 + ''
    let retorno = minutagem.split('.')
    return retorno[0] + ' dia(s)'
  }
}