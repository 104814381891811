import React                from "react";
import { BiChevronRight }   from "react-icons/bi";
import { HiArrowPath }      from "react-icons/hi2";
import { Loading }          from "../../Basics";
import { Services }         from "../../../services";         
import "./stylesCMens.css"

export default function CompMensalista({mens, onClick, viewUser}) {
  
  const [loading, setLoading] = React.useState(false);
  const [modalHF, setModalHF] = React.useState(false);
  /* -- padrão de cores! -- 
      colorSolicitado: '#ff7043',
      colorReservado: '#24ad49',
      colorRealizado: '#00cda4',
      colorCancelado: '#bf3324',
  */
  // 

  // Renderiza o componente do mensalista de acordo com o status do pedido!
  function ReturnStatus() {
    switch(mens.status){
      case true   : { // Só os status Solicitado e Agendado!  
        if(mens.statusSolicitacao === "Solicitado") return "btt-comp-mensalista btt-gradient-solicitado-hf";
        else if(mens.statusSolicitacao === "Agendado") return "btt-comp-mensalista btt-gradient-agendado-hf";
      }
      // Pedido INATIVO! -> Por que está inativo? 
      case false  : { // Só o status Rejeitado!
        return "btt-comp-mensalista btt-gradient-cancelado-hf";
      }
    }
  }
  // Retorna o componente referente ao status do pedido, selecionando a cor!
  function ReturnColorStatus(status) {
    switch(status){
      case "Solicitado" : return "#ff7043";
      case "Agendado"   : return "#22ac4a";
      case "Rejeitado"  : return "#bf3324";
      case "Cancelado"  : return "#bf3324";
      case "Realizado"  : return "#bf3324";
    }
  }
  // Retorna a string do status do horarios fixos!
  function RetornaStatus(status) {
    switch(status){
      case "Solicitado" : return "SOLICITADO";
      case "Agendado"   : return "ATIVO";
      case "Rejeitado"  : return "INATIVO";
      case "Cancelado"  : return "INATIVO";
      case "Realizado"  : return "INATIVO";
    }
  }
  //console.log("Mensalista: ", mens);
  
  //{mens.idmensalista === 38973 &&//console.log("Mensalista: ", mens);}
  return (
    
    <button 
      className = {ReturnStatus()}
      onClick   = {() => onClick()}
    > 
      
      <div className = 'contain-btt-mensalista-infos left-mensalista'>
        <h2 className = "title-comp-hora-fixa">
          {`Horário Fixo ${(mens.tipoMensalista !== null) ? (mens.tipoMensalista === "Indeterminado" ? "Sem Fim" : mens.tipoMensalista) : ""}`}
        </h2>
        <div className = "left-mensalista-div" style = {{color: "#FF7043", fontSize: "14px", whiteSpace: "nowrap"}}>
          <HiArrowPath
            size  = {20}
            style = {{marginRight: "1px"}}
          />
          <h3>
            {Services.ReturnDiaSemana(4, new Date(mens.dataPrimeiraReserva)).toUpperCase()}
          </h3>
        </div>
        { mens.pedidos.length > 0 ?
          <h3 className = "title-comp-hora-fixa">
            {mens.pedidos && (`${Services.ReturnHoraMin(new Date(mens.pedidos[0].dataInicio))} às ${Services.ReturnHoraMin(new Date(mens.pedidos[0].dataFinal))}`)}
          </h3> : null
        }
        {(mens.tipoMensalista !== null && mens.tipoMensalista !== "Indeterminado") ?
          <p>
            {`${Services.DataFormat("DD/MM/YY", mens.dataPrimeiraReserva)} à ${Services.DataFormat("DD/MM/YY", mens.dataUltimaReserva)}`}
          </p> : 
          <p>
            {`Início em ${Services.DataFormat("DD/MM/YY", mens.dataPrimeiraReserva)}`}
          </p>
        }
        <h3 style = {{color: ReturnColorStatus(mens.statusSolicitacao)}}>
          {RetornaStatus(mens.statusSolicitacao)}
        </h3>
      </div>
      <div className = 'contain-btt-mensalista-infos right-mensalista'>
        {
          (viewUser && mens.usuario) &&
          <h4 style = {{color: "#FF7043"}}>
            {`${mens.usuario.nome}`}
          </h4>
        }
        { (mens.tipoMensalista !== "Indeterminado") ?
          (mens.valorDescontoCupom > 0 ?
            <h3 className = "value-comp-hora-fixa">
              {`R$${(mens.valorTotal - mens.valorDescontoCupom).toFixed(2).replace('.', ',')}`}
            </h3>
              :
            <h3 className = "value-comp-hora-fixa">
              {`R$${mens.valorTotal.toFixed(2).replace('.', ',')}`}
            </h3>
          ) : 
          <>
            {/*<h3 className = "value-comp-hora-fixa">
              {`R$${mens.pedidos[0].valorTotal.toFixed(2).replace('.', ',')}`}
            </h3>*/}
          </>
        }
        { (mens.tipoMensalista !== "Indeterminado") &&
          <h4>{`${mens.pedido} Agendamentos`}</h4>
        }
        <h4>{`${mens.quadra.descricao}`}</h4>
        
      </div>
      <div className = "contain-arrow-rigth">
        <BiChevronRight
          size  = {30}
          color = "#FF7043"
        />
      </div>
    </button>
  );
}