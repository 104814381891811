import React            from "react";
import { useLocation }  from "react-router-dom";
import { logo }         from "../../../assets";
import { Alerts, IconChat }     from "../../Basics";
import { BiCog }        from "react-icons/bi";
import {
  ModalCnfg, ModalChat
} from "../../Modais/";
import { AuthContext }  from "../../../contexts/AuthContext";
import { ChatContext }  from "../../../contexts/ChatContext";
import RouterHook       from "../../../contexts/RouterHook";

import "./stylesHeader.css"

export default function Header({telaPg}) {

  const location = useLocation();
  function RetornaTela(){
    let tl = location.pathname;
   //console.log("Tela: ", tl);
    if(tl === "/") return "login";
    else {
      // separa a string em um array, separando cada "/" encontrado
      tl = tl.split("/");
      // verifica o ultimo elemento do array, que é o nome da tela
      tl = tl[tl.length - 1];
     //console.log("Tela: ", tl);
      if(tl === "conversas") return "chat";
      
      return "home"
    }
  }

  const serverRouter          = RouterHook();
  const { 
    isAuth, date, modalOpen, handleMOP,
  } = React.useContext(AuthContext);
  const { states, actions } = React.useContext(ChatContext);
  const elementRef            = React.useRef(null);
  const elementRefChat        = React.useRef(null);
  const [modal, setModal]     = React.useState(false);
  const [modalC, setModalC]   = React.useState(false);
  const [tela, setTela]       = React.useState(RetornaTela());
  //console.log("IdUsuario: ", date.gestor.idusuario);
  
  React.useEffect(() => {
    setTela(RetornaTela());
  }, [location.pathname]);

  React.useEffect(() => {
    if(modal || modalC) handleMOP(true);
  }, [modal, modalC]);
  React.useEffect(() => {
    if(!modalOpen){
      modalC && actions.handleChatOn(false);
      setModal(false);
      setModalC(false);
    } 
  }, [modalOpen]);

 
  //console.log("Date.Empresa: ", date.empresa);
  function ClosedModalOpen(chamou){
    switch(chamou){
      case "config":
        if(!modal){ // Se o modal de configurações estiver aberto, fecha os outros modais
          setModalC(false);
        }
        break;
      case "chat":
        if(!modalC){ // Se o modal de chat estiver aberto, fecha os outros modais
          setModal(false);
          actions.handleChatOn(false);
        }
        break;
      default:
        //console.log("Algo deu muito errado! :/ -- ClosedModais No header.js");
        break;

    } 
  }
  function CountMenssages(){
    let count = 0;
    if(states.chatMNLP){
      states.chat_CEP.map((chat) => {
        if(chat.ultimaMensagem !== null){
          if(chat.ultimaMensagem.status === "ENVIADA" && !chat.ultimaMensagem.criadaPorAdmin) count++;
        }
      });
    } else if(states.chatMNLE){
      states.chat_CEE.map((chat) => {
        if(chat.ultimaMensagem !== null){
          if(chat.ultimaMensagem.status === "ENVIADA" && !chat.ultimaMensagem.criadaPorAdmin) count++;
        }
      });
    }
    return count === 0 ? "!" : count;
  }

  if(!isAuth){
    return (
      <div className = "header">
        <div className = "contain-header-defined">
          <div className = "contain-img-title">
            <div className = "contain-img-title-click"
              onClick = {() => {
                //serverRouter("login");
              }}
            >
              <img src = {logo} alt = "Logo Agendei Quadras" />
              <h1> Agendei Quadras</h1>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className = "header">
       { modal &&
          <ModalCnfg
            elementRef  = {elementRef}
            modal       = {modal}
            ClosedModal = {() => setModal(false)}
            userName    = {date.gestor.nome}
            handlePage  = {(val) => {serverRouter("settings")}}
          />
        }
        { (modalC && date.perfilG.chat) &&
          <ModalChat
            idUser      = {date.gestor.idusuario}
            elementRef  = {elementRefChat}
            modal       = {modalC}
            ClosedModal = {() => {
              actions.handleChatOn(false);
              setModalC(false)
            }}
            onClick     = {(val) => {serverRouter("chat")}}
          />
        }
        <div className = "contain-header-defined">
          <div className = "contain-img-title">
            <div className = "contain-img-title-click"
              onClick = {() => {
                //serverRouter("login");
              }}
            >
              <img src = {logo} alt = "Logo Gestor Agendei" />
              <h1> Gestor Agendei</h1>
            </div>
          </div>
          {
            telaPg !== "login" &&
            <div className = "contain-private"> 
              <h2> Olá, {date.gestor.nome} </h2>
              <div className = "contain-buttons-icons">               
                { (tela !== "chat" && date.perfilG.chat) &&
                  <IconChat
                    refChat   = {elementRefChat}
                    count     = {CountMenssages()}
                    onClick   = {() => {
                      if(modalC) actions.handleChatOn(false);
                      setModalC(!modalC);
                      ClosedModalOpen("chat");
                    }}
                  />
                }
                <button 
                  title     = "Configurações"
                  ref       = {elementRef}
                  className = "button-icon"
                  onClick   = {() => {
                    setModal(!modal);
                    ClosedModalOpen("config");
                  }}
                >
                  <BiCog
                    size = {33}
                    color = "white"
                  />
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}