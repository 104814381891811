import React from "react";

import "./stylesLD.css";

export default function LoadingDots(){
  return (
    <div className="loading-dots">
      <div className="dot dot-1"/>
      <div className="dot dot-2"/>
      <div className="dot dot-3"/>
    </div>
  );
};


