import React            from "react";
import Modal            from "react-modal";
import { capaCompLink } from "../../../assets";
import { Alerts }       from "../../Basics";
import { 
  BiLogoFacebookSquare, BiX 
} from "react-icons/bi";
import { 
  BsInstagram, BsWhatsapp 
} from "react-icons/bs";
import { AuthContext }  from "../../../contexts/AuthContext";

import "./stylesMCL.css";

export default function ModalCompLink({ modal, ClosedModal }) {
  
  const { date }  = React.useContext(AuthContext);
  const url       = `https://app.agendeiquadras.com.br/acessar/quadra/${date.empresa.username}`;
  const text      = `😀 Olá!\n🕗 Veja os horários disponíveis e agende online seu jogo aqui na ${date.empresa.nome}: ${url}\n\n📲 Ainda não tem o App Agendei Quadras? Baixe agora e crie sua conta: bit.ly/agendeiquadras`;
  
  return (
    <Modal
      className         = "contain-content-comp-link"
      overlayClassName  = "contain-overlay-comp-link"
      onRequestClose    = {ClosedModal}
      isOpen            = {modal}
    >
      <BiX
        className = "icon-closed-modal" 
        onClick   = {ClosedModal}
      />
      <img
        className = "contain-img-capa-comp-link"
        src       = {capaCompLink}
      />
      <div className = "contain-modal-comp-link">
        <h2>
          Divulgue sua quadra com o link direto
        </h2>
        <p>
          Agora você pode compartilhar um link direto para seus clientes 
          acessarem diretamente o perfil de sua quadra no Site ou App 
          Agendei Quadras
        </p>
        <h3>
          SEU LINK DIRETO:
        </h3>
        <a
          onClick = {() => {
            window.open(url, "_blank");
          }}
        >
          {url}
        </a>
        <button
          className = "btt-copia-link"
          onClick   = {() => {
            // copiar o link para a area de transferencia
            copyToClipboard(url, true);
          }}
        >
          Copiar Link
        </button>
        <h4>
          Copiar e Compartilhar
        </h4>
        <div className = "contain-btts-redes-sociais">
          <button
            className = "btt-icon-rs"
            onClick={() => {
              copyToClipboard(url, false);
              const txt = encodeURIComponent(text);  // Usamos o text completo aqui
              window.open(`https://web.whatsapp.com/send?text=${txt}`, '_blank');
            }}
          >
            <BsWhatsapp size = {25} />
          </button>

          {/*<button
            className = "btt-icon-rs"
            onClick={() => {
              // Ir para instagram
              window.open(`https://www.instagram.com/`, '_blank');
            }}
          >
            <BsInstagram
              size  = {25}
            />
          </button>*/}
          <button
            className = "btt-icon-rs"
            onClick={() => {
              copyToClipboard(url, false);
              window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`, '_blank');
            }}
          >
            <BiLogoFacebookSquare size = {25} />
          </button>
        </div>
      </div>
    </Modal>
  );

  function copyToClipboard(text, alert) {
    var textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if(alert) Alerts.sucesso("Sucesso", "Link copiado com sucesso");
  }
}
             