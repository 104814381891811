import React      from "react";
import { MdPix }  from "react-icons/md";
import "./stylesSelPay.css";

export default function SeletorPay({textA, textB, select, onClick, Icon, mode}) {
  
  return (
    <button
      className   = {!select ? "btt-seletor-div" : "btt-seletor-div btt-selected-check"}
      onClick     = {onClick}
    >
      <div className = {"contain-icon-select-pay"}>
        {
          !mode ? 
            <img
              className = {"icon-select-pay"}
              src       = {Icon}
            />
          :
            <MdPix
              className = {!select ? "icon-select-pay" : "icon-selected-pay"}
              size      = {30}
            />
        }
      </div>
      <div className = {"contain-infos-select-pay"}>
        <h2
          className = {select ? "select-text-pay-label text-select" : "select-text-pay-label"}
        >
          {textA}
        </h2>
        <p 
          className = {select ? "select-text-pay-p text-select" : "select-text-pay-p"}
        >
          {textB}
        </p>
      </div>
      <input
        className = "input-seletor-check"
        type      = "radio"
        checked   = {select}
        onChange  = {onClick}
      />
    </button>
  );
}