import React                from "react";
// Components da screen
import Modal                from "react-modal";
import { AuthContext }      from "../../../contexts/AuthContext";
import { ClosedX, Loading } from "../../Basics";
import { Services }         from "../../../services";
import Swal                 from "sweetalert2";
import moment               from "moment";
import { EmpresaAPI }       from "../../../servicesAPI";
import { 
  BiCheckbox, BiCheckboxChecked
} from "react-icons/bi";

import "./stylesMSHF.css";
 
Modal.setAppElement('#root');

export default function ModalSelHF({modal, ClosedModal, quadra, empresa, onClick, onClickRemoveHF, state, periodo, hrFixa, agenda, diaSemana}) {
  
  const { token }                   = React.useContext(AuthContext);
  
  const [ bttSel, setBttSel ]       = React.useState(!state ? periodo : null);
  const [ horaFixa, setHoraFixa ]   = React.useState(!state ? hrFixa  : null);
  const [ prePedidos, setPrePedidos ] = React.useState(!state ? hrFixa.prePedidos : []); 
  const [ loading, setLoading ]     = React.useState(false);
  const [ conflito, setConflito ]   = React.useState(false);
  /*
    //console.log("State: ",    state);
    //console.log("Periodo: ",  periodo);
    //console.log("hrFixa: ",   hrFixa);
    //
  */
    //console.log("BttSel: ",   bttSel);
  const btts = [
    {a: "1",   b: "MÊS"}, 
    {a: "3",   b: "MESES"},
    {a: "6",   b: "MESES"},
    {a: "1",   b: "ANO"},
    {a: "SEM", b: "FIM"},
  ];
  // Verifica horarios conflitos sempre que setar um hora fixa
  React.useEffect(() => {
    if(horaFixa !== null){
      let flag = false;
      // Verifica se existe algum horario ocupado
      if(bttSel === 4){
        // Verifica se existe algum horario ocupado
        horaFixa.prePedidos.map((item) => {
          if(item.status === "OCUPADO") flag = true;
        });
        if(flag){
          // Atualiza o estado de conflito
          setConflito(true);
          // atualiza o estado de prePedidos
        } else {
          setConflito(false);
        }
      }
    }
  }, [horaFixa]);
  
  /* Função que calcula o valor do horario fixo! */
  const chama = async (idx, dia, data) => {
    setLoading(true);
    console.log("Data: ", data, dia, idx);
    const daySel = new Date(dia);
    const ress = await EmpresaAPI.SimularMensalista(token, quadra.idquadra, agenda, RetornaLabelMensalista(idx), (daySel.getDay()+1));
    console.log("Ress: ", ress);
    if(!ress.status){ 
      Swal.fire({
        title               : "Aviso",
        text                : "Estamos com problemas de comunicação com o servidor, tente novamente mais tarde",
        confirmButtonText   : "Entendi",
        icon                : "warning",
        confirmButtonColor  : "#DA5A34"
      });
      setBttSel(null);
    } else {
      
      const valPer = agenda[0].valor * agenda.length;
      console.log("ValPer: ", valPer);
      let valCont = 0;
      ress.req.map((item) => {
        if(item.idpedido) {
          // Tem conflito!
          item.status   = "OCUPADO";
          item.valor    = valPer;
        } else {
          valCont       += valPer;
          item.status   = "LIVRE";
          item.valor    = valPer;
        }
      });
      
      const hf_Data = {
        prePedidos    : ress.req,
        valorTotal    : valCont,
        valorAPagar   : valCont,
        ValorRealizado: 0,
        dataInicio    : agenda[0].dataInicio,
        dataFinal     : agenda[agenda.length - 1].dataFinal,
        descontoGeral : 0,
        tipoMensalista: RetornaLabelMensalista(idx)
      };
      setHoraFixa(hf_Data);

      setPrePedidos(ress.req);
    }
    setLoading(false);
  }
  // Função que seleciona o botão de acordo com o periodo selecionado!
  function SelecionaBttMes(idx) {
    console.log("Idx: ", idx, state, bttSel);
    if(loading) return;
    if(state){
      if(bttSel === idx) {
        setHoraFixa(null);
        setBttSel(null);
      } else {
        setBttSel(idx);
        chama(idx, diaSemana, moment(agenda[0].data).utcOffset(empresa.gmt).format("DD/MM/YYYY"));
      }
    }
  }
  // Função que retorna o periodo de acordo com o indice do botão!
  function RetornaPeriodo(idx){
    switch(idx){
      case 0: return 1;
      case 1: return 3;
      case 2: return 6;
      case 3: return 12;
      case 4: return 13;
    }
  }
  // Função que define a configuração do horario fixo!
  function SelecionarHorarioFixo(){
    if(bttSel !== null && horaFixa !== null){
      setHoraFixa({...horaFixa, prePedidos: prePedidos});
      // Verifica se é um horário sem fim!
      Swal.fire({
        title               : "Aviso",
        text                : "Deseja configurar este período como horário fixo?",
        confirmButtonText   : "Sim",
        cancelButtonText    : "Não",
        icon                : "warning",
        showCancelButton    : true,
        confirmButtonColor  : "#DA5A34",
        cancelButtonColor   : "gray"
      }).then((result) => {
        if(result.isConfirmed){
          onClick(horaFixa, bttSel);
          //console.log("HoraFixa: ", horaFixa);
          ClosedModal();
        }
      });
    } else {
      Swal.fire({
        title               : "Aviso",
        text                : "Selecione um período para continuar",
        confirmButtonText   : "Entendi",
        icon                : "warning",
        confirmButtonColor  : "#DA5A34"
      });
    }
  }
  function RetornaLabelMensalista(idx){
    console.log("Idx: ", idx);
    //const mesesMensalista = {"Indeterminado": 12, "Anual": 12, "Semestral": 6, "Trimestral": 3, "Mensal": 1};
    switch(idx){
      case 0 :    return "Mensal";
      case 1 :    return "Trimestral";
      case 2 :    return "Semestral";
      case 3 :    return "Anual";
      case 4 :    return "Indeterminado";
      default :   return "";
    }
  }

  function StartAction(){
    if(state){ 
      let flag = false;
      if(bttSel === 4){ // Verifica se o horário fixo é sem prazo para terminar
        horaFixa.prePedidos.map((item) => {
          if(item.status === "OCUPADO") flag = true;
        });
        if(!flag) SelecionarHorarioFixo();
        else {
          Swal.fire({
            icon    : "warning",
            title   : "Aviso",
            text    : "Existem conflitos de horários, por favor contate o gestor da quadra para solicitar um agendamento fixo sem fim",
            confirmButtonText     : "Entendi",
            confirmButtonColor    : "#DA5A34",
            timer                 : 5000,
          })
        }
      } else {
        SelecionarHorarioFixo();
      }
    }else {
      Swal.fire({
        icon    : "warning",
        title   : "Aviso",
        text    : (bttSel === periodo ? "Deseja remover a configuração de horário fixo?" : "Deseja modificar a configuração de horário fixo?"),
        showCancelButton      : true,
        confirmButtonColor    : "#DA5A34",
        cancelButtonColor     : "gray",
        confirmButtonText     : "Sim",
        cancelButtonText      : "Não",
      }).then((e) => {
        if(e.isConfirmed){ 
          if(bttSel === periodo){ 
            onClickRemoveHF();
            ClosedModal();
          } else {
            onClick(horaFixa, bttSel);
            //console.log("HoraFixa: ", horaFixa);
            ClosedModal();
          }
        } else return;
      });
    }
  
  }
  
  //console.log("HoraFixa: ", horaFixa);
  //console.log("Quadra: ", quadra);
  //console.log("Empresa: ", empresa);
  //console.log("Pedido: ", pedido);
  function GerenciaPrePedido(item, index){
   //console.log("Item: ", item);
    if(item.status === "OCUPADO"){
      Swal.fire({
        icon    : "warning",
        title   : "Aviso",
        text    : "Este horário está ocupado, por favor selecione outro horário",
        confirmButtonText     : "Entendi",
        confirmButtonColor    : "#DA5A34",
        timer                 : 5000,
      })
    } else {
      if(item.status === "LIVRE"){
        // atualiza o status do prePedido
        let aux = prePedidos;
        aux[index].status = "DESMARCADO";
        // atualiza o valor total do horario fixo
        setHoraFixa({...horaFixa, valorTotal: horaFixa.valorTotal - item.valor});
        // atualiza o valor total do prePedido
        setPrePedidos(aux);
      } else if(item.status === "DESMARCADO"){
        // atualiza o status do prePedido
        let aux = prePedidos;
        aux[index].status = "LIVRE";
        // atualiza o valor total do horario fixo
        setHoraFixa({...horaFixa, valorTotal: horaFixa.valorTotal + item.valor});
        // atualiza o valor total do prePedido
        setPrePedidos(aux);
      } 
    }
  }

  function ButtonPP({item, onClick}){
    //console.log("Item: ", item);
    return (
      <button 
        className = {item.status === "LIVRE" ? "btt-pre-pedido-hf gradiente-solicit" : item.status === "DESMARCADO" ? "btt-pre-pedido-hf gradiente-desmarc" : "btt-pre-pedido-hf gradiente-ocuped"}
        onClick   = {() => {if(state) onClick()}}
      > 
        <div className = "container-btt-div-prehf align-left">
          <h3 className = "text-btt-pre-pedido-hf text-left-hf">
            {Services.ReturnPeriodoHorarioArray(agenda)}
          </h3>
          <h4 className = "text-btt-pre-pedido-hf">
            {Services.DataFormat("dia,DD/MM/YY", item.dataInicio)}
          </h4>
        </div>
        <div className = "container-btt-div-prehf align-left">
          { item.status === "OCUPADO" ? 
            <>
              <h3 className = "text-btt-pre-pedido-hf text-color-ocup">
                OCUPADO
              </h3>
            </>
              :
            <>
              { 
                item.status === "LIVRE" ?
                <BiCheckboxChecked
                  className   = "icon-btt-pre-pedido"
                  color       = {"#23A820"}
                />
                  :
                <BiCheckbox
                  className   = "icon-btt-pre-pedido"
                  color       = {"#A8A8A8"}
                />
              }
              <h3 
                className = "text-btt-pre-pedido-hf"
                style     = {item.status === "LIVRE" ? {color: "#23A820"} : item.status === "DESMARCADO" ? {color: "#A8A8A8"} : {color: "#DA5A34"}}
              >
                {item.status === "OCUPADO" ? "OCUPADO" : item.status === "LIVRE" ? "SELECIONADO" : "DESMARCADO"}
              </h3>
              <h4 className = "text-btt-pre-pedido-hf">
                {`R$${item.valor.toFixed(2).toString().replace(".", ",")}`}
              </h4>
            </>
          }
        </div>
      </button>
    );
  }

  function RetornaDesconto(hr, bttIdx){
    //console.log("Hora: ", hr);
    //console.log("BttIdx: ", bttIdx);
    if(hr.descontoGeral > 0){
      switch(bttIdx){
        case 0  : return `(valor mensal com ${hr.descontoGeral}% de desconto)`;
        case 1  : return `(valor trimestral com ${hr.descontoGeral}% de desconto)`;
        case 2  : return `(valor semestral com ${hr.descontoGeral}% de desconto)`;
        case 3  : return `(valor anual com ${hr.descontoGeral}% de desconto)`;
        default : return "";
      }
    } else return "";
  }

  return (
    <Modal
      isOpen              = {modal}
      className           = "contain-content-sel-hf"
      overlayClassName    = "contain-overlay-sel-hf"
      onRequestClose      = {() => {setHoraFixa(null);setBttSel(null);ClosedModal();}}
    >
      <div className = "contain-horario-fixo">
        <ClosedX
          onClick = {() => {setHoraFixa(null);setBttSel(null);ClosedModal();}}
        />
        <h1 className = "text-title-hf">
          Horário Fixo
        </h1>
        
          <>
            <h2 className = "text-subtitle-hf">
              Repetir esse horário durante?
            </h2>
            <div className = "contain-btts-meses">
              {/* Grade de botões para configurar horário fixo! */}
              {btts.map((item, index) => {
                return(
                  <button
                    key       = {index}
                    className = {"btt-meses-hf"}
                    style     = {index === 0 ? {borderRadius: "7px 0px 0px 7px"} : index === 4 ? {borderRadius: "0px 7px 7px 0px"} : {}}
                    onClick   = {() => SelecionaBttMes(index)}
                  >
                    { bttSel === index &&
                      <div
                        className = "btt-meses-hf-sel"
                        style={index === 0 ? {borderRadius: "7px 0px 0px 7px"} : index === 4 ? {borderRadius: "0px 7px 7px 0px"} : {}}
                      />
                    }
                    <h2 className = "text-btt-meses-hf">{item.a}</h2>
                    <h2 className = "text-btt-meses-hf">{item.b}</h2>
                  </button>
                );
              })}
            </div>
            {/* Renderiza os horarios de agendamento */}
            {
              loading ? 
              <div className = "contain-loading">
                <Loading mode = {true} text={"Carregando horários..."}/>
              </div>
                :
              <>
                <div className = "contain-pre-agendamento-list">
                  { 
                    horaFixa !== null ?
                    prePedidos.map((item, index) => {
                      //if(item.status === "OCUPADO") setConflito(true);
                      return(
                        <ButtonPP
                          key   = {index}
                          index = {index}
                          item  = {item}
                          onClick={() => GerenciaPrePedido(item, index)}
                        />
                      );
                    }) : <></>
                  }
                </div>
                {/* Linha de divisão das informações */}
                <div 
                  className = "contain-informa-hf"
                  style     = {
                    horaFixa !== null ? 
                    {
                      marginTop: "5px", 
                      borderTop: "var(--border-hig) solid var(--color-border-mid)", 
                      borderBottom: "var(--border-hig) solid var(--color-border-mid)"
                    } : {}}
                >
                  {
                    horaFixa !== null &&
                    <>
                      { conflito &&
                        <div className = "contain-aviso-conflitos">
                          {bttSel === 4 ?
                            <>
                              <h3 style = {{fontFamily: "jost-mediun"}}>
                                Atenção
                              </h3>
                              <h4>
                                Não será possivel agendar horário fixo sem fim nesse horário, 
                                pois um ou mais agendamentos já estão marcados para as 
                                próximas semanas. Se for necessário, entre em contato 
                                com o gestor da quadra.
                              </h4>
                            </>
                              :
                            <>
                              <h3 style = {{fontFamily: "jost-mediun"}}>
                                Atenção
                              </h3>
                              <h4>
                                Horários ocupados não serão agendados
                              </h4>
                            </>
                          }
                        </div>
                      }
                      { (bttSel === 4 && conflito) ? <></> :
                        <>
                          <h3 className = "text-infos-hf">{quadra.descricao}</h3>
                          <div className = "contain-informa-hf-line">
                            <h3 className = "text-infos-hf text-color-hig">{Services.DataFormat("label-hf", horaFixa.prePedidos[0].dataInicio, agenda)}</h3>
                            <h3 className = "text-infos-hf text-color-hig text-low">{"(GMT "  + empresa.gmt + ":00)"}</h3>
                          </div>
                          <div className = "contain-informa-hf-line">
                            <h3 className = "text-infos-hf">{Services.DataFormat("label-hf-2", horaFixa.prePedidos[0].dataInicio)}</h3>
                            <h3 className = "text-infos-hf text-low">{bttSel === 4 ? "(Sem prazo para terminar)" : ("(Durante " + RetornaPeriodo(bttSel) + (bttSel !== 0 ? " meses)" : " mês)"))}</h3>
                          </div>
                          <div className = "contain-informa-hf-line">
                            <h3 className = "text-infos-hf text-color-hig margin-left" style = {{fontFamily: "jost-mediun"}}>{bttSel === 4 ? `R$${horaFixa.prePedidos[0].valor.toFixed(2).toString().replace(".",",")} cada agendamento ` : "R$" + horaFixa.valorTotal.toFixed(2).toString().replace(".",",")}</h3>
                            <h3 className = "text-infos-hf text-low">{RetornaDesconto(horaFixa, bttSel)}</h3>
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </>
            }
          </>
        {/* Botão para salvar horário fixo */}
        {
          (bttSel === 4 && conflito) ? <></> :
          <button
            className = "btt-confirm-modal-hf"
            onClick = {() => {StartAction();}}
          >
            {state ? "Salvar e configurar horário fixo" : (bttSel == periodo ? "Remover configuração de horário fixo" : "Modificar configuração de horário fixo")}
          </button> 
        }
      </div>
    </Modal>
  );
}