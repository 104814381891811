import React                        from "react";
// Components da screen
import { BiX, BiLeftArrowAlt }      from "react-icons/bi";
import { LocalStorage, Services }   from "../../../services";
import { BttPix, Loading }          from "../../Basics";
import Modal                        from "react-modal";
import { AuthContext }              from "../../../contexts/AuthContext";
import { semFoto }                  from "../../../assets";
import Swal                         from "sweetalert2";
import moment                       from "moment";
import RouterHook                   from "../../../contexts/RouterHook";

import "moment/locale/pt-br";
import "./stylesMVD.css";
import { EmpresaAPI } from "../../../servicesAPI";

moment.locale("pt-br");

export default function ModalViewDiaria({modal, ClosedModal, diaria, SavedStt}) {

  const serverRouter              = RouterHook();
  const { token, date }           = React.useContext(AuthContext);
  const [loading, setLoading]     = React.useState(false);
  const [width, setWidth]         = React.useState(window.innerWidth);
  const [mode, setMode]           = React.useState(true);
  const [motivo, setMotivo]       = React.useState("");
  const [cancelarOk, setCancelar] = React.useState(false);
  const [actCancel, setActCancel] = React.useState(false);
  const [urlImg, setUrlImg]       = React.useState(`${diaria.dayuse.empresa.urlFoto}${diaria.dayuse.empresa.foto}`);
  
  // Função que atualiza a largura da tela
  React.useEffect(() => {
    verificaCancelamento(diaria);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => window.removeEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  React.useEffect(() => {
    if(cancelarOk && motivo.length > 5){
      setActCancel(true);
    } else {
      setActCancel(false);
    }
  }, [cancelarOk, motivo]);
  // Função que cancela a diária caso a regra permita
  async function CancelarDiaria(){
    if(cancelarOk){
      Swal.fire({
        icon  : 'warning',
        title : 'Tem certeza?',
        text  : "Você não poderá reverter o cancelamento desta diária",
        confirmButtonText : 'Sim',
        cancelButtonText  : 'Não',
        confirmButtonColor: '#DA5A34',
        cancelButtonColor : 'gray',
        showCancelButton  : true,
      }).then(async (result) => {
        if(result.isConfirmed){
          setLoading(true);
          const reqs = await EmpresaAPI.CancelarCompraDayUse(token, diaria.idcompraDayuse, date.user.nome, motivo);
          if(reqs.status){
            Swal.fire({
              icon  : "success",
              title : "Sucesso",
              text  : "Diária cancelada com sucesso",
              confirmButtonText   : "Entendi",
              confirmButtonColor  : "#DA5A34",
              timer: 5000
            });
            setLoading(false);
            ClosedModal(true);
          } 
        }
      });
    }
  }

  // Função que inicia o processo de pagamento via PIX
  
  
  // Verifica se o usuário pode cancelar a diária
  function verificaCancelamento(dayuse){
    function getTimeCancelar(data){
      if (moment(data).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        return false;
      } else if (moment(data).isAfter(moment())) {
        return true;
      } else {
        return false;
      }
    }
    if(dayuse.formaPagamento === "PIX" && dayuse.status === "SOLICITADA") return setCancelar(false);

    if (dayuse.dayuse.usuarioPodeCancelar === 'SEMPRE') {
      setCancelar(true);
    } else if (dayuse.dayuse.usuarioPodeCancelar === 'DIA' && getTimeCancelar(dayuse.data)) {
      setCancelar(true);
    } else {
      setCancelar(false);
    }
  }

  // Retorna o componente referente ao status do pedido, selecionando a cor!
  function RetornaColorSttDiaria(stt){
    switch(stt){
      case "SOLICITADA" : return "#ff7043";
      case "AGENDADA"   : return "#22ac4a";
      case "CANCELADA"  : return "#bf3324";
      case "REALIZADA"  : return "#00cda4";
    }
  }
  // Retorna o status do pagamento
  function RetornaPayState(){
    if(diaria.status !== "CANCELADA"){
      if(diaria.valorTotal === diaria.valorRealizado) return (<h4 style = {{color: "#8FBC8F"}}>PAGO</h4>);
      else return (<h4 style = {{color: "red"}}>PENDENTE</h4>);
    } else {
      return (<h4 style = {{color: "#bf3324   "}}>CANCELADO</h4>);
    }
  }
  //console.log("diaria", diaria);

  // Retorna a forma de pagamento da diária
  function FormaPay(status){
    switch(status){
      case "CARTAO_CREDITO" : return "CARTÃO DE CRÉDITO";
      default               : return status;
    }
  }
  
  return (
    <Modal
      className         = "contain-content-view-diaria"
      overlayClassName  = "contain-overlay-view-diaria"
      onRequestClose    = {ClosedModal}
      isOpen            = {modal}
    >
      <div className = "contain-view-diaria">
        { width > 767 ?
          (mode ? 
            <BiX
              className   = "icon-x-closed-modal"
              size        = {25}
              onClick     = {() => {
                if(mode) ClosedModal();
              }}
            />
              :
            <BiLeftArrowAlt
              className   = "icon-arrow-left-closed-modal"
              size        = {30}
              onClick     = {() => {
                setMode(true);
              }}
            />
          )
            :
          <BiLeftArrowAlt
            className   = "icon-arrow-left-closed-modal"
            size        = {30}
            onClick     = {() => {
              if(mode) ClosedModal();
              else setMode(true);
            }}
          />
        }
        <h2 className = "text-title-diaria">
          {`Diária #${diaria.idcompraDayuse}`}
        </h2>
        {/* Componente superior da modal */}
        { loading ? <div className = "contain-loading"><Loading mode={false}/></div> :
          mode ?
          <div className = "contain-modal-diaria-overflow">
            <div className = "contain-comp-modal-diaria-super">
              <h2 className = "text-diaria-infos">
                Solicitado em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", diaria.createdAt)} às ${Services.ReturnHoraMin(new Date(diaria.createdAt))}, por ${diaria.criadoPorGestor ? diaria.dayuse.empresa.nome+"." : "você."}`}</a>
              </h2>
              { (diaria.dataAprovacao !== null) && 
                <h2 className = "text-diaria-infos">
                  Aprovado em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", diaria.dataAprovacao)} às ${Services.ReturnHoraMin(new Date(diaria.dataAprovacao))}, por: ${diaria.aprovadoPor}`}</a>
                </h2>
              }
              { (diaria.dataCancelamento !== null) &&
                <>
                  <h2 className = "text-diaria-infos">
                    Cancelado em: <a style={{color: "#DA5A34"}}>{`${Services.DataFormat("dia,DD/MM/YY", diaria.dataCancelamento)} às ${Services.ReturnHoraMin(new Date(diaria.dataCancelamento))}${(diaria.canceladoPor !== null) ? (diaria.canceladoPor === date.user.nome ? ", por você." : ", por "+ diaria.dayuse.empresa.nome) : ""}`}</a>
                  </h2>
                  { (diaria.motivoCancelamento !== null && diaria.motivoCancelamento !== "") &&
                    <h2 className = "text-diaria-infos">
                      Motivo do cancelamento: <a style={{color: "#DA5A34"}}>{`${diaria.motivoCancelamento}`}</a>
                    </h2>
                  }
                </>
              }
            </div>
            <div className = "contain-comp-modal-diaria-status">
              { (((diaria.status === "SOLICITADA" && diaria.formaPagamento !== "PIX") || diaria.status === "AGENDADA") && width > 768) &&
                <button
                  className = "btt-cancelar-diaria-fixo"
                  onClick   = {() => { setMode(false) }}
                >
                  Cancelar
                </button>
              }
              <h3 className = "title-comp-diaria">
                {diaria.dayuse.titulo}
              </h3>
              <h3 className = "rotul-diaria-data">
                {`${Services.ReturnDay(new Date(diaria.data).getDay())}, ${Services.ReturnToStringDate(new Date(diaria.data))}`}      
              </h3>
              <h2 
                className = "rotul-diaria-status"
                style     = {{color: RetornaColorSttDiaria(diaria.status)}}
              >
                {diaria.status}
              </h2>
            </div>
            <div className = "contain-modal-diaria-infer">
              <img 
                src     = {urlImg}
                onError = {() => {setUrlImg(semFoto)}}
              />
              <h3 className = "text-diaria-status-pay">{diaria.dayuse.empresa.nome}</h3>
            </div>
            <div className = "contain-modal-status-pays">
              <div className = "contain-modal-status-pays-line">
                <h3 className="title-pagamentos-modal-view">Pagamento</h3>
                {RetornaPayState()}
              </div>
              {
                <div className = "contain-modal-status-pays-line">
                  <h3 className = "modal-txt-infos-valores">Forma de Pagamento</h3>
                  <h3 className = "modal-txt-valores color-contratado">{FormaPay(diaria.formaPagamento)}</h3>
                </div>
              }
              <div className = "contain-modal-status-pays-line">
                <h3 className = "modal-txt-infos-valores">
                  Valor Total
                </h3>
                <h3 className = "modal-txt-valores color-contratado">
                  {`R$${diaria.valorTotal.toFixed(2).toString().replace(".",",")}`}
                </h3>
              </div>
              { 
                (diaria.valorPendente !== 0 && diaria.valorPendente !== null && diaria.status !== "CANCELADA") &&
                <div className = "contain-modal-status-pays-line">
                  <h3 className = "modal-txt-infos-valores">
                    Valor Pendente
                  </h3>
                  <h3 className = "modal-txt-valores color-restante">
                    {`R$${diaria.valorPendente.toFixed(2).toString().replace(".",",")}`}
                  </h3>
                </div>
              }
              {
                (diaria.valorRealizado !== 0 && diaria.valorRealizado !== null && diaria.status !== "CANCELADA") &&
                <div className = "contain-modal-status-pays-line">
                  <h3 className = "modal-txt-infos-valores">
                    Valor Pago
                  </h3>
                  <h3 className = "modal-txt-valores color-pago">
                    {`R$${diaria.valorRealizado.toFixed(2).toString().replace(".",",")}`}
                  </h3>
                </div>
              }
              { (diaria.valorEstornado > 0 && diaria.status === "CANCELADA") &&
                <div className = "contain-modal-status-pays-line">
                  <h3 className = "modal-txt-infos-valores">
                    Valor Estornado
                  </h3>
                  <h3 className = "modal-txt-valores color-cancelado">
                    {`R$${diaria.valorEstornado.toFixed(2).toString().replace(".",",")}`}
                  </h3>
                </div>
              }
              { (diaria.status === "SOLICITADA" && diaria.formaPagamento === "PIX") ? 
                <BttPix
                  onClick   = {() => {
                    //
                  }}
                  text      = {"Ver código PIX"}
                /> : <></>
              }
            </div>
            <div className = "contain-modal-regras-diaria">
              <h2>REGRAS DA DIÁRIA</h2>
              <h3>Forma de pagamento: {(diaria.formaPagamento.includes("PIX") && diaria.formaPagamento.includes("CARTAO_CREDITO")) ? "Pix ou Cartão de crédito" : (diaria.formaPagamento.includes("PIX") ? "Pix" : "Cartão de crédito")}</h3>
              <h3>Duração: {diaria.dayuse.duracao}</h3>
              <h3>Cancelamento: {diaria.dayuse.usuarioPodeCancelar === "DIA" ? "Até o dia anterior" : (diaria.dayuse.usuarioPodeCancelar === "SEMPRE" ? "Você pode cancelar a qualquer momento" : "Não poderá cancelar")}</h3>
              <h3>Valida para: {diaria.dayuse.quadra}</h3>
            </div>
            { (((diaria.status === "SOLICITADA" && diaria.formaPagamento !== "PIX") || diaria.status === "AGENDADA") && width < 768) &&
              <button
                className = "btt-cancelar-diaria-mobile"
                onClick   = {() => setMode(false)}
              >
                Cancelar
              </button> 
            }
          </div>
            :
          <div className = "contain-modal-cancelamento-diaria">
            <h2>CANCELAMENTO</h2>
            { diaria.dayuse.usuarioPodeCancelar === "NAO" ?
              <>
                <h3 className = "cancelamento-label-info">
                  Não é possível cancelar! Essa quadra definiu a seguinte regra de cancelamento:
                </h3>
                <h3 className = "cancelamento-label-motivo"
                  style = {{backgroundColor: "#D9D9D9", padding: "5px"}}
                >
                  Regra de cancelamento: <b style = {{fontFamily: "jost-mediun"}}>Não poderá cancelar</b>
                </h3>
              </>
                :
              <>
                {
                  ((diaria.formaPagamento === 'CARTAO_CREDITO' || diaria.formaPagamento === 'PIX') && diaria.valorRealizado > 0) && 
                  <h3 className = "cancelamento-label-info">
                    Essa diária foi paga pelo Agendei Quadras, se você cancelar, receberá o estorno total.
                  </h3>
                }
                <h3 className = "cancelamento-label-motivo">
                  Qual o motivo do cancelamento?
                </h3>
                <input
                  className     = "cancelamento-input-motivo"
                  placeholder   = "Digite aqui"
                  value         = {motivo}
                  onChange      = {(e) => setMotivo(e.target.value)}
                  />
                <button
                  className = {actCancel ? "btt-modal-confirmar-cancelar-diaria" : "btt-modal-confirmar-cancelar-diaria-off"}
                  onClick   = {() => {
                    actCancel && CancelarDiaria();
                  }}
                  >
                  Cancelar
                </button>
              </>              
            }
          </div>
        }
      </div>
    </Modal>
  );
}