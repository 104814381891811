import alertMP3     from "./alert.mp3";
import logo         from './logo-header.png';
import logoAgendei  from './LogoAgendeiQuadras.png';
import login1       from "./missao-min.png";
import recPass      from "./rec-pass.png";
import confPassLogo from "./img-conf-pass.png";
import notAgend     from "./not-agends.png";
import semFoto      from "./semFoto.jpg";
import search       from "./Search.png";
import loadCresc    from "./loading.gif";
import creditCard   from "./credit-card-payment-colored.png";
import logoPix      from "./logo_pix.png";
import masterCard   from "./mastercard.png";
import payColor     from "./pay-colored.png";
import payTerminal  from "./payment-terminal-colored.png";
import pixAgendei   from "./pix-agendei.png";
import notClient    from "./not-client.png";
import capaCompLink from "./fundo-deeplink.jpeg";
import capaLogin    from "./siteGestor.png";
import DigitedComp  from "./digited-pc.png";
import iconCone     from "./icon-cone.png";
import iconFest     from "./icon-fest.png";
import iconAlvo     from "./icon-alvo.png";
import smartAlert   from "./smartphone-alert.png";
import iconTrofeu   from "./icon-trofeu.png";
import iconInsta    from "./insta-logo.png";
import {ReactComponent as CalClock}     from "./calendar-clock.svg";
import {ReactComponent as CalSearch}    from "./calendar_search_icon.svg";
import {ReactComponent as CalArrow}     from "./calendar_arrow.svg";
import {ReactComponent as CalCheck}     from "./calendar-check.svg";
import {ReactComponent as CompLink}     from "./compart-icon.svg";
import {ReactComponent as WhatsPerson}  from "./what-person-icon.svg";
import {ReactComponent as ClosedX}      from "./closedX.svg";
import {ReactComponent as DollarNote}   from "./dollar-not.svg";
import {ReactComponent as UserSet}      from "./user-setting.svg";
import {ReactComponent as CheckBox}     from "./check-box.svg";
import {ReactComponent as CheckBoxSel}  from "./check-sel.svg";
import {ReactComponent as Check}        from "./check.svg";
import {ReactComponent as ChatSearche}  from "./chat-search.svg";
import {ReactComponent as BookSearch}   from "./book-page.svg";
import {ReactComponent as UserCircle}   from "./circle-user.svg";
import {ReactComponent as CentralIcon}  from "./central-icon.svg";
import {ReactComponent as SmartCheck}   from "./smart-check.svg";
import {ReactComponent as ICO_FX}       from "./grafik.svg";
import {ReactComponent as MedalIco}     from "./icon-medal.svg";
import {ReactComponent as PersonLove}   from "./person-love.svg";
import {ReactComponent as PersonStar}   from "./person-star.svg";
import {ReactComponent as IconQdr}      from "./icon-quadra.svg";
import {ReactComponent as IcCLockCk}    from "./icon-check-clock.svg";
import {ReactComponent as IconMoney}    from "./icon-money.svg";
import {ReactComponent as CalMoney}     from "./calendar-money.svg";
import {ReactComponent as CalXMoney}    from "./calendar-x-money.svg";
import {ReactComponent as IconCupon}    from "./icon-cupons.svg";

const iconsPayment = {
  creditCard, logoPix, masterCard, payColor, payTerminal, pixAgendei
}

export {
  alertMP3, logo, login1, recPass, confPassLogo, logoAgendei, search,
  semFoto, CalClock, notAgend, iconsPayment, CalSearch, CalArrow, CalCheck,
  CompLink, WhatsPerson, loadCresc, ClosedX, DollarNote, notClient, UserSet,
  CheckBox, CheckBoxSel, Check, ChatSearche, BookSearch, UserCircle, CentralIcon,
  capaCompLink, capaLogin, SmartCheck, DigitedComp, ICO_FX, iconCone, MedalIco,
  iconFest, PersonLove, PersonStar, IconQdr, IcCLockCk, IconMoney, iconAlvo,
  CalMoney, CalXMoney, smartAlert, iconTrofeu, iconInsta, IconCupon
}