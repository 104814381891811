import React                          from "react";
// Componentes
import { 
  CompChat, Mensage, LoadingDots, Loading, Alerts 
} from "../../Basics/";
import { 
  MdOutlineMarkChatUnread, MdOutlineMarkChatRead 
} from "react-icons/md";
import { HeaderChat }                 from "../../Complext";
import { BiSend, BiArrowBack }        from "react-icons/bi";
import { AuthContext }                from "../../../contexts/AuthContext";
import { ChatContext }                from "../../../contexts/ChatContext";
import RouterHook                     from "../../../contexts/RouterHook";
import { EmpresaAPI }                 from "../../../servicesAPI";
import Swal                           from "sweetalert2";

import "./stylesChat.css";
import { state } from "@antv/g2plot/lib/adaptor/common";

export default function Chat() {
  
  const serverRouter                  = RouterHook();
  const inputRef                      = React.useRef(null);
  const listRef                       = React.useRef(null);
  const containRef                    = React.useRef(null);

  const { date, token }               = React.useContext(AuthContext);
  const { states, actions }           = React.useContext(ChatContext);
  //console.log("Chat! CE: ", states.chat_CE);
  //console.log("Chat! CS: ", states.chat_Sel);
  const [inputChat, setInputChat]     = React.useState("");
  
  // Função que atualiza o tamanho da tela
  React.useEffect(() => {
    if(!states.chatOn) actions.handleChatOn(true);
    document.title = "Conversas | Agendei Quadras";
    return () => {
      // Mostra url
      // Verifica se a página é do chat, se não for, fecha chat_Sel
      if(window.location.pathname !== `/centro-esportivo/${date.empresa.username}/conversas`){	
        console.log("ChatContext: page aqui! ");
        actions.setChat_SelContext(null);
        actions.handleChatOn(false);
      }
    }
  }, []);
  // Quando sair da tela do chat, caso states.chat_Sel !== null ele deve ser setado para null
  //ChatGPT aqui, não pode ser com return () => {setChat_Sel(null);} ok,,, pois assim, quando eu abro a teloa do chat, ele ja seleciona o primeiro e esta descelecionando quando abre a tela, preciso que sete como null só quando eu saio da tela do chat!
  //console.log("ChatContext: ", sttCtx);
  function ReturnSttPage(){
    actions.handleChatOn(false);
    actions.setChat_SelContext(null);
    serverRouter("back");
  }
  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
  }, [states.chatOn, states.chat_Sel, states.modeCE]);
  // Função que atualiza o scroll da lista de chats
  let flagRef = React.useRef(false);
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop = listRef.current.scrollTop;
      const scrollHeight = listRef.current.scrollHeight;
      const clientHeight = listRef.current.clientHeight;
      if(states.modeCE){
        if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flagRef.current && states.paginaP.maxScroll > states.paginaP.offset) {
          flagRef.current = true;
          actions.BuscarCaixaEntradaPaginado().then(() => {
            flagRef.current = false;
          });
        }
      } else {
        if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flagRef.current && states.paginaE.maxScroll > states.paginaE.offset) {
          flagRef.current = true;
          actions.BuscarCaixaEntradaPaginado().then(() => {
            flagRef.current = false;
          });
        }
      }
    };

    if(listRef.current) listRef.current.addEventListener("scroll", handleScroll);
    return () => {if(listRef.current) listRef.current.removeEventListener("scroll", handleScroll);}
  
  }, [states.chat_CEP, states.chat_CEE, states.loadSend, states.modeCE, states.paginaE, states.paginaP]);
  
  // Função que escuta o enter no input do chat
  React.useEffect(() => {
    async function handleKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if(inputChat === "") return;
        await actions.SendMsgChat(inputChat, states.chat_Sel.idchat);       
        setInputChat("");
      }
    }
    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [inputChat]);
  //console.log("Chat asads: ", states.chat_Sel);
  // Função que joga o scrool do chat para baixo
  React.useEffect(() => {
    if (containRef.current) {
      console.log("Scroll: down");
      // O elemento existe, rola para o final
      const container = containRef.current;
      container.scrollTop = container.scrollHeight;
      //console.log("Scroll: ", container.scrollTop, container.scrollHeight)
    } else {
      console.log("Aqui veio por que???");
      // O elemento não existe, faça algo apropriado
      //console.log('O elemento .container não foi encontrado na página.');
    }
  }, [ states.loadingCS, states.chat_Sel, states.loadSend, states.newMsg ]);
  
  function AcepptAgendamento(idagendamento){
    //console.log("pedido", idagendamento);
    Swal.fire({
      title: 'Aprovar',
      text: "Deseja aprovar essa solicitação de agendamento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00cda4',
      cancelButtonColor: '#bf3324',
      confirmButtonText: 'Aprovar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if(!result.isConfirmed) return;
      actions.handleLoad(3, true);
      const req = await EmpresaAPI.AprovarPedido( token, idagendamento, date.gestor.nome );
      if(req.status){
        Alerts.sucesso("Sucesso", "Agendamento aprovado com sucesso");
      } else {
        Alerts.aviso("Aviso!", req.message);
      }
      const idx = states.chat_CEP.findIndex((chat) => chat.idchat === states.chat_Sel.idchat);
      await actions.ReturnChatMsg(states.chat_Sel.idchat, idx);
      actions.handleLoad(3, false);
    });
     
  }

  function DeclinedAgendamento(tipo, idagendamento){
    Swal.fire({
      title   : 'Rejeitar?',
      text    : "Deseja rejeitar essa solicitação de agendamento?",
      icon    : 'warning',
      showCancelButton      : true,
      confirmButtonColor    : '#00cda4',
      cancelButtonColor     : '#bf3324',
      confirmButtonText     : 'Rejeitar',
      cancelButtonText      : 'Cancelar'
    }).then(async (result) => {
      if(!result.isConfirmed) return;
      actions.handleLoad(3, true);
      const req = await EmpresaAPI.RecusarPedido( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
      if(req.status){
        Alerts.sucesso("Sucesso", "Agendamento rejeitado com sucesso");
      } else {
        Alerts.aviso("Aviso!", req.message);
      }
      const idx = states.chat_CEP.findIndex((chat) => chat.idchat === states.chat_Sel.idchat);
      await actions.ReturnChatMsg(states.chat_Sel.idchat, idx);
      actions.handleLoad(3, false);
    });
  }
  
  // Estados da mensagem dividida em dois blocos, caixa de entrada, não contém a conversa
  // CaixaEntrada status
  // -> Enviada = Não lida
  // -> Lida    = Lida
  // Conversa contém seu status e seu bloco com lista de mensagens:: status ::
  // -> ABERTA = Ainda podem trocar informações
  // -> FECHADA = Não podem mais trocar informações  
  function ReturnInputChat(chat_Sel){
    // Se o chat estiver aberto, retorna o input
    //console.log("Chat Sel: ", chat_Sel);
    if(chat_Sel.status === "FECHADO"){ // Retorna o aviso de conversa encerrada
      return(
        <div className = "contain-chat-off">
          <h3> CONVERSA ENCERRADA </h3>
          <label>Talvez esse agendamento já tenha acontecido, ou foi cancelado!</label>
        </div>
      )
    } else { // Retorna o input
      return (
        <div className = "contain-chat-input-icon">
          <textarea
            ref         = {inputRef}
            className   = "input-chat-msg"
            type        = "text"
            placeholder = "Digite sua mensagem"
            value       = {inputChat}
            onChange    = {(e) => {setInputChat(e.target.value)}}
          />
          { states.loadSend ?
            <LoadingDots/> 
              :
            ( inputChat === "" ?
              <BiSend
                size      = {35}
                className = "btt-icon-send-low"
                onClick   = {async () => {
                  //VerificaTabela(states.chat_Sel.idchat);
                  
                }}
              />
                :
              <BiSend
                size      = {35}
                className = "btt-icon-send"
                onClick   = {async () => {
                  //VerificaTabela(states.chat_Sel.idchat);
                  await actions.SendMsgChat(inputChat, chat_Sel.idchat);
                  setInputChat("");
                }}
              />
            )
          }
        </div>
      )
    }
  }

  if(states.loading) {
    return (
      <div className = "contain-loading">
        <Loading mode = {true} text = {"Carregando conversas..."} />
      </div>
    );
  }

  return (
    <>
      { 
        <div className = "contain-chat-main">
          <div className = "contain-list-chats">
            <div className = "contain-list-title-btt">
              <BiArrowBack
                className = "btt-icon-back"
                onClick   = {() => {
                  ReturnSttPage();
                }}
              />
              <h2>Conversas</h2>
            </div>
            
            <div className = "contain-handle-peds-diars" style = {{padding: "0px var(--padding-mid)"}}>
              <button
                className = { states.modeCE ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
                style    = {{justifyContent: "space-around"}}
                onClick   = {() => {
                  if(states.loadingCE || states.modeCE) return;
                  actions.handleLoad( 1, true );
                  actions.handleModeCE( true );
                }}
              >
                { states.chatMNLP ?
                  <MdOutlineMarkChatUnread
                    className = "icon-chat-not-read-handle"
                  /> 
                    : 
                  <MdOutlineMarkChatRead
                    className = "icon-chat-read-handle"
                  />
                }
                <h2 className = "text-btts-handle-chat">
                  Agendamentos
                </h2>
              </button>
              <button
                className = { !states.modeCE ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
                style     = {{justifyContent: "space-around"}}
                onClick   = {() => {
                  if(states.loadingCE || !states.modeCE) return;
                  actions.handleLoad( 1, true );
                  actions.handleModeCE( false );
                }}
              > 
                { states.chatMNLE ?
                  <MdOutlineMarkChatUnread
                    className = "icon-chat-not-read-handle"
                  /> 
                    : 
                  <MdOutlineMarkChatRead
                    className = "icon-chat-read-handle"
                  />
                }
                <h2 className = "text-btts-handle-chat">
                  Mensagens Diretas
                </h2>
              </button>
            </div>
            <div 
              className = "contain-list-caixa-e"
              ref       = {listRef}
            >
              { states.loadingCE ? 
                <div className = "contain-loading" style = {{height: "350px"}}>
                  <Loading mode={true} text={"Carregando Conversas..."}/>
                </div> :
                states.modeCE ?
                <>
                  {
                    states.chat_CEP.map((item, index) => {
                      //if(index == 0) //console.log("Item: ", item);
                      return (
                        <CompChat
                          key       = {index}
                          chat      = {item}
                          nome      = {date.gestor.nome}
                          modeCE    = {states.modeCE}
                          selected  = { states.chat_Sel ? (states.chat_Sel.idchat === item.idchat ? true : false): false }
                          onClick   = {() => {
                            actions.ReturnChatMsg(item.idchat, index);
                          }}
                        />
                      );
                    })
                  }{ 
                    states.loadingCEPg &&
                    <div className = "contain-loading"><Loading mode={false}/></div>
                  }
                </>
                  : // Clientes
                <>
                  {
                    states.chat_CEE.map((item, index) => {
                      //if(index == 0) //console.log("Item: ", item);
                      return (
                        <CompChat
                          key       = {index}
                          chat      = {item}
                          nome      = {date.gestor.nome}
                          modeCE    = {states.modeCE}
                          selected  = { states.chat_Sel ? (states.chat_Sel.idchat === item.idchat ? true : false): false }
                          onClick   = {() => {
                            actions.ReturnChatMsg(item.idchat, index, item.usuario);
                          }}
                        />
                      );
                    })
                  }{ 
                    states.loadingCEPg &&
                    <div className = "contain-loading"><Loading mode={false}/></div>
                  }
                </>
              }
              { (states.modeCE && states.chatEmpyP && states.chat_CEP.length === 0 ) ?
                <h3 className = "text-aviso-modal-chat">
                  Você ainda não tem conversas. Faça um agendamento para iniciar uma conversa com a quadra.
                </h3> :
                (!states.modeCE && states.chatEmpyE && states.chat_CEE.length === 0 ) &&
                <h3 className = "text-aviso-modal-chat">
                  Você ainda não possui conversas com clientes.
                </h3>
              }
            </div>
          </div>
          <div className = "contain-chat-read-write">
              {
                states.loadingCS ? <Loading mode ={true} text={"Carregando conversa..."}/> :
                states.chat_Sel === null ?
                <h1>Nenhum Chat Selecionado!</h1>
                  :
                <>
                  <HeaderChat
                    onClick     = {(val) => {
                      if(val === "ACEPPT") {
                        AcepptAgendamento(states.chat_Sel.pedido.idpedido);
                      } else if(val === "DECLINED") {
                        DeclinedAgendamento(states.chat_Sel.pedido.idpedido);
                      }
                    }}
                  />
                  <div ref = {containRef} className = "contain-chat-mensagens" >
                    {
                      states.chat_Sel.mensagens.map((item, index) => {
                        return(
                          <Mensage
                            nome      = {date.gestor.nome}
                            key       = {index}
                            msg       = {item}
                          />
                        );
                      })
                    }
                  </div>
                  { (states.chat_Sel !== null && states.chat_Sel !== undefined) &&
                    ReturnInputChat(states.chat_Sel)
                  }
                </>  
              }
          </div>
        </div>
      }
    </>
  );
}