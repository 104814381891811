import React              from "react";
import { 
  Loading, InpSelData
} from "../../Basics";
import { CalendarFiltro, CompPedido } from "../../Complext/";
import { 
  BiArrowBack,
  BiCalendar, BiCalendarCheck, BiCalendarEvent, BiCalendarHeart, BiCalendarX, BiSearchAlt
} from "react-icons/bi";
import { notAgend, CalClock, CalArrow, semFoto } from "../../../assets";
import { AuthContext }        from "../../../contexts/AuthContext";
import RouterHook             from "../../../contexts/RouterHook";
import Swal                   from "sweetalert2";
import { EmpresaAPI }         from "../../../servicesAPI";
import { Services }           from "../../../services";

import "./stylesGAgd.css";

export default function GestorAgenda() {

  const serverRouter        = RouterHook();
  const { 
    date, token, modalOpen, handleMOP 
  } = React.useContext(AuthContext);
  
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : 0,
    scroll      : null,
  }
  const arraySttRef                     = React.useRef(null);
  const [pagina, setPagina]             = React.useState(pagBuffer);
  const [mude, setSttMude]              = React.useState(false);
  // Index do botão para api
  // null -> todos
  // SOLICITADO -> 1
  // AGENDADO -> 2
  // CANCELADO -> 3
  // REALIZADO -> 4
  const [loading, setLoading]           = React.useState(true);
  const [loadingCp, setLoadingCp]       = React.useState(false);
  const [sttPage, setSttPage]           = React.useState({
    index_quadra      : (date.empresa.quadras.length > 1 ? null : 0),
    dataInicial       : Services.ReturnData("init"), // Formato YYYY-MM-DD
    dataFinal         : Services.ReturnData("end"),  // Formato YYYY-MM-DD
    viewFixos         : "TODOS",
    status            : null, //
    realizados        : 0,
    cancelados        : 0,
    previsto          : 0,
  });
  const [arrayState, setArrayState]     = React.useState([]); // array de pedidos

  React.useEffect(() => {
    document.title = "Buscar Agendamentos | Gestor Agendei";
  }, []);
  React.useEffect(() => {
    FiltroPedidos(true);
  }, [mude]);

  React.useEffect(() => {
    if(arrayState.length > 0){
      let countRealizados = 0;
      let countCancelados = 0;
      let countPrevisto   = 0;

      arrayState.map((pedido) => {
        if(pedido.status === "REALIZADO")       countRealizados += pedido.valorTotal;
        else if(pedido.status === "CANCELADO")  countCancelados += pedido.valorTotal;
        else                                    countPrevisto   += pedido.valorTotal; // Agendado ou Solicitado
      }); //console.log("pedido", pedido);
      setSttPage({...sttPage, realizados: countRealizados, cancelados: countCancelados, previsto: countPrevisto});
    }
  }, [arrayState]);
/*  ---- Não está funcionando ----
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);
*/
  const modPedidos = [
    {text:"Todos"},         // 0 - Todos
    {text:"Solicitados" },  // 1 - Solicitados
    {text:"Agendados"  },   // 2 - Agendados 
    {text:"Cancelados" },   // 3 - Cancelados
    {text:"Realizados" },   // 4 - Realizados 
  ];
  //console.log("sttPage", sttPage);
  async function FiltroPedidos(pg){
    //pg ? setLoading(true) : setLoadingCp(true);
    setLoading(true);
    const req = await EmpresaAPI.BuscarMyReservas(
      token,
      date.empresa.idempresa,
      (sttPage.index_quadra === null ? null : date.empresa.quadras[sttPage.index_quadra].idquadra),
      sttPage.dataInicial,
      sttPage.dataFinal,
      ReturnStatusSelct(sttPage.status),
      sttPage.viewFixos,
      null,             // limit -> fixo
      null,        // countAll
      null   // offSet
    );

    //console.log("FiltroPedidos", req);
    if(req.status){
      if(pg){
        //setPagina({...pagina, maxScroll: req.req.pedido.count, offSet: req.req.pedido.rows.length});
        // Ordenar por data
        req.req.sort((a, b) => b.dataInicio.localeCompare(a.dataInicio));
        setArrayState(req.req);
      } else {
        setPagina({...pagina, offSet: pagina.offSet + req.req.length});
        // Ordenar por data
        req.req.sort((a, b) => b.dataInicio.localeCompare(a.dataInicio));
        setArrayState([...arrayState, ...req.req.pedido.rows]);
      }
      //console.log("FiltroPedidos", req.req);
      let countRealizados = 0;
      let countCancelados = 0;
      let countPrevisto   = 0;
      
      setSttPage({...sttPage, realizados: countRealizados, cancelados: countCancelados, previsto: countPrevisto});
      //console.log("FiltroPedidos, sttPage", sttPage);
    } else {
      Swal.fire({
        icon          : "warning",
        title         : "Aviso",
        text          : req.req.message,
        confirmButtonText : "Entendi",
        confirmButtonColor: "#DA0175",
      });  
    }
    pg ? setLoading(false) : setLoadingCp(false);
  }
  
  //console.log("GestorAgenda", date);
  //console.log("GestorAgenda", inpData);
  async function FiltrarPorData(dataIn, dataFn){
    //console.log("FiltrarPorData", inpData); 
    // Verifica se as strings estão menores que o tamanho esperado
    //console.log("FiltrarPorData", dataIn, dataFn);
    if(dataIn.length < 10 || dataFn.length < 10){
      Swal.fire({
        icon          : "warning",
        title         : "Aviso",
        text          : "Por favor, selecione um período para filtrar seus agendamentos por data.",
      });  
    } else {
      setLoading(true);
      const req = await EmpresaAPI.BuscarMyReservas(
        token,
        date.empresa.idempresa,
        (sttPage.index_quadra === null ? null : date.empresa.quadras[sttPage.index_quadra].idquadra),
        Services.ConverteData(true, dataIn),
        Services.ConverteData(true, dataFn),
        ReturnStatusSelct(sttPage.status),    // status -> "SOLICITADO", "AGENDADO", "CANCELADO", "REALIZADO"
        sttPage.viewFixos,                    // viewFixos -> "TODOS", "AVULSO", "FIXO
        null,                                 // limit -> fixo
        null,                                 // countAll
        null                                  // offSet
      );
      //console.log("FiltrarPorData", req);
      if(req.status){
        //setPagina({...pagina, maxScroll: req.req.count});
        //setArrayState(req.req.rows);
        // Ordenar por data
        //console.log("FiltrarPorData", req.req);
        req.req.sort((a, b) => b.dataInicio.localeCompare(a.dataInicio));
        setSttPage({...sttPage, dataInicial: Services.ConverteData(true, dataIn), dataFinal: Services.ConverteData(true, dataFn)});
        setArrayState(req.req);
        //setPagina({...pagina, maxScroll: req.req.pedido.count, offSet: req.req.length});
      } else {
        Swal.fire({
          icon          : "warning",
          title         : "Aviso",
          text          : req.req.message,
          confirmButtonText : "Entendi",
          confirmButtonColor: "#DA0175",
        });
      }
      setLoading(false);
    }
  }
  //console.log("SttPage", sttPage);
  //console.log("pagina", pagina);
  
  function ReturnSttPage(){
    if(modalOpen){
      handleMOP(false);
    } else {
      serverRouter("back");
    }
  }
  React.useEffect(() => {
    ////console.log("ModalOpen: ", modalOpen);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
   }, [modalOpen]);
/*
  function FormatDataInput(value, mode){
    // Remove todos os caracteres não numéricos, exceto a barra
    let formattedValue = value.replace(/[^0-9/]/g, '');
  
    // Limita o comprimento da string a 8 caracteres: DD/MM/YY
    if (formattedValue.length > 8) formattedValue = formattedValue.substr(0, 8);
  
    // Adiciona uma barra depois do segundo e quinto caracteres, se necessário
    if (formattedValue.length > 2 && formattedValue.charAt(2) !== '/')
      formattedValue = `${formattedValue.substr(0, 2)}/${formattedValue.substr(2)}`;
    if (formattedValue.length > 5 && formattedValue.charAt(5) !== '/')
      formattedValue = `${formattedValue.substr(0, 5)}/${formattedValue.substr(5)}`;
  
    // Retorna o novo valor formatado
    if(mode) setInpData({...inpData, dataIn: formattedValue});
    else     setInpData({...inpData, dataFn: formattedValue});
  }
*/
  // Função que retorna o componente de acordo com o índice!
  function ButtonQuadra({quadra, index}){
    const [urlImg, setUrlImg] = React.useState(null);
    return (
      <button 
        key       = {index}
        className = {`btt-quadra ${sttPage.index_quadra === index ? "select-quadra" : "not-select-quadra"}`}
        onClick   = {() => {
          if(sttPage.index_quadra !== index && !loading){
            setSttPage({...sttPage, index_quadra: index});
            setSttMude(!mude);
          }
        }}
      > 
        <img
          src     = {urlImg === null ? `${quadra.urlFoto + quadra.foto}` : urlImg}
          alt     = {quadra.descricao}
          onError = {() => {setUrlImg(semFoto)}}
        />
      </button>
    )
  }

  /* Escuta a rolagem do scroll para carregar mais pedidos
  let flag1 = false;
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop     = arraySttRef.current.scrollTop;
      const scrollHeight  = arraySttRef.current.scrollHeight;
      const clientHeight  = arraySttRef.current.clientHeight;
      //console.log("AS");
      const pg = {
        ...pagina,
        scroll : scrollTop,
      }
      //SalveSttPage("pg-clients", pg);
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag1 && pagina.maxScroll > pagina.offSet) {
        flag1 = true;
        //console.log("Scroll", pagina);
        FiltroPedidos(false).then(() => {
          flag1 = false;
        });
      }
    }
    if(arraySttRef.current) arraySttRef.current.addEventListener("scroll", handleScroll);
    return () => {if(arraySttRef.current) arraySttRef.current.removeEventListener("scroll", handleScroll);}
  }, [arraySttRef.current, pagina.offSet, pagina.maxScroll, sttPage.dataInicial, sttPage.dataFinal, sttPage.status, sttPage.viewFixos]);
  //*/
  // Page de Filtrar Pedidos
  return (
    <div className = "contain-agenda-page">
      <div className = "contain-filtro-agenda-usuario">
        <BiArrowBack
          className = "icon-arrow-back"
          onClick={() => ReturnSttPage()}
          size={30}
        />
        <h2 className = "title-me-agenda"> Buscar Agendamentos </h2>
        <div className = "contain-filtro-quadras-hf">
          <div className="contain-filtro-quadras">
            <div className = "contain-title">
              <h2 className = "title-quadras">{`Quadra${date.empresa.quadras.length > 1 ? "s" : ""}`}</h2>
              <h3 className = "text-quadra-select"> {sttPage.index_quadra === null ? "Todas" : date.empresa.quadras[sttPage.index_quadra].descricao} </h3>
            </div>
            <div className = "contain-list-quadras">
              {
                date.empresa.quadras.length > 1 &&
                <button 
                  className = {`btt-quadra ${sttPage.index_quadra === null ? "select-quadra" : "not-select-quadra"}`}
                  onClick   = {() => {
                    if(sttPage.index_quadra !== null && !loading){
                      setSttMude(!mude);
                      setSttPage({...sttPage, index_quadra: null});
                    }
                  }}
                > 
                  Todas
                </button>
              }{
                date.empresa.quadras.map((quadra, index) => {
                  return (
                    <ButtonQuadra
                      key       = {index}
                      quadra    = {quadra}
                      index     = {index}
                    />
                  )
                })
              }
            </div>
          </div>
          <div className = "contain-filtra-hora-fixa">
            <h3>Tipo de Agendamento</h3>
            <h4>{sttPage.viewFixos[0]+sttPage.viewFixos.slice(1).toLowerCase()}</h4>
            <div className = "grade-filtra-tipo-agend">
              <button
                className = {sttPage.viewFixos === "TODOS" ? "btt-filtra-tipo-agend-select" : "btt-filtra-tipo-agend"}
                style     = {{borderRadius: "7px 0px 0px 7px"}}
                onClick   = {() => {
                  if(sttPage.viewFixos !== "TODOS" && !loading){
                    setSttMude(!mude);
                    setSttPage({...sttPage, viewFixos: "TODOS"});
                  }
                }}
              >
                <BiCalendar
                  size = {27}
                />
                <h2>Todos</h2>
              </button>
              <button
                className = {sttPage.viewFixos === "AVULSO" ? "btt-filtra-tipo-agend-select" : "btt-filtra-tipo-agend"}
                onClick   = {() => {
                  if(sttPage.viewFixos !== "AVULSO" && !loading){
                    setSttMude(!mude);
                    setSttPage({...sttPage, viewFixos: "AVULSO"});
                  }
                }}
              >
                <BiCalendarEvent
                  size = {27}
                />
                <h2>Avulso</h2>
              </button>
              <button
                className = {sttPage.viewFixos === "FIXO" ? "btt-filtra-tipo-agend-select" : "btt-filtra-tipo-agend"}
                style     = {{borderRadius: "0px 7px 7px 0px"}}
                onClick   = {() => {
                  if(sttPage.viewFixos !== "FIXO" && !loading){
                    setSttMude(!mude);
                    setSttPage({...sttPage, viewFixos: "FIXO"});
                  }
                }}
              >
                <CalArrow
                  className = "icon-fixo-hf"
                />
                <h2>
                  Fixo
                </h2>
              </button>
            </div>
          </div>
        </div>
        <div className = "contain-filtro-status-periodo">
          <div className = "contain-filtro-status">
            <h2 className = "title-quadras"> Status </h2>
            <h3 className = "text-quadra-select"> 
              {ReturnStatus(sttPage.status)} 
            </h3>
            <div className = "contain-btts-list-pedidos">
              {
                modPedidos.map((item, index) => {
                  return(
                    <BttIcone
                      key     = {index}
                      text    = {item.text}
                      idx     = {index}
                    />
                  );
                })
              }
            </div>
          </div>
          <CalendarFiltro
            tela            = {true}
            dataInicial     = {sttPage.dataInicial}
            dataFinal       = {sttPage.dataFinal}
            onClickFiltrar  = {(dataIn, dataFn) => {
              if(!loading) FiltrarPorData(dataIn, dataFn);
            }}
          />
        </div>
      </div>
      {
        loading ? 
        <div className = "contain-loading">
          <Loading
            mode  = {true}
            text  = {"Buscando Agendamentos..."}         
          /> 
        </div>
        :
        <div className = "contain-list-pedidos">
          <div className = "contain-resumo-values">
            <h3 className = "title-label-values">
              Resumo do Período
            </h3>
            <div className = "contain-resumo-values-label">
              <div 
                className = "contain-resumo-labels"
                style     = {{marginRight: "15px"}}
              >
                <h4 className = "text-label-values">
                  Total de Agendamentos:
                </h4>
                { (sttPage.status === null  || sttPage.status === 4) &&
                  <h4 className = "text-label-values">
                    Agendamentos Realizados:
                  </h4>
                }
                {
                  (sttPage.status === null  || sttPage.status === 3) &&
                  <h4 className = "text-label-values">
                    Agendamentos Cancelados:
                  </h4>
                }
                { (sttPage.status === null  || sttPage.status === 1 || sttPage.status === 2) &&
                  <h4 className = "text-label-values">
                    Agendamentos Previstos:
                  </h4>  
                }
              </div>
              <div className = "contain-resumo-labels">
                <h4 className = "text-resumo-values">
                  {`${arrayState.length}`}
                </h4>
                { (sttPage.status === null  || sttPage.status === 4) &&
                  <h4 className = "text-resumo-values" style = {{color: "#02BEBE"}}>
                    {`R$${sttPage.realizados.toFixed(2).toString().replace(".", ",")}`}
                  </h4>
                }
                { 
                  (sttPage.status === null  || sttPage.status === 3) &&
                  <h4 className = "text-resumo-values">
                    {`R$${sttPage.cancelados.toFixed(2).toString().replace(".", ",")}`}  
                  </h4>
                }
                {
                  (sttPage.status === null  || sttPage.status === 1 || sttPage.status === 2) &&
                  <h4 className = "text-resumo-values">
                    {`R$${sttPage.previsto.toFixed(2).toString().replace(".", ",")}`}
                  </h4>
                }
              </div>
            </div>
          </div>
          <div className = "contain-list-pedidos-flat">
            <div 
              ref       = {arraySttRef}
              className = "contain-flat-list-pedidos"
            >
              {
                arrayState.length > 0 ? 
                arrayState.map((pedido, index) => {
                  return (
                    <>
                      <CompPedido
                        key       = {index}
                        pedido    = {pedido}
                        index     = {index}
                      />
                      {
                        (loadingCp && index === arrayState.length - 1) &&
                        <div className = "contain-loading">
                          <Loading
                            mode  = {false}
                          />
                        </div>
                      }
                    </>
                  );
                })
                  :
                <div className = "contain-aviso-not-diarias">
                  <img
                    className = "img-list-vazia"
                    src       = {notAgend}
                  />
                  <h2 className = "text-rotulo-gray">
                    Você não possui possui agendamentos neste período.
                  </h2>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
  
  // Botão de status
  // Função que retorna o componente de acordo com o índice!
  function BttIcone({text, idx}){
    switch(idx){
      case 0 : // Todos
        return (
          <button
            className = {sttPage.status === null ? "btt-list-pedidos-sel" : "btt-list-pedidos"}
            style     = {{borderRadius: "7px 0px 0px 7px"}}
            onClick   = {() => {
              if(!loading && sttPage.status !== null){
                setSttPage({...sttPage, status: null});
                setSttMude(!mude);
              }
            }}
          >
            <BiCalendar
              size = {27}
            />
            <h2 className = "text-btt-list-pedidos">{text}</h2>
          </button>    
        );

      case 1 : // Solicitados
        return (
          <button
            className = {sttPage.status === idx ? "btt-list-pedidos-sel btt-sel-solicitado" : "btt-list-pedidos"}
            onClick   = {() => {
              if(!loading && sttPage.status !== idx){
                setSttPage({...sttPage, status: idx});
                setSttMude(!mude);
              }
            }}
          >
            <CalClock
              className = "color-pedido-solicitado"
              style     = {{width: "27px", height: "27px"}}
            />
            <h2 className = "text-btt-list-pedidos color-pedido-solicitado">{text}</h2>
          </button>
        );

      case 2 : // Agendados
        return (
          <button
            className = {sttPage.status === idx ? "btt-list-pedidos-sel btt-sel-agendado" : "btt-list-pedidos"}
            onClick   = {() => {
              if(!loading && sttPage.status !== idx){
                setSttPage({...sttPage, status: idx});
                setSttMude(!mude);
              }
            }}
          >
            <BiCalendarHeart
              className = "color-pedido-agendado"
              size      = {27}
            />
            <h2 className = "text-btt-list-pedidos color-pedido-agendado">{text}</h2>
          </button>
        );

      case 3 : // Cancelados
        return (
          <button
            className = {sttPage.status === idx ? "btt-list-pedidos-sel btt-sel-cancelado" : "btt-list-pedidos"}
            onClick   = {() => {
              if(!loading && sttPage.status !== idx){ 
                setSttPage({...sttPage, status: idx})
                setSttMude(!mude);
              }
            }}
          >
            <BiCalendarX
              className = "color-pedido-cancelado"
              size = {27}
            />
            <h2 className = "text-btt-list-pedidos color-pedido-cancelado">{text}</h2>
          </button>
        );

      case 4 : // Realizados
        return (
          <button
            className = {sttPage.status === idx ? "btt-list-pedidos-sel btt-sel-realizado" : "btt-list-pedidos"}
            style     = {{borderRadius: "0px 7px 7px 0px"}}
            onClick   = {() => {
              if(!loading && sttPage.status !== idx){
                setSttPage({...sttPage, status: idx})
                setSttMude(!mude);
              } 
            }}
          >
            <BiCalendarCheck
              className = "color-pedido-realizado"
              size = {27}
            />
            <h2 className = "text-btt-list-pedidos color-pedido-realizado">{text}</h2>
          </button>
        );

      default:
        return null;     
    }
  }
  // Função que retorna o status (string) de acordo com o índice do botão!
  function ReturnStatus(status){
    if(status === null)   return "Todos";
    else if(status === 1) return "Solicitados";
    else if(status === 2) return "Agendados";
    else if(status === 3) return "Cancelados";
    else if(status === 4) return "Realizados";
  }
  // Função que retorna o período selecionado no calendário
  
  // Função que retorna o status (string) de acordo com o índice do botão, para api!
  function ReturnStatusSelct(val){
    switch(val){
      case 0 : return null;
      case 1 : return "SOLICITADO";
      case 2 : return "AGENDADO";
      case 3 : return "CANCELADO";
      case 4 : return "REALIZADO";
      default: return null;
    }
  }
}
