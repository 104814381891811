import React                                  from "react";
import { FaArrowTrendUp, FaArrowTrendDown }   from "react-icons/fa6";
import { BiLeftArrowAlt }                     from "react-icons/bi";
import { Alerts, ErroComunic, Loading }       from "../../Basics";
import { MdOutlineManageSearch }              from "react-icons/md";
import { 
  CalendarFiltro, GraficoPizza, CompRegistro, GraficoBarras 
} from "../../Complext";
import { ModalReceita }                       from "../../Modais";
import { EmpresaAPI }                         from "../../../servicesAPI";
import { LocalStorage, Services }             from "../../../services";
import { AuthContext }                        from "../../../contexts/AuthContext";
import { BsFilter }                           from "react-icons/bs";
import { notAgend }                           from "../../../assets";
import RouterHook                             from "../../../contexts/RouterHook";

import "./stylesFX.css";
import moment from "moment";

export default function FluxoCaixa() {

  const serverRouter    = RouterHook();
  const { date, token, modalOpen, handleMOP } = React.useContext(AuthContext);
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : null,
    scroll      : 0,
  }
  const clientRef                 = React.useRef(null);

  const sttBuffer = LocalStorage.get("sttPageFluxoCaixa");
  //console.log("sttBuffer: ", sttBuffer);
  const [pgClients, setPgClients] = React.useState(sttBuffer ? sttBuffer.pagina : pagBuffer);
  // Controla os estados da página
  const [sttPage, setSttPage]     = React.useState(//sttBuffer ? sttBuffer.sttPage : {
  {
    title             : "Fluxo de Caixa",
    titleDets         : "Detalhes completo no período:",
    filtro            : "Completo",                   // "categorias": "null = Completo", "RECEITA_MANUAL, DESPESA_MANUAL, FATURA, AGENDAMENTO, DIARIA",
    dataInicial       : "2023-12-01",//Services.ReturnData("init-periodo"),  // Formato YYYY-MM-DD
    dataFinal         : "2023-12-12",//Services.ReturnData("end-periodo"),   // Formato YYYY-MM-DD
    erroComunic       : false,                        // true = erro de comunicação
    viewDet           : "details-receit",             // details-receit, details-despesa
    modal             : false,
    addReceita        : "",                           // "receita", "despesa"
  });
  const [mude, setMude]           = React.useState(false);
  //console.log("sttPage: ", sttPage);
  const [arrayReg, setArrayReg]   = React.useState([]);
  const [sttCalc, setSttCalc]     = React.useState(null);
  const bttSelect = [
    "Completo",
    "Receitas",
    "Despesas",
    "Faturas",
    //"Agendamentos",
    //"Diárias",
  ];
  //console.log("Calculko: ", sttCalc);
  const [load, setLoad] = React.useState({
    page      : true,
    grafic    : false,
    comp      : false,
    arrayReg  : false,
  });
  // Salva Estado da página
  function SaveSttPage(scroll){
    const buffer = {
      sttPage,
      pagina     : {
        offSet      : pgClients.offSet,
        limit       : pgClients.limit,
        maxScroll   : pgClients.maxScroll,
        scroll      : scroll,
      }
    }
    LocalStorage.set("sttPageFluxoCaixa", buffer);
  }
  React.useEffect(() => {
    SaveSttPage();
  }, [pgClients, sttPage]);
  
  // Controla retorno nos states
  function ReturnSttPage(){   
    if(modalOpen){
      handleMOP(false);
    } else {
      serverRouter("back");
    }
  }
  //console.log("Empresa", date.empresa);
  function RetornaFiltroSel(type){
    switch(type){
      case "Completo"     : return "RECEITA_MANUAL,DESPESA_MANUAL,FATURA,AGENDAMENTO,DIARIA";
      case "Receitas"     : return "RECEITA_MANUAL,AGENDAMENTO,DIARIA";
      case "Despesas"     : return "DESPESA_MANUAL,FATURA";
      case "Faturas"      : return "FATURA";
      case "Agendamentos" : return "AGENDAMENTO";
      case "Diárias"      : return "DIARIA";
      default             : return null;
    } 
  }
  
  async function BuscarFluxoDeCaixa(){
    setLoad({...load, arrayReg: true, grafic: true});
    const req = await EmpresaAPI.FiltrarFluxoDeCaixa(
      token, 
      RetornaFiltroSel(sttPage.filtro), 
      sttPage.dataInicial,
      sttPage.dataFinal,
      date.empresa.idempresa, 
      sttPage.filtro === "Completo" ? pgClients.limit: null, 
      sttPage.filtro === "Completo" ? 0 : null,
      sttPage.filtro === "Completo" ? true : null
    );
    //console.log("req: Aqui ", req);
    if(req.status){
      const req1 = await EmpresaAPI.CalcularFluxoDeCaixa(
        token, 
        RetornaFiltroSel(sttPage.filtro),
        date.empresa.idempresa,
        sttPage.dataInicial, 
        sttPage.dataFinal
      );
      if(req1.status){
        if(sttPage.filtro === "Completo"){

          setSttCalc(req1.req);
          setPgClients({
            ...pgClients,
            maxScroll: req.req.pagamento.count,
            offSet   : req.req.pagamento.rows.length,
          })
          setArrayReg(req.req.pagamento.rows);
        } else {
          //console.log("req: ", req);
          //console.log("req1: ", req1);
          setSttCalc(req1.req);
          setPgClients({
            ...pgClients,
            maxScroll: req.req.length,
            offSet   : req.req.length,
          })
          setArrayReg(req.req);
        }
      } else {
        setSttPage({
          ...sttPage,
          erroComunic: true,
        });
      }      
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: true,
      });
    }
    //setLoad({...load, page: false, arrayReg: false, grafic: false});
  }

  async function BuscarFluxoDeCaixaPaginado(){
    setLoad({...load, comp: true});
    const req = await EmpresaAPI.FiltrarFluxoDeCaixa(
      token, 
      RetornaFiltroSel(sttPage.filtro), 
      sttPage.dataInicial,
      sttPage.dataFinal,
      date.empresa.idempresa, 
      pgClients.limit, 
      pgClients.offSet, 
      false
    );
    //console.log("req: Aqui ", req);
    if(req.status){  
      setPgClients({
        ...pgClients,
        offSet   : pgClients.offSet + req.req.pagamento.rows.length,
      })
      setArrayReg([...arrayReg, ...req.req.pagamento.rows]);
      
    } 
    setLoad({...load, comp: false});     
  }

  async function AdicionarReceita(obs, valor, data){
    //console.log("AdicionarReceita: ", obs, valor, data);
    //console.log("Receita");
    const req = await EmpresaAPI.AdicionarPagamentoFluxoCaixa(token, data, obs, valor, "total", "RECEITA_MANUAL", date.empresa.idempresa, date.gestor.nome);
    //console.log("req: ", req);
    if(req.status){
      Alerts.sucesso("Sucesso", "Receita adicionada com sucesso!");
      setSttPage({...sttPage, modal: false, addReceita: ""});
      setMude(!mude);
    } else {
      Alerts.aviso("Aviso", "Erro ao adicionar receita, tente novamente mais tarde!");
    }
  }

  async function AdicionarDespesa(obs, valor, data){
    const req = await EmpresaAPI.AdicionarPagamentoFluxoCaixa(token, data, obs, valor, "total", "DESPESA_MANUAL", date.empresa.idempresa, date.gestor.nome);
    //console.log("req: ", req);
    if(req.status){
      Alerts.sucesso("Sucesso", "Receita adicionada com sucesso!");
      setSttPage({...sttPage, modal: false, addReceita: ""});
      setMude(!mude);
    } else {
      Alerts.aviso("Aviso", "Erro ao adicionar receita, tente novamente mais tarde!");
    }
  }

  async function RemoverRegistro(registro){
    //console.log("Registro: ", registro);

    const req = await EmpresaAPI.DeletarPagamentoFluxoCaixa( token, registro.idpagamento );
    //console.log("Resposta de Deletar Pagamento: ", req);
    if(req.status){
      Alerts.sucesso("Sucesso", "Registro removido com sucesso!");
      setMude(!mude);
    } else {
      Alerts.aviso("Aviso", "Erro ao remover registro, tente novamente mais tarde!");
    }
  }

  React.useEffect(() => {
    document.title = "Fluxo de Caixa | Gestor Agendei";
   
  }, []);

  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    BuscarFluxoDeCaixa().then(() => {
      setLoad({...load, page: false, arrayReg: false, grafic: false});
    });
    
  }, [sttPage.filtro, mude]);

  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    if(!modalOpen && sttPage.modal){
      setSttPage({...sttPage, modal: false});
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
  }, [modalOpen]);
  React.useEffect(() => {
    if(sttPage.modal && !modalOpen){
      handleMOP(true);
    } else if(!sttPage.modal && modalOpen){
      handleMOP(false);
    }
  }, [sttPage.modal]);
  function AlterarPeriodo(dataIn, dataFn){
    //console.log("AlterarPeriodo: ", dataIn, dataFn);
    // Monta string DD/MM/YY para YYYY-MM-DD
    const dataInit = Services.ConverteData(true, dataIn);
    const dataFim = Services.ConverteData(true, dataFn);
    //console.log("dataInit: ", dataInit);
    //console.log("dataFim: ", dataFim);
    // Verifica se as datas são iguais a data inicial e final do state
    if(dataInit !== sttPage.dataInicial || dataFim !== sttPage.dataFinal){
      setSttPage({...sttPage, dataInicial: dataInit, dataFinal: dataFim});
      setMude(!mude);
    } else {
      Alerts.aviso("Aviso", "Altere o período para realizar a busca");
    }
  }

  // Controla o scroll da lista de clientes (Paginação)
  let flag1 = false;
  React.useEffect(() => {
    function handleScrollA() {
      const scrollTop     = clientRef.current.scrollTop;
      const scrollHeight  = clientRef.current.scrollHeight;
      const clientHeight  = clientRef.current.clientHeight;
      // SalvaScroll
      SaveSttPage(scrollTop);
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag1 && pgClients.maxScroll > pgClients.offSet) {
        flag1 = true;
        //console.log("Scroll ativado", pgClients);
        BuscarFluxoDeCaixaPaginado().then(() => {
          flag1 = false;
        });
      }
    }
    if(clientRef.current) clientRef.current.addEventListener("scroll", handleScrollA);
    return () => {if(clientRef.current) clientRef.current.removeEventListener("scroll", handleScrollA);}
  }, [sttPage, pgClients]);
  // Controla o scroll do arrayState (Paginação)*/

  React.useEffect(() => {
    if(sttPage.filtro === "Completo"){
      setSttPage({...sttPage, titleDets: "Detalhes completo no período:"});
    } else if(sttPage.filtro === "Receitas"){
      setSttPage({...sttPage, titleDets: "Detalhes de receitas no período:"});
    } else if(sttPage.filtro === "Despesas"){
      setSttPage({...sttPage, titleDets: "Detalhes de despesas no período:"});
    } else if(sttPage.filtro === "Faturas"){
      setSttPage({...sttPage, titleDets: "Detalhes de faturas no período:"});
    } else if(sttPage.filtro === "Agendamentos"){
      setSttPage({...sttPage, titleDets: "Detalhes de agendamentos no período:"});
    } else {
      setSttPage({...sttPage, titleDets: "Detalhes de diárias no período:"});
    }
  }, [sttPage.filtro]);

  return (
      <>
        { sttPage.modal &&
          <ModalReceita
            modal     = {sttPage.modal}
            mode      = {sttPage.addReceita}
            ClosedModal = {() => {
              setSttPage({...sttPage, modal: false, addReceita: ""});
            }}
            onClick   = {async (observacao, valor, data) => {
              if(sttPage.addReceita === "receita"){ // Adicionar receita
                await AdicionarReceita(observacao, valor, data);
              } else {  // Adicionar despesa
                await AdicionarDespesa(observacao, valor, data);
              }
            }}
          />
        }
        <div className = "contain-agenda-page">
          <div className = "contain-filtro-agenda-hf">
            <BiLeftArrowAlt
              className = "icon-arrow-back-hf"
              onClick={() => {
                ReturnSttPage();
              }}
            />
            <h2 className = "title-me-agenda"> {sttPage.title} </h2>
            { load.page ? 
              <div className = "contain-loading" style = {{height: "300px"}}>
                <Loading 
                  mode  = {true}
                  text  = { "Buscando receita..." }  
                />
              </div>
                :
              sttPage.erroComunic ?
              <ErroComunic
                onClick = {() => {
                  setSttPage({
                    ...sttPage,
                    erroComunic: false,
                  });
                }}
              />
                :
              <div className = "contain-view-hora-fixa" style   = {{flexDirection: "row"}}>
                <div className = "contain-list-clientes" style  = {{minWidth: "410px", height: "730px"}}>
                  
                  {
                    <div 
                      className = "contain-filtro-periodo"
                      style     = {{width: "100%"}}
                    >
                      <h3 className = "title-quadras" style = {{width: "100%", textAlign: "left"}}>
                        Filtro
                      </h3>
                      <div 
                        className = "contain-comp-input-forms-cif"
                        style     = {{width: "100%"}}
                      >
                        <BsFilter
                          size      = {25}
                          color     = "#DA5A34"
                        />
                        <select
                          value       = {sttPage.filtro}
                          className   = "contain-select-personalized"
                          style       = {{color: "#302d2a"}}
                          placeholder = "Selecione uma opção"
                          onChange    = {(e) => {
                            //console.log("e.target.value: ", e.target.value);
                            setSttPage({...sttPage, filtro: e.target.value});
                          }}
                        >
                          {
                            bttSelect.map((item, index) => (
                              <option value = {item}>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  }
                    <CalendarFiltro
                      tela            = {false}
                      dataInicial     = {sttPage.dataInicial}
                      dataFinal       = {sttPage.dataFinal}
                      onClickFiltrar  = {(dataIn, dataFn) => {
                        AlterarPeriodo(dataIn, dataFn);
                      }}
                    />
                  <div className = "line-border-bottom" style = {{margin: "10px 0px"}}/>
                  {
                    load.arrayReg ?
                    <div className = "contain-loading">
                      <Loading mode = {true} text={"Buscando Registros..."}/>
                    </div> :
                    <>
                      { pgClients.maxScroll > 0 &&
                        <h3 className = "title-list-clientes"> 
                          {`${pgClients.maxScroll} Registro${pgClients.maxScroll > 1 ? "s" : ""}`} 
                        </h3>
                      }
                      <div
                        ref       = {clientRef} 
                        className = "flat-list-creg">
                        { arrayReg.length > 0 ?
                          arrayReg.map((registro, index) => {
                            return (
                              <>
                                <CompRegistro
                                  key       = {index}
                                  index     = {index}
                                  registro  = {registro}
                                  onClick   = {async () => {
                                    await RemoverRegistro(registro);
                                  }}
                                />
                                { ( index === arrayReg.length - 1 && load.comp ) &&
                                  <Loading mode = {false} />
                                }
                              </>
                            )
                          })
                            :
                          <div className = "contain-not-agend">
                            <img
                              title = "Sem Registros"
                              src   = {notAgend}
                              alt   = "Not Agend"
                            />
                            <h2>
                              Sem registros para este período
                            </h2>
                          </div>
                        }
                      </div>
                    </>
                  }
                </div>
                {
                  <div className = "contain-view-details-creg" >
                    <div className = "contain-clientes-internal-as" style = {{maxWidth: "780px", width: "100%"}}>
                      
                      <h3 className = "text-title-array-stt" style = {{margin: "0px"}}>
                        {sttPage.titleDets}
                      </h3>
                      <h3 className = "title-periodo-creg">
                        {`${Services.DiminuiDate(Services.ConverteData(false, sttPage.dataInicial))}`} até {`${Services.DiminuiDate(Services.ConverteData(false, sttPage.dataFinal))}`}
                      </h3>
                      <div className = "line-border-bottom"/>
                      <div className = "contain-detalhamento-receitas-creg">
                        {
                          load.grafic ? 
                          <div className = "contain-loading" style = {{height: "300px"}}>
                            <Loading mode = {true} text = {"Carregando gráficos..."}/>
                          </div> :
                          <>
                            { sttPage.filtro === "Completo" ?
                              <>
                                <div className = "contain-handle-graphics">
                                  <button
                                    className = {sttPage.viewDet === "details-receit" ? "btt-select-graphics-select" : "btt-select-graphics"}
                                    style     = {
                                      sttPage.viewDet === "details-receit" ? 
                                      {color: "var(--color-text-hig)", borderRadius: "5px 0px 0px 0px"} : 
                                      {}
                                    }
                                    onClick   = {() => {
                                      if(sttPage.viewDet !== "details-receit"){
                                        setSttPage({...sttPage, viewDet: "details-receit"});
                                      }
                                    }}
                                  >
                                    Detalhes de Receitas
                                  </button>
                                  <button
                                    className = {sttPage.viewDet === "formas-pagamento" ? "btt-select-graphics-select" : "btt-select-graphics"}
                                    style     = {
                                      sttPage.viewDet === "formas-pagamento" ?
                                      {color: "var(--color-text-hig)", borderRadius: "0px 5px 0px 0px"} :
                                      {}
                                    }
                                    onClick   = {() => {
                                      if(sttPage.viewDet !== "formas-pagamento"){
                                        setSttPage({...sttPage, viewDet: "formas-pagamento"});
                                      }
                                    }}
                                  >
                                    Formas de Pagamento
                                  </button>
                                </div>
                                <div className = "contain-comp-details-creg">      
                                  {
                                    sttPage.viewDet === "details-receit"?
                                    <>
                                      <div className="contain-line-details-creg">
                                        <h2>Agendamentos</h2>
                                        <h3>
                                          {`R$ ${parseFloat(sttCalc.somaTotalAgendamentos).toLocaleString('pt-BR', { 
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className="contain-line-details-creg">
                                        <h2>Pagamentos Manuais</h2>
                                        <h3>
                                          {`R$ ${parseFloat(sttCalc.somaTotalReceitasManuais).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>

                                      { date.empresa.licenca.moduloDiaria ?
                                        <div className = "contain-line-details-creg" style = {{marginBottom: "4px"}}>
                                          <h2>
                                            Diárias
                                          </h2>
                                          <h3>
                                            {`R$ ${parseFloat(sttCalc.somaTotalDiarias).toLocaleString('pt-BR', {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}`}
                                          </h3>
                                        </div> : <></>
                                      }
                                      <div className = "line-border-bottom" style = {{backgroundColor: "var(--color-fundo-light)"}}/>
                                      <div className = "contain-line-details-creg">
                                        <h2>
                                          Total de receitas
                                        </h2>
                                        <h3>
                                          {`R$${parseFloat(sttCalc.somaTotalReceitas).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className = "contain-grafico-creg">
                                        { sttCalc.somaTotalAgendamentos+sttCalc.somaTotalReceitasManuais+sttCalc.somaTotalDiarias > 0 ?
                                          <div className = "comp-graphic">
                                            <GraficoPizza
                                              data={Services.ReturnDataGraphic("pizza-receit", sttCalc, arrayReg, sttPage.dataInicial, sttPage.dataFinal)}
                                            />
                                          </div>
                                            :
                                          <div className = "contain-not-agend">
                                            <MdOutlineManageSearch
                                              size  = {100}
                                              color = {"var(--color-text-low)"}
                                            />
                                            <h2>
                                              Nenhum registro entre as datas selecionadas
                                            </h2>
                                          </div>
                                        }
                                      </div>
                                    </> : 
                                    sttPage.viewDet === "formas-pagamento" ?
                                    <>
                                      <div className = "contain-line-details-creg">
                                        <h2>
                                          Pagamentos por PIX
                                        </h2>
                                        <h3>
                                          {`R$${parseFloat(sttCalc.somaTotalPix).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className = "contain-line-details-creg">
                                        <h2>
                                          Pagamentos por cartão de crédito
                                        </h2>
                                        <h3>
                                          {`R$${parseFloat(sttCalc.somaTotalCartaoCredito).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className = "contain-line-details-creg" style = {{marginBottom: "4px"}}>
                                        <h2>
                                          Pagamentos no local
                                        </h2>
                                        <h3>
                                          {`R$${parseFloat(sttCalc.somaTotalLocal).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className = "line-border-bottom" style = {{backgroundColor: "var(--color-fundo-light)"}}/>
                                      <div className = "contain-line-details-creg">
                                        <h2>
                                          Total de pagamentos
                                        </h2>
                                        <h3>
                                          {`R$${parseFloat(sttCalc.somaTotalPix+sttCalc.somaTotalCartaoCredito+sttCalc.somaTotalLocal).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}`}
                                        </h3>
                                      </div>
                                      <div className = "contain-grafico-creg">
                                        { sttCalc.somaTotalPix+sttCalc.somaTotalCartaoCredito+sttCalc.somaTotalLocal > 0 ?
                                          <GraficoPizza
                                            data = {Services.ReturnDataGraphic("pizza-pays", sttCalc, arrayReg, sttPage.dataInicial, sttPage.dataFinal)}
                                          /> : 
                                          <div className = "contain-not-agend">
                                            <MdOutlineManageSearch
                                              size  = {100}
                                              color = {"var(--color-text-low)"}
                                            />
                                            <h2>
                                              Nenhum registro entre as datas selecionadas
                                            </h2>
                                          </div>
                                        }
                                      </div>
                                    </>
                                    : 
                                    <></>
                                  }
                                </div>
                                <div className = "line-border-bottom" style = {{margin: "10px 0px"}}/>
                                <div className = "contain-details-creg">
                                  <div className = "contain-line-details-creg" style = {{borderRadius: "7px"}}>
                                    <h2>
                                      Receitas
                                    </h2>
                                    <h3>
                                      {`R$${parseFloat(sttCalc.somaTotalReceitas).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}`}
                                    </h3>
                                  </div>
                                  <div className = "contain-line-details-creg" style = {{borderRadius: "7px"}}>
                                    <h2>
                                      Despesas
                                    </h2>
                                    <h3 style = {{color: "#F05545"}}>
                                      {`R$${parseFloat(sttCalc.somaTotalDespesas).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}`}
                                    </h3>
                                  </div>
                                  <div className = "line-border-bottom" style = {{backgroundColor: "var(--color-fundo-light)"}}/>
                                  <div className = "contain-line-details-creg" style = {{borderRadius: "7px"}}>
                                    <h2>
                                      Total
                                    </h2>
                                    <h3 style={sttCalc.somaTotalReceitas - sttCalc.somaTotalDespesas > 0 ? {} : {color:"#F05545"}}>
                                      {`R$ ${parseFloat(sttCalc.somaTotalReceitas - sttCalc.somaTotalDespesas).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}`}
                                    </h3>
                                  </div>
                                </div>
                                <div className = "contain-btts-creg">
                                  <button 
                                    className = "btt-add-receita-creg"
                                    onClick   = {() => {
                                      setSttPage({...sttPage, modal: true, addReceita: "receita"});
                                    }}
                                  >
                                    Adicionar Receita
                                    <FaArrowTrendUp
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                  <button 
                                    className = "btt-remov-receita-creg"
                                    onClick   = {() => {
                                      //console.log("Despesa");
                                      setSttPage({...sttPage, modal: true, addReceita: "despesa"});
                                    }}
                                  >
                                    Adicionar Despesa
                                    <FaArrowTrendDown
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                </div>
                              </> :
                              (sttPage.filtro === "Receitas" || sttPage.filtro === "Despesas") ?
                              <>
                                <div className = "contain-comp-details-creg" style = {{borderRadius: "var(--border-radius-low)"}}>
                                  { sttPage.filtro === "Receitas" ?
                                    <div className = "contain-line-details-creg">
                                      <h2>
                                        Total de receitas
                                      </h2>
                                      <h3>
                                        {`R$${parseFloat(sttCalc.somaTotalReceitas).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}`}
                                      </h3>
                                    </div> :
                                    <div className = "contain-line-details-creg">
                                      <h2>
                                        Total de despesas
                                      </h2>
                                      <h3 style = {{color: "#F05545"}}>
                                        {`R$${parseFloat(sttCalc.somaTotalDespesas).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}`}
                                      </h3>
                                    </div>
                                  }
                                  <div className = "line-border-bottom" style = {{backgroundColor: "var(--color-fundo-light)"}}/>
                                  <div className = "contain-grafico-creg">
                                    { sttPage.filtro === "Receitas" ?
                                      sttCalc.somaTotalReceitas > 0 ?
                                      <GraficoBarras data = {Services.ReturnDataGraphic("line-receit", sttCalc, arrayReg, sttPage.dataInicial, sttPage.dataFinal)}/> :
                                      <div className = "contain-not-agend">
                                        <MdOutlineManageSearch
                                          size  = {100}
                                          color = {"var(--color-text-low)"}
                                        />
                                        <h2>
                                          Nenhum registro entre as datas selecionadas
                                        </h2>
                                      </div> :
                                      sttCalc.somaTotalDespesas > 0 ?
                                      <GraficoBarras data = {Services.ReturnDataGraphic("line-receit", sttCalc, arrayReg, sttPage.dataInicial, sttPage.dataFinal)}/> :
                                      <div className = "contain-not-agend">
                                        <MdOutlineManageSearch
                                          size  = {100}
                                          color = {"var(--color-text-low)"}
                                        />
                                        <h2>
                                          Nenhum registro entre as datas selecionadas
                                        </h2>
                                      </div>
                                    }  
                                  </div>
                                </div>
                                <div className = "line-border-bottom" style = {{margin: "10px 0px"}}/>
                                <div className = "contain-btts-creg">
                                  <button 
                                    className = "btt-add-receita-creg"
                                    onClick   = {() => {
                                      setSttPage({...sttPage, modal: true, addReceita: "receita"});
                                    }}
                                  >
                                    Adicionar Receita
                                    <FaArrowTrendUp
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                  <button 
                                    className = "btt-remov-receita-creg"
                                    onClick   = {() => {
                                      //console.log("Despesa");
                                      setSttPage({...sttPage, modal: true, addReceita: "despesa"});
                                    }}
                                  >
                                    Adicionar Despesa
                                    <FaArrowTrendDown
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                </div>
                              </> :
                              <>
                                <div className = "contain-comp-details-creg" style = {{borderRadius: "var(--border-radius-low)"}}>
                                  <div className = "contain-line-details-creg">
                                    <h2>
                                      Total de faturas
                                    </h2>
                                    <h3 style = {{color: "#F05545"}}>
                                      {`R$${parseFloat(sttCalc.somaTotalFaturas).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}`}
                                    </h3>
                                  </div>
                                  <div className = "line-border-bottom" style = {{backgroundColor: "var(--color-fundo-light)"}}/>
                                  <div className = "contain-grafico-creg">
                                    { sttCalc.somaTotalFaturas > 0 ?
                                      <GraficoBarras data = {Services.ReturnDataGraphic("line-faturas", sttCalc, arrayReg, sttPage.dataInicial, sttPage.dataFinal)}/> :
                                      <div className = "contain-not-agend">
                                        <MdOutlineManageSearch
                                          size  = {100}
                                          color = {"var(--color-text-low)"}
                                        />
                                        <h2>
                                          Nenhum registro entre as datas selecionadas
                                        </h2>
                                      </div>
                                    }  
                                  </div>
                                </div>
                                <div className = "line-border-bottom" style = {{margin: "10px 0px"}}/>
                                <div className = "contain-btts-creg">
                                  <button 
                                    className = "btt-add-receita-creg"
                                    onClick   = {() => {
                                      setSttPage({...sttPage, modal: true, addReceita: "receita"});
                                    }}
                                  >
                                    Adicionar Receita
                                    <FaArrowTrendUp
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                  <button 
                                    className = "btt-remov-receita-creg"
                                    onClick   = {() => {
                                      //console.log("Despesa");
                                      setSttPage({...sttPage, modal: true, addReceita: "despesa"});
                                    }}
                                  >
                                    Adicionar Despesa
                                    <FaArrowTrendDown
                                      size  = {20}
                                      color = {"white"}
                                      style = {{marginLeft: "10px"}}
                                    />
                                  </button>
                                </div>
                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </>
  );

  
  
}