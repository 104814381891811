import React                from "react";
import { BiCheck, BiX }     from "react-icons/bi";
import { HiArrowPath }      from "react-icons/hi2";
import RouterHook           from "../../../contexts/RouterHook";
import { 
  LocalStorage, Services 
} from "../../../services";      
import moment               from "moment";

import "./stylesCMS.css"

export default function CompMenSol({ mensalista, onClick, show, onClickA }) {

  const serverRouter          = RouterHook();
  
  //console.log("Mensalista: ", mensalista);
  function ReturnSolicitou() {
    const falta = moment(mensalista.createdAt).startOf("minute").fromNow().toLowerCase();
    return `Solicitou ${falta} por`;
  }
  function ReturnMetPay() {
    switch(mensalista.pedidos[0].formaPagamento){
      case "DINHEIRO" : 
        return "Dinheiro";
      case "PIX" : 
        return "Pix";
      case "MAQUINA_CARTAO" : 
        return "Máquina de Cartão";
      case "CARTAO_CREDITO" :
        return "Cartão de Crédito";
    }
  }
  //console.log("Mensalista: ", mensalista);
  function GoHoraFixaPG() {
    LocalStorage.set("hora-fixa-selected", mensalista.idmensalista);
    onClickA();
    serverRouter("hora-fixa");
  }
  if(show){
    return (
      <button 
        className = "btt-comp-mensalista-sol"
        onClick   = {() => GoHoraFixaPG()}
      > 
        <div className = 'contain-btt-mensalista-infos-sol left-mensalista-sol'>
          <h2 className = "title-comp-hora-fixa-sol">
            {`Horário Fixo ${(mensalista.tipoMensalista !== null) ? (mensalista.tipoMensalista === "Indeterminado" ? "Sem Fim" : mensalista.tipoMensalista) : ""}`}
          </h2>
          <div className = "left-mensalista-div-sol">
            <HiArrowPath
              style = {{minWidth: "20px", minHeight: "20px"}}
              color = {"#ff7043"}
            />
            <h3 className = "text-ds-comp-soli">
              {Services.ReturnDiaSemana(4, new Date(mensalista.dataPrimeiraReserva)).toUpperCase()}
            </h3>
          </div>
          { mensalista.pedidos.length > 0 ?
            <h3>
              {mensalista.pedidos && (`${Services.ReturnHoraMin(new Date(mensalista.pedidos[0].dataInicio))} às ${Services.ReturnHoraMin(new Date(mensalista.pedidos[0].dataFinal))}`)}
            </h3> : null
          }
          { (mensalista.tipoMensalista !== null && mensalista.tipoMensalista !== "Indeterminado") ?
            <p className = "text-solicitou-sol" style={{marginRight: "5px"}}>
              {`${Services.DataFormat("DD/MM/YY", mensalista.dataPrimeiraReserva)} até ${Services.DataFormat("DD/MM/YY", mensalista.dataUltimaReserva)}`}
            </p> : 
            <p className = "text-solicitou-sol" style={{marginRight: "5px"}}>
              {`Início em ${Services.DataFormat("DD/MM/YYYY", mensalista.dataPrimeiraReserva)}`}
            </p>
          }
          <h3 style = {{color: "#ff7043", fontFamily: "jost-mediun"}}>
            SOLICITADO
          </h3>
        </div>
        <div className = 'contain-btt-mensalista-infos-sol right-mensalista-sol'>
          <p className = "text-solicitou-sol">
            {ReturnSolicitou()}
          </p>
          <h2 className = "text-infos-negrito-sol-hig" >
            {mensalista.usuario.nome}
          </h2>
          { (mensalista.tipoMensalista !== "Indeterminado") &&
            (mensalista.valorDescontoCupom > 0 ?
            <h3>{`R$${(mensalista.valorTotal - mensalista.valorDescontoCupom).toFixed(2).replace('.', ',')}`}</h3>
              :
            <h3>{`R$${mensalista.valorTotal.toFixed(2).replace('.', ',')}`}</h3>)
          }
          { (mensalista.tipoMensalista !== "Indeterminado") &&
            <p className = "text-solicitou-sol" style = {{fontSize: "14px"}}>
              {mensalista.pedidos && `${mensalista.pedidos.length} AGENDAMENTO${mensalista.pedidos.length > 1 ? "S" : ""}`}
            </p>
          }
          <h4>{`${mensalista.quadra.descricao}`}</h4>
        </div>
        <div className = "contain-arrow-rigth-sol">
          <button
            className   = "btt-declined"
            onClick     = {(e) => {
              onClick("declined");
              // Evitar que o botão pai receba o click
              e.stopPropagation();
            }}
          >
            <BiX
              size  = {30}
              color = "white"
            />
          </button>
          <button
            className   = "btt-aceppt"
            onClick     = {(e) => {
              onClick("aceppt");
              // Evitar que o botão pai receba o click
              e.stopPropagation();
            }}
          >
            <BiCheck
              size  = {30}
              color = "white"
            />
          </button>
        </div>
      </button>
    );
  } else {
    return (
      <></>
    );
  }

}