import React                    from "react";
import { BiChevronRight, BiCircle }       from "react-icons/bi";
import { RiUserSettingsLine }   from "react-icons/ri";
import { AuthContext }          from "../../../contexts/AuthContext";

import "./stylesC.css";
import { Check, UserSet } from "../../../assets";

export default function Client({usuario, onClick, type, select}){
  const { date }    = React.useContext(AuthContext);
  const empresaNome = date.empresa.nome;
  //console.log('Select: ', select);
  if(type === "open"){
    return (
      <button 
        className = "btt-contain-client"
        onClick = {onClick}
      >
        <div className = "contain-infos-client">
          <h2>
            {usuario.nome}
          </h2>
          <label>
            {usuario.atleta.telefone}
          </label>
          <h3>
            {("Cliente " + empresaNome).toUpperCase()}
          </h3>
        </div>
        <BiChevronRight className = "icon-right-client"/>
      </button>
    );
  } else if(type === "select"){
    return (
      <button 
        className = {select ? "btt-contain-client-select" : "btt-contain-client"}
        onClick = {onClick}
      >
        <div className = "contain-infos-client">
          <h2>
            {usuario.nome}
          </h2>
          { usuario.atleta &&
            <label>
              {usuario.atleta.telefone}
            </label>
          }
          { (usuario.buffer || usuario.nome === usuario.usuario) ?
            <div className = "contain-buffer-client">
              <h3>
                {"CRIADO MANUALMENTE POR GESTOR"}
              </h3>  
              <UserSet
                style = {{marginLeft: "7px", color: "#A8A8A8", minWidth: "20px", minHeight: "20px"}}
              />
            </div>
              :
            <h3>
              {("Cliente " + empresaNome).toUpperCase()}
            </h3>
          }
        </div>
        { select ?
          <Check
            className = "icon-check-client"
          />
            :
          <BiCircle
            className = "icon-circle-client"
          />

        }
      </button>
    );
  }

}