import React                          from "react";

import { BiCalendar, BiSearchAlt }    from "react-icons/bi";
import Calendar                       from "react-calendar";
import { Services }                   from "../../../services";
import moment                         from "moment";

import "./stylesGCal.css";


export default function CalendarFiltro({dataInicial, dataFinal, onClickFiltrar, tela}){

  const calendarRef                         = React.useRef(null);
  const [showCalendar, setShowCalendar]     = React.useState(false);
  const [inpData, setInpData]               = React.useState({
    dataIn    : Services.ConverteData(false, dataInicial),
    dataFn    : Services.ConverteData(false, dataFinal),
  });

  function ReturnPeriodoCalendar() {
    if (inpData.dataIn !== null && inpData.dataFn !== null) {
      const createDateFromString = (dateStr) => {
       //console.loglog("ReturnPeriodoCalendar", dateStr);
        const [day, month, year] = dateStr.split("/");
        return new Date(year, month - 1, day);
      };
      const array = [createDateFromString(inpData.dataIn), createDateFromString(inpData.dataFn)];
     //console.loglog("ReturnPeriodoCalendar", array);
      return array;
    } else {
      return null;
    }
  }

  function handlePeriodo(e) {
    //Monta string DD/MM/YY a partir de um obj Date
    function MontaStringData(date){
      // adiciona o 0 caso o dia ou mês seja menor que 10
      const day   = date.getDate() < 10       ? `0${date.getDate()}`      : date.getDate();
      const month = date.getMonth() + 1 < 10  ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const year  = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    }
    const dataInicial = MontaStringData(e[0]);
    const dataFinal   = MontaStringData(e[1]);
   //console.loglog("handlePeriodo", dataInicial, dataFinal);
    
    setInpData({...inpData, dataIn: dataInicial, dataFn: dataFinal});
    //setShowCalendar(false); 
  }

  return (
    <div 
      className = "contain-filtro-periodo"
      style     = {!tela ? {width: "100%"} : {width: "auto"}} // 100% para tela da agenda, auto para tela de fluxo de caixa
    >
      <h2 className = "title-quadras"> 
        Período
      </h2>
      <h3 
        className = "text-quadra-select"
      >
        {`${moment(dataInicial).format("DD/MM/YY")} até ${moment(dataFinal).format("DD/MM/YY")}`}
      </h3>
      <div 
        className = "contain-select-periodo"
      >
        <div 
          className = "btt-select-data"
          style     = {{width: "auto"}}
          onClick   = {() => {
            if(!showCalendar)  setShowCalendar(true);
            else               setShowCalendar(false);
          }}
        >
          <BiCalendar
            className = "btt-select-data-icon"
          />
          <div 
            className = "contain-label-sel-dt"
            style     = {{marginRight: "10px"}}
          >
            <h3 className = "text-label-info-data">
              {"Data Inicial"}
            </h3>
            <div className = "contain-label-input-data">
              <h4 className = "text-label-select-data">
                {`${Services.FormatDate(inpData.dataIn)} `}
              </h4>
              <h4 className = "text-label-select-data" style = {{marginRight: "0px"}}>
                {Services.DiminuiDate(inpData.dataIn)}
              </h4>
              {
              /*
                <input
                className = "input-select-data"
                type      = "text"
                value     = {inpData.dataIn}
                onClick   = {(e) => {
                  e.stopPropagation();
                }}
                onChange  = {(e) => {
                  FormatDataInput(e.target.value, true);
                }}
              />*/
              }
            </div>
          </div>
          <div className = "contain-label-sel-dt">
            <h3 className = "text-label-info-data">
              {"Data Final"}
            </h3>
            <div className = "contain-label-input-data">
              <h4 className = "text-label-select-data">
                {`${Services.FormatDate(inpData.dataFn)} `}
              </h4>
              <h4 className = "text-label-select-data" style = {{marginRight: "0px"}}>
                {Services.DiminuiDate(inpData.dataFn)}
              </h4>
              {
              /*
                <input
                className = "input-select-data"
                type      = "text"
                value     = {inpData.dataIn}
                onClick   = {(e) => {
                  e.stopPropagation();
                }}
                onChange  = {(e) => {
                  FormatDataInput(e.target.value, true);
                }}
              />*/
              }
            </div>
          </div>
          {showCalendar &&
            <div 
              ref       = {calendarRef}
              onClick   = {(e) => {
                e.stopPropagation();
              }}
            >
              
              <Calendar
                
                className   = "calendar-select-data"
                selectRange = {true}
                value       = {ReturnPeriodoCalendar()}
                view        = "month"
                onChange    = {(e) => {
                  handlePeriodo(e);
                }}
                formatMonthYear={(locale, date) => {
                  const month = date.toLocaleString(locale, { month: 'long' });
                  const year = date.getFullYear();
                  return `${month.charAt(0).toUpperCase() + month.slice(1)} de ${year}`;
                }}
              />
            </div>
          }
        </div>
        <button
          className = "btt-filtrar-agenda"
          onClick   = {() => {
            showCalendar && setShowCalendar(false);
            onClickFiltrar(inpData.dataIn, inpData.dataFn)
          }}
        >
          Buscar
          <BiSearchAlt
            size = {25}
            style = {{marginLeft: "5px"}}
          />
        </button>
      </div>
    </div>
  );

}