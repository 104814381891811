import React                        from "react";
import { BiUser, BiInfoCircle }     from "react-icons/bi";
import { AuthContext }              from "../../../contexts/AuthContext";
import { WhatsPerson }              from "../../../assets";
import { ImHammer2 }                from "react-icons/im";
import { TbLogout }                 from "react-icons/tb";
import { CompCnfg }                 from "../../Complext/";
import RouterHook                   from "../../../contexts/RouterHook";
import Modal                        from "react-modal";
import Swal                         from "sweetalert2";

import "./stylesMC.css";

Modal.setAppElement('#root');

export default function ModalCnfg({modal, ClosedModal, elementRef, userName, handlePage}) {
  const { logout, date }            = React.useContext(AuthContext);
  const serverRouter                = RouterHook();
  const [position, setPosition]     = React.useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });

  // Atualiza o tamanho da janela
  React.useEffect(() => {
    // Função que atualiza o tamanho da janela
    const handleWindowResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    // Adiciona o evento de resize
    window.addEventListener("resize", handleWindowResize);
    // Remove o evento de resize
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  //console.log("Usuario - ",date.user);
  React.useEffect(() => {
    // Função que atualiza a posição do modal
    const { current } = elementRef;
    // Verifica se o elemento existe
    if (current) 
      // Atualiza a posição do modal
      setPosition({ 
        x : current.offsetLeft, 
        y : current.offsetTop
      });
  }, [elementRef, windowSize]);
  
  const configs = [
    {
      Icon      : BiUser,
      title     : userName,
      describe  : "Editar meus dados pessoais.",
      user      : true,
      onClick   : () => {
        //navegar para a tela de editar dados pessoais
        serverRouter("settings", date.gestor.idusuario);
        ClosedModal();
      }
    },{
      Icon      : ImHammer2,
      title     : "Aspectos legais",
      describe  : "Termo de uso, privacidade, dados...",
      user      : false,
      onClick   : () => {
        //navegar para os aspectos legais
        serverRouter("aspectos-legais", date.gestor.idusuario);
        ClosedModal();
      }
    },{
      Icon      : WhatsPerson,
      title     : "Ajuda",
      describe  : "FAQ, chat, guia rápido, tutoriais...",
      user      : false,
      onClick   : () => {
        //navegar para a tela de central de atendimento
        serverRouter("ajuda");
        ClosedModal();
      }
    },{
      Icon      : BiInfoCircle,
      title     : "Sobre",
      describe  : "Conheça mais do Agendei Quadras",
      user      : false,
      onClick   : () => {
        //navegar para a tela de sobre
        window.open("https://www.agendeiquadras.com.br/appAgendei/", "_blank");
      }
    },{
      Icon      : TbLogout,
      title     : "Desconectar da conta",
      describe  : "Voltar à página inicial",
      user      : false,
      onClick   : async () => {
        Swal.fire({
          icon    : 'warning',
          title   : 'Aviso',
          text    : 'Você deseja sair da sua conta?',
          timer   : 10000,
          showCancelButton: true,
          confirmButtonColor    : '#bf3324',
          cancelButtonColor     : '#00cda4',
          confirmButtonText     : 'Não',
          cancelButtonText      : 'Sim'
        }).then(async (result) => {
          if(!result.isConfirmed && result.dismiss === "cancel"){
            await logout();
            serverRouter("login");
          }
        });
      }
    }
  ];

  return (
    <Modal
      isOpen          = {modal}
      id              = "id-modal-cnfg"
      className       = "contain-content-cnfg"
      overlayClassName= "contain-overlay-cnfg"
      onRequestClose  = {ClosedModal}
      style     = {{
        content : {
          top   : position.y + 70,
          left  : position.x - 300,
        }
      }}
    >
      <div
        className = "contain-ref-arrow-top-cnfg"
        style     = {{ 
          top   : position.y + 53,
          left  : position.x + 10
        }}
      />
      <h1>Configurações</h1>
      <div className = "contain-modal-cnfg-list">
        {
          configs.map((item, index) => {
            return (
              <CompCnfg
                key       = {index}
                ic        = {index}
                Icon      = {item.Icon}
                title     = {item.title}
                describe  = {item.describe}
                user      = {item.user}
                onClick   = {item.onClick}
              />
            )
          })
        }
      </div>
    </Modal>
  );
}