import React              from "react";
import { 
  Alerts, ErroComunic, Loading 
} from "../../Basics";
import {
  CompPedido, CompMensalista
} from "../../Complext/";
import { 
  BiCalendarPlus, BiCalendarX, BiCheck, BiCircle, BiLeftArrowAlt, BiX
} from "react-icons/bi";
import { notAgend, Check, UserCircle, logoAgendei } from "../../../assets";
import { AuthContext }        from "../../../contexts/AuthContext";
import { LocalStorage }       from "../../../services";
import RouterHook             from "../../../contexts/RouterHook";
import Swal                   from "sweetalert2";
import { EmpresaAPI }         from "../../../servicesAPI";
import { Services }           from "../../../services";
import { RiUserSearchLine, RiUserSettingsLine } from "react-icons/ri";

import "./stylesHF.css";

export default function HoraFixa({handle}) {

  const serverRouter = RouterHook();
  const { date, token, modalOpen, handleMOP } = React.useContext(AuthContext);
  
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : null,
    scroll      : 0,
    countAll    : true,
  };
  const pgCB = LocalStorage.get("pg-client-buffer");
  const pgASB = LocalStorage.get("pg-array-stt-buffer");
  const clientRef   = React.useRef(null);
  const arraySttRef = React.useRef(null);
  const [pgClients, setPgClients]       = React.useState(pgCB   !== null ? pgCB   : pagBuffer);
  const [pgArrayStt, setPgArrayStt]     = React.useState(pgASB  !== null ? pgASB  : pagBuffer);
  // Index do botão para api
  // null -> todos
  // SOLICITADO -> 1
  // AGENDADO   -> 2
  // CANCELADO  -> 3
  // REALIZADO  -> 4
  const [load, setLoad]                 = React.useState({
    page      : true,
    compC     : false,
    compM     : false,
    filter    : false,
    horaFxSel : false,
    arrayStt  : false,
  });

  const bufStt = LocalStorage.get("stt-page-hf");

  const [sttPage, setSttPage]           = React.useState(
    bufStt !== null ? bufStt : 
    {
      mode              : "init",     // init -> Inicial, client-sel -> Cliente selecionado, hf-sel -> Horário fixo selecionado 
      title             : "Horários Fixos",
      clienteSelect     : null,
      hfSelect          : null,
      motivo            : "",
      filter            : "",
      erroComunic       : false,
    }
  );
 ////console.loglog("sttPage", sttPage);
  const [arrayState, setArrayState]         = React.useState([]); // array de pedidos
  const [clientes, setClientes]             = React.useState([]); // array de clientes
  const [clientesBuffer, setClientesBuffer] = React.useState([]); // array de clientes
  // hora fixa selecionada
  const [horaFx, setHoraFx]                 = React.useState({
    hfSel       : null,
    handle      : false,
  });
  //console.log("Clientes", clientes);
  //console.log("ClientesBuffer", clientesBuffer);
  //console.log("ArrayState", arrayState);

  React.useEffect(() => {
    if(clientRef.current) {
     //console.log("Scroll Clientes", pgClients)
      if(pgClients.scroll > 0) clientRef.current.scrollTop = pgClients.scroll;
    } 
    if(arraySttRef.current) {
     //console.log("Scroll ArrayStt" , pgArrayStt)
      if(pgArrayStt.scroll > 0) arraySttRef.current.scrollTop = pgArrayStt.scroll; 
    }
  },[clientRef.current, arraySttRef.current]);

  // Busca os clientes da empresa
  async function BuscaClientes(){
    const req = await EmpresaAPI.BuscarMensalistasEmpresaId(
      token, 
      date.empresa.idempresa,
      pgClients.limit,
      true,
      0,
    );
    if(req.status){
      if(req.reqs.retorno){
        setPgClients({
          ...pgClients,
          offSet      : req.reqs.mensalista.rows.length,
          maxScroll   : req.reqs.mensalista.count,
          countAll    : false,          
        }); // limpa o buffer
        setClientes(req.reqs.mensalista.rows);
        setClientesBuffer(req.reqs.mensalista.rows);
      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
      }
    } else {
      // Mapear quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
  }
  // Busca mais clientes da empresa rota paginada
  async function BuscaMaisClientes(){
    setLoad({
      ...load,
      compC: true,
    });
    const req = await EmpresaAPI.BuscarMensalistasEmpresaId(
      token, 
      date.empresa.idempresa,
      pgClients.limit,
      pgClients.countAll,
      pgClients.offSet,
    );
    //console.log("BuscaClientes", req);
    if(req.status){
      if(req.reqs.retorno){
        setPgClients({
          ...pgClients,
          offSet      : pgClients.offSet + req.reqs.mensalista.rows.length, 
        }); // limpa o buffer
        setClientes([
          ...clientes,
          ...req.reqs.mensalista.rows
        ]);
        setClientesBuffer([
          ...clientesBuffer,
          ...req.reqs.mensalista.rows
        ]);
      } else {
        // Mapear quando tem problema de resposta mas conexão funcionou
      }
    } else {
      // Mapear quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      compC: false,
    });
  }

  // Busca os clientes da empresa por filtro de nome
  async function BuscaClientesFiltro(){
    setLoad({
      ...load,
      filter: true,
    });
    const req = await EmpresaAPI.BuscarClientesPorFiltro( token, date.empresa.idempresa, sttPage.filter);
    //console.log("BuscaClientesFiltro", req);
    if(req.status){
      setClientes(req.req);
    } else {
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      filter: false,
    });
  }

  // Busca o mensalistas da empresa
  async function BuscarMensalistaId(){
    const req = await EmpresaAPI.BuscarHorariosFixos(
      token,
      date.empresa.idempresa,
      pgArrayStt.limit,
      0,
      true,
    );
    //console.log("BuscarMensalistaId", req);
    if(req.status){
      if(req.req.retorno){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : req.req.mensalista.rows.length,
          maxScroll   : req.req.mensalista.count,
          countAll    : false,          
        }); // limpa o buffer
        setArrayState(req.req.mensalista.rows);
      } else {
        // Tratar quando não tem retorno mas conexão funcionou
        Alerts.aviso("Aviso", req.req.messagem);
      }
    } else {
      // Tratar quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      arrayStt: false,
    });
  }
  // Busca mais mensalistas da empresa
  async function BuscarMaisMensalistaId(){
    setLoad({
      ...load,
      compM: true,
    });
    const req = await EmpresaAPI.BuscarHorariosFixos(
      token,
      date.empresa.idempresa,
      pgArrayStt.limit,
      pgArrayStt.offSet,
      false,
    );
    //console.log("BuscarMaisMensalistaId - PG", req);
    if(req.status){
      if(req.req.retorno){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : pgArrayStt.offSet + req.req.mensalista.rows.length,
        }); // limpa o buffer
        setArrayState([
          ...arrayState,
          ...req.req.mensalista.rows
        ]);
      } else {
        // Tratar quando não tem retorno mas conexão funcionou

      }
    } else {
      // Tratar quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      compM: false,
    });
  }

  //Busca os mensalistas de um cliente selecionado
  async function BuscarMensalistaIdCliente(){
    setLoad({
      ...load,
      arrayStt: true,
    });
    const req = await EmpresaAPI.BuscarMensalistasClienteId(
      token,
      sttPage.clienteSelect.idusuario,
      date.empresa.idempresa,
      pgArrayStt.limit,
      true,
      0,
    );
    //console.log("BuscarMensalistaIdCliente", req);
    if(req.status){
      if(req.reqs.retorno){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : req.reqs.mensalista.rows.length,
          maxScroll   : req.reqs.mensalista.count,
          countAll    : false,          
        }); // limpa o buffer
        setArrayState(req.reqs.mensalista.rows);
       ////console.loglog("ArrayState", arrayState);
      } else {
        // Tratar quando não tem retorno mas conexão funcionou
      }
    } else {
      // Tratar quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      arrayStt: false,
    });
  }
  // Busca mais mensalistas de um cliente selecionado
  async function BuscarMaisMensalistaIdCliente(){
    setLoad({
      ...load,
      compM: true,
    });
    const req = await EmpresaAPI.BuscarMensalistasClienteId(
      token,
      sttPage.clienteSelect.idusuario,
      date.empresa.idempresa,
      pgArrayStt.limit,
      false,
      pgArrayStt.offSet,
    );
    //console.log("BuscarMensalistaIdCliente", req);
    if(req.status){
      if(req.reqs.retorno){
        setPgArrayStt({
          ...pgArrayStt,
          offSet      : pgArrayStt.offSet + req.reqs.mensalista.rows.length,
        }); // limpa o buffer
        setArrayState([...arrayState, ...req.reqs.mensalista.rows]);
       ////console.loglog("ArrayState", arrayState);
      } else {
        // Tratar quando não tem retorno mas conexão funcionou
      }
    } else {
      // Tratar quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    setLoad({
      ...load,
      compM: false,
    });
  }
  // Busca um mensalista selecionado, mostrando seus pedidos!
  async function BuscaHFId(id, mode){
    //console.log("BuscaHFId", id, mode);
    if(mode !== true) setLoad({...load, horaFxSel: true,});
    if(horaFx.hfSel === null && !mode) return //console.log("Caiu aqui! - BuscaHFId");
    const req = await EmpresaAPI.BuscarMensalistaId( token, mode ? id : horaFx.hfSel.idmensalista );
   //console.log("BuscaHFId", req);
    if(req.status){
      setHoraFx({
        ...horaFx,
        hfSel : req.req,
      });
      setSttPage({
        ...sttPage,
        mode: "hf-sel",
        hfSelect: req.req.idmensalista,
        title: "Horário Fixo #" + req.req.idmensalista,
      });
    } else {
      // Tratar quando não tem conexão
      setSttPage({
        ...sttPage,
        erroComunic: false,
      });
    }
    if(mode !== true) setLoad({...load, horaFxSel: false,});
  }
  
  // Chama api para cancelar o horário fixo
  async function CancelarHorarioFixoAPI(motivo){
    const req = await EmpresaAPI.CancelarMensalistaCliente(token, horaFx.hfSel.idmensalista, date.gestor.nome, motivo);
   ////console.loglog("CancelarHorarioFixoAPI", req);
    if(req.status){
      if(req.req.retorno){
        Swal.fire({
          icon    : 'success',
          title   : 'Horário Fixo Cancelado',
          text    : 'O horário fixo foi cancelado com sucesso',
          timer   : 7000,
          confirmButtonText   : 'Entendi',
          confirmButtonColor  : '#FF7043',
          showConfirmButton   : true,
        });
        await BuscaHFId();
      } else {
        Alerts.aviso("Aviso", req.req.messagem);
      }
    } else {
      Alerts.aviso("Aviso", "Não foi possível cancelar o horário fixo, por favor tente novamente mais tarde!");
    }
  }

  // Aceitar a solicitação do horário fixo
  function AceitarHorarioFixo(idagendamento){
    ////console.log("mensalista", idagendamento);
    Swal.fire({
      title   : 'Aprovar',
      text    : "Deseja aprovar essa solicitação de agendamento?",
      icon    : 'warning',
      showCancelButton      : true,
      confirmButtonColor    : '#bf3324',
      cancelButtonColor     : '#00cda4',
      confirmButtonText     : 'Não',
      cancelButtonText      : 'Sim'
    }).then(async (result) => {
      if(!result.isConfirmed && result.dismiss === "cancel"){
        setLoad({
          ...load,
          page : true,
        });
        const req = await EmpresaAPI.AprovarMensalistaCliente( token, idagendamento, date.gestor.nome );
       //console.log("AceitarHorarioFixo", req);
        if(req.status){
          // Chama a api para atualizar o horário fixo
          await BuscaHFId(true, idagendamento);
          Alerts.sucesso("Sucesso", "A solicitação de horário fixo foi aprovada com sucesso"); 
        } else {
          Alerts.aviso("Aviso!", req.message);
        }
        setLoad({
          ...load,
          page : false,
        });
      }
    });
  }

  // Rejeitar a solicitação do horário fixo
  function RejeitarHorarioFixo(idagendamento){
    Swal.fire({
      title   : 'Cancelar',
      text    : "Deseja rejeitar essa solicitação de agendamento?",
      icon    : 'warning',
      showCancelButton      : true,
      confirmButtonColor    : '#bf3324',
      cancelButtonColor     : '#00cda4',
      confirmButtonText     : 'Não',
      cancelButtonText      : 'Sim'
    }).then(async (result) => {
      if(!result.isConfirmed && result.dismiss === "cancel"){      
        setLoad({
          ...load,
          page : true,
        });  
        const req = await EmpresaAPI.RecusarMensalistaCliente( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
       //console.log("RejeitarHorarioFixo", req);
        if(req.status){
          // Remover o pedido do array
          await BuscaHFId(true, idagendamento);
          Alerts.sucesso("Sucesso", "A solicitação de horário fixo foi rejeitada com sucesso");
        } else {
          
          Alerts.aviso("Aviso!", req.message);
        }
        setLoad({
          ...load,
          page : false,
        });
      }
    });
  }
  
  // Cancela o horário fixo
  function CancelarHorarioFixo(){
    const label = sttPage.motivo.length === 0 ? 
    "Tem certeza que deseja cancelar os agendamentos restantes desse horário fixo, sem um motivo? Essa ação não poderá ser desfeita."  
      : 
    sttPage.motivo.length <= 6 ?
    "Tem certeza que deseja cancelar os agendamentos restantes desse horário fixo, com um motivo tão curto? Essa ação não poderá ser desfeita."
      :
    "Tem certeza que deseja cancelar os agendamentos restantes desse horário fixo? Essa ação não poderá ser desfeita.";
    Swal.fire({
      icon  : 'warning',
      title : 'Cancelar Horário Fixo?',
      text  : label,
      showCancelButton: true,
      confirmButtonColor: '#bf3324',
      cancelButtonColor: '#00cda4',
      confirmButtonText: 'Não',
      cancelButtonText: 'Sim',
    }).then(async (result) => {
      if(result.isConfirmed) return;
      setLoad({
        ...load,
        page: true,
      });
      let motivo = sttPage.motivo;
      if(sttPage.motivo.length <= 6) motivo = "Sem motivo"; 
      await CancelarHorarioFixoAPI(motivo);
      setLoad({
        ...load,
        page: false,
      });
    });
    
  }

  function SalveSttPage( mode, pg ) {
    //console.log("SalveSttPage", mode, pg);
    if( mode === "stt-page")          LocalStorage.set("stt-page-hf",         pg);
    else if( mode === "pg-clients")   LocalStorage.set("pg-client-buffer",    pg);
    else                              LocalStorage.set("pg-array-stt-buffer", pg);
  }
  
  React.useEffect(() => {
    document.title = "Horários Fixos | Gestor Agendei";
    async function SetData(){
      const horaFxSel = LocalStorage.get("hora-fixa-selected");
      if(horaFxSel !== null){
        setSttPage({
          ...sttPage,
          mode: "hf-sel",
          title: "Horário Fixo #" + horaFxSel,
          hfSelect: horaFxSel,
        });
       //console.log("HoraFxSel", horaFxSel);
        LocalStorage.remove("hora-fixa-selected"); 
      } else{
        if(sttPage.mode === "init"){
          await BuscaClientes();
          await BuscarMensalistaId();
        } else if(sttPage.mode === "client-sel"){
          await BuscaClientes();
          await BuscarMensalistaIdCliente();
        } else if(sttPage.mode === "hf-sel"){
          setSttPage({
            ...sttPage,
            motivo: "",
          });
          await BuscaHFId(sttPage.hfSelect, true);
        }
        setLoad({...load, page: false, arrayStt: false});
      }
    }
    // Averigua sttPage.mode e busca os dados
    SetData();
  }, [sttPage.mode, sttPage.clienteSelect, sttPage.hfSelect, sttPage.title]);

  React.useEffect(() => {
    SalveSttPage("stt-page", sttPage);
  }, [sttPage]);
  // Busca horario fixo caso tenha sido selecionado
  React.useEffect(() => {
    const horaFxSel = LocalStorage.get("hora-fixa-selected");
    if(horaFxSel !== null && !load.page){
      BuscaHFId(horaFxSel, true).then(() => {
        LocalStorage.remove("hora-fixa-selected");
      });
    } 
  }, [handle]);

  React.useEffect(() => {
    if(horaFx.hfSel !== null){
     ////console.loglog("HoraFx", horaFx);
      setSttPage({
        ...sttPage,
        title: "Horário Fixo #" + horaFx.hfSel.idmensalista,
        motivo: "",
      });
      BuscaHFId();
    }
  }, [horaFx.handle]);
  // Controla o scroll da lista de clientes (Paginação)
  let flag1 = false;
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop     = clientRef.current.scrollTop;
      const scrollHeight  = clientRef.current.scrollHeight;
      const clientHeight  = clientRef.current.clientHeight;
     //console.log("AS");
      const pg = {
        ...pgClients,
        scroll : scrollTop,
      }
      SalveSttPage("pg-clients", pg);
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag1 && pgClients.maxScroll > pgClients.offSet && sttPage.filter.length <= 0) {
        flag1 = true;
        ////console.log("Scroll", pgClients);
        BuscaMaisClientes().then(() => {
          flag1 = false;
        });
      }
    }
    if(clientRef.current) clientRef.current.addEventListener("scroll", handleScroll);
    return () => {if(clientRef.current) clientRef.current.removeEventListener("scroll", handleScroll);}
  }, [sttPage, load, clientes, pgClients]);

  // Controla o scroll do arrayState (Paginação)
  let flag2 = false;
  React.useEffect(() => {
    function handleScroll() {
      const scrollTop     = arraySttRef.current.scrollTop;
      const scrollHeight  = arraySttRef.current.scrollHeight;
      const clientHeight  = arraySttRef.current.clientHeight;
     //console.log("AS");
      const pg = {
        ...pgArrayStt,
        scroll : scrollTop,
      }
      SalveSttPage("pg-array-stt", pg);
      if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight && !flag2 && pgArrayStt.maxScroll > pgArrayStt.offSet) {
        flag2 = true;
        ////console.log("Scroll", pgArrayStt);
        if(sttPage.clienteSelect !== null){
          ////console.log("ClienteSelect", sttPage.clienteSelect);
          BuscarMaisMensalistaIdCliente().then(() => {
            flag2 = false;
          });
        } else {
          ////console.log("ClienteSelect", sttPage.clienteSelect);
          BuscarMaisMensalistaId().then(() => {
            flag2 = false;
          });
        }
      }
    }
    if(arraySttRef.current) arraySttRef.current.addEventListener("scroll", handleScroll);
    return () => {if(arraySttRef.current) arraySttRef.current.removeEventListener("scroll", handleScroll);}
  }, [sttPage, load, arrayState, pgArrayStt]);

  // Controla retorno nos states
  function ReturnSttPage(){
    if(sttPage.mode === "hf-sel"){
      setLoad({
        ...load,
        page: true,
      });
      setSttPage({
        ...sttPage,
        mode    : "init",
        title   : "Horários Fixos",
        hfSelect: null,
      });
      setHoraFx({
        ...horaFx,
        hfSel: null,
      });
    } else if(sttPage.mode === "client-sel"){
      setLoad({
        ...load,
        arrayStt: true,
      });
      setSttPage({
        ...sttPage,
        mode            : "init",
        clienteSelect   : null,
      });
    } else if(modalOpen) {
      handleMOP(false);
    } else {
      serverRouter("back");
    }
  }
  // Escuta o popstate
  React.useEffect(() => {
    ////console.log("ModalOpen: ", modalOpen);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", ReturnSttPage);
    return () => window.removeEventListener("popstate", ReturnSttPage);
   }, [ horaFx.hfSel, sttPage.clienteSelect, modalOpen]);
  // Retorna o componente cliente
  function Cliente({usuario, onClick, select}){
    const empresaNome = date.empresa.nome;
    //console.log('Select: ', select);
    return (
      <button 
        className = {select ? "btt-contain-client-select" : "btt-contain-client"}
        style     = {{width: "100%"}}
        onClick   = {() => {
          onClick();
        }}
      >
        <div className = "contain-infos-client">
          <h2>
            {usuario.nome}
          </h2>
          { usuario.atleta &&
            <label>
              {usuario.atleta.telefone}
            </label>
          }
          { usuario.nome === usuario.usuario ?
            <div className = "contain-buffer-client-hf">
              <h3>
                {"CRIADO MANUALMENTE POR GESTOR"}
              </h3>  
              <RiUserSettingsLine
                color = "#A8A8A8"
                size  = {20}
                style = {{marginLeft: "5px"}}
              />
            </div>
              :
            <div className = "contain-buffer-client-hf">
              <h2 className = "text-cliente-empresa">
                {("Cliente " + empresaNome).toUpperCase()}
              </h2>
              <img
                className = "img-buffer-empresa"
                src       = {logoAgendei}
              />
            </div>
          }
        </div>
        { select ?
          <Check
            className = "icon-check-select"
          />
            :
          <BiCircle
            className = "icon-check-circle"
          />
        }
      </button>
    );
  }
  // Retorna o componente referente ao status do pedido, selecionando a cor!
  function ReturnColorStatus(status) {
    switch(status){
      case "Solicitado" : return "#ff7043";
      case "Agendado"   : return "#22ac4a";
      case "Rejeitado"  : return "#bf3324";
      case "Cancelado"  : return "#bf3324";
      case "REALIZADO"  : return "#00cda4";
    }
  }
  // Retorna a string do status do horarios fixos!
  function RetornaStatus(status) {
    switch(status){
      case "Solicitado" : return "SOLICITADO";
      case "Agendado"   : return "ATIVO";
      case "Rejeitado"  : return "INATIVO";
      case "Cancelado"  : return "INATIVO";
    }
  }
  React.useEffect(() => {
    const buscar_clientes = debounce(async () => {
      if(sttPage.filter.length > 3){
        await BuscaClientesFiltro();
      } 
    }, 500);
    buscar_clientes();
  }, [sttPage.filter]);
  //console.log("Clientes", clientes);
  function debounce(fn, delay) {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  //console.log("HoraFx", horaFx.hfSel);
  // Page de Filtrar Pedidos
  return (
  
    <div className = "contain-agenda-page">
      <div className = "contain-filtro-agenda-hf">
        <BiLeftArrowAlt
          className = "icon-arrow-back-hf"
          onClick={() => {
            ReturnSttPage();
          }}
        />
        <h2 className = "title-me-agenda"> {sttPage.title} </h2>
        { load.page ? 
          <div className = "contain-loading" style = {{height: "300px"}}>
            <Loading 
              mode  = {true}
              text  = { "Buscando Horários Fixos..." }  
            />
          </div>
            :
          sttPage.erroComunic ?
          <ErroComunic
            onClick = {() => {
              setSttPage({
                ...sttPage,
                erroComunic: false,
              });
            }}
          />
            :
          <div className = "contain-view-hora-fixa">
            <div className = "contain-filtra-hf">
              { sttPage.mode === "init" || sttPage.mode === "client-sel" ?
                <div className = "contain-list-clientes">
                  <button 
                    className = {sttPage.clienteSelect === null ? "btt-contain-client-select" : "btt-contain-client"}
                    style     = {{minWidth: "300px"}}
                    onClick = {() => {
                      if(sttPage.mode !== "init") { 
                        setLoad({
                          ...load,
                          arrayStt: true,
                        });
                        setSttPage({
                          ...sttPage,
                          mode            : "init",
                          clienteSelect   : null
                        });
                      }
                    }}
                  >
                    <div className = "contain-infos-client">
                      <h2 style = {{fontSize: "16px"}}>
                        Ver todos os Horários Fixos
                      </h2>
                    </div>
                    {
                      sttPage.clienteSelect === null ?
                      <Check
                        className = "icon-check-select"
                      />
                        :
                      <BiCircle
                        className = "icon-check-circle"
                      />
                    }
                  </button>
                  <button
                    className = "btt-text-icon"
                    onClick   = {() => {
                      // Manda para a página de criar novo horário fixo
                      serverRouter("menu", date.empresa.username);
                    }}
                  >
                    Criar novo Horário Fixo
                    <BiCalendarPlus 
                      className = "icon-btt-text-icon"
                    />
                  </button>
                  <div className = "line-border-bottom"/>
                  <h3 className = "title-list-clientes"> 
                    {`${pgClients.maxScroll} Clientes Fixos`} 
                  </h3>
                  <div 
                    className = "contain-comp-input-forms"
                    style     = {{width: "100%", marginBottom: "7px"}}
                  >
                    <input
                      className     = "input-comp"
                      placeholder   = {"Buscar Clientes..."}
                      type          = {"text"}
                      value         = {sttPage.filter}
                      onChange      = {(e) => {
                        if(e.target.value.length === 0) setClientes(clientesBuffer);
                        setSttPage({
                          ...sttPage,
                          filter: e.target.value
                        });
                      }}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') { 
                          // Verificar tamanho do texto
                          if(sttPage.filter.length > 3){
                            //console.log("Buscar Clientes");
                            await BuscaClientesFiltro();
                          } else {
                            Alerts.aviso("Aviso", "Por favor digite mais de 3 caracteres para filtrar os clientes!");
                          }
                        }
                      }}
                    /> 
                    <RiUserSearchLine
                      size      = {25}
                      color     = "#A8A8A8"
                      style     = {{cursor: "pointer"}}
                      onClick   = {() => {
                        // Verificar tamanho do texto
                        if(sttPage.filter.length > 3){
                          //console.log("Buscar Clientes");
                          BuscaClientesFiltro();
                        } else {
                          Alerts.aviso("Aviso", "Por favor digite mais de 3 caracteres para filtrar os clientes!");
                        }
                      }}
                    />
                  </div>
                  <div
                    ref       = {clientRef} 
                    className = "flat-list-clientes">
                    { load.filter ?
                      <div>
                        <Loading mode = {true} text={"Buscando Clientes..."}/>
                      </div>
                        :
                      clientes.length > 0 ?
                      clientes.map((cliente, index) => {
                        return (
                          <>
                            <Cliente
                              key       = {index}
                              type      = {"select"}
                              usuario   = {cliente.usuario}
                              select    = {sttPage.clienteSelect !== null ? sttPage.clienteSelect.idusuario === cliente.usuario.idusuario : false}
                              onClick   = {() => {
                                
                                if(sttPage.mode === "client-sel") {
                                 //console.log("Cliente", cliente);
                                  if(sttPage.clienteSelect.idusuario !== cliente.usuario.idusuario) { 
                                    setLoad({
                                      ...load,
                                      arrayStt: true,
                                    });
                                    setSttPage({
                                      ...sttPage,
                                      mode          : "client-sel",
                                      clienteSelect : cliente.usuario,
                                    });
                                  } 
                                } else {
                               //console.log("Cliente", cliente);
                                  setLoad({
                                    ...load,
                                    arrayStt: true,
                                  });
                                  setSttPage({
                                    ...sttPage,
                                    mode          : "client-sel",
                                    clienteSelect : cliente.usuario
                                  });
                                }
                              }}
                            />
                            {
                              clientes.length - 1 === index && load.compC &&
                              <Loading mode = {false} />
                            }
                          </>
                        )
                      })
                        :
                      <div className = "contain-not-agend">
                        <img
                          title = "Sem Clientes Fixos"
                          src   = {notAgend}
                          alt   = "Not Agend"
                        />
                        <h2>
                          { sttPage.filter.length > 0 ?
                            "Não foi encontrado nenhum cliente com esse nome"
                              :
                            "Esta empresa não possui clientes fixos"
                          }
                        </h2>
                      </div>
                    }
                  </div>
                </div>
                  :
                <div className = "contain-list-clientes">
                  <div className = "contain-resumo-hora-fixa">
                    <h3 className = "hf-title-dia">
                      {Services.ReturnDiaSemana(5, new Date(horaFx.hfSel.dataPrimeiraReserva)).toUpperCase()}
                    </h3>
                    <h3 className = "hf-text-normalize" style = {{color: "#FF7043"}}>
                      {horaFx.hfSel.quadra.descricao}
                    </h3>
                    { 
                      horaFx.hfSel.pedidos.length > 0 ?
                      <h3 className = "hf-text-normalize">
                        {Services.ReturnHoraMin(new Date(horaFx.hfSel.pedidos[0].dataInicio))}
                        <p style = {{margin : "0px 4px", fontWeight: "lighter"}}> às </p>
                        {Services.ReturnHoraMin(new Date(horaFx.hfSel.pedidos[0].dataFinal))}
                      </h3> : null
                    }{
                      (horaFx.hfSel.tipoMensalista !== null && horaFx.hfSel.tipoMensalista !== "Indeterminado") ?
                      <h3 className = "hf-text-normalize">
                        {Services.DataFormat("DD/MM/YY", horaFx.hfSel.dataPrimeiraReserva)}
                        <p style = {{margin : "0px 4px", fontWeight: "lighter"}}> à </p>
                        {Services.DataFormat("DD/MM/YY", horaFx.hfSel.dataUltimaReserva)}
                      </h3> : 
                      <h3 className = "hf-text-normalize">
                        {`Início em ${Services.DataFormat("DD/MM/YY", horaFx.hfSel.dataPrimeiraReserva)}`}
                      </h3>
                    }
                    <h3
                      className = "hf-text-normalize" 
                      style = {{color: ReturnColorStatus(horaFx.hfSel.statusSolicitacao), fontFamily: "jost-mediun"}}
                    >
                      {RetornaStatus(horaFx.hfSel.statusSolicitacao)}
                    </h3>
                    <div className="line-border-bottom"/>
                    <h3 className = "hf-text-aviso">
                      Os valores são atualizados automaticamente, mas você pode alterá-los em cada agendamento
                    </h3>
                    <div className = "contain-valor-recebido">

                      { horaFx.hfSel.valorAtualizado !== null && horaFx.hfSel.valorAtualizado !== 0 ?
                        <div className = "contain-valor-line">
                          <h3>
                            Valor contratado
                          </h3>
                          <h4 style = {{color: "white"}}>
                            {`R$${horaFx.hfSel.valorAtualizado.toFixed(2).replace('.', ',')}`}
                          </h4>
                        </div>
                          :
                        <div className = "contain-valor-line">
                          <h3>
                            Valor contratado
                          </h3>
                          <h4 style = {{color: "white"}}>
                            {`R$${horaFx.hfSel.valorTotal.toFixed(2).replace('.', ',')}`}
                          </h4>
                        </div>
                      }{
                        (horaFx.hfSel.valorPrevisto !== null && horaFx.hfSel.valorPrevisto !== 0 && horaFx.hfSel.tipoMensalista !== "Indeterminado") &&
                        <div className = "contain-valor-line">
                          <h3>
                            Valor a receber
                          </h3>
                          <h4 style = {{color: "#FFA012"}}>
                            {`R$${horaFx.hfSel.valorPrevisto.toFixed(2).replace('.', ',')}`}
                          </h4>
                        </div>
                      }{ 
                        (horaFx.hfSel.valorCancelado !== null && horaFx.hfSel.valorCancelado !== 0) && 
                        <div className = "contain-valor-line">
                          <h3>
                            {`Valor perdido (cancelados)`}
                          </h3>
                          <h4 style = {{color : "#be3223"}}>
                              {`R$${horaFx.hfSel.valorCancelado.toFixed(2).replace('.', ',')}`}
                          </h4>
                        </div>
                      }{
                        (horaFx.hfSel.valorRealizado !== null && horaFx.hfSel.valorRealizado !== 0) && 
                        <div className = "contain-valor-line">
                          <h3>
                            Valor recebido
                          </h3>
                          <h4>
                              {`R$${horaFx.hfSel.valorRealizado.toFixed(2).replace('.', ',')}`}
                          </h4>
                        </div>
                      }

                    </div>
                    { 
                      horaFx.hfSel.status ?
                      horaFx.hfSel.tipoMensalista === "Indeterminado" ?
                      <h3 className = "hf-text-aviso" style = {{color: "#FFA012"}}>
                        Esse horário fixo é renovado semanalmente, ele não tem fim.
                        Para cancelar, clique no botão abaixo.
                      </h3>
                        :
                      <h3 className = "hf-text-aviso" style = {{color: "#FFA012"}}>
                        Para cancelar esse horário fixo, clique no botão abaixo.
                      </h3> : <></> 
                    }{ 
                      (horaFx.hfSel.status && horaFx.hfSel.statusSolicitacao !== "Solicitado") &&
                      <>
                        <button
                          className = "btt-text-icon-cliente-cancel"
                          onClick   = {() => {
                            // Cancela o horário fixo
                            CancelarHorarioFixo();
                          }}
                        >
                          Cancelar esse Horário Fixo
                          <BiCalendarX
                            className = "icon-btt-text-icon"
                          />
                        </button>
                        <h3 className = "text-subtitle-low">Qual o motivo?</h3>
                        <textarea
                          className     = "text-area-motivo-hf"
                          value         = {sttPage.motivo}
                          onChange      = {(e) => setSttPage({...sttPage, motivo: e.target.value})}
                          placeholder   = "Ex: Estamos fechando a quadra para manutenção"
                        />
                      </>
                    }{
                      horaFx.hfSel.statusSolicitacao === "Solicitado" &&
                      <>
                        <button
                          className = "btt-text-icon-cliente-acept"
                          onClick   = {() => {
                            // Aceita o horário fixo
                            AceitarHorarioFixo(horaFx.hfSel.idmensalista);
                          }}
                        >
                          Aceitar
                          <BiCheck
                            size  = {30}
                            color = "white"
                          />
                        </button>
                        <button
                          className = "btt-text-icon-cliente-reject"
                          onClick   = {() => {
                            // Rejeita o horário fixo
                            RejeitarHorarioFixo(horaFx.hfSel.idmensalista);
                          }}
                        >
                          Recusar
                          <BiX
                            size  = {30}
                            color = "white"
                          />
                        </button>
                      </>
                    }
                    
                  </div>
                </div>
              }
              <div className = "contain-list-array-state">
                { load.arrayStt ?
                  <div className = "contain-loading" style={{height: "300px"}}>
                    <Loading 
                      mode  = {true}
                      text  = {"Buscando Horários Fixos..."}  
                    />
                  </div> 
                    :
                  sttPage.mode === "init" ?
                  <div 
                    ref       = {arraySttRef}
                    className = "contain-flat-list-array-stt"
                  >
                    {
                      arrayState.length > 0 ? 
                        arrayState.map((item, index) => {
                          ////console.log("Item", item);
                          return (
                            <>
                              <CompMensalista
                                key       = {index}
                                mens      = {item}
                                viewUser  = {true}
                                onClick   = {() => {
                                  setSttPage({
                                    ...sttPage,
                                    mode          : "hf-sel",
                                    hfSelect      : item.idmensalista,
                                  });
                                  setHoraFx({
                                    hfSel       : item,
                                    handle      : !horaFx.handle,
                                  });
                                }}
                              />
                              {
                                arrayState.length - 1 === index && load.compM &&
                                <Loading mode = {false} />
                              }
                            </>
                          );
                        })
                        :
                        <div className = "contain-not-agend">
                          <img 
                            title = "Sem Horários Fixos"
                            src   = {notAgend}
                            alt   = "Not Agend"
                          />
                          <h2>
                            {"Essa Empresa não possui Horários Fixos cadastrados"}
                          </h2>
                        </div>
                      }
                  </div>
                    :
                  sttPage.mode === "client-sel" ? 
                  <>
                    <div className = "contain-resumo-cliente">
                      <UserCircle
                        className = "icon-user-circle-hf"
                      />
                      <h2>{sttPage.clienteSelect.nome}</h2>
                      <h3>{sttPage.clienteSelect.username}</h3>
                      { sttPage.clienteSelect.atleta &&
                        <p>
                          {sttPage.clienteSelect.atleta.telefone}
                        </p>
                      }
                      <button
                        className = "btt-text-icon-cliente"
                        onClick   = {() => {
                          ////console.loglog("HoraFx Aqui 2", horaFx);
                          // Salva o idusuario do cliente e salva no localStorage!
                          LocalStorage.set("clienteSelect", sttPage.clienteSelect);
                          LocalStorage.set("criar-hf", true);
                          // Manda o gestor para tela inicial de criar novo horário fixo
                          serverRouter("menu", date.empresa.username);
                        }}
                      >
                        Agendar Horário Fixo para este cliente
                        <BiCalendarPlus 
                          className = "icon-btt-text-icon"
                        />
                      </button>
                    </div>
                    <div className = "line-border-bottom" style = {{marginBottom: "10px"}}/>
                    <div 
                      ref       = {arraySttRef}
                      className = "contain-flat-list-array-stt"
                      style     = {{maxHeight: "calc(600px - 185px)"}}
                    >
                      {
                        load.arrayStt ?
                          <div className = "contain-loading">
                            <Loading 
                              mode  = {true}
                              text  = {"Buscando Horários Fixos..."}  
                            />
                          </div> 
                            :
                          arrayState.length > 0 ? 
                            arrayState.map((item, index) => {
                              ////console.log("Item", item);
                              return (
                                <>
                                  <CompMensalista
                                    key       = {index}
                                    mens      = {item}
                                    onClick   = {() => {
                                      setHoraFx({
                                        hfSel       : item,
                                        handle      : !horaFx.handle,
                                      });
                                      setSttPage({
                                        ...sttPage,
                                        mode          : "hf-sel",
                                        hfSelect      : item.idmensalista,
                                      });
                                    }}
                                  />
                                  {
                                    arrayState.length - 1 === index && load.compM &&
                                    <Loading mode = {false} />
                                  }
                                </>
                              );
                            })
                              :
                            <div className = "contain-not-agend">
                              <img 
                                src   = {notAgend}
                                alt   = "Not Agend"
                              />
                              <h2>
                                {"O Cliente não possui Horários Fixos cadastrados"}
                              </h2>
                            </div>
                      }
                    </div>
                  </>
                    :
                  <>         
                    <div 
                      ref       = {arraySttRef}
                      className = "contain-flat-list-array-stt"
                      style     = {{maxHeight: "calc(600px - 100px)"}}
                    >
                      {
                        horaFx.hfSel.pedidos.length > 0 ?
                          horaFx.hfSel.pedidos.length === 1 ?
                          <div className = "contain-loading" style={{height: "300px"}}>
                            <Loading 
                              mode  = {true}
                              text  = {"Buscando Horários Fixos..."}  
                            />
                          </div> 
                            :
                          horaFx.hfSel.pedidos.map((item, index) => {
                            ////console.log("Item", item);
                            return (
                              <CompPedido
                                key       = {index}
                                pedido    = {item}
                                nome      = {horaFx.hfSel.usuario.nome}
                                fixo      = {true}
                                maxW      = {true}
                              />
                            );
                          })
                            :
                          <div className = "contain-not-agend">
                            <img 
                              title = "Sem Agendamentos para esse Horário Fixo"
                              src   = {notAgend}
                              alt   = "Not Agend"
                            />
                            <h2>
                              {"O Cliente não possui Agendamentos para esse Horário Fixo"}
                            </h2>
                          </div>
                      }
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    
  );
}
