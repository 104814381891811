import React                  from "react";
import { 
  SeletoRadio, Loading
} from "../../Basics";
import { BiX }                from "react-icons/bi";
import { HiChatAlt2 }         from "react-icons/hi";
import Modal                  from "react-modal";
import Swal                   from "sweetalert2";
import moment                 from "moment";

import "moment/locale/pt-br";
import "./stylesMCP.css";
import { EmpresaAPI } from "../../../servicesAPI";
import { AuthContext } from "../../../contexts/AuthContext";

moment.locale("pt-br");

Modal.setAppElement('#root');

/* ---- Regras de negócio: ---- Usuário tenta cancelar um pedido
  1. A regra de cancelamento possui os seguintes atributos: porcentagemMulta, 
    permitirCancelamentoPosHorario e minutosAntecedencia
  2. Não é possível cancelar um pedido que pertença à um horário fixo
    a. Somente os gestores da quadra podem cancelar horários fixos. 
      Você pode entrar em contato com o gestor para solicitar o cancelamento.
  3. Se o usuário tentar cancelar antes de extrapolar minutosAntecedencia, 
    informa que estorno será total
    a. Você ainda tem retornaMinutagem(this.retornaTempoRestanteCancelamento(dataInicio, minutosAntecedencia)) para cancelar gratuitamente. O estorno será de R$valorRealizado e acontecerá automaticamente.
  4. Se o usuário tentar cancelar após extrapolar minutosAntecedencia, e 
    permitirCancelamentoPosHorario == false, informa que não será possível cancelar.
    a. O período para cancelamento já passou. Não é mais possível cancelar! Se necessário, 
      entre em contato com o gestor solicitando o cancelamento.
  5. Se o usuário tentar cancelar após extrapolar minutosAntecedencia e 
    permitirCancelamentoPosHorario == true, informa ao usuário que ele irá pagar uma multa 
    de R$X,XX devido ao atraso no cancelamento, que é calculado usando o porcentagemMulta.
    a. O período para cancelamento sem custo já passou, se você cancelar, é cobrado 
      porcentagemMulta% (R$(valorTotal - valorDesconto) * porcentagemMulta) / 100) 
      do valor total do agendamento em virtude do atraso.
  6. Se o usuário tentar cancelar após extrapolar minutosAntecedencia e 
    permitirCancelamentoPosHorario == true, e porcentagemMulta == 0, informa que 
    a regra de cancelamento foi infringida, mas não será cobrada nenhuma multa.
    a. O período para cancelamento sem custo já passou, mas essa empresa não cobra multa.
  7. Se o pagamento for do tipo SINAL e permitirCancelamentoPosHorario == true, informa que 
    será possível cancelar mas o valor do sinal ficará retido como multa.
    a. O período para cancelamento sem custo já passou. O valor de R$valorRealizado pago como 
      sinal do agendamento ficará retido devido à esse cancelamento

  ---- Regras de negócio: ---- Gestor tenta cancelar um pedido
  O gestor, diferente do cliente, pode cancelar um pedido a qualquer momento, porém,
  se o pedido for de um horário fixo, ele terá a opção de cancelar apenas o pedido ou todo o contrato
  se o pedido for avulso, ele terá a opção de cancelar apenas o pedido.
*/

export default function ModalCP({modal, ClosedModal, onClick, onClickChat, onClickMens, fixo, pedido}) {

  const { token, date } = React.useContext(AuthContext);

  const [stt, setStt]               = React.useState({
    op        : null,
    loading   : false,
    libera    : false,
    regra     : null,
  });
  //console.log("ModalCP Render!!!!: ", "pedido"+pedido, "Fixo"+fixo);
  const opcoes = [
    "O cliente não conseguiu formar um time",
    "O cliente solicitou o cancelamento",
    "O horário é conflitante com outro agendamento",
    "Estarei fechado nesse horário",
    "Quero reagendar para outro horário",
    "Outro motivo",
  ]
  //console.log("Pedido ModalCP: ", pedido);
 
  React.useEffect(() => {
    if(stt.op !== null) setStt({...stt, libera: true});
    else                setStt({...stt, libera: false});
  }, [stt.op]);

  
  // Cancelamento de pedido
  async function ChamaCancelPedido(motivo){
    if(stt.op === 5) {
      if(motivo.length < 5){
        Swal.fire({
          icon  : "warning",
          title : "Atenção",
          text  : "Você deve informar um motivo para cancelar o agendamento",
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
          timer: 6000,
        });
        return;
      }
    }
      Swal.fire({
        icon  : "warning",
        title : pedido.mensalista_idmensalista !== null ? "Tem certeza que deseja cancelar apenas este agendamento?" : "Tem certeza que deseja cancelar este agendamento?",
        text  : "Você não poderá desfazer essa ação!",
        showCancelButton      : true,
        confirmButtonColor    : '#bf3324',
        cancelButtonColor     : '#00cda4',
        confirmButtonText     : 'Não',
        cancelButtonText      : 'Sim'
      }).then(async (result) => {
        if(!result.isConfirmed && result.dismiss === "cancel"){
          setStt({...stt, loading: true});
          //console.log("Motivo: ChamaCancelPedido", motivo)
          if(stt.op === 5) await onClick(motivo);
          else await onClick(opcoes[stt.op]);
          ClosedModal();
          setStt({...stt, loading: false});
        }
      });
  }

  async function ChamaCancelMensalista(motivo){
    if(stt.op === 5) {
      if(motivo.length < 5){
        Swal.fire({
          icon  : "warning",
          title : "Atenção",
          text  : "Você deve informar um motivo para cancelar o agendamento",
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
          timer: 6000,
        });
        return;
      }
    }
    Swal.fire({
      icon  : "warning",
      title : "Tem certeza que deseja cancelar o horário fixo inteiro?",
      text  : "Atenção! Todos os agendamentos desse contrato fixo serão cancelados e você não poderá desfazer esta ação.",
      showCancelButton      : true,
      confirmButtonColor    : '#bf3324',
      cancelButtonColor     : '#00cda4',
      confirmButtonText     : 'Não',
      cancelButtonText      : 'Sim'
    }).then(async (result) => {
      if(!result.isConfirmed && result.dismiss === "cancel"){
        setStt({...stt, loading: true});
        //console.log("Motivo: ChamaCancelPedido", motivo)
        if(stt.op === 4) await onClickMens(motivo);
        else await onClickMens(opcoes[stt.op]);
        ClosedModal();
        setStt({...stt, loading: false});
      }
    });
  }
  // useEffect para pegar o enter e enviar o formulário
  //console.log("Pedido: ", pedido);

  const [motivo, setMotivo]         = React.useState("");
  React.useEffect(() => {
    if(modal){
      const handleKeyDown = async (e) => {
        if(e.key === "Enter"){
          ChamaCancelPedido(motivo);
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [modal]);
 

  return (
    <Modal
      isOpen            = {modal}
      className         = "contain-content-modal-cancel-pedido"
      overlayClassName  = "contain-overlay-modal-cancel-pedido"
      onRequestClose    = {ClosedModal}
    >
      <button 
        className = "btt-closed-x"
        onClick   = {ClosedModal}
      >
        <BiX
          size = {30}
        />
      </button>
      
      <div className = "contain-modal-cancel-pedido">
        <h1>CANCELAMENTO</h1>
        { stt.loading ? 
          <div className = "contain-loading" style={{flexDirection: "column"}}>
            <Loading 
              mode  = {true}
              text  = {"Cancelando..."}
              color = {true}
            />
            <h2 className = "sub-title-cancel-pedido">
            </h2>
          </div>
            :
            <>
            {/* Formulario */}
            <h2 className="sub-title-cancel-pedido">
              Conta para gente, qual o motivo do cancelamento?
            </h2>
            <div className = "contain-modal-formulario">
              {
                opcoes.map((opcao, index) => {
                  return (
                    <SeletoRadio
                      key       = {index}
                      textA     = {opcao}
                      textB     = {""}
                      select    = {stt.op === index ? true : false}
                      onClick   = {() => {
                        if(stt.op === index) setStt({...stt, op: null});
                        else                 setStt({...stt, op: index})
                      }}
                    />
                  )
                })
              }
              {
                stt.op === 5 &&
                <div className = "contain-modal-qual-pedido">
                  <h3>Qual o motivo?</h3>
                  <textarea
                    value     = {motivo}
                    onChange  = {(e) => {
                      setMotivo(e.target.value);
                    }}
                  />
                </div>
              }
            </div>
            <button
              className = "btt-cancelar-pedido-chat"
              onClick   = {onClickChat}
            >
              Falar com o Cliente
              <HiChatAlt2
                size  = {25}
                style = {{marginLeft: "10px"}}
              />
            </button>
            <button
              className = {stt.libera ? (stt.op === 5 ? (motivo.length > 5 ? "btt-cancelar-pedido" : "btt-cancelar-pedido-disabled") : "btt-cancelar-pedido") : "btt-cancelar-pedido-disabled"} 
              style = {pedido.mensalista_idmensalista !== null ? {marginBottom: "3px"} : {}}
              onClick = {() => {
                if(stt.libera === true ){
                 //console.log("stt.op: ", stt.op);
                  if(stt.op !== 5)          ChamaCancelPedido(motivo);
                  else{
                    if(motivo.length > 5)   ChamaCancelPedido(motivo);
                    else {
                      Swal.fire({
                        icon  : "warning",
                        title : "Atenção",
                        text  : "Você deve informar um motivo para cancelar o agendamento, com no mínimo 5 caracteres.",
                        confirmButtonText   : "Entendi",
                        confirmButtonColor  : "#DA5A34",
                        timer: 6000,
                      });
                    } 
                  }
                } 
                else Swal.fire({
                  icon  : "warning",
                  title : "Atenção",
                  text  : "Selecione um motivo para cancelar o agendamento",
                  confirmButtonText   : "Entendi",
                  confirmButtonColor  : "#DA5A34",
                  timer: 6000,
                });
              }}
            >
              {pedido.mensalista_idmensalista !== null ? "Cancelar apenas esse horário" : "Cancelar horário"}
            </button> 
            { 
              pedido.mensalista_idmensalista !== null &&
              <button
                className = { stt.libera ? ( stt.op === 5 ? ( motivo.length > 5 ? "btt-cancelar-pedido-hf" : "btt-cancelar-pedido-disabled" ) : "btt-cancelar-pedido-hf") : "btt-cancelar-pedido-disabled"} 
                
                onClick = { () => {
                  if(stt.libera === true ){
                    if(stt.op !== 5)          ChamaCancelMensalista(motivo);
                    else{
                      if(motivo.length > 5)   ChamaCancelMensalista(motivo);
                      else {
                        Swal.fire({
                          icon  : "warning",
                          title : "Atenção",
                          text  : "Você deve informar um motivo para cancelar o agendamento, com no mínimo 5 caracteres.",
                          confirmButtonText   : "Entendi",
                          confirmButtonColor  : "#DA5A34",
                          timer: 6000,
                        });
                      } 
                    }
                  } 
                  else Swal.fire({
                    icon  : "warning",
                    title : "Atenção",
                    text  : "Selecione um motivo para cancelar o agendamento",
                    confirmButtonText   : "Entendi",
                    confirmButtonColor  : "#DA5A34",
                    timer: 6000,
                  });
                }}
              >
                {pedido.mensalista_idmensalista !== null ? "Cancelar o horário fixo inteiro" : "Cancelar horário"}
              </button> 
            }
          </>
        }
      </div>
    </Modal>
  );
}