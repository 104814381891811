const PedidosAPI = {
  AvaliarPedido : async (token, blocoAvals) => {
    const reqs = await avaliar_pedido(token, blocoAvals);
    if(reqs.retorno) return {status : true, avaliacao: reqs.registro};
    else return {status : false, msg : "Peço desculpa, estamos com instabilidade no nosso servidor. Por favor, tente mais tarde.", req: reqs};
  },
  BuscarPedidoId : async (token, idpedido) => {
    const ress = await buscar_pedido_id(token, idpedido);
    if(ress.status) return {status : true,    req: ress.req};
    else            return {status : false,   req: ress};
  },
  CancelarPedido : async ( id, motivo, nome, token) => {
    //console.log("Cancelar Pedido - API", id, motivo, nome, token);
    const data = {
      idpedido             : id,
      nomeGestor           : nome,
      motivoCancelamento   : motivo
    }
    //console.log("DATA - Cancelar Pedido", data);
    const ress = await cancelar_reserva(token, data);
    if(ress.status) return {status : true, res: ress.reqs};
    else            return {status : false, res: ress.reqs};
  },
} ;

export default PedidosAPI ;

// Avaliar Pedido
const avaliar_pedido = async (tkn, data) => {
  try {
    const url = `${process.env.REACT_APP_URL_API}/avaliacao/avaliarPedido/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${tkn}`
      },
      body      : JSON.stringify(data)
    });
    const ress = await req.json();
    //console.log("Avaliar Pedido: ", ress);
    return ress;
  } catch(err) {
    return { retorno : false, reqs : err};
  }
};
// Buscar pedido por id
const buscar_pedido_id = async (token, idpedido) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/buscarPorId/?id=${idpedido}`;
    const req = await fetch(url, {
      method  : "GET",
      headers : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "accept"        : "application/json",
        "Authorization" : `Bearer ${token}`
      }
    });
    const ress = await req.json();
    //console.log("Resposta Pedido: ", ress);
    return {status: true,  req: ress};
  } catch (err){
    return {status : false, req : err};
  }
};
// Cancelar Pedido
const cancelar_reserva = async (token, data) => {
  try{
    const url = `${process.env.REACT_APP_URL_API}/pedidos/cancelar/?isGestor=${true}`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${token}`
      },
      body      : JSON.stringify(data)
    })
    const ress = await req.json();
    //console.log("Resposta Cancelar Reserva: ", ress);
    return {status : true, reqs : ress};
  } catch(err) {
    return {status : false, reqs : err};
  }
};


