import React                  from 'react';
import { useNavigate }        from 'react-router-dom';
import { AuthContext }        from './AuthContext';
import { LocalStorage }       from '../services';
import { Alerts }             from '../components/Basics';

export default function RouterHook(){
  const navigate    = useNavigate();
  const { date }    = React.useContext(AuthContext);
  const serverRouter = (rota, query) => {
    switch(rota){
      case "back"             :   return navigate(-1);

      case "login"            :   return navigate(`/`);

      case "menu"             :   return navigate(`/centro-esportivo/${query ? query : date.empresa.username}`);

      case "chat"             :   return navigate(`/centro-esportivo/${date.empresa.username}/conversas`);

      case "settings"         :   return navigate(`/centro-esportivo/${date.empresa.username}/configuracoes/${query}`);

      case "details-appoint"  :   return navigate(`/centro-esportivo/${date.empresa.username}/agendamento/${query}`);
    
      case "buscar"           :   return navigate(`/centro-esportivo/${date.empresa.username}/buscar-agendamentos`);

      case "hora-fixa"        :  {
        if(!date.perfilG.gerenciarMensalistas) return Alerts.aviso("Aviso", "Parece que você ainda não tem permissão para acessar esta área. Verifique com o administrador para mais informações.")
        return navigate(`/centro-esportivo/${date.empresa.username}/horarios-fixos`);
      }

      case "ajuda"            :   return navigate(`/centro-esportivo/${date.empresa.username}/ajuda`);

      case "diaria"           :   return navigate(`/centro-esportivo/${date.empresa.username}/diarias`);

      case "clientes"         :  {
        if(!date.perfilG.verClientes) return Alerts.aviso("Aviso", "Parece que você ainda não tem permissão para acessar esta área. Verifique com o administrador para mais informações.")
        return navigate(`/centro-esportivo/${date.empresa.username}/clientes`);
      }

      case "avaliacoes"       :   return navigate(`/centro-esportivo/${date.empresa.username}/avaliacoes`);

      case "aspectos-legais"  :   return navigate(`/centro-esportivo/${date.empresa.username}/aspectos-legais/${query}`);

      case "fluxo-caixa"      :   {
        LocalStorage.remove("sttPageFluxoCaixa");
        return navigate(`/centro-esportivo/${date.empresa.username}/fluxo-caixa`);
      }

      //case "estatisticas"     :   return navigate(`/centro-esportivo/${date.empresa.username}/retrospectiva-empresa-2023`);

      case "cupons"           :   return navigate(`/centro-esportivo/${date.empresa.username}/cupons`);

      case "assinatura"       :   return navigate(`/centro-esportivo/${date.empresa.username}/assinatura`);
    }
  }

  return serverRouter;
}
