import React              from "react";
import {
  QuadraHor, EmpresaDiaria,
} from "../../Complext";
import { 
  Alerts, InfoStats, Loading 
} from "../../Basics/";
import Swal               from "sweetalert2";
import { DiasRoler }      from "../../Complext";
import { notAgend, search }         from "../../../assets";
// Contextos
import { AuthContext }    from "../../../contexts/AuthContext"; 
import RouterHook         from "../../../contexts/RouterHook";
import CheckoutGestor     from "../CheckoutGestor/CheckoutGestor";
import { 
  LocalStorage, Services 
} from "../../../services";
import {
  EmpresaAPI
} from "../../../servicesAPI";
import "./stylesMA.css";

export default function MinhaAgend({handleBttSel}) {
  
  const serverRouter          = RouterHook();
  const { 
    date, token, isAuth, handleDate, modalOpen, handleMOP
  } = React.useContext(AuthContext);

  function ReturnAmplitude(){
    const width = window.innerWidth;
    if(width > 1200)      return 4;
    else if(width > 1000) return 3;
    else if(width > 800)  return 3;
    else if(width > 768)  return 2;
    // Aqui para baixo vai para mobile e diminui o tamanho dos dayCircle!
  }
  console.log("Date: ", date);
  const stateBuffer                 = LocalStorage.get("home-company-desktop");
  const amplitude                   = ReturnAmplitude();
  const [loading, setLoading]       = React.useState(false);
  const [loadingQdr, setLoadingQdr] = React.useState(false);
  const [horarios, setHorarios]     = React.useState([]);

  const dataDay                     = new Date();
  const [daySel, setDaySel]         = React.useState(stateBuffer !== null ? new Date(stateBuffer.daySel) : dataDay);
  const [dataMoment, setDataMoment] = React.useState(stateBuffer !== null ? new Date(stateBuffer.dataMoment) : dataDay);
  const [arrayDias, setArrayDias]   = React.useState(Services.OrganizarDias((stateBuffer !== null ? new Date(stateBuffer.daySel) : dataDay), amplitude));
  const [quadras, setQuadras]       = React.useState([]);
  const [marginBtt, setMarginBtt]   = React.useState({margin:0, index: 0});
  const [horaSels, setHoraSels]     = React.useState([]);
  const [checkout, setCheckout]     = React.useState(stateBuffer !== null ? stateBuffer.checkout : false);
  
  React.useEffect(() => {
    setHoraSels([]);
  }, [checkout]);

  React.useEffect(() => {
    LocalStorage.set("home-company-desktop", {
      dataMoment : daySel,
      daySel,
      checkout,
    });
  }, [daySel, dataMoment]);

  // Escuta o popstate
  React.useEffect(() => {
   //console.log("ModalOpen: ", modalOpen);
    function handleBttBack(){
      if(modalOpen){
        handleMOP(false);
      } else {
        if(checkout)  setCheckout(false);
        else          serverRouter("back");
      }
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBttBack);
    return () => window.removeEventListener("popstate", handleBttBack);
  }, [ modalOpen, checkout ]);

  // Adiciona o nome da página ao título da página
  React.useEffect(() => {
    if (checkout)   document.title = "Criar Agendamento | Gestor Agendei";
    else            document.title = "Início | Gestor Agendei";
  }, [checkout]);

  // Função que retorna os horarios da empresa ou as diarias.
  async function getData(day){
    setLoading(true);
    //console.log("Chamou GetDate!");
    //console.log("Dia (dayStr): ", dayStr);
    // Dia
    const [resp, resp_per] = await Promise.all([
      EmpresaAPI.BuscaQuadrasEmpresa(token, date.empresa.idempresa),
      EmpresaAPI.BuscarPerfilAcesso( token, date.gestor.idusuario, date.empresa.idempresa),
    ]);
    if(resp.status && resp_per.status) {
      //console.log("Date Aqui1: ", date);
      date.empresa.quadras  = resp.req;
      date.perfilG          = resp_per.req.registro;
      //console.log("Perfil: ", resp_per.req.registro);
      //console.log("Date Aqui2: ", date);
      // Busca a agenda das quadras
      const day  = Services.ReturnToStringDate(daySel);
      //console.log("Day: ", day);
      const promiss = resp.req.map((qdr) => EmpresaAPI.BuscarAgendaQuadra(token, qdr.idquadra, day));
      const array = await Promise.all(promiss);
      //console.log("Arraty: ", array);
      resp.req.map((qdr, idx) => {
        if(!array[idx].status)  qdr.agenda = []; 
        else {
          qdr.agenda = array[idx].req.registro;
          qdr.agenda.map((hr) => {
            hr.quadra_idquadra = qdr.idquadra;
          })
        }
      });//array[idx].req);
      //console.log("Quadras: ", resp.req);
      handleDate(date);
      setQuadras(resp.req);
      setDataMoment(daySel);
    } else {
      Alerts.aviso("Aviso", "Estamos com problemas para carregar as quadras da empresa. Por favor, tente novamente mais tarde");
    }
    setHoraSels([]);
    setMarginBtt({margin:0, index: 0});
    setLoading(false);
    setLoadingQdr(false);
    //console.log("Resposta do perfil: ", resp_per);
    //console.log("Resposta das quadras: ", resp);

    /*
      const perfilAg = {
        "idperfilAcesso"            : 1026,
        "chat"                      : true,
        "aprovar"                   : true,
        "cancelar"                  : true,
        "agendarAvulso"             : true,
        "verAgendaCalendario"       : true,
        "verListaAgendamentos"      : true,
        "gerenciarMensalistas"      : true,
        "configurarValoresAvulsos"  : true,
        "configurarValoresFixos"    : true,
        "gerenciarLicenca"          : true,
        "editarQuadra"              : true,
        "editarEmpresa"             : true,
        "verClientes"               : true,
        "verEstatisticas"           : true,
        "desativarQuadra"           : true,
        "ativarQuadra"              : true,
        "rankingEstados"            : true,
        "rankingCidades"            : true,
        "rankingFaturamento"        : true,
        "gestaoAulas"               : true,
        "fluxoCaixa"                : true,
        "diarias"                   : true
      };
    //*/
    
  }
  const initialRender = React.useRef(false);
  //  
  React.useEffect(() => {    
    if (!initialRender.current) {
      getData(daySel);
      initialRender.current = true;
    }
  }, [daySel]);

  //console.log("Date: ", date);
  // Função que inicia a solicitação de agendamento
  async function StartSolicitAgend(agend, quadra){
    //console.log("Valores recebidos", agend, quadra.idquadra);
    // Verifica se a quadra possui valor definido!
    if(agend.length) {
      //Consumir rota que calcula o valor total do agendamento

      if(!date.perfilG.agendarAvulso){
        return Alerts.aviso("Aviso", "A funcionalidade de agendamento avulso não está disponível para o seu perfil. Para mais informações ou para habilitar essa opção, por favor, contate o administrador do sistema ou verifique as configurações no aplicativo Gestor Agendei.");
      }

      setLoading(true);
      let valorTotal = 0;
      agend.map((horario) => {
        valorTotal += horario.valor;
      });
      LocalStorage.set("checkout-gestor", {
        horarioAvulso : true,
        // ordena os horarios por ordem crescente
        agenda        : agend.sort((a, b) => new Date(a.dataInicio) - new Date(b.dataInicio)),
        quadra        : quadra,
        valorTotal    : valorTotal,
        dia           : daySel,
      });
      setCheckout(true);
      setLoading(false);

    } else {
      Swal.fire({
        confirmButtonText   : "Entendi",
        title               : "Aviso",
        text                : "Selecione um horário para continuar",
        icon                : "warning",
        confirmButtonColor  : "#DA5A34",
        allowEnterKey       : true,
      });
    }
  }
  
  // Função que navega entre os dias e os seleciona!
  function ChangeDay(type, day) {
    if(type === "next-left") {
      // Bloquear o botão caso o daySel seja o dia de hoje!
      //if(dataMoment.getDate() === dataDay.getDate() && dataMoment.getMonth() === dataDay.getMonth() && daySel.getFullYear() === dataDay.getFullYear()) return; // Verifica se o dia é hoje!
      //if((arrayDias[0].getTime() - dataDay.getTime()) < 0) return; // Verifica se o index == 0 é menos que hoje!
      // O arrayDias deve ser deslocado para a esquerda
      // O primeiro elemento do arrayDias deve ser o dia anterior ao primeiro elemento do arrayDias
      // O último elemento do arrayDias deve ser o dia anterior ao primeiro elemento do arrayDias
      // O arrayDias deve ser atualizado
      const diaAux = new Date(arrayDias[amplitude]);
      diaAux.setDate(diaAux.getDate() - (amplitude * 2));
      const array = Services.OrganizarDias(diaAux, amplitude);
      setArrayDias(array);
      setDataMoment(array[amplitude]);
    } else if(type === "next-right") {
      // O arrayDias deve ser deslocado para a direita
      // O primeiro elemento do arrayDias deve ser o dia posterior ao último elemento do arrayDias
      // O último elemento do arrayDias deve ser o dia posterior ao último elemento do arrayDias
      // O arrayDias deve ser atualizado

      // Verifica se tem regra de agendamento limite!
      
      const diaMax = new Date();
      // Sempre verifica se não passou 1 ano!
      diaMax.setDate(diaMax.getDate() + 364);
      if(arrayDias[arrayDias.length - 1].getDate() >= diaMax.getDate() && arrayDias[arrayDias.length - 1].getMonth() >= diaMax.getMonth() && arrayDias[arrayDias.length - 1].getFullYear() >= diaMax.getFullYear())
        return Swal.fire({
          icon                : "warning",
          title               : "Aviso",
          text                : "Só é possível agendar até um ano a partir de hoje",
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
          allowEnterKey       : true,
          timer               : 6000,
        });

      const diaAux = new Date(arrayDias[amplitude]);
      diaAux.setDate(diaAux.getDate() + (amplitude * 2));
      const array = Services.OrganizarDias(diaAux, amplitude);
      setArrayDias(array);
      setDataMoment(array[amplitude]);
    } else if(type === "selected") { // tratei para não selecionar dias anteriores a hoje!
      setLoading(true);
      // O arrayDias deve ser atualizado com o dia selecionado no centro
      const array = Services.OrganizarDias(day, amplitude);
      initialRender.current = false;
      setArrayDias(array);
      setDaySel(day);
    }
  }

  // Função que verifica se o dia permite marcar horários segundo a regra de agendamento!
  function ValidaAgendamento(qdr, idx){
    //console.log("ValidaAgendamento", daySel);
    let stt = false;
    if(qdr.regraAgendamento.maximoTempoAgendamento === 0) stt = true;
    else { // Calcula o dia limite para agendamento! e se ja passou desse dia!
      const data = new Date(); // Data atual!
      data.setDate(data.getDate() + qdr.regraAgendamento.maximoTempoAgendamento);
      // Verifica se o daySel é maior que a data limite pelo dia, mês e ano! 
      if(data.getTime() > daySel.getTime()){
        stt = true;
      }
    }
    // Verifica se a outra quadra ta selecionada!
    if(marginBtt.margin > 0) {
      if(quadras[idx] === quadras[marginBtt.index]){ 
        stt = true;
        //console.log("Aqui tem! true: " + quadras[idx].descricao);
      } else {
        stt = false;
        //console.log("Aqui não tem! false: " + quadras[idx].descricao);
      }
    }
    return stt;
  }
  //console.log("MarginBtt: ", marginBtt);
  return (
    <>
      { !date.perfilG.verAgendaCalendario ?
        <div className="contain-minha-agenda">
          <div className = "contain-lists-quadras">
            <div className = "contain-list-aviso-not-quadras">
              <img src={notAgend}/>
              <h3 style = {{color: "white", fontSize: "22px"}}>
                Acesso restrito
              </h3>
              <h3 style = {{color: "white"}}>
                Parece que você ainda não tem permissão para acessar esta área. 
              </h3>
              <h3 style = {{color: "white", marginTop: "-15px"}}>
                Verifique com o administrador para mais informações.
              </h3>
            </div>
          </div>
        </div>
        : !checkout ?
        <div className="contain-minha-agenda">
          <div className = "contain-lists-quadras">
            
            { loading ?
              <div className = "contain-loading">
                <Loading
                  mode      = {true}
                  text      = {"Carregando..."}
                />
              </div>
                :
              <>
                <div className = "contain-mes-dias">
                  <h1 className = "title-list-quadras">
                    {"Minha Agenda"}
                  </h1>
                </div>

                <h2 className = "title-list-agend">{`${Services.RetornaMes(dataMoment.getMonth() + 1)} ${dataMoment.getFullYear()}`}</h2>
                {/* Componente Que seleciona os Dias */}
                
                <DiasRoler
                  arrayDias   = {arrayDias}
                  daySel      = {daySel}
                  ChangeDay   = {(cmd, dia) => ChangeDay(cmd, dia)}
                />

                <div className = "contain-quadras-horarios"
                  style = {{marginBottom: marginBtt.margin}}
                >
                  { 
                    quadras.length === 0 ?
                    <div className = "contain-list-aviso-not-quadras">
                      <img src={search}/>
                      <h3>No momento não há quadras disponíveis para agendamento!</h3>
                    </div>
                      :
                    quadras.map((quadra, index) => {
                      return (
                        <QuadraHor
                          id          = {isAuth ? date.gestor.idusuario : null}
                          idx         = {index}
                          setMargin   = {(value) => setMarginBtt(value)}
                          key         = {index}
                          lib         = {ValidaAgendamento(quadra, index)}
                          quadra      = {quadra}
                          day         = {daySel}
                          horaSels    = {horaSels}
                          onClick     = {(hrSel, qdr) => StartSolicitAgend(hrSel, qdr)}
                          handleBttSel= {() => handleBttSel("details-agend")}
                          loadingQdr  = {loadingQdr}
                          horarios    = {quadra.agenda}
                        />
                      );
                    })  
                  }
                </div>
              </>
            }
          </div>
        </div>
          :
        <CheckoutGestor
          handleBttState  = {() => setCheckout(false)}
        />
      }
    </>

  );

}