import React                  from "react";
// Components da screen
import Modal                  from "react-modal";
import { CompChat, Loading }  from "../../Basics/";
import { AuthContext }        from "../../../contexts/AuthContext";
import { ChatContext }        from "../../../contexts/ChatContext";
import RouterHook             from "../../../contexts/RouterHook";
import { 
  MdOutlineMarkChatRead, MdOutlineMarkChatUnread 
} from "react-icons/md";

import "./stylesMChat.css";
import { state } from "@antv/g2plot/lib/adaptor/common";

Modal.setAppElement('#root');

export default function ModalChat({modal, ClosedModal, elementRef}) {

  const serverRouter                = RouterHook();
  const listRef                     = React.useRef(null);
  const { date }                    = React.useContext(AuthContext);
  const { states, actions }         = React.useContext(ChatContext);
  const [position, setPosition]     = React.useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });
  const [loadCmp, setLoadCmp]       = React.useState({
    load  : false,
    idx   : null
  });
  //console.log("ChatContext: ", states);                          
  // Função que atualiza o tamanho da janela
  React.useEffect(() => {
    // Função que atualiza o tamanho da janela
    //console.log("ModalChat: ", modal);
    actions.handleLoad(1, true);
    actions.handleChatOn(true);
    const handleWindowResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    // Adiciona o evento de resize
    window.addEventListener("resize", handleWindowResize);
    // Inicia o timer para o chat!
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [modal]);

  // Função que atualiza a posição do modal
  React.useEffect(() => {
    const { current } = elementRef;
    // Verifica se o elemento existe
    if (current) 
      // Atualiza a posição do modal
      setPosition({ 
        x : current.offsetLeft, 
        y : current.offsetTop
      });
  }, [elementRef, windowSize]);

  let flagRef = React.useRef(false);
  React.useEffect(() => {
    if(modal){
      //console.log("Tentou ativar o evento Scroll!");
      function handleScroll() {
        const scrollTop     = listRef.current.scrollTop;
        const scrollHeight  = listRef.current.scrollHeight;
        const clientHeight  = listRef.current.clientHeight;
        //console.log("handle Scroll! Ativado");
        //console.log("ScrollTop + clienteHeight: ", (scrollTop + clientHeight));
        //console.log("ScrollHeight: ", scrollHeight);
        //console.log("Teste! scrollTop + clientHeight >= scrollHeight:" , (scrollTop + clientHeight >= scrollHeight))
        //console.log("ScrollTop: ", flag);
        if(states.modeCE){
          if (scrollTop + clientHeight >= scrollHeight && !flagRef.current && states.paginaP.maxScroll > states.paginaP.offset){
            flagRef.current = true;
            actions.BuscarCaixaEntradaPaginado().then(() => {
              flagRef.current = false;
            });
          }
        } else {
          if (scrollTop + clientHeight >= scrollHeight && !flagRef.current && states.paginaE.maxScroll > states.paginaE.offset){
            flagRef.current = true;
            actions.BuscarCaixaEntradaPaginado().then(() => {
              flagRef.current = false;
            });
          }
        }
      }
      if(listRef.current) {
          //console.log("Adicionando Evento Scroll!", listRef.current.removeEventListener)
          listRef.current.addEventListener("scroll", handleScroll);
      }
      return () => {
        if(listRef.current) listRef.current.removeEventListener("scroll", handleScroll);
      }
    }
  }, [ modal, listRef, states.loadingCE, states.modeCE, states.paginaP, states.paginaE]);
  // Função que vai para a tela de chat
  async function GoChat(chat, index) {
    //console.log("Chat Aqui!!!: ", chat);
    setLoadCmp({
      load  : true,
      idx   : index
    });
    if(chat !== null) await actions.ReturnChatMsg(chat.idchat, index);
    serverRouter("chat", date.empresa.username);
    setLoadCmp({
      load  : false,
      idx   : null
    });
    ClosedModal();
  }
  //console.log("States: ", states);
  return (
    <Modal
      id                  = "id-modal-chat"
      isOpen              = {modal}
      className           = "contain-content-chat"
      overlayClassName    = "contain-overlay-chat"
      onRequestClose      = {ClosedModal}
      style     = {{
        content : {
          top   : position.y + 70,
          left  : position.x - 250,
        }
      }}
    >
      <div
        className = "contain-ref-arrow-top-chat"
        style     = {{ 
          top   : position.y + 53,
          left  : position.x + 10 
        }}
      />

      <div className = "contain-modal-chat-title">
        <h2>Conversas</h2>
      </div>
      <div className = "contain-handle-peds-diars" style = {{padding: "0px var(--padding-mid)"}}>
      <button
        className = { states.modeCE ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
        style    = {{justifyContent: "space-around"}}
        onClick   = {() => {
          if(states.loadingCE || states.modeCE) return;
          actions.handleLoad( 1, true );
          actions.handleModeCE( true );
        }}
      >
        { states.chatMNLP ?
          <MdOutlineMarkChatUnread
            className = "icon-chat-not-read-handle"
          /> 
            : 
          <MdOutlineMarkChatRead
            className = "icon-chat-read-handle"
          />
        }
        <h2 className = "text-btts-handle-chat">
          Agendamentos
        </h2>
      </button>
      <button
        className = { !states.modeCE ? "btt-select-ped-dia-select" : "btt-select-ped-dia" }
        style     = {{justifyContent: "space-around"}}
        onClick   = {() => {
          if(states.loadingCE || !states.modeCE) return;
          actions.handleLoad( 1, true );
          actions.handleModeCE( false );
        }}
      > 
        { states.chatMNLE ?
          <MdOutlineMarkChatUnread
            className = "icon-chat-not-read-handle"
          /> 
            : 
          <MdOutlineMarkChatRead
            className = "icon-chat-read-handle"
          />
        }
        <h2 className = "text-btts-handle-chat">
          Mensagens Diretas
        </h2>
      </button>
      </div>
      <div
        ref       = {listRef} 
        className = "contain-modal-chat-list-cc"
      >
        { states.modeCE ?
          states.chat_CEP === null || states.loadingCE ? 
          <div className = "contain-loading">
            <Loading 
              mode = {true}
              text = "Carregando conversas..."
            /> 
          </div>
            : 
          states.chatEmpyP ?   
          <h3 className = "text-aviso-modal-chat">
            Realize um agendamento e inicie uma conversa com o gestor para ver suas conversas.
          </h3>
          : 
          <>
            {
              states.chat_CEP.map((item, index) => (
                <>
                  {
                    (loadCmp.load && loadCmp.idx === index) ?
                    <div style = {{height: "90px"}} key = {index}>
                      <Loading mode = {false} /> 
                    </div>
                      :
                    <CompChat
                      key     = {index}
                      chat    = {item}
                      onClick = {async (chat) => await GoChat(chat, index)}
                    />
                  }
                </>
              ))
            }{
              states.loadingCEPg && <Loading mode = {false}/> 
            }
          </>
            : // Fim do modo de agendamentos, agora é chat_CEE
          states.chat_CEE === null || states.loadingCE ?
          <div className = "contain-loading">
            <Loading 
              mode = {true}
              text = "Carregando conversas..."
            />
          </div>
            :
          states.chatEmpyP ?
          <h3 className = "text-aviso-modal-chat">
            Realize um agendamento e inicie uma conversa com o gestor para ver suas conversas.
          </h3>
            :
          <>
            {
              states.chat_CEE.map((item, index) => {
                return (
                  <>
                    {
                      (loadCmp.load && loadCmp.idx === index) ?
                      <Loading mode = {false} /> :
                      <CompChat
                        key     = {index}
                        chat    = {item}
                        onClick = {(chat) => GoChat(chat, index)}
                      />
                    }
                  </>
                );
              })
            } {
              states.loadingCEPg && <Loading mode = {false}/> 
            }
          </>
        }
      </div>
      
      {
        (!states.chatVazio && !states.loadingCE) ? 
        <button
          className = "btt-acess-mensg"
          onClick   = {() => {
            if(states.modeCE && states.chat_CEP.length > 0) GoChat(states.chat_CEP[0], 0);
            else if(!states.modeCE && states.chat_CEE.length > 0) GoChat(states.chat_CEE[0], 0);
            else {
              GoChat(null);
            }
          }}
        >
          Ver todas conversas
        </button> : <></>
      }
    </Modal>
  );
}