import React                from "react";
// Components da screen
import Modal                from "react-modal";
import { Alerts, ClosedX, Loading } from "../../Basics";

import "./stylesMR.css";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { BiCalendar, BiX } from "react-icons/bi";
import Calendar from "react-calendar";
import { Services } from "../../../services";

Modal.setAppElement('#root');

export default function ModalSelHF({modal, ClosedModal, onClick, mode}) {
  
  const [sttInput, setSttInput]         = React.useState({
    obser : "",
    valor : "0,00",
    data  : new Date(),
  });
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [loading, setLoading]           = React.useState(false);

  const av1 = mode === "receita" ? "Preencha o campo de observação da receita com a descrição da receita." : "Preencha o campo de observação da despesa com a descrição da despesa.";
  const av2 = mode === "receita" ? "Preencha o campo de valor da receita, você não pode adicionar uma receita de valor R$0,00." : "Preencha o campo de valor da despesa, você não pode adicionar uma despesa de valor R$0,00.";

  function handleValue(e) {
    let value = e.target.value.replace(/[^\d]/g, "");

    // Se o valor não for vazio, converta-o.
    if (value !== "") {
        // Interpretamos o valor como centavos.
        let cents = parseInt(value);
        if (isNaN(cents) || cents === 0) {
          value = "0,00";
        } else {
          // Converte os centavos em uma representação decimal.
          value = (cents / 100).toFixed(2);
        }
    } else {
        value = "0,00";
    }
    setSttInput({...sttInput, valor: value});
  }

  function ValidaForms(){
    if(sttInput.obser === ""){
      Alerts.aviso("Atenção", av1);
      return;
    }
    if(sttInput.valor === "0,00"){
      Alerts.aviso("Atenção", av2);
      return;
    }
    setLoading(true);
    // transforma a data em string : "2022-05-30T15:01:00.000Z"
    const str = sttInput.data.toISOString();
    //console.log("str", str);  
    onClick(sttInput.obser, sttInput.valor, sttInput.data).then((res) => {
      ClosedModal();
      setLoading(false);
    });
  }
  if(mode === "receita"){
    return (
      <Modal
        isOpen              = {modal}
        className           = "contain-content-receita"
        overlayClassName    = "contain-overlay-receita"
        onRequestClose      = {() => {ClosedModal();}}
        style               = {
          showCalendar ? 
            {
              content: {
                minHeight: "530px"
              }
            } :
            {}
        }
      >
        { loading ?
          <div className = "contain-loading" style = {{height: "400px"}}>
            <Loading
              mode    = {true}
              text    = "Adicionando Receita..."
            />
          </div> :
          <div className = "contain-add-receita">
          <BiX
            className = "icon-closed-creg"
            size  = {30}
            onClick = {() => {ClosedModal();}}
          />
          <h1>
            <FaArrowTrendUp
              className = "icon-title-creg"
              size  = {35}
              color = {"white"}
              style = {{marginLeft: "10px"}}
            />
            Adicionar Receita
          </h1>
          <div className = "contain-input-creg">
            <h2>
              Que receita é essa?
            </h2>
            <textarea
              type        = "text"
              placeholder = "Ex: Conta de luz, aluguel..."
              value       = {sttInput.obser}
              onChange    = {(e) => {
                // Adicionar filtro de tamanho máximo de caracteres 75 caracteres
                if(e.target.value.length > 75) {
                  Alerts.aviso("Atenção", "O campo de observação da receita não pode ter mais de 75 caracteres.");
                  return;
                }
                setSttInput({...sttInput, obser: e.target.value});
              }}
            />
          </div>
          <div className = "contain-input-creg">
            <h2>
              Valor
            </h2>
            <div className = "contain-input-line-creg">
              <h2
                // se valor 0.00 cor do placeholder cinza
                style = {sttInput.valor === "0,00" ? {color: "#A4A4A4"} : {}}
              >R$ </h2>
              <input
                type        = "text"
                placeholder = "R$ 0,00"
                style={sttInput.valor === "0,00" ? {color: "#A4A4A4"} : {}}
                value       = {sttInput.valor.toString().replace(".", ",")}
                onChange    = {(e) => {handleValue(e)}}
              />
            </div>
          </div>
          <div className = "contain-input-creg">
            <h2>
              Qual a data que deseja registrar?
            </h2>
            <div className = "contain-calendar-creg">
              <h2 
                onClick={() => {setShowCalendar(!showCalendar);}}
              >
                {ReturnStringFormat(sttInput.data)}
              </h2>
              {
                showCalendar &&
                <Calendar
                  className   = "calendar-select-data-creg"
                  style       = {
                    {}
                  }
                  value       = {sttInput.data}
                  view        = "month"
                  onChange    = {(e) => {
                    setSttInput({...sttInput, data: e});
                    setShowCalendar(false);
                  }}
                  formatMonthYear={(locale, date) => {
                    const month = date.toLocaleString(locale, { month: 'long' });
                    const year = date.getFullYear();
                    return `${month.charAt(0).toUpperCase() + month.slice(1)} de ${year}`;
                  }}
                />
              }
            </div>
            <BiCalendar
              className = "icon-calendar-fx-creg"
              size      = {40}
              onClick   = {() => {setShowCalendar(!showCalendar);}}
            />
          </div>
          <div className = "line-border-bottom" style = {{backgroundColor: "white", width: "97%", opacity: "0.5"}}/>
          <button
            className = "btt-add-receita"
            onClick   = {() => {
              ValidaForms();
            }}
          >
            Adicionar Receita
          </button>
          </div>
        }
      </Modal>
    );
  } else {
    return (
      <Modal
        isOpen              = {modal}
        className           = "contain-content-despesa"
        overlayClassName    = "contain-overlay-receita"
        onRequestClose      = {() => {ClosedModal();}}
        style               = {
          showCalendar ? 
            {
              content: {
                minHeight: "530px"
              }
            } :
            {}
        }
      >
        { loading ?
          <div>
            <Loading
              mode    = {true}
              text   = "Adicionando Despesa..."
            />
          </div> :
          <div className = "contain-add-receita">
          <BiX
            className = "icon-closed-creg"
            size  = {30}
            onClick = {() => {ClosedModal();}}
          />
          <h1>
            <FaArrowTrendDown
              className = "icon-title-creg"
              size  = {35}
              color = {"white"}
              style = {{marginLeft: "10px"}}
            />
            Adicionar Despesa
          </h1>
          <div className = "contain-input-creg">
            <h2>
              Que despesa é essa?
            </h2>
            <input
              type        = "text"
              placeholder = "Ex: Conta de luz, aluguel..."
              value       = {sttInput.obser}
              onChange    = {(e) => {
                if(e.target.value.length > 75) {
                  Alerts.aviso("Atenção", "O campo de observação da despesa não pode ter mais de 75 caracteres.");
                  return;
                }
                setSttInput({...sttInput, obser: e.target.value});}}
            />
          </div>
          <div className = "contain-input-creg">
            <h2>
              Valor
            </h2>
            <div className = "contain-input-line-creg">
              <h2
                // se valor 0.00 cor do placeholder cinza
                style = {sttInput.valor === "0,00" ? {color: "#C4C4C4"} : {}}
              >R$ </h2>
              <input
                type        = "text"
                placeholder = "R$ 0,00"
                style={sttInput.valor === "0,00" ? {color: "#C4C4C4"} : {}}
                value       = {sttInput.valor.toString().replace(".", ",")}
                onChange    = {(e) => {handleValue(e)}}
              />
            </div>
          </div>
          <div className = "contain-input-creg">
            <h2>
              Qual a data que deseja registrar?
            </h2>
            <div className = "contain-calendar-creg">
              <h2 
                onClick={() => {setShowCalendar(!showCalendar);}}
              >
                {ReturnStringFormat(sttInput.data)}
              </h2>
              {
                showCalendar &&
                <Calendar
                  className   = "calendar-select-data-creg"
                  style       = {
                    {}
                  }
                  value       = {sttInput.data}
                  view        = "month"
                  onChange    = {(e) => {
                    setSttInput({...sttInput, data: e});
                    setShowCalendar(false);
                  }}
                  formatMonthYear={(locale, date) => {
                    const month = date.toLocaleString(locale, { month: 'long' });
                    const year = date.getFullYear();
                    return `${month.charAt(0).toUpperCase() + month.slice(1)} de ${year}`;
                  }}
                />
              }
            </div>
            <BiCalendar
              className = "icon-calendar-fx-creg"
              size      = {40}
              onClick   = {() => {setShowCalendar(!showCalendar);}}
            />
          </div>
          <div className = "line-border-bottom" style = {{backgroundColor: "white", width: "97%", opacity: "0.5"}}/>
          <button
            className = "btt-add-receita"
            onClick   = {() => {
              ValidaForms();
            }}
          >
            Adicionar Despesa
          </button>
          </div>
        }
      </Modal>
    );
  }

  function ReturnStringFormat(date){
    const dias = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    // recebe um new Date() e retorna uma string formatada: "dia,DD/MM/YY"
    let dia = date.getDay();
    let mes = date.getMonth();
    let ano = date.getFullYear();
    let str = "";
    str += dias[dia] + ", ";
    str += date.getDate() + "/";
    str += (mes+1) + "/";
    str += ano.toString().substr(-2);
    return str;
  }
}