import React                  from 'react';
import { BsFillChatLeftFill } from 'react-icons/bs';
import { logoAgendei }               from '../../../assets';
import { BiX, BiXCircle }                from 'react-icons/bi'; 
import './stylesHB.css';

export default function HelpBox({mode}){
  const [sttComp, setSttComp] = React.useState({
    showHelp        : false,
    userClosedAlert : false
  });
  //console.log("Mode: ", mode);
  const closeHelp = () => {
    setSttComp({
      showHelp        : false,
      userClosedAlert : true
    });
  }

  React.useEffect(() => {
    // Só executar o temporizador se o alerta não tivesse sido fechado pelo usuário
    if (!sttComp.userClosedAlert) {
      const timer = setTimeout(() => {
        setSttComp({
          ...sttComp,
          showHelp        : !sttComp.showHelp
        }); // Limpa o estado
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [sttComp]); 

  function RetornaAltura(mode){
    switch(mode){
      case "not"    : return {bottom: "110px"};
      case "total"  : return {bottom: '165px'};
      case "sinal"  : return {bottom: "224px"};
      case "low-d"  : return {bottom: "190px"};
      case "show-d" : return {bottom: "300px"};
    }
  }

  if(window.innerWidth > 767){
    return (
      <div className = 'contain-comp-help-btt'>
        <div 
          className = 'icon-help-btt'
          onClick = {() => {
            // Somente alterar o showHelp se o usuário ainda não tiver fechado o alerta
            if(sttComp.showHelp) window.open("https://www.agendeiquadras.com.br/chatSuporte.html", "_blank"); 
            setSttComp({
              ...sttComp,
              showHelp        : !sttComp.showHelp
            });
          }}
        >
          <BsFillChatLeftFill 
            size = {20}
          />
        </div>
        { sttComp.showHelp &&
          <>
            <div className = 'contain-arrow-right-help'/>
            <div 
              className = 'contain-message-help'
            >
              <div
                onClick={() => {
                  window.open("https://www.agendeiquadras.com.br/chatSuporte.html", "_blank");
                }}
              >
                <img
                  className = 'logo-help-message' 
                  alt       = 'Logo'
                  src       = {logoAgendei}
                />
                <div className  = 'contain-message-help-inter'>
                  <h3>Precisa de ajuda?</h3>
                  <h3>Ficarei feliz em ajudar.</h3>
                </div>
              </div>
              <BiXCircle
                className   = 'icon-closed-help-message'
                onClick     = {closeHelp}
                size        = {20}
              />
            </div>
          </>
        }
      </div>
    );
  } else {
    return (
      <div 
        className = 'contain-comp-help-btt'
        style     = {RetornaAltura(mode)}
      >
        { sttComp.showHelp &&
          <>
            <div 
              className = 'contain-message-help'
            >
              <div
                onClick={() => {
                  window.open("https://www.agendeiquadras.com.br/chatSuporte.html", "_blank");
                }}
              >
                <img
                  alt = 'Logo'
                  className = 'logo-help-message' 
                  src ={logoAgendei}
                />
                <div className  = 'contain-message-help-inter'>
                  <h3>Precisa de ajuda?</h3>
                  <h3>Ficarei feliz em ajudar.</h3>
                </div>
              </div>
              <BiXCircle
                className   = 'icon-closed-help-message'
                onClick     = {closeHelp}
                size        = {20}
              />
            </div>
            <div className = 'contain-arrow-right-help'/>
          </>
        }
         <div 
          className = 'icon-help-btt'
          onClick = {() => {
            // Somente alterar o showHelp se o usuário ainda não tiver fechado o alerta
              if(sttComp.showHelp) window.open("https://www.agendeiquadras.com.br/chatSuporte.html", "_blank"); 
              setSttComp({
                ...sttComp,
                showHelp        : !sttComp.showHelp
              });
          }}
        >
          <BsFillChatLeftFill 
            size = {20}
          />
        </div>
      </div>
    );
  }
}
