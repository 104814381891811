import React                          from "react";
// Componentes
import { 

} from "../../Basics/";
import { BiArrowBack }                from "react-icons/bi";

import { BookSearch, CentralIcon, ChatSearche }    from "../../../assets";
import { MdOutlineOpenInNew }         from "react-icons/md";
import { BsYoutube }                  from "react-icons/bs";
import { HelpBox }                    from "../../Complext/";  
import { AuthContext }                from "../../../contexts/AuthContext";
import RouterHook                     from "../../../contexts/RouterHook";
import "./stylesHp.css";

export default function Help() {
  
  const serverRouter                  = RouterHook();
  const { date, token }               = React.useContext(AuthContext);
  
  // Função que atualiza o tamanho da tela
  React.useEffect(() => {
    document.title = "Ajuda | Agendei Quadras";
  }, []);

  // Função que seta null no chat quando sai da tela!
  

  return(
    <div className = "contain-chat-main">
      <HelpBox/>
      <div className = "contain-list-chats">
        <div className = "contain-list-title-btt">
          <BiArrowBack
            className = "btt-icon-back"
            onClick   = {() => {
              serverRouter("back");
            }}
          />
          <h2>Ajuda</h2>
        </div>
        <div className = "contain-list-caixa-e">
          <button
            className = "btt-select-duvida"
          >
            <ChatSearche
              className = "icon-chat-search"
            />
            FAQ - Dúvidas Frequentes
          </button>
          <button
            className = "btt-new-page-duvidas"
            onClick   = {() => {
              window.open("https://agendeiquadras.com.br/pdf/guia-rapido-agendei.pdf");
            }}
          >
            <BookSearch
              className = "icon-btt-new-page"
            />
            <h3>
              Guia Rápido
            </h3>
            <MdOutlineOpenInNew
              className = "icon-btt-new-page-2"
            />
          </button>
          <button
            className = "btt-new-page-duvidas"
            onClick   = {() => {
              window.open("https://www.youtube.com/playlist?list=PL648Vfg2yLmnRsCOEPBYmqwOLuwzE3IfO");
            }}
          >
            <BsYoutube
              className = "icon-btt-new-page"
            />
            <h3>
              Tutoriais Youtube
            </h3>
            <MdOutlineOpenInNew
              className = "icon-btt-new-page-2"
            />
          </button>
          <button
            className = "btt-new-page-duvidas"
            onClick   = {() => {
              window.open("https://www.agendeiquadras.com.br/chatSuporte.html", "_blank");
            }}
          >
            <CentralIcon
              className = "icon-btt-new-page"
            />
            <h3>
              Chat de Atendimento
            </h3>
            <MdOutlineOpenInNew
              className = "icon-btt-new-page-2"
            />
          </button>
        </div>
      </div>
      <div className = "contain-chat-read-write">
        {<iframe
          title     = "Ajuda"
          className = "iframe-help"
          src       = "https://agendeiquadras.com.br/ajuda.html"
          sandbox   = "allow-scripts allow-same-origin"
        />}
      </div>
    </div>
  );
}