import React from "react";
// Components
import { 
  TxtSwitch, CompInputForm, Loading,
} from "../../Basics/";
import {
  BiPhone, BiCalendar 
} from "react-icons/bi";
import { ModalNewPass }   from "../../Modais";
import { HiOutlineUser }  from "react-icons/hi";
import { BsGenderTrans }  from "react-icons/bs";
import { MdEmail }        from "react-icons/md";
import { BiArrowBack }    from "react-icons/bi";
import Swal               from "sweetalert2";
import { AuthContext }    from "../../../contexts/AuthContext";
import RouterHook         from "../../../contexts/RouterHook";
import validator          from "validator";
import md5                from "md5";
import moment             from "moment";

import "./stylesCfg.css";
import { EmpresaAPI } from "../../../servicesAPI";

export default function Settings() {

  const serverRouter = RouterHook();
  const { 
    date, token, handleDate
  } = React.useContext(AuthContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  //console.log("Usuario", date.gestor);

  function RetornaNascimento(nasc){
    // Verifica se esta neste formato DD/MM/YYYY
    if(nasc === null)       return "";
    if(nasc.includes("/"))  return nasc;
    let data = moment(nasc).format("DD/MM/YYYY");
    return data;
  }

  function RetornaPhone(phone){
    // (99) 99999-9999
    let data = phone;
    data = data.replace(/\D/g, ''); // remove any non-numeric characters
    // apply the mask
    data = data.replace(/^(\d{2})(\d)/g,"($1) $2");
    data = data.replace(/(\d)(\d{4})$/,"$1-$2");
    return data;
  }

  const label                             = "Aqui você pode configurar quando deseja receber avisos em seu e-mail. Notificações push sempre serão enviadas, você não pode desativá-las.";
  const [newRes, setNewRes]               = React.useState(date.gestor.emailUsuarioNovaReserva);
  const [newResAprov, setNewResAprov]     = React.useState(date.gestor.emailUsuarioReservaAprovada);
  const [newResCancel, setNewResCancel]   = React.useState(date.gestor.emailUsuarioReservaCancelada);
  const [name, setName]                   = React.useState(date.gestor.nome);
  const [email, setEmail]                 = React.useState(date.gestor.atleta.email);
  const [nasc, setNasc]                   = React.useState(RetornaNascimento(date.gestor.atleta.nascimento));
  const [phone, setPhone]                 = React.useState(RetornaPhone(date.gestor.atleta.telefone));
  const [genero, setGenero]               = React.useState(date.gestor.atleta.sexo);
  const [modal, setModal]                 = React.useState(false);
  const [loading, setLoading]             = React.useState(false);
  const [label2, setLabel2]               = React.useState("");
  const [showEmail, setShowEmail]         = React.useState(false);
  const [state, setStt]                   = React.useState(false);
  
  React.useEffect(() => {
    document.title = "Dados Pessoais | Agendei Quadras";
  }, []);
  
  function handleBackButtonEvent(){
    if(modal) setModal(false);
    else if(modalOpen) setModalOpen(false);
    else serverRouter("back");
  }
  // Escuta popstate
  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handleBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
    }
  }, [modal, modalOpen]);

  React.useEffect(() => {
    if(modal) setModalOpen(true);
    else setModalOpen(false);
  }, [modal]);

  //console.log("Usuario data", moment(date.gestor.atleta.nascimento).format("YYYY-MM-DD"));
  async function SalvarDados(mode, oldPas, pass){
    async function StartRotina(){
      // Verifica se o usuário alterou algum campo
      //setLoading(true);
      if((name === date.gestor.nome && email === date.gestor.atleta.email && nasc === moment(date.gestor.atleta.nascimento).format("YYYY-MM-DD") && phone === date.gestor.atleta.telefone && genero == date.gestor.atleta.sexo && newRes == date.gestor.emailUsuarioNovaReserva && newResAprov == date.gestor.emailUsuarioReservaAprovada && newResCancel == date.gestor.emailUsuarioReservaCancelada) && mode){
        return Swal.fire({
          icon                : "warning",
          title               : "Nenhum campo foi alterado",
          text                : "Você precisa alterar algum campo para salvar as alterações.",
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
        });
      } else {
        // Valida o email!
        if(!validator.isEmail(email)){
          return Swal.fire({
            icon                : "warning",
            title               : "Email inválido",
            text                : "Você precisa digitar um email válido para salvar as alterações",
            confirmButtonText   : "Entendi",
            confirmButtonColor  : "#DA5A34",
          });
        } else {
          // Joga os valores para o date.gestor
          date.gestor.nome                          = name;
          date.gestor.atleta.email                  = email;
          date.gestor.atleta.nascimento             = nasc;
          date.gestor.atleta.telefone               = phone
          date.gestor.atleta.sexo                   = genero;
          date.gestor.emailUsuarioNovaReserva       = newRes;
          date.gestor.emailUsuarioReservaAprovada   = newResAprov;
          date.gestor.emailUsuarioReservaCancelada  = newResCancel;

          const reqs = await EmpresaAPI.UpdateGestor(token, date.gestor);
          //console.log("Resposta da solicitação!", reqs);
          if (reqs.status === true){
            // Salvar dados do usuário no contexto!
            handleDate(date);
            if(mode){ 
              return Swal.fire({
                icon                : "success",
                title               : "Sucesso",
                text                : "Seus dados foram salvos com sucesso",
                confirmButtonText   : "Entendi",
                confirmButtonColor  : "#DA5A34",
              });
            } else {
              return Swal.fire({
                icon                : "success",
                title               : "Senha alterada com sucesso",
                text                : "A senha foi alterada com sucesso",
                confirmButtonText   : "Entendi",
                confirmButtonColor  : "#DA5A34",
              });
            } 
          }
        }
      }

    }
    // Verificar se os dados foram alterados
    setLoading(true);
    if(mode){
      await StartRotina();
    } else {
      // Verifica se a senha antiga está correta
      //console.log("Senha antiga", oldPas, md5(oldPas));
      const ress = await EmpresaAPI.ValidaSenhaAtual(token, date.gestor.idusuario, md5(oldPas));
      if(ress.status === true){
        date.gestor.senha = md5(pass);
        await StartRotina();
      } else { 
        Swal.fire({
          icon                : "warning", 
          title               : "Aviso",
          text                : ress.msg,
          confirmButtonText   : "Entendi",
          confirmButtonColor  : "#DA5A34",
        });
      }
    }
    setLoading(false);
  }

  async function VerificaEmail(eml){
    //console.log("email: ", eml);
    if(eml === date.gestor.atleta.email){
      setLabel2("");
      setShowEmail(false);
      if(!state) setStt(true);
      return ;
    }
    if (eml.length >= 8 && validator.isEmail(eml)){
      //console.log("eml: ", eml);
      //setLoading(true);
      const reqs = await EmpresaAPI.VerificaEmailCadastro(token, eml);
      //console.log("reqs: ", reqs);
      if(reqs.status){ //console.log("Comunicção com back end funcionou!!!");// Comunicacao com o backend Funcionou!! 
        if(reqs.req){ // eml valido
          setStt(true);
          setLabel2("E-mail validado com sucesso!");
          setShowEmail(true);
          /*Swal.fire({
            icon  : "success",
            title : "Sucesso!",
            text  : "E-mail validado com sucesso!",
            confirmButtonText : "Entendi",
            confirmButtonColor: '#DA5A34',  
            timer: 3000           
          });*/
        } else { // eml invalido
          setLabel2("E-mail já cadastrado, tente outro e-mail, ou tente recuperar sua senha.");
          setShowEmail(true);
          if(state) setStt(false);
          /*Swal.fire({
            icon  : "warning",
            title : "E-mail inválido!",
            text  : "E-mail já cadastrado, tente outro e-mail, ou tente recuperar sua senha.", 
            confirmButtonText : "Entendi",
            confirmButtonColor: '#DA5A34',
            timer: 5000
          });*/
        }
      } else { // Comunicacao com o backend não funcionou
        setLabel2("Não foi possível validar o e-mail, tente novamente mais tarde.");
        setShowEmail(true);
        if(state) setStt(false);
        /*Swal.fire({
          icon  : "warning",
          title : "Aviso",
          text  : "Não foi possível validar o e-mail, tente novamente mais tarde.",
          confirmButtonText : "Entendi",
          confirmButtonColor: '#DA5A34',
          timer: 5000
        });*/
      }
    } else{ 
      setLabel2("E-mail inválido!");
      setShowEmail(true);
      if(state) setStt(false);
      /*Swal.fire({
        icon  : "warning",
        title : "E-mail inválido!",
        text  : "",
        confirmButtonColor: '#DA5A34',
        confirmButtonText : "Entendi",
        timer: 5000
      });*/
    }
    //setLoading(false);
  }

  function handlePhone(e){
    let val = e.target.value.replace(/\D/g, ''); // remove any non-numeric characters
    // apply the mask
    val = val.replace(/^(\d{2})(\d)/g,"($1) $2");
    val = val.replace(/(\d)(\d{4})$/,"$1-$2");
    //console.log("value: ", val);
    e.target.value = val;
    setPhone(val);
  }
  // Filtra a data de nascimento
  function handleNasc(e){
    let val = e.target.value.replace(/\D/g, ''); // remove any non-numeric characters
    // apply the mask
    val = val.replace(/^(\d{2})(\d)/g,"$1/$2");
    val = val.replace(/(\d)(\d{4})$/,"$1/$2");
    //console.log("value: ", val);
    e.target.value = val;
    setNasc(val);
  }

  return (
    
    <div className   = "contain-configures">
      { modal &&
        <ModalNewPass
          onClick     = {async (oldPass, pass) => { await SalvarDados(false, oldPass, pass)}}
          modal       = {modal}
          ClosedModal = {() => {setModal(false)}}
        />
      }
      <div className = "contain-titles-confgs">
        <h1>Dados Pessoais</h1>
      </div>
      { loading ? 
        <div className = "contain-loading">
          <Loading mode = {true} text={"Carregando..."}/>
        </div> 
          :
        <div className = "contain-overflow-configs">
          <h1 className = "title-h1"> 
            Recebimento de e-mails 
          </h1>
          <p>{label}</p>
          <div className = "contain-switch">
            <TxtSwitch
              info      = "Novos agendamentos"
              value     = {newRes}
              onChange  = {() => setNewRes(!newRes)}
            />
            <TxtSwitch
              info      = "Agendamentos cancelados"
              value     = {newResCancel}
              onChange  = {() => setNewResCancel(!newResCancel)}
            />
          </div>
          <h1 className = "title-h1">
            Informações da conta
          </h1>
          <p>Aqui você pode editar suas informações pessoais.</p>
          <div className    = "contain-form-cnfg-dp">
            <div className  = "div-interna-configs">
              <CompInputForm
                placeHolder   = {"Nome Completo"}
                typeA         = {"text"}
                value         = {name}
                onChange      = {(e) => setName(e.target.value)}
                Icon          = {HiOutlineUser}
                autoComplete  = {"name"}
              />
              <CompInputForm
                placeHolder   = {"Telefone"}
                typeA         = {"text"}
                value         = {phone}
                onChange      = {handlePhone}
                Icon          = {BiPhone}
                maxLength     = {15} // (99) 99999-9999
                autoComplete  = {"tel"}
              />
              <CompInputForm
                placeHolder   = {"Data de Nascimento ex. 10/02/1995"}
                typeA         = {"text"}
                value         = {nasc}
                onChange      = {handleNasc}
                Icon          = {BiCalendar}
                maxLength     = {10} // 99/99/9999
                autoComplete  = {"bday"}
              />
              <div className = "contain-email-label">
                <CompInputForm
                  placeHolder   = {"E-mail"}
                  type          = {"email"}
                  value         = {email}
                  onChange      = {(e) => setEmail(e.target.value)}
                  Icon          = {MdEmail}
                  lock          = {false}
                  autoComplete  = {"email"}
                  onBlur        = {async () => await VerificaEmail(email)}
                />
                { showEmail &&
                  <h3>{label2}</h3>
                }
              </div>
              <div className = "contain-buttons">
                <button
                  onClick   = {() => {
                    setModal(true);
                  }}
                >
                  Alterar Senha
                </button>
                <button
                  onClick   = {
                    async () => {
                      //console.log("Salvar Dados");
                      await SalvarDados(true, "");
                    }
                  }
                >
                  Salvar Dados
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
