import React                      from "react";
import { BsPatchCheckFill }       from "react-icons/bs";
import { 
  BiCalendarCheck, BiMap, BiInfoCircle 
} from "react-icons/bi";
import { GiTennisCourt }          from "react-icons/gi";
import { ModalInfos }             from "../../Modais";
import { FaMoneyBill }            from "react-icons/fa";
import { Loading }                from "../../Basics";
import Swal                       from "sweetalert2";
import { semFoto }                from "../../../assets";
import { AuthContext }            from "../../../contexts/AuthContext";
import RouterHook                 from "../../../contexts/RouterHook";
import { LocalStorage, Services } from "../../../services";
import { EmpresaAPI, PedidosAPI } from "../../../servicesAPI/";
import "./stylesQH.css";

export default function QuadraHor({quadra, onClick, day, setMargin, idx, horaSels, horarios, loadingQdr}) {
  
  const serverRouter            = RouterHook();
  const { 
    date, token, modalOpen, handleMOP 
  } = React.useContext(AuthContext);
  //console.log("Empresa: ", date.empresa);
  //console.log("Quadra ", item);
  //console.log("Horarios: ", horarios);  
  //console.log("ID: ", id);
  //console.log("Quadra " + item.descricao, horaSels);

  const [stt, setStt]           = React.useState(true);
  const [modal, setModal]       = React.useState(false);
  const [urlImage, setUrlImage] = React.useState(null);
  
  let quadraSel = null;

  React.useEffect(() => {
    //console.log("ModalOpen: ", modalOpen);
    if(!modalOpen) setModal(false);
  }, [modalOpen]);
  React.useEffect(() => {
    //console.log("Modal: ", modal);
    if(modal)   handleMOP(true);
    else        handleMOP(false);
  }, [modal]);

  function GerenciaHorarios(item) {
    if(horaSels.includes(item)){
      const index = horarios.indexOf(item);
      if(index == 0) horaSels.splice(horaSels.indexOf(item), 1);
      else if(index == horarios.length-1) horaSels.splice(horaSels.indexOf(item), 1);
      else{
        if(horaSels.includes(horarios[index-1]) && horaSels.includes(horarios[index+1])){ 
          Swal.fire({
            title   : "Horário não pode ser desmarcado",
            text    : "Para desmarcar esse horário, é necessário desmarcar o horário anterior ou posterior a ele",
            icon    : "warning",
            confirmButtonText   : "Entendi",
            confirmButtonColor  : "#DA5A34",
            timer               : 6000,
          });
        }else horaSels.splice(horaSels.indexOf(item), 1);
      } 
    } else horaSels.push(item);
    if(horaSels.length === 0) setMargin({margin:0 , index: 0});
    else setMargin({margin: 60, index: idx}); 
    setStt(!stt);
    //console.log("horaSels: ", horaSels);
  }
  //console.log("Horarios: ", horarios);
  function BttHors({item, onClick, check, ofusked}){
    /*
      Cores dos status dos horários:
        colorSolicitado: '#ff7043',
        colorReservado: '#24ad49',
        colorRealizado: '#00cda4',
        colorCancelado: '#bf3324',
        colorSolicitadoAlpha: 'rgba(255, 112, 67, 0.3)',
        colorReservadoAlpha: 'rgba(36, 173, 73, 0.3)',
        colorRealizadoAlpha: 'rgba(0, 205, 164, 0.3)',
        colorCanceladoAlpha: 'rgba(191, 51, 36, 0.3)', 
    */
   const [loading, setLoading] = React.useState(false);
    //console.log("Check ", check);
    //console.log("Ofusked ", ofusked);
    async function StartGoDetaislPed(idpedido){
      setLoading(true);
      const res = await PedidosAPI.BuscarPedidoId(token, idpedido);
      //console.log("Res: ", res);
      LocalStorage.set("pedidoDetails", {pedido: res.req, page: "agendamento"});
      serverRouter("details-appoint", res.req.idpedido);
      setLoading(false);
    }
    if(loading) {
      return (
        <div className = "contain-loading">
          <Loading
            mode      = {false}
            text      = {""}
          />
        </div>
      );
    }
    const isPedido  = item.status !== 'livre' ? true : false;   // Verifica se o horário tem um pedido

    //console.log("check: ", check);
    //console.log("isPedido: ", isPedido);
    //console.log("pedIsUser: ", pedIsUser);
    //if(isPedido) //console.log("item: ", item);
    //console.log("Item Horario: ", item);
    // Verifica regra de negocios, dias após hoje! true = liberado, false = bloqueado
    
    function ReturnCssBtt(){
      let label = "";
      if(item.status === "solicitado")     label = "pedido-solicit";
      else if(item.status === "agendado")  label = "pedido-agendado";
      else if(item.status === "realizado") label = "pedido-realizado";
      //console.log("label: ", label);
      return ("btt-hora " + label); 
    }

    if(isPedido){ // Se o horário tiver um pedido
     //console.loglog("Pedido Aqui!!!!", item); 
      if(!horaSels.length){
        return(
          <button
            className = {ReturnCssBtt()}
            onClick   = {() => {
              //console.log("Pedido Aqui!!!!", item);
              StartGoDetaislPed(item.idpedido);
            }}
          >
            <div className = "contain-btt-hora-div">
              <h2 className = "btt-hora-text-label">
                {Services.RetornaPeriodoHorario(item)}
              </h2>
            </div>
            <div className = "contain-btt-hora-div">
              <h2 className = {item.status === "solicitado" ? "btt-hora-text-rotul rotul-solicit" : item.status === "agendado" ? "btt-hora-text-rotul rotul-agendado" : "btt-hora-text-rotul rotul-realizado"}>
                {item.status.toUpperCase()}
              </h2>
              { item.nobre &&
                <BsPatchCheckFill
                  title     = "Horário nobre"
                  className = {"icon-nobre"}
                  size      = {17}
                />
              }
            </div>
              <h2 className = "btt-hora-text-label">{item.nomeUsuario}</h2>
          </button>
        );
      } else {
        return(
          <button
            className = {item.status === "solicitado" ? "btt-hora pedido-solicit not-view-ofusck not-iterator" :  "btt-hora pedido-agendado not-view-ofusck not-iterator"}
            style     = {{cursor: "pointer"}}
            onClick   = {() => {
              //console.log("Pedido Aqui!!!!", item);
              StartGoDetaislPed(item.idpedido);
            }}
          >
            <div className = "contain-btt-hora-div">
              <h2 className = "btt-hora-text-label">
                {Services.RetornaPeriodoHorario(item)}
              </h2>
            </div>
            <div className = "contain-btt-hora-div">
              <h2 className = {item.status === "solicitado" ? "btt-hora-text-rotul rotul-solicit" : item.status === "agendado" ? "btt-hora-text-rotul rotul-agendado" : "btt-hora-text-rotul rotul-realizado"}>
                {item.status.toUpperCase()}
              </h2>
              { item.nobre &&
                <BsPatchCheckFill
                  title     = "Horário nobre"
                  className = {"icon-nobre"}
                  size      = {17}
                />
              }
            </div>
              <h2 className = "btt-hora-text-label">{item.nomeUsuario}</h2>
          </button>
        );
      }
    } else{ // Se o horário não tiver um pedido
      if(ofusked){
        return(
          <button
            className = {"btt-hora not-view-ofusck not-iterator"}
            onClick   = {()=>{}}
            title     = {"Seleção indisponível"}
          >
            <div className = "contain-btt-hora-div">
              <input
                checked   = {check}
                onChange  = {() => {}}
                type      = "checkbox"
              />
              <h2 className = "btt-hora-text-label">
                {Services.RetornaPeriodoHorario(item)}
              </h2>
            </div>
            <div className = "contain-btt-hora-div">
              <h2 className = {"btt-hora-text-rotul rotul-livre"}>
                LIVRE
              </h2>
              { item.nobre &&
                <BsPatchCheckFill
                  title     = "Horário nobre"
                  className = {"icon-nobre"}
                  size      = {17}
                />
              }
            </div>
          </button>
        );
      } else {
        return(
          <button
            className = {check ? "btt-hora selected-btt-hora" : "btt-hora"}
            onClick   = {onClick}
            title     = {check ? "Desmarcar horário" : "Selecionar horário"}
          >
            <div className = "contain-btt-hora-div">
              <input
                checked   = {check}
                onChange  = {() => {}}
                type  = "checkbox"
              />
              <h2 className = "btt-hora-text-label">
                {Services.RetornaPeriodoHorario(item)}
              </h2>
            </div>
            <div className = "contain-btt-hora-div">
              <h2 className = {"btt-hora-text-rotul rotul-livre"}>
                LIVRE
              </h2>
              { item.nobre &&
                <BsPatchCheckFill
                  title     = "Horário nobre"
                  className = {"icon-nobre"}
                  size      = {17}
                />
              }
            </div>
          </button>
        );
      }
    }
  }

  function InfosPay(){
    const [loading, setLoading]   = React.useState(true);
    const [pays, setPays]           = React.useState([]);
    const chamaPays = async () => {
      setLoading(true);
      const ress = await EmpresaAPI.BuscarPrecoQuadraDia(token, quadra.idquadra, (day.getDay() + 1));
      //console.log("ress Pays: ", ress);
      setPays(ress.req);
      setLoading(false);
    }
    
    React.useEffect(() => {
      chamaPays();
    }, []);

    function RetornaLabel(item){
      function VeificaMin(min){
        if(min == 0) return "";
        else return `E ${min} MINUTO${min > 1 ? "S" : ""}`;
      }
      if(item.tempo < 60) return `${item.tempo} MINUTOS`;
      else if(item.tempo == 60) return `1 HORA`;
      else {
        const horas = Math.floor(item.tempo / 60);
        const minutos = item.tempo % 60;
        return `${horas} HORA${horas > 1 ? "S" : ""} ${VeificaMin(minutos)}`;
      }
    }

    function InfoComp({item}){
      return (
        <div className = "contain-modal-pays-info">
          <h3>CADA <label>{RetornaLabel(item)}</label> CUSTA</h3>
          <div className="contain-modal-info-table">
            <div className = "contain-modal-pays-info-line">
              <h3>Em horários normais</h3>
              {item.valorPadrao == 0 ? 
                <h3 style = {{color: "red"}}>Não definido</h3> 
                  : 
                <h3>{`R$${item.valorPadrao.toFixed(2)}`}</h3>
              }
            </div>
            <div className = "contain-modal-pays-info-line">
              <h3>Em horários nobres</h3>
              {item.valorNobre == 0 ? 
                <h3 style = {{color: "red"}}>
                  Não definido
                </h3> 
                  : 
                <h3>
                  {`R$${item.valorNobre.toFixed(2)}`}
                </h3>
              }
            </div>
          </div>
        </div>
      );
    }

    return(
      <div className = "contain-modal-pays">
        <h2> Preços de {Services.ReturnDayComplet(day.getDay())}</h2>
        <h3>{quadra.descricao}</h3>
        {
          loading ? 
          <div className = "contain-loading">
            <Loading
              mode      = {true}
              text      = {"Carregando..."}
            />
          </div>
            :
          <div className = "contain-modal-pays-infos">
            {
              pays.precosAvulsos.map((item, index) => (
                <InfoComp
                  key       = {index}
                  item      = {item}
                />
              ))
            }
          </div>
        }
        <button
          className = "btt-modal-pays"
          onClick   = {() => setModal(false)}
        >Entendi</button>
      </div>
    );
    
  }
   
  function VerificaCheckHorarios(item, index){
    if(horaSels.includes(item)) return  false;
    if(index == 0) if(horaSels.includes(horarios[index+1])) return false;
    if(index == horarios.length-1) if(horaSels.includes(horarios[index-1])) return false;
    if(horaSels.includes(horarios[index-1]) || horaSels.includes(horarios[index+1])) return false;
    return true;
  }
  
  function ReturnLabel(array){
    
    function ReturnQuadra(){
      let label = "Não achou";
      date.empresa.quadras.map((quadra) => {
        if(quadra.idquadra === array[0].quadra_idquadra){
          label     = quadra.descricao;
          quadraSel = quadra;
        } 
      });
      return label;
    }
    
    array.sort((a, b) => new Date(a.data) - new Date(b.data)); // Ordena os horários selecionados por data e hora crescente, utilizando o método sort
    return (
      <>
        <h2>{`Horários da ${ReturnQuadra()}`}</h2>
        <h2>{`${array.length} horário${(array.length > 1) ? "s" : ""} selecionado${(array.length > 1) ? "s" : ""} das ${Services.ReturnPeriodoHorarioArray(array)}, para ${Services.DataFormat("dia,DD/MM/YY", day.toString())}`}</h2>
      </>
    );
  }
  //console.log("Quadra", date.empresa);
  //console.log("horaSels: ", horaSels);
  return (
    <div className = "contain-quadra-hor">
      { (horaSels.length > 0) && // DESKTOP -> Resumo dos horários selecionados!   
        <div className = "contain-resumo-horarios-desktop">
          <div>
            <div className = "contain-resumo-horarios-infos">
              {ReturnLabel(horaSels)}
            </div>
            <button
              className = "btt-agendar"
              title     = "Agendar horários selecionados"
              onClick   = {() => {
                horaSels.sort((a, b) => new Date(a.data) - new Date(b.data)); // Ordena os horários selecionados por data e hora crescente, utilizando o método sort
                onClick(horaSels, quadraSel);
              }}
            >
              {`Escolher horário${horaSels.length > 1 ? "s" : ""}`}
              <BiCalendarCheck
                style = {{marginLeft: 10}}
                size  = {40}
              />
            </button>
          </div>    
        </div>
      }
      { modal &&
        <ModalInfos
          modal         = {modal}
          ClosedModal   = {() => setModal(false)}
          Component     = {<InfosPay/>}
        />
      }
      <div className = "contain-img-info-hor">
        <button
          className = "btt-fixed-infos-left"
          onClick   = {() => {
            setModal(true);
          }}
        >
          <FaMoneyBill
            size        = {25}
          />
        </button>
        <img src = {urlImage === null ? `${quadra.urlFoto}${quadra.foto}` : urlImage} onError={() => {setUrlImage(semFoto)}} />
        <div className = "contain-name-ender">
          <h2 className="name-quadra-title">
            {quadra.descricao}
          </h2>
          { quadra.categoriaEsporte.nome !== null && quadra.categoriaEsporte.nome !== "" ?
            <div className="contain-line-name-ender">
              <GiTennisCourt
                size  = {20}
                color = "white"
                style = {{minWidth: "20px", minHeight: "20px", marginRight: "3px"}}
              />
              <h2>
                {quadra.categoriaEsporte.nome}
              </h2>
            </div>
            : <></>
          }
          <div className="contain-line-name-ender">
            <BiMap 
              size  = {20} 
              color = "white"
              style = {{minWidth: "20px", minHeight: "20px", marginRight: "3px"}}
            />
            <h3>
              {Services.ArrumaEndereco(quadra.endereco)}
            </h3>
          </div>
          { (quadra.informacoes !== null && quadra.informacoes !== "") ?
            <div className="contain-line-name-ender">
              <BiInfoCircle
                size  = {20}
                color = "#DA5A34"
                style = {{minWidth: "20px", minHeight: "20px", marginRight: "3px"}}
              />
              <h4>
                {quadra.informacoes}
              </h4>
            </div>
            : <></>
          }
        </div>
        
      </div>
      <div className = "contain-horarios-agends">
        { 
          loadingQdr ? 
          <div className = "contain-loading">
            <Loading 
              mode = {false}
            />
          </div>
            :
          horarios.map((item, index) => (
            //console.log("Item Horarios: ", item);
            horaSels.length ?     
            <BttHors
              key     = {index}
              item    = {item}
              check   = {horaSels.includes(item)}
              ofusked = {VerificaCheckHorarios(item, index)}
              onClick = {() => GerenciaHorarios(item)}
            />
              : 
            <BttHors
              key     = {index}
              item    = {item}
              check   = {false}
              ofusked = {false}
              onClick = {() => GerenciaHorarios(item)}
            />
          ))
        } 
      </div>
    </div>
  );
}