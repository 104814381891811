import React from "react";
import { AlertGmt } from "../../Basics/";
import { BiAlarmExclamation } from "react-icons/bi";

import "./stylesGmtAv.css";

export default function GmtAviso({gmt, local, horaEmpresa, horaUsuario}) {

  const [gmtAlert, setGmtAlert] = React.useState(false);
  const width = window.innerWidth;
  
  React.useEffect(() => {
    if(width < 768) { // Mobile
      let timer = null;
      if (gmtAlert) {
        timer = setTimeout(() => {
          setGmtAlert(false);
        }, 5000);
      }
      // Isto será executado quando o componente desmontar
      return () => {if(timer) clearTimeout(timer);}
    }
  }, [gmtAlert]);
  
  if(width < 768) { // Mobile
    return(
      <div 
        className     = {"contain-detalhes-pedido-topo-central-label-gmt-"+local}
        onClick       = {() => {setGmtAlert(!gmtAlert)}}
      >
        <AlertGmt
          show  = {gmtAlert}
          local = {local}
          horaEmpresa = {horaEmpresa}
          horaUsuario = {horaUsuario}
        />
        <BiAlarmExclamation
          style = {{marginRight : 5}}
          size  = {local === "right" ? 20 : 15}
          color = "#DA5A34"
        />
        Fuso horário local GMT {gmt}
      </div>
    );
  } else { // Desktop
    return(
      <div 
        className     = {"contain-detalhes-pedido-topo-central-label-gmt-"+local}
        onMouseEnter  = {() => {setGmtAlert(true)}}
        onMouseLeave  = {() => {setGmtAlert(false)}}
      >
        <AlertGmt
          show  = {gmtAlert}
          local = {local}
          horaEmpresa = {horaEmpresa}
          horaUsuario = {horaUsuario}
        />
        <BiAlarmExclamation
          style = {{marginRight : 5}}
          size  = {local === "right" ? 20 : 15}
          color = "#DA5A34"
        />
        Fuso horário local GMT{gmt}
      </div>
    );  
  }
}