import React    from 'react';
import "./stylesMax.css";

export default function ContainMax({ children }) {
  return(
    <div className = "contain-max-center">
      {children}
    </div>
  );
}
