import React          from "react";
import { Services } from "../../../services";
import "./stylesVD.css";

export default function VenDiaria({day, onClick, diaria}) {
  //console.log("diaria", diaria);
  return (
    <div 
      className = "contain-diaria-ven"
      onClick   = {() => {if(onClick !== null) onClick()}}
    >
      <div className = "contain-diaria-superior border-botton-diaria-2">
        <div className="contain-diaria-interior">
          <h2>{diaria.titulo}</h2>
          <h3 className="rotulo-h3-novo-padrao">
            {`R$${diaria.valor.toFixed(2).toString().replace(".",",")}`}
          </h3>
        </div>
        <h3 className = "rotul-diaria-data-2">
          {`${Services.ReturnDiaSemana(3, day)}`}      
        </h3>
      </div>
      <div className = "contain-diaria-inferior">
        <h2>REGRAS DA DIÁRIA</h2>
        <h3>Duração: {diaria.duracao}</h3>
        <h3>Válida para: {diaria.quadra}</h3>
      </div>
      {
        onClick !== null &&
        <button
          className = "btt-selecionar-diaria"
          onClick   = {() => {if(onClick !== null) onClick()}}
        >
          Escolher Diária
        </button>
      }
    </div>
  );
}