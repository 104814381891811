import React                    from "react";
import { useLocation }          from "react-router-dom";
import { 
  AiOutlineDoubleLeft, AiOutlineDoubleRight 
} from "react-icons/ai";
import {
  TbSettingsDollar
} from "react-icons/tb";
import { 
  CalArrow, CalSearch, CalCheck, CompLink, ICO_FX, semFoto, IconCupon 
} from "../../../assets";
import { AiOutlineHome }        from "react-icons/ai";
import { MdOutlineGroups }      from "react-icons/md";
import { FaRegStar }            from "react-icons/fa";
import { 
  ArenaSB, BttIcon, CompCSB 
} from "../../Basics";
import { AuthContext }          from "../../../contexts/AuthContext";

import "./stylesSB.css";
import { BiParty } from "react-icons/bi";
import { EmpresaAPI } from "../../../servicesAPI";

export default function SideBar({show, handleBttSel, onClick}) {

  //console.log("SideBar.js - show: ", show);
  const location        = useLocation();
  const { date, token } = React.useContext(AuthContext);
  //console.log("SideBar.js - location: ", location);
  const bttsRows = [
    { 
      icon  : AiOutlineHome,
      text  : "Início",
      sel   : "home"
    },{ 
      icon  : CalSearch,
      text  : "Buscar Agendamento",
      sel   : "buscar"
    },{
      icon  : CalArrow,
      text  : "Horários Fixos",
      sel   : "hora-fixa"
    },{
      icon  : CalCheck,
      text  : "Diárias",
      sel   : "diaria"
    },{
      icon  : MdOutlineGroups,
      text  : "Clientes",
      sel   : "clientes"
    },{
      icon  : FaRegStar,
      text  : "Avaliações",
      sel   : "avaliacoes"
    },{
      icon  : ICO_FX,
      text  : "Fluxo de Caixa",
      sel   : "fluxo-caixa"
    },{
      icon  : IconCupon,
      text  : "Cupons",
      sel   : "cupons"
    },{
      icon  : TbSettingsDollar,
      text  : "Assinatura",
      sel   : "assinatura"
    }
  ];
  //console.log("PerfilG: ", date.perfilG);

  /*,{ ---- Outros btts side-bar ----
      icon  : BiSolidDiscount,
      text  : "Cupom de desconto",
      sel   : "cupons"
    },{
      icon  : GiMoneyStack,
      text  : "Agendei Pay",
      sel   : "agendei-pay"
    },{
      icon  : LiaCashRegisterSolid,
      text  : "Fluxo de Caixa",
      sel   : "fluxo-caixa"
    },{
      icon  : TbSettingsDollar,
      text  : "Assinatura",
      sel   : "assinatura"
    }*/
  const [bttSel, setBttSel] = React.useState("home");
  const [payAss, setPayAss] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState(null);
  React.useEffect(() => {
    // verifica ultima parte da url separada por "/"
    let tela = location.pathname.split("/");
    tela = tela[tela.length - 1];
    VerificarBoletos();
    //console.loglog("SideBar.js - tela: ", tela);
    if(tela === "buscar-agendamentos"){
      setBttSel("buscar");
    } else if(tela === "horarios-fixos"){
      setBttSel("hora-fixa");
    } else if(tela === "conversas"){
      setBttSel("conversas");
    } else if(tela === "diarias"){
      setBttSel("diaria");
    } else if(tela === "clientes"){
      setBttSel("clientes");
    } else if(tela === date.empresa.username || undefined){
      setBttSel("home");
    } else if(tela === "avaliacoes"){
      setBttSel("avaliacoes");
    } else if(tela === "fluxo-caixa"){
      setBttSel("fluxo-caixa");
    } else if(tela === "assinatura"){
      setBttSel("assinatura");
    } else if(tela === "cupons"){
      setBttSel("cupons");
    } else setBttSel("");
  }, [location.pathname]);
  /*- Pages do SideBar:
      - Home
      - Conversas
      - Minha Agenda
      - Buscar agendamento
      - Agendei Pay
      - Fluxo de Caixa
      - Clientes
      - Avaliações
      - Cupons
      - Assinatura
  */

  async function VerificarBoletos(){
    const req = await EmpresaAPI.BuscarFaturaFechadaEmpresaID( token, date.empresa.idempresa);
    //console.log("SideBar.js - FaturaFechada: ", req);
    if(req.status === true){
      if(req.req.length > 0){
        req.req.map((item) => {
          if(item.statusPagamento === "pending"){
            setPayAss(true);
            //encerrar o map
            return;
          } 
        });
      } else setPayAss(false);
    } else {
      setPayAss(false);
    }
  }

  function IconBtt({Icon, selected, onClick, index}){

    return(
      <button
        className = {selected ? "btt-icon-sb-selected" : "btt-icon-sb"}
        style     = {index === 0 ? {borderTop: "1px solid #302D2A"} : {}}
        onClick   = { onClick }
      >
        <Icon
          className = { selected ? "icon-btt-sb-selected" : "icon-btt-sb" }
        />
        { index === 8 ?
          payAss ?
          <div className = "contain-new-function"/>
            :
          <div className = "contain-new-function"/>
            :
          <></>
        }
      </button>
    );
  }

  function SelBtt(sel){
    if(bttSel === sel){
      return true;
    } else {
      if(bttSel === "checkout" && sel === "home"){
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <div className = {show ? "contain-side-bar-open" : "contain-side-bar-closed"}>
      {
        show ? 
        <>
          <AiOutlineDoubleLeft
            className     = {`icon-show-side-bar`}
            onClick       = {() => {handleBttSel("sideBar")}}
          />
          
          <ArenaSB
            empresa   = {date.empresa}
          />
          
          <div className = "contain-side-bar-btts">
            {
              bttsRows.map((btt, index) => {
                if(!VerificaPerfil(index)) return <></>;
                return(
                  <BttIcon
                    index     = {index}
                    Icon      = {btt.icon}
                    text      = {btt.text}
                    selected  = {SelBtt(btt.sel)}
                    onClick   = {() => {handleBttSel(btt.sel)}}
                    novo      = {index === 8 ? true : false}
                    payAss    = {payAss}
                  />
                )
              })
            }
            <div className = "contain-links-sb">
              <CompCSB
                mode    = {true}
                title   = {"Que tal Divulgar sua Quadra?"}
                text    = {"Compartilhe um link direto para seus clientes acessarem sua quadra"}
                onClick = {() => {
                  onClick();
                }}
              />
            </div>
      
          </div>
        </> :
        <>
          <AiOutlineDoubleRight
            className = {"icon-not-show-side-bar"}
            onClick   = {() => { handleBttSel("sideBar"); }}
          />
          <div className = "contain-img-empresa">
            <img
              className = "img-empresa-sb"
              src       = {imgUrl === null ? (date.empresa.urlFoto + date.empresa.foto) : imgUrl}
              onError   = {() => setImgUrl(semFoto)}
            />
          </div>
          <div className = "contain-side-bar-btts">
            {
              bttsRows.map((btt, index) => {
                if(!VerificaPerfil(index)) return <></>;
                return(
                  <IconBtt
                    key       = {index}
                    index     = {index}
                    Icon      = {btt.icon}
                    selected  = {SelBtt(btt.sel)}
                    onClick   = {() => { handleBttSel(btt.sel); }}
                  />
                );
              })
            }
            <IconBtt
              index     = {10}
              Icon      = {CompLink}
              selected  = {SelBtt("link")}
              onClick   = {() => onClick()}
            />
          </div>
        </> 
      }
    </div>
  );

  function VerificaPerfil(index){
    switch(index){
      //Menu
      case 0: return true;
      // Agenda Calendário
      case 1: return date.perfilG.verAgendaCalendario;
      // Gerenciar Mensalistas
      case 2: return date.perfilG.gerenciarMensalistas;
      // Diárias
      case 3: return date.perfilG.diarias;
      // Clientes
      case 4: return date.perfilG.verClientes;
      // Avaliações
      case 5: return date.perfilG.verEstatisticas;
      // Fluxo de Caixa
      case 6: return date.perfilG.fluxoCaixa;
      // Estatísticas
      default: return true;
    }
  }
}; // Fim SideBar
