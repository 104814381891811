import React                from "react";
import { BsStarFill }       from "react-icons/bs";
import { BiChevronRight }   from "react-icons/bi";
import { semFoto }          from "../../../assets";
import { AuthContext }      from "../../../contexts/AuthContext";

import "./stylesASB.css";

export default function ArenaSB(){

  const { date }                      =   React.useContext(AuthContext);
  const [urlImagem, setUrlImagem]     =   React.useState(null);
  const [urlImagem2, setUrlImagem2]   =   React.useState(null);
  const [show, setShow]               =   React.useState(false);
  //console.log("Empresa :: ", empresa);
  
  return(
    <div 
      className = "contain-info-empresa"
      onClick   = {() => {
        //setShow(!show);
      }}
    >
      <button className = "button-img">
        <img
          className = "button-img-img" 
          src       = { urlImagem === null ? `${date.empresa.urlFoto}${date.empresa.foto}` : urlImagem } 
          onError   = { () => { setUrlImagem(semFoto)} }
        />
        <div className = "contain-nome-avaliacao">
        <h2>
          {date.empresa.nome}
        </h2>

          {(date.empresa.mediaAvaliacoes > 0) && 
            <div
              className = "contain-avaliacao-arena"
              onClick = {() => {

              }}
            >
              <BsStarFill 
                style = {{height: "20px", width: "20px", marginLeft: "7px"}}
                color = "#FFD700"
              />
              <h2>
                <span style={{color: "#FFD700"}}>
                  {date.empresa.mediaAvaliacoes.toFixed(1).toString().replace(".",",")}
                </span>
                {` (${date.empresa.quantidadeAvaliacoes} ${date.empresa.quantidadeAvaliacoes > 1 ? "avaliações)" : "avaliação)"}`}  
              </h2>
            </div>
          }
        </div>
      </button>
      { show &&
        <div className = "contain-view-empresa-sb">
          <h2>{`${date.empresas.length} Empresa${date.empresas.length > 1 ? "s":""}`}</h2>
          <div className = "contain-comp-empresa-handle-sb">
            <img
              className = "button-img-img-show" 
              src       = { urlImagem2 === null ? `${date.empresa.urlFoto}${date.empresa.foto}` : urlImagem2 } 
              onError   = { () => {setUrlImagem2(semFoto)} }
            />
            <div className = "contain-infos-empresa">
              <h2>{date.empresa.nome}</h2>
              <h3>{`${date.empresa.quadras.length} Quadra${date.empresa.quadras.length > 1 ? "s" : ""}`}</h3>
            </div>
            <BiChevronRight
              size    = {25}
              color   = "#DA5A34"
            />
          </div>
        </div>
      }
    </div>
  );
}