import React                  from "react";

import { LocalStorage }       from "../services";
import { Auth, EmpresaAPI }   from "../servicesAPI/";

export const AuthContext = React.createContext({
  date            : LocalStorage.get("date")    || {perfilG: {}, gestor: {}, empresa: {}, empresas: []},
  token           : LocalStorage.get("token")   || null,
  isAuth          : LocalStorage.get("isAuth")  || false,
  modalOpen       : false,
  login           : async (email, senha) => {},
  logout          : async (token) => {},
  isLogged        : async (token) => {},
  handleMOP       : (val) => {},
  handleDate      : (data) => {},
});

export default function AuthContextProvider({children}) {

  const [date, setDate]           = React.useState(LocalStorage.get("date") || {perfilG: {}, gestor: {}, empresa: {}, empresas: []});
  const [token, setToken]         = React.useState(LocalStorage.get("token") || null);
  const [isAuth, setIsAuth]       = React.useState(LocalStorage.get("isAuth") || false);
  const [modalOpen, setModalOpen] = React.useState(false);

  // Seta o date no localStorage e no state
  function setDateContext(date){
    setDate(date);
    LocalStorage.set('date', date);
  }
  const handleDate = (date) => setDateContext(date);
  // Seta o token no localStorage e no state
  function setTokenContext(val){
    setToken(val);
    LocalStorage.set('token', val);
  }

  // Seta o isAuth no localStorage e no state
  function setIsAuthContext(val){
    setIsAuth(val);
    LocalStorage.set('isAuth', val);
  }

  // Seta o modalOpen no state
  function handleMOP(val){
    setModalOpen(val);
  }
  //console.log("Date", date);
  // Função para fazer login
  async function login(email, password){
    //console.log("Email: ", email, "Senha: ", password);
    //const resp = await Auth.login(em, ps);
    const resp = await Auth.login(email, password);
    //console.log("Resposta Form: ", resp);
    if(resp.status){
      if(resp.req.token !== null && resp.req.token !== undefined && resp.req.usuario !== null && resp.req.usuario !== undefined){
        setTokenContext(resp.req.token);
        setIsAuthContext(true);
        const req_per = await EmpresaAPI.BuscarPerfilAcesso(resp.req.token, resp.req.usuario.idusuario, resp.req.usuario.empresas[0].idempresa);
        //console.log("Req_per PERFIL de acesso!!!: ", req_per);
        const dt = {
          gestor      : resp.req.usuario,
          empresas    : resp.req.usuario.empresas,
          empresa     : resp.req.usuario.empresas[0],
          perfilG     : req_per.req.registro,
        };
        //console.log("Date: ", dt);
        if(dt.empresa.status === 0 && dt.empresa.licenca.status === 0){
        //if(true){
          return {status: false, message: "Esta empresa encontra-se desativada no momento. Por favor, entre em contato com o suporte para mais informações."}
        }
        const qdrs = LocalStorage.get("quadras-empresa");
        if(qdrs !== null){ 
          dt.empresa.quadras = qdrs;
          LocalStorage.remove("quadras-empresa");
        }
       //console.log("Date: ", dt);
        setDateContext(dt);
        return {status: true, req: resp.req};    
      
      } else if(resp.req.retorno === false) {
        return {status: false, message: resp.req.detalhes }
      }
    } else {
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", req: resp}
    }
  }
  
  // Função para fazer logout
  async function logout(){
    // TODO: Fazer logout no servidor ----
    const reqs = await Auth.logout(token);
    //console.log("Logout: ", reqs);
    setDateContext(null);
    setTokenContext(null);
    setIsAuthContext(false);
    LocalStorage.clear();
  }

  // Função para verificar se o usuário está logado
  async function isLogged(){
    //LocalStorage.clear();
    const storageDate   = LocalStorage.get('date');
    const storageToken  = LocalStorage.get('token');

    //console.log("Storage: ", storageDate, storageToken);
    // Se existem dados, o usuários deve ser validado e redirecionado para o menu
    if (storageDate !== null && storageToken !== null && storageDate.gestor !== null) {
      //console.log("Date: ", storageDate);
      if(!storageDate.gestor || !storageToken || !storageDate.perfilG || !storageDate.empresa) {
        //console.log("Usuário não logado, removeu os dados do storage!");
        LocalStorage.remove('date');
        LocalStorage.remove('token');
        return {status: false};
      }
      // Verifica se a empresa está completa
      if(!storageDate.empresa.nome){
        if(storageDate.empresas.length > 0){
          storageDate.empresa = {...storageDate.empresas[0], ...storageDate.empresa};
        } else {
          return {status: false};
        }
      }
      if(storageDate.empresa.status === 0 && storageDate.empresa.licenca.status === 0){
        //if(true){
          return {status: false, message: "Esta empresa encontra-se desativada no momento. Por favor, entre em contato com o suporte para mais informações."}
        }
      // Pergunta! Validar o token, retorna o novo contexto da aplicação??? Usuário, Cidade, Empresas, Quadras ...
      const resp = await Auth.isLogged(storageDate.gestor.idusuario, storageToken);
      //console.log("isLogged: ", resp);
      if(resp.status){
        if(resp.req.error === "Token expirado") return {status: false};
        if(storageDate.gestor !== null){

          setDate(storageDate);
          setToken(storageToken);
          setIsAuthContext(true);
          return {status: true};
        } else {
          return {status: false};
        }
      } else {
        //console.log("Erro ao verificar login: ", resp);
        setDate(null);
        setToken(null);
        setIsAuth(false);
        //console.log("Erro ao verificar login: ");
        LocalStorage.set('isAuth', false);
        //LocalStorage.clear();
        return {status: false};
      }
    } else setIsAuth(false); return {status: false};
  }

  return (
    <AuthContext.Provider 
      value={{
        date, token, isAuth, modalOpen, handleMOP,
        login, logout, isLogged, handleDate, setModalOpen
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
