import React                        from "react";
import { BiChevronRight, BiEdit }   from "react-icons/bi";
import { ChatContext }              from "../../../contexts/ChatContext";

import "./stylesCC.css";

export default function CompCnfg({ Icon, title, describe, onClick, user, ic, title_btt }) {

  const { states } = React.useContext(ChatContext);

  function CountMenssages(){
    let count = 0;
    states.chat_CE.map((chat) => {
      if(chat.ultimaMensagem !== null){
        if(chat.ultimaMensagem.status === "ENVIADA" && chat.ultimaMensagem.criadaPorAdmin) count++;
      }
    })
    return count;
  }

  
  return (
    <button
      title       = {title_btt}
      className   = "contain-button-cnfg"
      onClick     = {onClick}
    >
      { ic === 2 ?
        <Icon
          className = "icon-ajuda-cnfg"
        />
        :
        <Icon
          size      = {40}
          className = {user ? "circle" : "now-circle"}
        />
      }
      <div className = "contain-button-cnfg-internal">
        <h2>{title}</h2>
        <h3>{describe}</h3>
      </div>
      { user ? 
        <BiEdit
          size      = {30}
          className = "now-circle"
        /> :
        <BiChevronRight
          size      = {30}
          className = "now-circle"
        />
      }
    </button>
  );
}