import React                              from 'react';
import { ModalInfos }                     from '../../Modais/';
import { BiArrowBack, BiChevronRight }    from 'react-icons/bi';
import { TbAlertTriangleFilled }          from 'react-icons/tb';
import { MdCancel }                       from 'react-icons/md';
import { Services }                       from '../../../services/';
import { AuthContext }                    from '../../../contexts/AuthContext';
import Swal                               from 'sweetalert2';
import RouterHook                         from '../../../contexts/RouterHook';
import { Loading }                        from '../../Basics';

import "./stylesAL.css";

export default function AspectsLegais(){

  const serverRouter                  = RouterHook();
  const { date, token, logout }       = React.useContext(AuthContext);

  // States da tela
  const [loading, setLoading]         = React.useState(false);
  const [modal, setModal]             = React.useState(false);
  
  const [comptModal, setComptModal]   = React.useState(null);
  const [userIsGes, setUserIsGes]     = React.useState(false);
  const [modalOpen, setModalOpen]     = React.useState(false);

  function handleBackButtonEvent(){
    if(modalOpen){
      setModalOpen(false);
      modal && setModal(false);
    } else {
      serverRouter("back");
    }
  }
  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", handleBackButtonEvent);
    };
  }, [modalOpen, modal]);

  React.useEffect(() => {
    if(modal) setModalOpen(true);
    else setModalOpen(false);
  }, [modal]);

  /*
  async function getUserDelete() {
    setLoading(true);
    const reqs = await UsuarioApi.VerificarUsuarioIsGestor(token, date.user.idusuario);
    if(reqs.status){
      setUserIsGes(reqs.req.registro);
      //console.log("UserIsGes: ", reqs);
    } else {
      // Erro de comunicação!!! impossibilita a exclusão de conta
      setUserIsGes(false);
    }
    setLoading(false);
  }
  //*/

  /*
  function deleteUser() {
    Swal.fire({
      icon    : 'warning',
      title   : 'Exclusão de dados',
      text    : 'Essa ação é irreversível, deseja mesmo excluir seus dados?',
      showDenyButton    : true,
      denyButtonText    : `Confirmo`,
      confirmButtonText : 'Não excluir',
      confirmButtonColor: '#DA5A34',
    }).then(async (result) => {
      if (result.isDenied) {
        setLoading(true);
        const reqs = await UsuarioApi.DeleteUsuario(token, date.user.idusuario, date.user.atleta_idatleta);
        //console.log("DeleteUser: ", reqs);
        if(reqs.status){
          if(reqs.req.retorno){
            Swal.fire('Dados excluídos', '', 'sucess');
            logout();
            serverRouter("login");
          } else {
            Swal.fire({
              icon    : 'error',
              title   : 'Erro ao excluir dados',
              text    : `Não foi possível excluir seus dados, ${reqs.req.detalhes}.`,
              timer   : 10000,
              confirmButtonText : 'Entendi',
              confirmButtonColor: '#DA5A34',
            })
          }
        } else {
          Swal.fire({
            icon    : 'error',
            title   : 'Erro ao excluir dados',
            text    : `Estamos com problemas de conexão, tenta novamente mais tarde.`,
            timer   : 10000,
            confirmButtonText : 'Entendi',
            confirmButtonColor: '#DA5A34',
          })
        } 
        setLoading(false);
      }
    })
  }
  //*/

  React.useEffect(() => {
    //getUserDelete();
    document.title = "Aspectos Legais | Agendei Quadras";
  }, []);

  // Componente de confirmação de exclusão de conta
  function ModalConfirmaExclusao(){
    const [confirmed, setConfirmed]     = React.useState({status: false, label: "Estou ciente e quero prosseguir"});
    return(
      <>
        {
          loading ? <div className = 'contain-loading'><Loading mode = {false}/> </div>
            :
          <div className = 'modal-confirm-exclusion'>
            <h1>Deseja mesmo excluir seus dados?</h1>
            <h2 className = 'aviso-atencao'>
              ATENÇÃO
            </h2>
            <h2 className = 'aviso-sub-atencao'>
              Esta ação é irreversível
            </h2>
            { userIsGes ?
              <div className = 'contain-list-avisos-read'>
                <h3>- Você é gestor de uma quadra.</h3>
                <h3>- Para exclusão dos dados, entre em contato com a central de atendimento pelo App Gestor.</h3>
              </div>
                :
              <>
                <div className = 'contain-list-avisos'>
                  <h3>- Todo dado relacionado a você será excluído da nossa plataforma.</h3>
                  <h3>- Se você quiser voltar a utilizar o Agendei, precisará criar uma nova conta.</h3>
                  <h3>- Ao confirmar, você voltará para a tela início imediatamente.</h3>
                </div>
                <button
                  className = {confirmed.status ? 'btt-confirm-exclusion' : 'btt-confirm-exclusion-disabled'}
                  onClick   = {() => {
                    if(!confirmed.status){
                      //console.log("Não confirmado");
                      setConfirmed({status: true, label: "Excluir meus dados"});
                    } else {
                      //deleteUser();
                    }
                  }}
                  >
                  <h3>{confirmed.label}</h3>
                  <TbAlertTriangleFilled
                    size = {30}
                  />
                </button>
              </>
            }
            <button
              className = 'btt-confirm-exclusion-cancel'
              onClick   = {() => {setModal(false)}}
              >
              <h3>Cancelar</h3>
              <MdCancel
                size = {30}
                />
            </button>
          </div>
        }
      </>
    );
  }
  //console.log("Usuario: ", date.user);
  
  const bttsList = [
    {
      label     : "Termos de Uso",
      onClick   : () => {
        window.open("https://www.agendeiquadras.com.br/termos.html", "_blank");
      }, 
    },{
      label     : "Política de Privacidade",
      onClick   : () => {
        window.open("https://www.agendeiquadras.com.br/politica-de-privacidade-agendei-quadras.html", "_blank");
      },
    },{
      label     : "Política de Cookies",
      onClick   : () => {
        window.open("https://www.agendeiquadras.com.br/politica-de-cookies.html", "_blank");
      },
    },{
      label     : "Exclusão de Dados",
      onClick   : () => {
        setComptModal(<ModalConfirmaExclusao/>);
        setModal(true);
      },
    },{
      label     : "Solicitar cópia dos meus dados",
      onClick   : () => {
        const email   = "dados@agendeiquadras.com.br"
        const subject = encodeURIComponent("Solicitação de Cópia de Dados");
        const body    = encodeURIComponent(`Solicito receber uma cópia dos meus dados cadastrados na plataforma Agendei Quadras. \n \n ID: ${date.gestor.idusuario} \n Nome: ${date.gestor.nome} \n Email: ${date.gestor.username}`); 
        const mailto  = `mailto:${email}?subject=${subject}&body=${body}`;
        /* Chamar envio de email */
        window.open(mailto, "_blank");
      },
    }
  ]
  //Componente que renderiza os botões de acesso aos aspectos legais
  function BttCompAspects({data, index}){
    return (
      <button
        className = 'btt-acess-aspects-legais'
        onClick   = {data.onClick}
      >
        <h2>{data.label}</h2>
        <BiChevronRight
          className = 'btt-icon-aspects'
          size      = {30}
        />
      </button>
    );
  }

  return(
    <div className = 'contain-agenda-page'>
      {
        modal &&
        <ModalInfos
          modal       = {modal}
          ClosedModal = {() => setModal(false)}
          Component   = {comptModal}
        />
      }
      <div className = "contain-filtro-agenda-hf">
        <BiArrowBack
          className = 'icon-back-aspects'
          size      = {30}
          onClick   = {() => serverRouter("back")}
        />
        <div className = 'contain-aspect-legais-desktop'>
          <h1 className = 'title-aspects-legais'>
            Aspectos Legais
          </h1>
          {/*Container com a linha que separa titulo e botões */}
          <div className = "contain-line-aspects"/>
          <div className = 'contain-list-acess-aspects-legais'>
            {
              bttsList.map((item, index) => {
                return(
                  <BttCompAspects
                    key   = {index}
                    index = {index}
                    data  = {item}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}