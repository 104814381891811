import React                            from "react";

import { LocalStorage }                 from "../../../services";
import { PedidosAPI }                   from "../../../servicesAPI";
import { Loading }                      from "../../Basics";
import { ModalInfos }                   from "../../Modais/";
import { AuthContext }                  from "../../../contexts/AuthContext";
import RouterHook                       from "../../../contexts/RouterHook";
import Swal                             from "sweetalert2";
import { BiSolidEditAlt }               from "react-icons/bi";
import { GiReceiveMoney }               from "react-icons/gi";
import moment from "moment";

export default function CompRegistro({registro, onClick}) {

  const serverRouter                    = RouterHook();
  const { token, handleMOP, modalOpen } = React.useContext(AuthContext);
  const [showDetails, setShowDetails]   = React.useState(false);
  
  React.useEffect(() => {
    if(!modalOpen && showDetails) {
      //console.log("ModalOpen: ", modalOpen, showDetails);
      setShowDetails(false);
    }
  }, [modalOpen]);
  //console.log("Registro: ", registro.transacao);
  if(registro.categoria === "AGENDAMENTO"){
    const data = new Date(registro.dataRecebivel);
    //console.log("Registro: PEDIDO", registro);
    return (
      <button 
        className = "btt-comp-registro"
        onClick = {() => {
          setShowDetails(currentShowDetails => {
            const newShowDetails = !currentShowDetails;
            handleMOP(newShowDetails);
            return newShowDetails;
          });
        }}
      >
        {/* Data */}
        { showDetails &&
          <ModalInfos
            modal         = {showDetails}
            ClosedModal   = {() => {
              setShowDetails(currentShowDetails => {
                const newShowDetails = !currentShowDetails;
                handleMOP(newShowDetails);
                return newShowDetails;
              });
            }}
            Component     = {
              <CompMRgeg 
                registro    = { registro }
                data        = { data }
                label       = { "Agendamento #" + registro.pedido.idpedido }
                valor       = { registro.pedido.valorRealizado }
              />
            }
          />            
        }
        <div className = "contain-data-creg">
          <h2>{data.getDate()}</h2>
          <h3>{GetMes(data)}</h3>
          <p>{data.getFullYear()}</p>
        </div>
        {/* Registro */}
        <div className = "contain-registro-creg">
          <h2>{`Agendamento #${registro.pedido.idpedido}`}</h2>
          {ReturnMetodPay(registro.pedido.formaPagamento)}
        </div>
        {/* Valor */}
        <div className = "contain-registro-valor-creg">
          <h2>Você recebeu</h2>
          {
            registro.formaPagamento === "LOCAL" ?
            <h3>{`R$${registro.pedido.valorRealizado.toFixed(2).toString().replace(".", ",")}`}</h3> 
              :
            <h3>{`R$${registro.transacao ? registro.transacao.valorLiquido.toFixed(2).toString().replace(".", ",") : registro.pedido.valorRealizado.toFixed(2).toString().replace(".", ",")}`}</h3>
          }
        </div>
      </button>
    );
  } else if(registro.categoria === "DESPESA_MANUAL"){
    const data = new Date(registro.data);
    //console.log("Data do registro: ", data);
    //console.log("Registro: ", registro);
    return (
      <button 
        className = "btt-comp-registro"
        onClick = {() => {
          setShowDetails(!showDetails);
          handleMOP(!showDetails);
        }}  
      >
        <ModalInfos
          modal         = {showDetails}
          ClosedModal   = {() => {
            setShowDetails(!showDetails);
            handleMOP(!showDetails);
          }}
          Component     = {
            <CompMRgeg 
              registro    = {registro}
              data        = {data}
              label       = {registro.observacao}
              valor       = {registro.valor}
            />
          }
        />
        {/* Data */}
        <div className = "contain-data-creg">
          <h2>{data.getDate()}</h2>
          <h3>{GetMes(data)}</h3>
          <p>{data.getFullYear()}</p>
        </div>
        {/* Registro */}
        <div className = "contain-registro-creg">
          <h2 style={{backgroundColor: "#F05545"}}>{`${registro.observacao}`}</h2>
          <div className = "contain-registro-icon-creg">
            <BiSolidEditAlt
              size  = {17}
              color = {"white"}
            />
            <h2>{`Registrado por: ${registro.criadoPor}`}</h2>
          </div>
        </div>
        {/* Valor */}
        <div className = "contain-registro-valor-creg" >
          <h2 style={{color: "#F05545"}}>Você gastou</h2>
          <h3 style={{color: "#F05545"}}>{`R$${registro.valor.toFixed(2).toString().replace(".",",")}`}</h3>
        </div>
      </button>
    );
  } else if(registro.categoria === "RECEITA_MANUAL"){
    const data = new Date(registro.data);
    //console.log("Data do registro: ", data);
    //console.log("Registro: RECEITA ", registro, showDetails);
    return (
      <button 
        className = "btt-comp-registro"
        onClick = {() => {
          setShowDetails(!showDetails);
          handleMOP(!showDetails);
        }}  
      >
        {
          showDetails &&
          <ModalInfos
            modal         = {showDetails}
            ClosedModal   = {() => {
              setShowDetails(false);
              handleMOP(false);
            }}
            Component     = {
              <CompMRgeg 
                registro    = {registro}
                data        = {data}
                label       = {registro.observacao}
                valor       = {registro.valor}
              />
            }
          />
        }
        {/* Data */}
        <div className = "contain-data-creg">
          <h2>{data.getDate()}</h2>
          <h3>{GetMes(data)}</h3>
          <p>{data.getFullYear()}</p>
        </div>
        {/* Registro */}
        <div className = "contain-registro-creg">
          <h2>{`${registro.observacao}`}</h2>
          <div className = "contain-registro-icon-creg">
          <BiSolidEditAlt
            size  = {17}
            color = {"white"}
          />
          <h2>{`Registrado por: ${registro.criadoPor}`}</h2>
        </div>
        </div>
        {/* Valor */}
        <div className = "contain-registro-valor-creg" >
          <h2 >Você recebeu</h2>
          <h3 >{`R$${registro.valor.toFixed(2).toString().replace(".",",")}`}</h3>
        </div>
      </button>
    );
  } else if(registro.categoria === "DIARIA"){
    //console.log("Registro de Diária: ", registro);
    const data = new Date(registro.dataRecebivel);
    return (
      <button 
        className = "btt-comp-registro"
        onClick = {() => {
          setShowDetails(!showDetails);
          handleMOP(!showDetails);
        }}  
      >
        <ModalInfos
          modal         = {showDetails}
          ClosedModal   = {() => {
            setShowDetails(!showDetails);
            handleMOP(!showDetails);
          }}
          Component     = {
            <CompMRgeg 
              registro    = {registro}
              data        = {data}
              label       = {"Diária"}
              valor       = {registro.transacao ? registro.transacao.valorLiquido : registro.valor}
            />
          }
        />
        {/* Data */}
        <div className = "contain-data-creg">
          <h2>{data.getDate()}</h2>
          <h3>{GetMes(data)}</h3>
          <p>{data.getFullYear()}</p>
        </div>
        {/* Registro */}
        <div className = "contain-registro-creg">
          <h2 style={{backgroundColor: "#FF7043"}}>{`Diária`}</h2>
          {ReturnMetodPay(registro.formaPagamento)}
        </div>
        {/* Valor */}
        <div className = "contain-registro-valor-creg" >
          <h2>Você recebeu</h2>
          <h3>{`R$${registro.transacao ? registro.transacao.valorLiquido.toFixed(2).toString().replace(".",",") : registro.valor.toFixed(2).toString().replace(".",",")}`}</h3>
        </div>
      </button>
    );
  } else {
    const data = new Date(registro.data);
    //console.log("Data do registro: ", data);
    //console.log("Registro: ", registro);
    return (
      <button 
        className = "btt-comp-registro"
        onClick = {() => {
          setShowDetails(!showDetails);
          handleMOP(!showDetails);
        }}  
      >
        <ModalInfos
          modal         = {showDetails}
          ClosedModal   = {() => {
            setShowDetails(!showDetails);
            handleMOP(!showDetails);
          }}
          Component     = {
            <CompMRgeg 
              registro    = {registro}
              data        = {data}
              label       = {registro.observacao}
              valor       = {registro.valor}
            />
          }
        />
        {/* Data */}
        <div className = "contain-data-creg">
          <h2>{data.getDate()}</h2>
          <h3>{GetMes(data)}</h3>
          <p>{data.getFullYear()}</p>
        </div>
        {/* Registro */}
        <div className = "contain-registro-creg">
          <h2 style={{backgroundColor: "#F05545"}}>{`${registro.observacao}`}</h2>
          
        </div>
        {/* Valor */}
        <div className = "contain-registro-valor-creg" >
          <h2 style={{color: "#F05545"}}>Você pagou</h2>
          <h3 style={{color: "#F05545"}}>{`R$${registro.valor.toFixed(2).toString().replace(".",",")}`}</h3>
        </div>
      </button>
    );
  }

  function ReturnMetodPay(metod, color){
    //console.log("Metod: ", metod);
    switch(metod){
      case "DINHEIRO" : return (
        <div className = "contain-registro-icon-creg">
          <GiReceiveMoney
            size  = {17}
            color = {"white"}
          />
          <h2>Recibimento no Local</h2>
        </div>
      );

      case "CARTAO_CREDITO" : return (
        <div className = "contain-registro-icon-creg">
          <GiReceiveMoney
            size  = {17}
            color = {"white"}
          />
          <h2>Pagamento online</h2>
        </div>
      );
      case "PIX" : return (
        <div className = "contain-registro-icon-creg">
          <GiReceiveMoney
            size  = {17}
            color = {color ? "balck" : "white"}
          />
          <h2 style={color ? {color: "black"}:{color:"white"}}>Pagamento via PIX</h2>
        </div>
      );
      case "LOCAL" : return (
        <div className = "contain-registro-icon-creg">
          <GiReceiveMoney
            size  = {17}
            color = {"black"}
          />
          <h2 style = {color ? {color: "black"}: {}}>Recibimento no Local</h2>
        </div>
      );
      default : return (<></>);
    }
  }

  function GetMes(data){
    const meses = [
      "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
      "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];
    return meses[data.getMonth()];
  }

  function CompMRgeg({registro, data, label, valor}){
    const [loadC, setLoadC] = React.useState(false);
    
    const meses = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET","OUT", "NOV", "DEZ"];
    const dt = new Date(data);
    const dia = dt.getDate();
    const mes = meses[dt.getMonth()];
    const ano = dt.getFullYear();
    const isDespesa = (registro.categoria === "DESPESA_MANUAL" || registro.categoria === "FATURA");
    const color = (registro.categoria === "DESPESA_MANUAL" || registro.categoria === "FATURA") ? "#F05545" : "#2CBB76";

    async function StartGoPedido(idpedido){
      setLoadC(true);
      const ress = await PedidosAPI.BuscarPedidoId(token, idpedido);
      LocalStorage.set("pedidoDetails", {pedido: ress.req, page: "fluxo-caixa"});
      serverRouter("details-appoint", idpedido);
      setLoadC(false);
    }
    
    return (
      <div className = "contain-comp-modal-registro">
        { loadC ?
          <div className = "contain-loading" style = {{height: "300px"}}>
            <Loading mode = {true} text = {registro.categoria === "AGENDAMENTO" ? "Carregando Agendamento..." : "Deletando registro..."}/>
          </div> :
          <>
            <div className = "contain-comp-modal" style={{marginBottom: "10px", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
              <h2 style = {{marginRight: "5px"}}>
                Data:
              </h2>
              <h3 style = {{margin: "3px 0px 0px 0px", fontFamily: "var(--font-jost-mediun)"}}>
                {moment(data).format("DD/MM/YY")}
              </h3>
            </div>
            <h2 className = "text-label-modal-creg" style = {{backgroundColor: color}}>
              {label}
            </h2>
            <div>
            { (registro.categoria === "DESPESA_MANUAL" || registro.categoria === "RECEITA_MANUAL") ?
              <div className = "contain-registro-icon-creg">
                <BiSolidEditAlt
                  size  = {20}
                  color = {"black"}
                />
                <h2 style = {{color: "black", fontSize: "15px"}}>{`Registrado manualmente por ${registro.criadoPor} em ${moment(data).format("DD/MM/YY")}`}</h2>
              </div> :
              ReturnMetodPay(registro.formaPagamento, true)
            }
            </div>
            <h2 className = "text-modal-creg-recibo" style = {{color: color, margin: "10px 0px 5px 0px"}}>
              {registro.categoria === "DESPESA_MANUAL" ? "Você gastou" : registro.categoria === "FATURA" ? "Você pagou"  : "Você recebeu"}
            </h2>
            <h2 className = "text-modal-creg-valor" style = {{color: color, marginBottom: "10px"}}>
              {`R$${valor.toFixed(2).toString().replace(".", ",")}`}
            </h2>
            { registro.categoria === "AGENDAMENTO" &&
              <button
                className = "btt-acessar-agendamento"
                onClick   = {() => {
                  StartGoPedido(registro.pedido.idpedido);
                }}
              >
                Acessar Agendamento
              </button>
            }{
              (registro.categoria === "DESPESA_MANUAL" || registro.categoria === "RECEITA_MANUAL") &&
              <button
                className = "btt-acessar-agendamento"
                style = {{backgroundColor : "#F05545"}}
                onClick   = {() => {
                  Swal.fire({
                    title: 'Atenção!',
                    text: "Deseja deletar este registro? Esta ação não poderá ser desfeita!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor    : '#bf3324',
                    cancelButtonColor     : '#00cda4',
                    confirmButtonText     : 'Não',
                    cancelButtonText      : 'Sim'
                  }).then(async (result) => {
                    if(!result.isConfirmed && result.dismiss === "cancel"){
                      setLoadC(true);
                      await onClick(registro);
                      setLoadC(false);
                    }
                  });
                }}
              >
                Excluir este registro
              </button>
            }
          </>
        }
      </div>
    );
  }
}
