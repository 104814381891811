import React                        from "react";
import { CalClock }                 from "../../../assets";
import { LocalStorage, Services }   from "../../../services";
import { 
  BiMap, BiCalendarCheck, BiCalendarHeart, BiCalendarX
} from "react-icons/bi";
import { Loading }                  from "../../Basics";
import { AuthContext }              from "../../../contexts/AuthContext";
import RouterHook                   from "../../../contexts/RouterHook";
import moment                       from "moment";
import { PedidosAPI }               from "../../../servicesAPI";

import "moment/locale/pt-br";
import "./stylesCPed.css"

moment.locale("pt-br");

export default function CompPedido({pedido, nome, maxW, fixo}) {

  const serverRouter            = RouterHook();
  const [loading, setLoading]   = React.useState(false);
  const { token, date }         = React.useContext(AuthContext);
  const quadra                  = pedido.quadra;
  //console.log("Pedido: ", pedido);
   /* padrão de cores!
      colorSolicitado: '#ff7043',
      colorReservado: '#24ad49',
      colorRealizado: '#00cda4',
      colorCancelado: '#bf3324',
    */
  // 
  //console.log("Fixo: ", fixo);
  // Renderiza o tempo que falta para acontecer o pedido! Passado ou Futuro!
  //console.log("Pedido: ", pedido);
  function ReturnFalta() {
    // Calcula o tempo para acontecer o pedido, futuro ou passado!
    //const falta = Services.RetornFaltaHora(new Date(pedido.dataInicio));
    const falta = moment(pedido.dataInicio).startOf("minute").fromNow().toUpperCase();
    switch(pedido.status){
      case "SOLICITADO" : 
        //console.log("Falta: ", falta);
        return (
          <div className = 'contain-btt-pedido-infos-line'>
            <CalClock
              size  = {25}
              style = {{color:"#ff7043", width: "25px", height: "25px"}}
            />
            <h3 
              className = {"text-states-header"}
              style     = {{color: "#ff7043"}}  
            >
              {falta}
            </h3>
          </div>
        );
      case "AGENDADO" : return (
        <div className = 'contain-btt-pedido-infos-line'>
          <BiCalendarHeart
            size  = {25}
            color = "#22ac4a"
          />
          <h3
            className = {"text-states-header"}
            style     = {{color: "#22ac4a"}}
          >
            {falta}
          </h3>
        </div>
      );
      case "CANCELADO" : return (
        <div className = 'contain-btt-pedido-infos-line'>
          <BiCalendarX
            size  = {25}
            color = "#bf3324"
          />
          {/*<h3
            className = {"text-states-header"}
            style     = {{color: "#bf3324"}}
          >
            {falta}
          </h3>*/}
        </div>
      );
      case "REALIZADO" : return (
        <div className = 'contain-btt-pedido-infos-line'>
          <BiCalendarCheck
            size  = {25}
            color = "#00cda4"
          />
          <h3
            className = {"text-states-header"}
            style     = {{color: "#00cda4"}}
          >
            {falta}
          </h3>
        </div>
      );
    }
  }
  function ReturnSolicitou() {
    const falta = moment(pedido.createdAt).startOf("minute").fromNow().toLowerCase();
    return `Solicitado ${falta} por`;
  }
  // Seleciona o estilo do botão de acordo com o status do pedido!
  function ReturnContainPedido(status) {
    if(maxW){
      switch(status){
        case "SOLICITADO" : return "btt-pedido-comp btt-gradient-solicitado-pd-hf";
        case "AGENDADO"   : return "btt-pedido-comp btt-gradient-agendado-pd-hf";
        case "CANCELADO"  : return "btt-pedido-comp btt-gradient-cancelado-pd-hf";
        case "REALIZADO"  : return "btt-pedido-comp btt-gradient-realizado-pd-hf";
      }
    } else{
      switch(status){
        case "SOLICITADO" : return "btt-pedido-comp btt-gradient-solicitado-pd";
        case "AGENDADO"   : return "btt-pedido-comp btt-gradient-agendado-pd";
        case "CANCELADO"  : return "btt-pedido-comp btt-gradient-cancelado-pd";
        case "REALIZADO"  : return "btt-pedido-comp btt-gradient-realizado-pd";
      }
    }
  }
  // Retorna o componente referente ao status do pedido, selecionando a cor!
  function ReturnColorStatus(status) {
    switch(status){
      case "SOLICITADO" : return <h3 style={{color: "#ff7043"}}>{status}</h3>;
      case "AGENDADO"   : return <h3 style={{color: "#22ac4a"}}>{status}</h3>;
      case "CANCELADO"  : return <h3 style={{color: "#bf3324"}}>{status}</h3>;
      case "REALIZADO"  : return <h3 style={{color: "#00cda4"}}>{status}</h3>;
    }
  }
  //console.log("Pedido: ", pedido);
  return (
    <>{
      loading ? 
      <div className = "contain-loading">
        <Loading mode = {false}/> 
      </div>
        :
      <button 
        className = {ReturnContainPedido(pedido.status)}
        style     = {maxW ? {maxWidth: "400px", cursor: "pointer"} : {cursor: "pointer"}}
        onClick   = {async () => {
          //console.log("Pedido: Aqui ", pedido);
          setLoading(true);
          const ress = await PedidosAPI.BuscarPedidoId(token, pedido.idpedido);
          LocalStorage.set("pedidoDetails", {pedido: ress.req, page: "agendamento"});
          serverRouter("details-appoint", pedido.idpedido);
          setLoading(false);
        }}
      >
        <div className = 'contain-btt-pedido-infos left-pedido'>
          {ReturnFalta()}
          <h1 className = "text-infos-negrito">
            {`${Services.ReturnHoraMin(new Date(pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(pedido.dataFinal))}`}
          </h1>
          <p className = "text-solicitou-sol">
            {`${Services.ReturnDay(new Date(pedido.dataInicio).getDay())}, ${Services.ReturnToStringDate(new Date(pedido.dataInicio))}`}
          </p>
          { (pedido.mensalista_idmensalista !== null) &&
            <h3>FIXO</h3>
          }
          { !fixo &&
            <h1 className = "text-infos-negrito" style = {{fontSize: "14px", color: "#FF7043", marginBottom: "3px"}}>
              {`${quadra.descricao}`}
            </h1>
          }
          {ReturnColorStatus(pedido.status)}
        </div>
        <div className = 'contain-btt-pedido-infos right-pedido'>
          <p className = "text-solicitou-sol">
            {ReturnSolicitou()}
          </p>
          <h1 className = "text-infos-negrito" style = {{color: "var(--color-text-hig)"}} >
            {nome ? nome : pedido.usuario.nome}
          </h1>
          <h2 className = "text-infos-negrito" style = {{fontSize: "14px"}}>
            {`Nº ${pedido.idpedido}`}
          </h2>
          <h1 className = "text-infos-negrito">
            {ReturnFormPay(pedido.formaPagamento)}
          </h1>
          <h2 className = "text-cp-valor">
            {`Valor Total ${pedido.valorTotal !== null ? (pedido.valorDesconto > 0 ? (`R$${(pedido.valorTotal - pedido.valorDesconto).toFixed(2).toString().replace(".",",")}`) : (`R$${pedido.valorTotal.toFixed(2).toString().replace(".",",")}`)) : "R$0,00"}`}
          </h2>
        </div>
      </button>
    }</>
  );

  function ReturnFormPay(val) {
    switch(val){
      case "DINHEIRO"         : return "Dinheiro";
      case "CARTAO_CREDITO"   : return "Cartão de crédito";
      case "PIX"              : return val;
      case "MAQUINA_CARTAO"   : return "Máquina de cartão";
    }
  }
}