import Switch from "react-switch";
import "./stylesTS.css";

export default function TxtSwitch({info, onChange, value}) {

  return (
    <div className = "label-sw">
      <span>{info}</span>
      <Switch
        title    = {value ? "Desativar" : "Ativar"}
        onChange = {onChange}
        checked  = {value}
      />
    </div>
  );
}