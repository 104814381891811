import React                from "react";
import { 
  Alerts, Client, Loading, 
} from "../../Basics";
import { AuthContext }      from "../../../contexts/AuthContext";
import { EmpresaAPI }       from "../../../servicesAPI";

import "./stylesLC.css";
import { LocalStorage } from "../../../services";
import { notClient } from "../../../assets";

export default function ListClients({mode, handleClient, filter, type, selected, addClient, newClient}){

  const { token, date } = React.useContext(AuthContext);
  const pagBuffer = {
    offSet      : 0,
    limit       : 20,
    maxScroll   : null,
    scroll      : 0
  }
  const listRef                       = React.useRef(null);
  const [loading, setLoading]         = React.useState(true);
  const [loading2, setLoading2]       = React.useState(false);
  const [flag, setFlag]               = React.useState(false);
  const [stt, setStt]                 = React.useState(pagBuffer);
  const [clientes, setClientes]       = React.useState([]);
  const [arrayState, setArrayState]   = React.useState([]);
 //console.log("Selected:> ", selected);
  // Carrega os clientes da empresa
  async function BuscaClientes(){
    setLoading(true);
    const reqs = await EmpresaAPI.BuscarClientsEmpresa(token, date.empresa.idempresa, null, null, null);// stt.limit, 0, true);
    //console.log("BuscaClientes Reqs:> ", reqs);
    if(reqs.status){
      const LC = LocalStorage.get("clientes-buffer");
      //console.log("BuscaClientes LC:> ", LC);
      setStt({
        ...stt, 
        maxScroll : reqs.req.usuario.count,
        offSet    : reqs.req.usuario.rows.length,
      });
      if(LC !== null){
       //console.log("Reqs Clientes:> ", reqs.req.usuario.rows);
        const array = [...reqs.req.usuario.rows].sort((a, b) => {
          return a.nome.localeCompare(b.nome);
        });
        if(selected){
          //console.log("Selected Entrou:> ", selected);
          const index = array.findIndex((user) => {
            return user.idusuario === selected.idusuario;
          });
          // joga o cliente selecionado para o topo da lista
          if(index !== -1){
            //console.log("Achou:> ", index);
            const user = array[index];
            array.splice(index, 1);
            array.unshift(user);
            setClientes([ ...LC,...array]);
            setArrayState([...LC, ...array]);
          } else {
            //console.log("Não Achou:> ", index);
            setClientes([...LC, ...array]);
            setArrayState([...LC, ...array]);
          }
        } else {
          setArrayState([...LC, ...array]);
          setClientes([...LC, ...array]);
        }
      } else {
        const array = [...reqs.req.usuario.rows].sort((a, b) => {
          return a.nome.localeCompare(b.nome);
        });
        if(selected){
         //console.log("Selected Entrou:> ", selected);
          const index = array.findIndex((user) => {
            return user.idusuario === selected.idusuario;
          });
          // joga o cliente selecionado para o topo da lista
          if(index !== -1){
            //console.log("Achou:> ", index);
            const user = array[index];
            array.splice(index, 1);
            array.unshift(user);
            setClientes(array);
            setArrayState(array);
          } else {
            //console.log("Não Achou:> ", index);
            setClientes(array);
            setArrayState(array);
          }
        } else {
          setClientes(array);
          setArrayState(array);
        }
      }
      setLoading(false);
    } else {
      Alerts.aviso("Aviso", "Não foi possível carregar os clientes da empresa, por favor tente novamente mais tarde.");
    }
  }
 //console.log("Clientes:> ", clientes);
  // Carrega os clientes da empresa
  React.useEffect(() => {
    BuscaClientes();
  }, [addClient]);

  React.useEffect(() => {
    // Filtra cliente pelo nome
    if(filter.length > 0){
      const filtered = arrayState.filter((user) => {
        return user.nome.toLowerCase().includes(filter.toLowerCase());
      });
      setClientes(filtered);
    } else {
      setClientes(arrayState);
    }
  }, [filter]);
  //console.log("Stt:> ", stt);
  //console.log("ListClients:> ", clientes);
  // Buscar mais clientes para a lista de clientes
  async function BuscaMaisClientes(){
    const req = await EmpresaAPI.BuscarClientsEmpresa( token, date.empresa.idempresa, stt.limit, stt.offSet, false);
    //console.log("BuscaMaisClientes:> ", req);
    if(req.status){
      if(req.req.retorno){
        setStt({
          ...stt,
          offSet    : stt.offSet + req.req.usuario.rows.length,
        });
        const array = [...arrayState, ...req.req.usuario.rows].sort((a, b) => {
          return a.nome.localeCompare(b.nome);
        });
        setArrayState(array);
        setClientes(array);
      }
    } else {
      Alerts.aviso("Aviso", "Não foi possível carregar mais clientes, por favor tente novamente mais tarde.");
    }
  }
  // Paginação
  /*React.useEffect(() => {
    function handleScroll() {
      const scrollTop     = listRef.current.scrollTop;
      const scrollHeight  = listRef.current.scrollHeight;
      const clientHeight  = listRef.current.clientHeight;   
      //console.log("Escutando Scroll!")
      if ((scrollTop + clientHeight) >= scrollHeight && !flag && arrayState.length < stt.maxScroll) {
        //console.log("Entrou aqui! novo handleScroll");
        setFlag(true);
        setLoading2(true);
        // Chama a api com os novos parametros de paginação'
        //console.log("stt:> ", stt);
        BuscaMaisClientes().then((res) => {
          setLoading2(false);
          setFlag(false);
        });
      }
    }
    //console.log("Adicionando Evento Scroll!", listRef.current.removeEventListener)
    if(listRef.current) listRef.current.addEventListener("scroll", handleScroll);
    return () => {
      if(listRef.current) listRef.current.removeEventListener("scroll", handleScroll);
    }
  }, [listRef, loading, stt]);*/
  
  
  if(type === "list-open"){
    return(
      <div className = "contain-list-clients-page">
        { loading ? 
          <div className = "contain-loading">
            <Loading 
              mode  = {true}
              text  = {"Carregando clientes..."}
            />
          </div>
            : 
          <>
            {
              clientes.map((user, index) => {
                return (
                  <Client
                    type    = {"open"}
                    key     = {index}
                    usuario = {user}
                    onClick = {() => handleClient(user)}
                  />
                );
              })
            }
          </>
        }
      </div>
    );
  } else if(type === "list-select"){
    return(
      <>
        { loading ?
          <div className = "contain-loading">
            <Loading
              mode  = {true}
              text  = {"Carregando clientes..."}
            />
          </div>
            :
          <div 
            className = "contain-list-clients-page"
            ref       = {listRef}
          > 
            { clientes.length > 0 ?
              <>
                {
                  clientes.map((user, index) => {
                    //if(user.nome === "Gianella")//console.loglog("User:> ", user);
                    return (
                      <Client
                        type    = {"select"}
                        key     = {index}
                        select  = {selected !== null ? selected.idusuario === user.idusuario ? true : false : false}
                        usuario = {user}
                        onClick = {() => handleClient(user)}
                      />
                    );
                  })
                }
                { loading2 &&
                  <div className = "contain-loading">
                    <Loading mode = {false}/>
                  </div>
                }
              </>
                :
              <div className = "contain-not-list-clients">
                <img 
                  alt = "Sem clientes" 
                  src = {notClient} 
                />
              </div>
            }
          </div>
        }
      </>
    );
  }
}