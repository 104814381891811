import React                        from "react";
import { CalClock }                 from "../../../assets";
import { LocalStorage, Services }   from "../../../services";
import { 
  BiMap, BiCalendarCheck, BiCalendarHeart, BiCalendarX, BiCheck, BiX
} from "react-icons/bi";
import { Loading }                  from "..";
import { AuthContext }              from "../../../contexts/AuthContext";
import RouterHook                   from "../../../contexts/RouterHook";
import moment                       from "moment";
import { PedidosAPI }               from "../../../servicesAPI";

import "moment/locale/pt-br";
import "./stylesCPS.css"

moment.locale("pt-br");

export default function CompPedSol({ pedido, onClick, show }) {
  
  const serverRouter            = RouterHook();
  const [loading, setLoading]   = React.useState(false);
  const { token, date }         = React.useContext(AuthContext);
  const quadra                  = pedido.quadra;

    /* padrão de cores!
      colorSolicitado: '#ff7043',
      colorReservado: '#24ad49',
      colorRealizado: '#00cda4',
      colorCancelado: '#bf3324',
    */

      /* Solicitação H Avulso 3 */



  // Renderiza o tempo que falta para acontecer o pedido! Passado ou Futuro!
  //console.log("Pedido: ", pedido);
  function ReturnFalta() {
    // Calcula o tempo para acontecer o pedido, futuro ou passado!
    //const falta = Services.RetornFaltaHora(new Date(pedido.dataInicio));
    const falta = moment(pedido.dataInicio).startOf("minute").fromNow().toUpperCase();
    switch(pedido.status){
      case "SOLICITADO" : 
        //console.log("Falta: ", falta);
        return (
          <div className = 'contain-btt-pedido-infos-line-sol'>
            <CalClock
              size  = {25}
              style = {{color:"#ff7043", width: "25px", height: "25px"}}
            />
            <h3 
              className = {"text-states-header-sol"}
              style     = {{color: "#ff7043"}}  
            >
              {falta}
            </h3>
          </div>
        );
    }
  }

  function ReturnSolicitou() {
    const falta = moment(pedido.createdAt).startOf("minute").fromNow().toLowerCase();
    return `Solicitou ${falta} por`;
  }

  function ReturnMetPay() {
    switch(pedido.formaPagamento){
      case "DINHEIRO" : 
        return "Dinheiro";
      case "PIX" : 
        return "Pix";
      case "MAQUINA_CARTAO" : 
        return "Máquina de Cartão";
      case "CARTAO_CREDITO" :
        return "Cartão de Crédito";
    }
  }
  
  async function GoDetaisAgend() {
    setLoading(true);
    const ress = await PedidosAPI.BuscarPedidoId(token, pedido.idpedido);
    LocalStorage.set("pedidoDetails", {pedido: ress.req, page: "agendamento"});
    serverRouter("details-appoint", pedido.idpedido);
    setLoading(false);
  }

  return (
    <>
      {
        loading ? 
        <div className = "contain-loading">
          <Loading mode = {false}/> 
        </div>
          :
        show ?
          <button 
            className = {"btt-pedido-comp-sol btt-gradient-solicitado-sol"}
            style = {{
              background          : `url(${pedido.quadra.urlFoto + pedido.quadra.foto})`,
              backgroundSize      : "cover",
              backgroundPosition  : "center",
              cursor              : "pointer",
            }}
            onClick   = {() => {
              //console.log("Pedido: Aqui ", pedido);
              GoDetaisAgend();
            }}
          >
            <div className = 'contain-btt-pedido-infos-sol left-pedido-sol'>
              {ReturnFalta()}
              <h2 className = "text-infos-negrito-sol">
                {`${Services.ReturnHoraMin(new Date(pedido.dataInicio))} às ${Services.ReturnHoraMin(new Date(pedido.dataFinal))}`}
              </h2>
              <p>{`${Services.ReturnDay(new Date(pedido.dataInicio).getDay())}, ${Services.ReturnToStringDate(new Date(pedido.dataInicio))}`}</p>
              <h3 style={{color: "#ff7043", fontFamily: "jost-mediun"}}>{pedido.status}</h3>
            </div>
            <div className = 'contain-btt-pedido-infos-sol right-pedido-sol'>
              <p>
                {ReturnSolicitou()}
              </p>
              <h2 className = "text-infos-negrito-sol-hig">
                {pedido.usuario.nome}
              </h2>
              <h2 className = "text-infos-negrito-sol" style = {{fontSize: "14px"}}>
                {`${quadra.descricao}`}
              </h2>
              <h2 className = "text-infos-negrito-sol" style = {{fontSize: "14px", fontFamily: "jost-mediun", marginBottom: "3px"}}>
                {ReturnMetPay()}
              </h2>
              <h4 className = "valor-pedido-sol">
                {`${pedido.valorTotal !== null ? (pedido.valorDesconto > 0 ? (`VALOR R$${(pedido.valorTotal - pedido.valorDesconto).toFixed(2).toString().replace(".",",")}`) : (`VALOR R$${pedido.valorTotal.toFixed(2).toString().replace(".",",")}`)) : "R$0,00"}`}
              </h4>
            </div>
            { (pedido.formaPagamento === "DINHEIRO" || pedido.formaPagamento === "MAQUINA_CARTAO") &&
              <div className = "contain-btt-acept-declined">
                {date.perfilG.cancelar &&
                  <button
                    className   = "btt-declined"
                    onClick     = {(e) => {
                      onClick("declined");
                      e.stopPropagation();
                    }}
                  >
                    <BiX
                      size  = {30}
                      color = "white"
                    />
                  </button>
                }
                <button
                  className   = "btt-aceppt"
                  onClick     = {(e) => {
                    onClick("aceppt");
                    e.stopPropagation();
                  }}
                >
                  <BiCheck
                    size  = {30}
                    color = "white"
                  />
                </button>
              </div>
            }
            <div className = "escurece-foto"/>
          </button>
          :
          <></>
      }
    </>
  );
}