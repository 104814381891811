import React            from 'react';
import moment           from 'moment';
import "./stylesAgmt.css";

export default function AlertGmt({show, local, horaEmpresa, horaUsuario}) {

  if(!show) return <></>;
  
  return(
    <div className = {`contain-alert-gmt-${local}`}>
      <div className = {`contain-arrow-alert-gmt-${local}`} />
      <div className = 'contain-internal-alert-gmt'>
        <h3>Horário local: <b style={{fontWeight:"bold"}}>{horaEmpresa}</b></h3>
        <h3>Em seu dispositivo: <b style={{fontWeight:"bold"}}>{horaUsuario}</b></h3>
      </div>
    </div>
  );
}