import React                              from "react";
import { 
  BiFileFind, BiMoneyWithdraw, BiChevronRight, BiCheck,
  BiUserCheck,
  BiEditAlt,
  BiArrowBack
} from "react-icons/bi";
import { RiUserSearchLine }               from "react-icons/ri";
import { 
  Alerts,
  InputIcon,
  SeletorPay
} from "../../Basics";
import { GmtAviso, ListClients } from "../../Complext";
import { ModalInfos, ModalSelHF }         from "../../Modais";
import { TbAlertCircleFilled }            from "react-icons/tb";
import Swal                               from "sweetalert2";
import { iconsPayment }                   from "../../../assets";
import RouterHook                         from "../../../contexts/RouterHook";
import { AuthContext }                    from "../../../contexts/AuthContext";
import { 
  LocalStorage, Services
} from "../../../services";
import moment                             from "moment";
import { EmpresaAPI }                     from "../../../servicesAPI";

import 'moment-timezone';
import "./stylesCG.css";

export default function CheckoutGestor({ handleBttState }) {
  const {
    valorTotal, agenda, quadra, horarioAvulso, dia
  } = LocalStorage.get("checkout-gestor");
  // Printa os valores
  //console.log("Valor Total: ", valorTotal, "Agenda: ", agenda, "Quadra: ", quadra, "Horario Avulso: ", horarioAvulso, "Dia: ", dia);
  const { 
    token, date, modalOpen, handleMOP 
  } = React.useContext(AuthContext);
  const serverRouter                      = RouterHook();
  // Variáveis de controle
  const [hrFixo, setHrFixo]               = React.useState(null);   // Horario fixo selecionado
  const [hrFixoPer, setHrFixoPer]         = React.useState(0);      // Periodo do Horario fixo selecionado
  const [loading, setLoading]             = React.useState(false);  // false = não está carregando, true = está carregando
  const [cliente, setCliente]             = React.useState(null);   // Cliente selecionado
  const client = {nome: "", telefone: ""} 
  const [newCliente, setNewCliente]       = React.useState(client); // Cliente criado
  const [addClient, setAddCliente]       = React.useState(false);   // Cliente criado
  const [periodo, setPeriodo]             = React.useState(0);      // Periodo do Horario fixo selecionado
  //console.log("Date", date.empresa);
  //console.log("Pedido", pedido);
  //console.log("Quadra", quadra);
  //console.log("Dia", dia);
  //console.log("Mode", mode);
  const hrFixoSelect = LocalStorage.get("criar-hf");
  const [sttPage, setSttPage]             = React.useState({
    textA             : "",
    filter            : "",
    nomeUser          : "",
    telefoneUser      : "",
    horaUser          : "",
    modalHF           : hrFixoSelect !== null ? hrFixoSelect : false,
    modalNV           : false,
    modalNC           : false,
    avulso            : true,
    viewSinal         : false,
    viewRestante      : false,
  });
  const sttPayBase = {
    valorT      : valorTotal,  // Valor total do pedido || Valor total do pedido fixo
    valorS      : valorTotal.toFixed(2),  // Valor selecionado pelo usuário
    valorA      : 0,                  // Para fixo -> valor por agendamento
    selCedulas  : true,
    selMaqCard  : false,
    metodPay    : {
      tipo      : "DINHEIRO",
    },
  }
  const [sttPay, setSttPay]               = React.useState(sttPayBase);
  const taxa = 0 ; // Para implementar a taxa de pagamento preciso do objeto planoAssinatura!
  
  // Escuta modalOpen
  React.useEffect(() => {
    const clientSelect = LocalStorage.get("clienteSelect");
   //console.log("HrFixoSelect", hrFixoSelect);

    if(!modalOpen) {
     //console.log("Modal fechado");
      if(sttPage.modalHF || sttPage.modalNV || sttPage.modalNC){
        setSttPage({
          ...sttPage,
          modalHF   : hrFixoSelect !== null ? hrFixoSelect : false,
          modalNV   : false,
          modalNC   : false,
        });
      }
    }
    // Verifica ação de cliente no localStorage
    if(clientSelect !== null){
      setCliente(clientSelect);
      // Apaga o cliente do localStorage
      LocalStorage.remove("clienteSelect");
    }
    if(hrFixoSelect !== null){
      LocalStorage.remove("criar-hf");
    }
  }, [modalOpen]);

  // Atualiza modalOpen
  React.useEffect(() => {
    if(sttPage.modalHF || sttPage.modalNV || sttPage.modalNC){ 
      handleMOP(true);
      // limpa os inputs
      setNewCliente(client); 
    } else handleMOP(false);
  }, [sttPage.modalHF, sttPage.modalNV, sttPage.modalNC]);
  
  /* Porcentagem de sinal*/
  
  // Avulsos, pagamentos referentes a horarios avulsos
  const recebimentos_maquina_local_avulso = quadra.regraAgendamento.recebimentos_maquina_local_avulso;
  const recebimentos_dinheiro_avulso      = quadra.regraAgendamento.recebimentos_dinheiro_avulso;
  // Fixos, pagamentos referentes a horarios fixos
  const recebimentos_maquina_local_fixo   = quadra.regraAgendamento.recebimentos_maquina_local_fixo;
  //const recebimentos_cartao_fixo          = quadra.regraAgendamento.recebimentos_cartao_fixo;
  const recebimentos_dinheiro_fixo        = quadra.regraAgendamento.recebimentos_dinheiro_fixo;
  //const recebimentos_pix_fixo             = quadra.regraAgendamento.recebimentos_pix_fixo;

  React.useEffect(() => {
   
    function defineGMT(gmt) {
      switch (gmt) {
          case -5:
              return '-05:00';
          case -4:
              return '-04:00';
          case -3:
              return '-03:00';
          case -2:
              return '-02:00';
          default:
              return gmt;
      }
    }
    const gmtUser = moment().format("Z");
    //const gmtUser = "-04:00";
    let gmtEmpresaMinutos = date.empresa.gmt * 60;
    if(defineGMT(date.empresa.gmt) !== gmtUser){
      if(hrFixo === null){
        let dataInicio = moment.parseZone(agenda[0].data).utcOffset(gmtEmpresaMinutos);
        let dataFinal = moment.parseZone(agenda[agenda.length - 1].data).utcOffset(gmtEmpresaMinutos);

        dataInicio = dataInicio.utcOffset(parseFloat(gmtUser)*60);
        dataFinal = dataFinal.utcOffset(parseFloat(gmtUser)*60);
        ;
      // soma a duração em minutos ao horário Final
        //dataFinal.add((pedido.duracaoTotal/pedido.agenda.length), "minutes");
        const label = `${dataInicio.format("HH:mm")} às ${dataFinal.format("HH:mm")}`;
        setSttPage({...sttPage, horaUser: label});
      } else {
        let dataInicio  = moment.parseZone(hrFixo.prePedidos[0].dataInicio).utcOffset(gmtEmpresaMinutos);
        let dataFinal   = moment.parseZone(hrFixo.prePedidos[0].dataFinal).utcOffset(gmtEmpresaMinutos);
        dataInicio      = dataInicio.utcOffset(parseFloat(gmtUser)*60);
        dataFinal       = dataFinal.utcOffset(parseFloat(gmtUser)*60);
        const label     = `${dataInicio.format("HH:mm")} às ${dataFinal.format("HH:mm")}`;
        setSttPage({...sttPage, horaUser: label});
      }
    } else {
      const label = hrFixo !== null ? `${Services.RetornaDuracaoFixa(hrFixo)}`:`${Services.ReturnPeriodoHorarioArray(agenda)}`
      setSttPage({...sttPage, horaUser: label});
    }
  }, []);
  // Função que solicita o agendamento avulso, com pagamento online ou local
  async function SolicitarAgendamento(){
    
    // Verificar se é cliente real ou criado pelo gestor, verifca analisando o cliente.usuario se tiver um @ na string é um cliente real
    if(cliente !== null){
      setLoading(true);
      let req = null;
      if(cliente.usuario.includes("@")){
        console.log("Cliente real", quadra.idquadra); 
        req = await EmpresaAPI.CriarPedidoClienteReal(token, cliente.idusuario, quadra.idquadra, date.gestor.nome, agenda[0].dataInicio, agenda[agenda.length - 1].dataFinal, sttPay.valorT, sttPay.metodPay.tipo )
  
      } else {
        console.log("Cliente criado pelo gestor");
        req = await EmpresaAPI.CriarPedidoClienteFicticio(token, cliente.nome, quadra.idquadra, date.gestor.nome, agenda[0].dataInicio, agenda[agenda.length - 1].dataFinal, sttPay.valorT, sttPay.metodPay.tipo )
      }
      console.log("Req", req);
      if(req.status){
        if(req.req.retorno){
          RemoveUsuarioNovo();
          LocalStorage.set("pedido", req.req.registro);
          // Selecionou total
          Alerts.sucesso("Sucesso", "Agendamento criado com sucesso.");
          const pedBuffer = {
            pedido: {
              ...req.req.registro, 
              usuario               : cliente,
              avaliacao_idavaliacao : null,
              empresa               : date.empresa,
              quadra
            }, 
            page: "checkout"};
          LocalStorage.set("pedidoDetails", pedBuffer);
          serverRouter("details-appoint", req.req.registro.idpedido);
        } else { 
          Alerts.aviso("Ops...", req.req.mensagem);
        }
      } else { // Tratando erro de comunicação com o servidor
        Alerts.aviso("Ops...", req.message);
      }
      setLoading(false);
    }
  }

  // Função que solicita o agendamento fixo, com pagamento online ou local
  async function SolicitarAgendamentoFixo(){
    setLoading(true);
    console.log("Hora Fixa RESS:", hrFixo);
  
    const day = new Date(agenda[0].dataInicio).getDay()+1;
    const req = await EmpresaAPI.RealizarPedidoFixo(token, hrFixo, quadra, cliente, sttPay.metodPay.tipo, day, date.empresa.cancelamentos);

    console.log("Req", req);
    if (req.status === true){
      // Navegar para agendamentos 
     //console.log("Hora Fixa RESS: ", ress);
      if(req.req.retorno){
        RemoveUsuarioNovo();
        const savedState = {
          bttAgSel    : 1,
          modPedSel   : 0,
          modPSMobile : "Solicitados",
          selHF       : req.req.registro.mensalista,
          diaria      : null,
          modal       : true,
          modalD      : false,
          page        : "checkout",
          pagina      : {
            offSet    : 0,
            limit     : 20,
            maxScroll : null,
            scroll    : 0
          }
        }
        LocalStorage.set("savedState", savedState);
        LocalStorage.set("hora-fixa-selected", req.req.registro.mensalista.idmensalista);
        Alerts.sucesso("Sucesso", "Agendamento criado com sucesso!");
        serverRouter("hora-fixa");
      } else {
        Alerts.aviso("Ops...", req.req.mensagem);
      }
    } else { // Tratando erro de comunicação com o servidor
      Alerts.aviso("Ops...", req.message);
    }
    setLoading(false);
  }
  // Verifica se usuário é novo e remove ele do localStorage
  function RemoveUsuarioNovo(){
    // Verifica se o cliente é novo pelo idusuario ser uma string e não int
    if(typeof cliente.idusuario === "string"){
     //console.log("Cliente novo");
      const listC = LocalStorage.get("clientes-buffer");
      const index = listC.findIndex((cli) => cli.nome === cliente.nome && cli.telefone === cliente.telefone);
      if(index !== -1){
        listC.splice(index, 1);
        LocalStorage.set("clientes-buffer", listC);
      } else {
       //console.log("Erro ao remover cliente novo do localStorage");
      }
    } else {
     //console.log("Cliente antigo");
    }
  }
  
  // Verifica os parametros para solicitar um agendamento!!!
  function VerificaAgendamentoOk(){
    if((sttPay.selMaqCard || sttPay.selCedulas) && cliente !== null){
      return "btt-contain-agendar"; 
    }
    return "btt-contain-agendar-disable";
  }

  // Configura horario fixo no pedido!
  function ConfiguraHorarioFixo(pedFx, idx){
    function Retorna(idx){
      switch(idx){
        case 0: return "Durante 1 mês";
        case 1: return "Durante 3 meses";
        case 2: return "Durante 6 meses";
        case 3: return "Durante 1 ano";
        case 4: return "Sem prazo para acabar";
      }
    }
    console.log("Pedido Fixo", pedFx, idx);
    setHrFixo(pedFx);
    const label = Retorna(idx);
   //console.log("Label", label);
    setHrFixoPer(`${Services.RetornaDiaSemana(pedFx.prePedidos[0].dataInicio)}, ${label}`);
    setPeriodo(idx);
    setSttPage({...sttPage, avulso: false, modalHF: false});
    // Setar os valores
    if(idx === 4){
      setSttPay({...sttPay, valorT : pedFx.valorTotal, valorS: pedFx.prePedidos[0].valor.toFixed(2), valorA: pedFx.prePedidos[0].valor});
    } else {
      setSttPay({...sttPay, valorT : pedFx.valorTotal, valorS: pedFx.valorTotal.toFixed(2), valorA: pedFx.prePedidos[0].valor});
    }
  }
  //console.log("HoraFx", hrFixo);
  // Remove o horario fixo
  function RemoveHorarioFixo(){
    setHrFixo(null);
    setHrFixoPer(null);
    setSttPage({...sttPage, avulso: true, modalHF: false});
    setSttPay({...sttPay, valorT : valorTotal, valorS: valorTotal.toFixed(2), valorA: 0});
  }
  //console.log("User", user);
  //console.log("Empresa", date.empresa);
  //console.log("Pedido: ", pedido);
  
  // Função que seleciona a forma de pagamento
  function SelectionFormPay(value){
    switch(value){
      case 1: // Dinheiro
        if(sttPay.selCedulas){  
          setSttPay({...sttPay, selCedulas: false, metodPay: null});
        } else {
          setSttPay({...sttPay, selMaqCard: false, selCedulas: true, metodPay: {tipo: "DINHEIRO"}});
        }
        break;
      case 2: // Máquina de Cartão
        if(sttPay.selMaqCard){
          setSttPay({...sttPay, selMaqCard: false, metodPay: null});
        } else {
          setSttPay({...sttPay, selCedulas: false, selMaqCard: true, metodPay: {tipo: "MAQUINA_CARTAO"}});  
        }
        break;
      case 3: // Ação para desmarcar todas as formas de pagamento
        setSttPay(sttPayBase);
        break;
        default:
          //console.log("Erro ao selecionar forma de pagamento! //console default do switch SelectionFormPay!")
          break;
    }
  }
  //console.log("Pedido", pedido);
  //console.log("Teste Dias", moment().diff(moment(pedido.agenda[0].data).utcOffset(-3), 'days'))
  // Funções organizadoras de código, renderiza forma de pagamento!
  function RenderFormasPagamento(){
    //console.log("RenderFormasPagamento")
    if(sttPage.avulso){ // Quando o horarios é avulso!
      //console.log("Avulso")
      return(
        <>
          {recebimentos_dinheiro_avulso &&
            <SeletorPay
              Icon      = {iconsPayment.payColor}
              textA     = "Dinheiro"
              textB     = "Pagamento no local"
              mode      = {false}
              select    = {sttPay.selCedulas}
              onClick   = {() => SelectionFormPay(1)}
            />
          }
          {recebimentos_maquina_local_avulso &&
            <SeletorPay
              Icon      = {iconsPayment.payTerminal}
              textA     = "Máquina de Cartão"
              textB     = "Pagamento no local"
              mode      = {false}
              select    = {sttPay.selMaqCard}
              onClick   = {() => SelectionFormPay(2)}
            />
          }
        </>  
      ); 
    } else { // Quando o horario é fixo!
      return(
        <>  
          {recebimentos_dinheiro_fixo &&
            <SeletorPay
              Icon      = {iconsPayment.payColor}
              textA     = "Dinheiro"
              textB     = "Pague no local"
              mode      = {false}
              select    = {sttPay.selCedulas}
              onClick   = {() => SelectionFormPay(1)}
            />
          }
          {recebimentos_maquina_local_fixo &&
            <SeletorPay
              Icon      = {iconsPayment.payTerminal}
              textA     = "Máquina de Cartão"
              textB     = "Pague no local"
              mode      = {false}
              select    = {sttPay.selMaqCard}
              onClick   = {() => SelectionFormPay(2)}
            />
          }
        </>
      ); 
    }
  }

  function SalvarCliente(){
    // Verifica se nome e telefone do cliente estão preenchidos
    if(newCliente.nome !== ""){
      const LC = LocalStorage.get("clientes-buffer");
      const cliente = {
        buffer      : true,
        nome        : newCliente.nome,
        idusuario   : newCliente.nome,
        atleta:{
          telefone  : newCliente.telefone,
        }
      }
      if(LC !== null){
        //Verificar se o cliente já existe
        const index = LC.findIndex((cli) => cli.nome === cliente.nome && cli.telefone === cliente.telefone);
        if(index !== -1){
          Alerts.aviso("Aviso", "Cliente já cadastrado!");
          return;
        } 
        LC.push(cliente);
        LocalStorage.set("clientes-buffer", LC);
      } else {
        LocalStorage.set("clientes-buffer", [cliente]);
      }
      setCliente(cliente);
      setNewCliente(client);
      setSttPage({...sttPage, modalNC: false});
      setAddCliente(!addClient);
    } else {
      Alerts.aviso("Aviso", "Por favor preencha o nome do cliente.");
    }

  }
  
  function SetaValorTotal(e) {
    // Remove todos os caracteres que não sejam dígitos.
    let value = e.target.value.replace(/[^\d]/g, "");

    // Se o valor não for vazio, converta-o.
    if (value !== "") {
        // Interpretamos o valor como centavos.
        let cents = parseInt(value);
        if (isNaN(cents) || cents === 0) {
            value = "0.00";
        } else {
            // Converte os centavos em uma representação decimal.
            value = (cents / 100).toFixed(2);
        }
    } else {
        value = "0.00";
    }

    setSttPay({...sttPay, valorS: value});
  }
  function RetornaPhone(phone){
    // (99) 99999-9999
    let data = phone;
    data = data.replace(/\D/g, ''); // remove any non-numeric characters
    // apply the mask
    data = data.replace(/^(\d{2})(\d)/g,"($1) $2");
    data = data.replace(/(\d)(\d{4})$/,"$1-$2");
    return data;
  }
  
  //console.log("SttPage", sttPage);
  function SetarNewValor(){
    //console.log("Aqui!!!");
    if(hrFixo !== null){
      if(periodo === 4){ // Muda o valor dos agendamentos e do valor total
        hrFixo.valorTotal = 0;
        hrFixo.prePedidos.map((item) => { 
          item.valorTotal = parseFloat(sttPay.valorS);
          if(item.status === "SOLICITADO") hrFixo.valorTotal += parseFloat(sttPay.valorS);
          const newVal = parseFloat(sttPay.valorS / item.agendamentos.length);
          item.agendamentos.map((agend) => {
            agend.valor = newVal;
          });
        });
        setHrFixo(hrFixo);
        setSttPay({...sttPay, valorT: hrFixo.valorTotal, valorA: parseFloat(sttPay.valorS)});
      } else { // Muda o valor total e divide o valor total pelo numero de agendamentos para definir o valor de cada agendamento
        hrFixo.valorTotal = parseFloat(sttPay.valorS);
        const newVal = parseFloat(sttPay.valorS / hrFixo.prePedidos.length);
        hrFixo.prePedidos.map((item) => { 
          item.valorTotal = newVal;
          const newAux = parseFloat(newVal / item.agendamentos.length);
          item.agendamentos.map((agend) => {
            agend.valor = newAux;
          });
        });
        setHrFixo(hrFixo);
        setSttPay({...sttPay, valorT: parseFloat(sttPay.valorS), valorA: newVal});
      }
    } else {
      setSttPay({...sttPay, valorT: parseFloat(sttPay.valorS)});
      const newVal = parseFloat(sttPay.valorS  / agenda.length);
      agenda.map((item) => {
        item.valor      = newVal;
        item.valorTotal = newVal;
      });
    }
    setSttPage({...sttPage, modalNV: false});
  }
  React.useEffect(() => {
    function handleKeyPress(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if(sttPage.modalNV){ 
         //console.log("Enter");
          SetarNewValor();
          setSttPage({...sttPage, modalNV: false});
        }
      }
    }
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [sttPay.valorS]);

  return(
    <div className = "contain-checkout-gestor">
      <div className = "contain-form-checkout">
        <BiArrowBack
          className = "btt-back-checkout"
          onClick   = {() => handleBttState()}
        />
        { sttPage.modalHF &&
          <ModalSelHF
            modal             = { sttPage.modalHF }
            ClosedModal       = { () => setSttPage({...sttPage, modalHF: false}) }
            onClick           = { (ped, index) => ConfiguraHorarioFixo(ped, index) }
            onClickRemoveHF   = { () => RemoveHorarioFixo() }
            quadra            = { quadra}
            agenda            = { agenda }
            diaSemana         = { dia}
            empresa           = { date.empresa }
            state             = { hrFixo === null ? true : false }
            periodo           = { periodo }
            hrFixa            = { hrFixo }
          />
        }     
        {
          sttPage.modalNV &&
          <ModalInfos
            modal             = { sttPage.modalNV }
            ClosedModal       = { () => {
              setSttPage({...sttPage, modalNV: false});
            } }
            Component         = {(
              <>
                <h3 className = "text-title-modal">
                  Editar Valor
                </h3>
                <h4 className = "text-subtitle-modal">
                  Para quanto você deseja alterar o valor total?
                </h4>
                <div className = "contain-new-value">
                  <h3 className = "text-preco-rs">
                    R$
                  </h3>
                  <input
                    placeholder   = "0,00"
                    type          = "text"
                    value         = {sttPay.valorS.replace(".", ",")}
                    onChange      = {(e) => {
                      SetaValorTotal(e);                    
                    }}
                  />
                </div>
                <button
                  className = "btt-contain-salvar-cliente"
                  onClick   = {() => {
                    SetarNewValor();
                  }}
                >
                  Definir esse valor
                </button>
              </>
            )}
          />
        } 
        {
          sttPage.modalNC &&
          <ModalInfos
            modal             = { sttPage.modalNC }
            ClosedModal       = { () => setSttPage({...sttPage, modalNC: false}) }
            Component         = {(
              <>
                <h3 className = "text-title-modal">
                  Criar Cliente
                </h3>
                <h4 className = "text-subtitle-modal">
                  Defina Nome e Contato para o novo cliente
                </h4>
                <input
                  className     = "input-new-client"
                  placeholder   = "Nome do cliente"
                  value         = {newCliente.nome}
                  onChange      = {(e) => setNewCliente({...newCliente, nome: e.target.value})}
                />
                <input
                  className     = "input-new-client"
                  placeholder   = "Telefone do cliente (opcional)"
                  value         = {newCliente.telefone}
                  onChange      = {(e) => {
                    if(e.target.value.length > 15) return;
                    setNewCliente({...newCliente, telefone: RetornaPhone(e.target.value)});
                  }}
                />
                <div className = "contain-alert-modal">
                  <TbAlertCircleFilled
                    size      = {30}
                    color     = "#DA5A34"
                  />
                  <h3 className = "text-alert-modal">
                    Atenção
                  </h3>
                  <h4 className = "text-info-modal">
                    Ao confirmar o agendamento você estará criando um novo
                    novo cliente manualmente. Nos próximos agendamento
                    você poderá reutilizá-los, basta buscá-los pelo NOME.
                  </h4>
                </div>
                <button
                  className = "btt-contain-salvar-cliente"
                  onClick   = {() => {
                    SalvarCliente();
                  }}
                >
                  Salvar cliente
                </button>
              </>
            )}
          />
        }
        <h2 className = "title-forms-checkout">
          Finalize o agendamento
        </h2>
        <>
          { loading ? 
            (
              <div className="contain-divs-loading">
                <div className="load-spinner-diaria"/>
                <h2 className = "text-aviso-loading">
                  Não feche essa tela, aguarde por gentileza, seus horários estão sendo agendados...
                </h2>
              </div>
            ) :
              <div className="contain-divs-forms">
                {/* Container esquerdo */}
                <div className = "contain-div-infos borders-simple">
                  <div className = "contain-title-div-infos">
                    <div className = "icon-div-infos">
                      <BiFileFind
                        color     = "white"
                        size      = {30}
                      />
                    </div>
                    Detalhes do Agendamento
                  </div>
                  <div className = "contain-div-infos-inf">
                    <h3 className = "text-subtitle-low">Alguma observação?</h3>
                    <textarea
                      value         = {sttPage.textA}
                      onChange      = {(e) => setSttPage({...sttPage, textA: e.target.value})}
                      placeholder   = "Ex: Cliente vai precisar de colete, raquete, etc..."
                    />
                    { (recebimentos_dinheiro_fixo || recebimentos_maquina_local_fixo) && 
                      <button 
                        className = { hrFixo === null ? "btt-seletor-modal" : "btt-seletor-modal-select"}
                        onClick   = {() => {
                          setSttPage({...sttPage, modalHF: !sttPage.modalHF});
                        //console.log(`${modalHF ? "Ativou" : "Desativou"} Modal`);
                        }}
                      >
                        { hrFixo === null ? "É um horário fixo?" : "Horário fixo configurado" }
                        { hrFixo === null ?
                          <BiChevronRight
                            size      = {30}
                            color     = "#DA5A34"
                          />
                            :
                          <BiCheck
                            size      = {30}
                            color     = "#11c16d"
                          />
                        }
                      </button>
                    }

                    <div className = "contain-payments">  
                      <div className = "contain-title-div-infos">
                        <div className = "icon-div-infos">
                          <BiMoneyWithdraw
                            color     = "white"
                            size      = {30}
                          />
                        </div>
                        Forma de Pagamento
                      </div>
                      <h3 className = "text-subtitle-low">
                        Qual a forma de pagamento?
                      </h3>
                      {RenderFormasPagamento()}
                    </div>
                  </div>
                </div>

                {/* Container centro */}
                <div className = "contain-div-infos borders-simple">
                  <div className = "contain-title-div-infos">
                    <div className = "icon-div-infos">
                      <BiUserCheck
                        color     = "white"
                        size      = {30}
                      />
                    </div>
                    Clientes
                  </div>
                  
                  <div className = "contain-div-infos-inf">
                    <h3 
                      className = "text-subtitle-low"
                      style    = {{marginBottom: "5px"}}
                    >
                      Busque ou crie manualmente
                    </h3>  
                    
                    <button 
                      className = { "btt-seletor-modal" }
                      onClick   = {() => {
                        //console.log(`${modalHF ? "Ativou" : "Desativou"} Modal`);
                        setSttPage({...sttPage, modalNC: true});
                      }}
                    >
                      { "Criar cliente manualmente" }
                      <BiChevronRight
                        size      = {30}
                        color     = "#DA5A34"
                      />
                    </button>
                    <InputIcon
                      placeHolder  = {"Buscar cliente..."}
                      type         = {"text"}
                      value        = {sttPage.filter}
                      onChange     = {(e) => setSttPage({...sttPage, filter: e.target.value})}
                      Icon         = {RiUserSearchLine}
                    />
                    <div className = "contain-lista-clientes-cg">
                      <ListClients
                        type          = {"list-select"}
                        filter        = {sttPage.filter}
                        selected      = {cliente}
                        addClient     = {addClient}
                        handleClient  = {(user)=>{
                         //console.log("User: ", user);
                          if(cliente !== null){
                            if(cliente.idusuario === user.idusuario){
                              setCliente(null);
                              return;
                            } else {
                              setCliente(user);
                              return;
                            }
                          } else setCliente(user);
                        }}
                      />
                    </div>
                  </div>
                </div>
              
                {/* Container Resumo */}
                <div 
                  className = "contain-div-infos borders-confirm"
                  style     = {{padding: "5px", height: "auto"}}
                >
                  <div className = "contain-title-div-infos" style = {{color: "black"}}> 
                    {hrFixo != null ? "Resumo do Horário Fixo" : "Resumo do Horário Avulso"}
                  </div>
                  <div className = "contain-div-infos-inf">
                    <div className  = "contain-div-resumo-line">
                      <h3 className = "text-align-left">
                        Horário
                      </h3>
                      <div className = "contain-div-resumo-horario-gmt-alert">
                        <h3 className = "text-align-right">
                          {
                            hrFixo != null ? 
                            `${Services.RetornaDuracaoFixa(hrFixo)}`
                            :
                            `${Services.ReturnPeriodoHorarioArray(agenda)}`
                          }
                        </h3>
                        <GmtAviso
                          gmt         = {date.empresa.gmt}
                          local       = {"bottom"}
                          horaEmpresa = {hrFixo != null ? `${Services.RetornaDuracaoFixa(hrFixo)}`:`${Services.ReturnPeriodoHorarioArray(agenda)}`}
                          horaUsuario = {sttPage.horaUser}
                        />
                      </div>
                    </div>
                    <div className  = "contain-div-resumo-line">
                      <h3 className = "text-align-left">
                        Data
                      </h3>
                      <h3 className = "text-align-right">
                      {
                        hrFixo != null ?
                        `${hrFixoPer}`
                          :
                        `${Services.RetornaPeriodoDataSelect(agenda)}`
                      } 
                      </h3>
                    </div>
                    <div className  = "contain-div-resumo-line">
                      <h3 className = "text-align-left">Local</h3>
                      <div className = "contain-div-resumo-line-3">
                        <h3>
                          {date.empresa.nome}
                        </h3>
                        <h3>
                          {quadra.descricao}
                        </h3>
                        <h3>
                          {quadra.endereco}
                        </h3>
                      </div>
                    </div>
                    {/* Cliente */}

                    { cliente !== null &&
                      <>
                        <div className  = "contain-divisao-line"/>
                        <div className  = "contain-div-resumo-line">
                          <h3 className = "text-align-left">
                            Cliente
                          </h3>
                          <div className = "contain-div-resumo-line-2">
                            <h3 className = "text-align-right">
                              {cliente.nome}  
                            </h3>
                          </div>  
                        </div> 
                      </>
                    }
                    {/* Linha de divisão!!! */}
                    <div className  = "contain-divisao-line"/>
                      { hrFixo === null ? 
                        <div className  = "contain-div-resumo-line">
                          <h3 className = "text-align-left">
                            Valor do horário avulso
                          </h3>
                          <button
                            className = "btt-handle-valor"
                            onClick   = {() => setSttPage({...sttPage, modalNV: true})}
                          >
                            <BiEditAlt
                              className = "icon-edit-valor"
                            />
                            <h3 className = "btt-text-valor">
                              R${sttPay.valorT.toFixed(2).toString().replace(".",",")}
                            </h3>
                          </button>
                        </div> 
                          :
                        periodo !== 4 ?
                        <>
                          <div className  = "contain-div-resumo-line">
                            <h3 className = "text-align-left">
                              Valor por agendamento
                            </h3>
                            <h3 
                              className = "text-align-right text-hig" 
                              style = {{color: "#11c16d", fontFamily: "jost-mediun", marginRight: "5px"}}
                            >
                              R${sttPay.valorA.toFixed(2).toString().replace(".",",")}
                            </h3> 
                          </div>
                          <div className  = "contain-div-resumo-line">
                            <h3 className = "text-align-left">
                              Valor total estimado
                            </h3>
                            <button
                              className = "btt-handle-valor"
                              onClick   = {() => setSttPage({...sttPage, modalNV: true})}
                            >
                              <BiEditAlt
                                className = "icon-edit-valor"
                              />
                              <h3 className = "btt-text-valor">
                                {
                                  `R$${sttPay.valorT.toFixed(2).toString().replace(".",",")}`
                                }
                              </h3>
                            </button>
                          </div>
                        </>
                        : // Apenas um agendamento
                        <div className  = "contain-div-resumo-line">
                          <h3 className = "text-align-left">
                            Valor por agendamento
                          </h3>
                          <button
                            className = "btt-handle-valor"
                            onClick   = {() => setSttPage({...sttPage, modalNV: true})}
                          >
                            <BiEditAlt
                              className = "icon-edit-valor"
                            />
                            <h3 className = "btt-text-valor">
                              R${sttPay.valorA.toFixed(2).toString().replace(".",",")}
                            </h3>
                          </button>
                        </div>
                      }
                    
                      {/* periodo !== 4 &&
                        <div className  = "contain-div-resumo-line">
                          <h3 className = "text-align-left"
                            style     = {{color: "black"}}
                          >
                            Você irá pagar
                          </h3> 
                          <h2 className = "text-align-right text-hig" style = {{color: "#11c16d", fontFamily: "jost-mediun"}}>
                            R${sttPay.valorT.toFixed(2).toString().replace(".",",")}
                          </h2>
                        </div>
                    */}  
                    <div className  = "contain-divisao-line"/>
                    <button 
                      className = {VerificaAgendamentoOk()}
                      onClick   = {() => {
                        function RotinaAgendamento() {
                          //RemoveUsuarioNovo();
                          //return ;
                          if(hrFixo === null)   SolicitarAgendamento();
                          else                  SolicitarAgendamentoFixo();
                        }
                        if(sttPay.valorT < 1) {
                          Swal.fire({
                            title               : "Atenção",
                            text                : "Valor mínimo de R$ 1,00. Caso seu agendamento possua um sinal menor que esse valor, ele será ajustado automaticamente para R$ 1,00.",
                            icon                : "warning",
                            confirmButtonText   : "Entendi",
                            confirmButtonColor  : "#DA5A34",
                          }).then((result) => { 
                            if(result.isConfirmed){
                              setSttPay({...sttPay, valorT: 1});
                              RotinaAgendamento();
                            }
                          });
                        } else RotinaAgendamento();
                        //console.log("Metodo de pagamento: ", metodPay);
                      }}
                    >
                        {(sttPay.selCedulas || sttPay.selMaqCard) && cliente !== null ?
                          (hrFixo != null ? "Agendar horário fixo" : "Agendar horário avulso") 
                            : 
                          (sttPay.selCedulas || sttPay.selMaqCard) ?
                          "Selecione um cliente":"Selecione uma forma de pagamento"
                        }
                    </button>
                  </div>
                </div>
              </div>
          }
        </>
      </div>
    </div>
  );
}