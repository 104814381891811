import React                from 'react';
import { ResponsivePie }    from '@nivo/pie';

import "./stylesGraphic.css";

/* Os dados que você deseja exibir no gráfico
const data = [
  {"id": "language0", "label": "language0", "value": 431, "color": "hsl(319, 58%, 55%)"},
  {"id": "language1", "label": "themeal", "value": 310, "color": "hsl(49, 70%, 57%)"},
  {"id": "language2", "label": "themeal", "value": 203, "color": "hsl(108, 67%, 55%)"},
  {"id": "language3", "label": "themeal", "value": 464, "color": "hsl(322, 42%, 54%)"},
  {"id": "language4", "label": "themeal", "value": 60, "color": "hsl(141, 53%, 43%)"},
  {"id": "language5", "label": "themeal", "value": 337, "color": "hsl(197, 65%, 60%)"},
  {"id": "language6", "label": "themeal", "value": 497, "color": "hsl(129, 60%, 42%)"},
  {"id": "language7", "label": "themeal", "value": 117, "color": "hsl(243, 65%, 48%)"},
  {"id": "language8", "label": "themeal", "value": 367, "color": "hsl(219, 41%, 50%)"},
  {"id": "language9", "label": "themeal", "value": 23, "color": "hsl(47, 49%, 50%)"}
];
//*/



export default function GraficoPizza({data}) {
  let total = 0;
  data.map((item) => {
    // altera de string para float e soma
    const value = parseFloat(item.value);
    total = total + value;
  })
  //console.log("total", total)
  const dataOk = data.map((item) => {
    const percent = (parseFloat(item.value) / total) * 100;
    return {
      ...item,
      percent : percent.toFixed(2),
    }
  });
  //console.log("dataOk", dataOk);
  function TooltipFunction({datum}){
    //console.log("TooltipFunction", datum);
    const { label, value, color } = datum;
    return (
      <div className = 'contain-tooltip'>
        <div
          className = 'contain-icon-legend'
          style     = {{ backgroundColor: color }}
        />
        <div className = 'contain-tooltip-texts'>
          <h2>{label}</h2>
          <h3>{`R$${value.replace(".",",")}`}</h3>
        </div>
      </div>
    );
  }

  const pieConfig = {
    margin                      : { top: 40, right: 230, bottom: 40, left: 80 },
    innerRadius                 : 0.3,
    padAngle                    : 0.7,
    cornerRadius                : 1,
    activeInnerRadiusOffset     : 5,
    activeOuterRadiusOffset     : 8,
    arcLabelsRadiusOffset       : 0.70,
    // cor do texto da legenda
    colors                      : { scheme: 'paired' },
    borderWidth                 : 2,
    borderColor                 : { from: 'color', modifiers: [['darker', 0.2]] },
    enableArcLinkLabels         : false, // Desabilita os rótulos das fatias do gráfico
    arcLabel                    : d => {
      //console.log("arcLabel", d);
      return `${d.data.percent}%`;
    }, // Formata o texto da fatia
    // adicione a cor do texto da fatia
    arcLabelsTextColor          : { from: 'color', modifiers: [['darker', 3]] },
    tooltip                     : TooltipFunction,
    legends: [
      {
        anchor          : 'right',
        direction       : 'column',
        justify         : false,
        translateX      : 120,
        translateY      : 0,
        itemsSpacing    : 3,
        itemWidth       : 100,
        itemHeight      : 18,
        itemTextColor   : 'white',
        itemDirection   : 'left-to-right',
        itemOpacity     : 1,
        symbolSize      : 15,
        symbolShape     : 'circle',
      }
    ],
    theme : {
      legends: {
        text: {
          fontSize    : 14,
          fontFamily  : "jost-light",
          fontWeight  : "bold",
        }
      }
    }
    // ...outras propriedades de configuração
  };
  
  

  return (
    <div style={{ height: "400px", width: "500px" }}>
      <ResponsivePie
        {...pieConfig}
        data = {dataOk}
      />
    </div>
  );
}


