import React              from "react";
import {
  BiCaretLeftCircle, BiCaretRightCircle
} from "react-icons/bi";
import { DayCircle }      from "../../Basics/";

import "./stylesDR.css";

export default function DiasRoler({arrayDias, daySel, ChangeDay }) {

  return(
    <div className = "contain-dias-rolavel">
      <button 
        className = "btt-next-cyrcle"
        onClick   = {() => ChangeDay("next-left")}
      >
        <BiCaretLeftCircle
          size        = {35}
          className   = "icon-roler-dia"
        />
      </button>
      {
        arrayDias.map((dia, index) => (
          <DayCircle
            key       = {index}
            day       = {dia}
            valid     = {true}
            selected  = {
              (daySel.getDate() === dia.getDate() && 
              (daySel.getMonth() + 1) === (dia.getMonth() + 1) && 
              daySel.getFullYear() === dia.getFullYear()) ? true : false}
            onClick   = {() => ChangeDay("selected", dia)}
          />
        ))
      }
      <button 
        className = "btt-next-cyrcle"
        onClick   = {() => ChangeDay("next-right")}
      >
        <BiCaretRightCircle
          size = {35}
          className = "icon-roler-dia"
        />
      </button>
    </div>
  );
}