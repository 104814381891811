import React        from "react";
import { Services } from "../../../services";
import "./stylesDC.css";

export default function DayCircle(data) {
  const { valid, onClick, day, selected } = data;
  //console.log("DayCircle ", data);
  const width = window.innerWidth;
  return(
    <div id = "contain-btt-day-cyrcle">
      <button
        title = {valid ? Services.ReturnToStringDate(day) : "Dia indisponível"}
        className = {selected ? "btt-day-cyrcle-sel" : (valid ? "btt-day-cyrcle" : "btt-day-cyrcle-invalid")}
        onClick = {valid ? onClick : () => {}}
      >
        <h3>
          {day.getDate()}
        </h3>
        <h3>
          {Services.ReturnStringDay(day)}
        </h3>
      </button>
    </div>
  );
}