import React            from "react";
import { 
  BiChevronRight, BiChevronLeft 
} from "react-icons/bi";
import {
  CompPedSol, CompMenSol, Alerts, Loading, CompDiaSol
} from "../../Basics";
import { 
  HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp 
} from "react-icons/hi2";
import { AuthContext }  from "../../../contexts/AuthContext";
import { EmpresaAPI }   from "../../../servicesAPI";
import Swal             from "sweetalert2";
import { LocalStorage } from "../../../services";
import { loadCresc }    from "../../../assets";
import RouterHook       from "../../../contexts/RouterHook";

import "./stylesAS.css";

export default function AlertSolicit({onClick, handleClkDay}) {

  const { token, date } = React.useContext(AuthContext);
  const serverRouter    = RouterHook();
  const idxsBff = LocalStorage.get("alert-idxs");


  const [stt, setStt]     = React.useState({
    loadingP      : false,
    loadingM      : false,
    mensalistas   : [],
    diarias       : [],
    pedidos       : [],
  });

  const [idxs, setIdxs]   = React.useState(idxsBff !== null ? idxsBff : {
    show    : true,
    modalP  : false,
    modalM  : false,
    mens    : 0,
    diar    : 0,
    pdd     : 0,
  });

  //console.log("Stt", stt);
  //console.log("Idxs", idxs);
  // Alterar o código para realizar uma chamada a cada 30 segundos!
  async function RunStartAlerts(){
    const reqs = await EmpresaAPI.BuscaNovasSolicitacoesEmpresa( token, date.empresa.idempresa );
    //console.log("Req - aquii", reqs);
    if(reqs.status){
      if(reqs.req.pedidos.length !== stt.pedidos.length || reqs.req.mensalistas.length !== stt.mensalistas.length || reqs.req.diarias.rows.length !== stt.diarias.length){
        //console.log("Atualizando");
        setIdxs({ ...idxs, pdd: 0, mens: 0, diar: 0 });
        
        setStt({
          ...stt,
          pedidos       : reqs.req.pedidos,
          mensalistas   : reqs.req.mensalistas,
          diarias       : reqs.req.diarias.rows,
        });
      }
    }
  }
  const initState = React.useRef(true);
  //*
  React.useEffect(() => {
    if(initState.current) RunStartAlerts();
    initState.current = false;
    const interval = setInterval(async () => {
      await RunStartAlerts();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [stt.pedidos, stt.mensalistas, stt.diarias, token]);
  //*/

  function AcepptAgendamento(tipo, idagendamento){
    if(tipo === "pedido"){
     //console.log("pedido", idagendamento);
      //console.log("pedido", idagendamento);
      Swal.fire({
        title: 'Aprovar',
        text: "Deseja aprovar essa solicitação de agendamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor    : '#bf3324',
        cancelButtonColor     : '#00cda4',
        confirmButtonText     : 'Não',
        cancelButtonText      : 'Sim'
      }).then(async (result) => {
        if(!result.isConfirmed && result.dismiss === "cancel"){
          setStt({...stt, loadingP: true});
          const req = await EmpresaAPI.AprovarPedido( token, idagendamento, date.gestor.nome );
          if(req.status){
            // Remover o pedido do array
            stt.pedidos.splice(idxs.pdd, 1);
            setIdxs({ ...idxs, pdd: 0 });
            setStt({...stt, pedidos : stt.pedidos, loadingP: false});  
            await RunStartAlerts();
          } else {
            setStt({...stt, loadingP: false});
            Alerts.aviso("Aviso!", req.message);
          }
        }
      });
    } else if(tipo === "mensalista"){
      //console.log("mensalista", idagendamento);
      Swal.fire({
        title: 'Aprovar',
        text: "Deseja aprovar essa solicitação de agendamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor    : '#bf3324',
        cancelButtonColor     : '#00cda4',
        confirmButtonText     : 'Não',
        cancelButtonText      : 'Sim'
      }).then(async (result) => {
        if(!result.isConfirmed && result.dismiss === "cancel"){
          setStt({...stt, loadingM: true});
          const req = await EmpresaAPI.AprovarMensalistaCliente( token, idagendamento, date.gestor.nome );
          if(req.status){
            // Remover o pedido do array
            stt.mensalistas.splice(idxs.mens, 1);
            setStt({...stt, mensalistas : stt.mensalistas, loadingM: false});
            setIdxs({ ...idxs, mens: 0 });
            await RunStartAlerts();
          } else {
            setStt({...stt, loadingM: false});
            Alerts.aviso("Aviso!", req.message);
          }
        }
      });  
    } else if(tipo === "dayuse"){
     //console.log("dayuse", idagendamento);
    
    }
  }

  function DeclinedAgendamento(tipo, idagendamento){
    if(tipo === "pedido"){
      Swal.fire({
        title   : 'Cancelar',
        text    : "Deseja rejeitar essa solicitação de agendamento?",
        icon    : 'warning',
        showCancelButton      : true,
        confirmButtonColor    : '#bf3324',
        cancelButtonColor     : '#00cda4',
        confirmButtonText     : 'Não',
        cancelButtonText      : 'Sim'
      }).then(async (result) => {
        if(!result.isConfirmed && result.dismiss === "cancel"){
          setStt({...stt, loadingP: true});
          const req = await EmpresaAPI.RecusarPedido( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
          if(req.status){
            // Remover o pedido do array
            stt.pedidos.splice(idxs.pdd, 1);
            setIdxs({ ...idxs, pdd: 0 });
            setStt({...stt, pedidos : stt.pedidos, loadingP: false});
          } else {
            setStt({...stt, loadingP: false});
            Alerts.aviso("Aviso!", req.message);
          }
          await RunStartAlerts();
        }
      });
    } else if(tipo === "mensalista"){
      //console.log("mensalista", idagendamento);
      Swal.fire({
        title   : 'Cancelar',
        text    : "Deseja rejeitar essa solicitação de agendamento?",
        icon    : 'warning',
        showCancelButton      : true,
        confirmButtonColor    : '#bf3324',
        cancelButtonColor     : '#00cda4',
        confirmButtonText     : 'Não',
        cancelButtonText      : 'Sim'
      }).then(async (result) => {
        if(!result.isConfirmed && result.dismiss === "cancel"){
          setStt({...stt, loadingM: true});
          const req = await EmpresaAPI.RecusarMensalistaCliente( token, idagendamento, date.gestor.nome, "" );// Sem motivo!
          if(req.status){
            // Remover o pedido do array
            stt.mensalistas.splice(idxs.mens, 1);
            setStt({...stt, mensalistas : stt.mensalistas, loadingM: false});
            setIdxs({ ...idxs, mens: 0 });
          } else {
            setStt({...stt, loadingM: false});
            Alerts.aviso("Aviso!", req.message);
          }
          await RunStartAlerts();
        }
      });
    }
  }
  //console.log("Stt", stt);
  

  React.useEffect(() => {
    LocalStorage.set("alert-idxs", idxs);
  }, [idxs]);

  function GoDiarias(){
   //console.log("GoDiarias");
    LocalStorage.set("diaria-select", stt.diarias[idxs.diar]);
    serverRouter("diaria");
    handleClkDay();
  }

  function HandleAlert({ mode }){
    let arrayLenght = null;
    if(mode === "pedidos") {
      arrayLenght = stt.pedidos.length - 1;
    } else if(mode === "mensalistas") {
      arrayLenght = stt.mensalistas.length - 1;
    } else {
      arrayLenght = stt.diarias.length - 1;
    }

    function handleindex(way, type){
      if(type === "pedidos"){
        if(way === "left"){
          if(idxs.pdd === 0){
            setIdxs({...idxs, pdd: arrayLenght});
          } else {
            setIdxs({...idxs, pdd: idxs.pdd - 1});
          }
        } else if(way === "right"){
          if(idxs.pdd === arrayLenght){
            setIdxs({...idxs, pdd: 0});
          } else {
            setIdxs({...idxs, pdd: idxs.pdd + 1});
          }
        }
      } else if(type === "mensalistas"){
        if(way === "left"){
          if(idxs.mens === 0){
            setIdxs({...idxs, mens: arrayLenght});
          } else {
            setIdxs({...idxs, mens: idxs.mens - 1});
          }
        } else if(way === "right"){
          if(idxs.mens === arrayLenght){
            setIdxs({...idxs, mens: 0});
          } else {
            setIdxs({...idxs, mens: idxs.mens + 1});
          }
        }
      } else if(type === "diarias"){
        if(way === "left"){
          if(idxs.diar === 0){
            setIdxs({...idxs, diar: arrayLenght});
          } else {
            setIdxs({...idxs, diar: idxs.diar - 1});
          }
        } else if(way === "right"){
          if(idxs.diar === arrayLenght){
            setIdxs({...idxs, diar: 0});
          } else {
            setIdxs({...idxs, diar: idxs.diar + 1});
          }
        }
      }
    }

    if(mode === "pedidos"){
      return (
        <div 
          className = "contain-alert-solicit-componente"
          style     = {stt.pedidos.length > 1 ? {} : {marginLeft: "15px"}}
        >
          <div className = "contain-label-alert-sol">
            <img
              className = "img-icon-alert-sol"
              style     = {(stt.pedidos.length > 1 && idxs.show) ? {marginLeft: "25px"} : {marginLeft: "10px"}}
              src       = {loadCresc}
            />
            <h2>{`${stt.pedidos.length} nova${stt.pedidos.length > 1 ? "s" : ""} solicita${stt.pedidos.length > 1 ? "ções" : "ção"} avulsa${stt.pedidos.length > 1 ? "s" : ""}`}</h2>
          </div>
          <div className = "contain-center-alert-sol">
            { (stt.pedidos.length > 1 && idxs.show) &&
              <BiChevronLeft
                className = "icon-alert-prox"
                onClick   = {() => {handleindex("left", "pedidos")}}
              />
            }
            { idxs.show &&
              stt.loadingP ?
              <Loading mode={false}/>
                :
              <CompPedSol
                pedido = {stt.pedidos[idxs.pdd]}
                show   = {idxs.show}
                onClick = {(action) => {
                  if(action === "aceppt"){
                    AcepptAgendamento("pedido", stt.pedidos[idxs.pdd].idpedido);
                  } else if(action === "declined"){
                    DeclinedAgendamento("pedido", stt.pedidos[idxs.pdd].idpedido);
                  }
                }}
              />            
            }
            { (stt.pedidos.length > 1 && idxs.show) &&
              <BiChevronRight
                className = "icon-alert-prox"
                onClick   = {() => {handleindex("right", "pedidos")}}
              />
            }
          </div>
        </div>
      );
    } else if(mode === "mensalistas"){
      if(date.perfilG.gerenciarMensalistas){
        return (
          <div 
            className = "contain-alert-solicit-componente"
            style     = {stt.mensalistas.length > 1 ? {marginLeft: "15px"} : {marginLeft: "25px"}}
          >
            <div className = "contain-label-alert-sol">
              <img
                className = "img-icon-alert-sol"
                src       = {loadCresc}
              />
              <h2>{`${stt.mensalistas.length} nova${stt.mensalistas.length > 1 ? "s" : ""} solicita${stt.mensalistas.length > 1 ? "ções" : "ção"} fixa${stt.mensalistas.length > 1 ? "s" : ""}`}</h2>
            </div>
            <div className = "contain-center-alert-sol">
              { (stt.mensalistas.length > 1 && idxs.show) &&
                <BiChevronLeft
                  className = "icon-alert-prox"
                  onClick   = {() => {handleindex("left", "mensalistas")}}
                />
              }
              { idxs.show &&
                stt.loadingM ?
                <Loading mode={false}/>
                  :
                <CompMenSol
                  mensalista  = {stt.mensalistas[idxs.mens]}
                  show        = {idxs.show}
                  onClickA    = {onClick}   // Força atualizar a tela caso esteja nela ja!
                  onClick     = {(action) => {
                    if(action === "aceppt"){
                      AcepptAgendamento("mensalista", stt.mensalistas[idxs.mens].idmensalista);
                    } else if(action === "declined"){
                      DeclinedAgendamento("mensalista", stt.mensalistas[idxs.mens].idmensalista);
                    }
                  }}
                />
              }
              { (stt.mensalistas.length > 1 && idxs.show) &&
                <BiChevronRight
                  className = "icon-alert-prox"
                  onClick   = {() => {handleindex("right", "mensalistas")}}
                />
              }
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else if(mode === "diarias"){
      return (
        <div 
          className = "contain-alert-solicit-componente"
          style     = {stt.diarias.length > 1 ? {marginLeft: "15px"} : {marginLeft: "25px"}}
        >
          <div className = "contain-label-alert-sol">
            <img
              className = "img-icon-alert-sol"
              src       = {loadCresc}
            />
            <h2>{`${stt.diarias.length} nova${stt.diarias.length > 1 ? "s" : ""} solicita${stt.diarias.length > 1 ? "ções" : "ção"} de diária${stt.diarias.length > 1 ? "s" : ""}`}</h2>
          </div>
          <div className = "contain-center-alert-sol">
            { (stt.diarias.length > 1 && idxs.show) &&
              <BiChevronLeft
                className = "icon-alert-prox"
                onClick   = {() => {handleindex("left", "diarias")}}
              />
            }
            { idxs.show &&
              <CompDiaSol
                diaria  = {stt.diarias[idxs.diar]}
                onClick = {() => {
                  GoDiarias();
                }}
              />
            }
            { (stt.diarias.length > 1 && idxs.show) &&
              <BiChevronRight
                className = "icon-alert-prox"
                onClick   = {() => {handleindex("right", "diarias")}}
              />
            }
          </div>
        </div>
      );
    }
  }
  //console.log("Stt", stt);
  return (
    <div className = "contain-alert-solicit">
      {
        (idxs.show) ?
        (stt.mensalistas.length > 0 || stt.pedidos.length > 0 || stt.diarias.length > 0) && 
        <HiOutlineChevronDoubleUp
          className = "icon-show-alert"
          onClick   = {() => {setIdxs({...idxs, show: false})}}
        />
          :
        (stt.mensalistas.length > 0 || stt.pedidos.length > 0 || stt.diarias.length > 0) && 
        <HiOutlineChevronDoubleDown
          className = "icon-show-alert"
          onClick   = {() => {setIdxs({...idxs, show: true})}}
        />
      }{
        stt.pedidos.length ?
        <HandleAlert 
          mode = "pedidos"
        /> : <></>
      }{
        stt.mensalistas.length ?
        <HandleAlert 
          mode = "mensalistas" 
        /> : <></>
      }{
        stt.diarias.length ?
        <HandleAlert 
          mode = "diarias" 
        /> : <></>
      }
    </div>
  );
}