import React              from "react";
import { 
  BiEnvelopeOpen, BiEnvelope 
} from "react-icons/bi";
import { ChatContext }    from "../../../contexts/ChatContext";
import { Services }       from "../../../services";
import moment             from "moment";

import "./stylesCChat.css";

export default function CompChat({chat, onClick, selected, modeCE}) {

  moment.locale('pt-br');
  const { states } = React.useContext(ChatContext);

  //console.log("chat: ", chat);

  if(chat.ultimaMensagem !== null){
    const not_read = (chat.ultimaMensagem.status === "ENVIADA" && !chat.ultimaMensagem.criadaPorAdmin);
    const _date = new Date(chat.ultimaMensagem.createdAt);
    //console.log("Chat! Selecionado: ", chat);
    
    function ReturnMsg(msg){
      // Verificar se a string tem tamanho maior que 16 caracteres
      if(msg.length > 16){
        // Se sim, cortar a string e adicionar "..."
        return msg.substring(0, 16) + "...";
      }else {
        // Se não, retornar a string
        return msg;
      }
    }
    
    return(
      <button
        className = {selected ? "contain-comp-btt-chat-hig" : (not_read ? "contain-comp-btt-chat-not-read" : "contain-comp-btt-chat")}
        onClick   = {() => {
          //console.log("Chat! Selecionado: ", chat);
          onClick(chat);
        }}
      >
        { not_read ? 
          <BiEnvelope
            className = "icon-comp-chat"
            size      = {30}
            color     = "#DA5A34" 
          /> 
          : 
          <BiEnvelopeOpen
            className = "icon-comp-chat"
            size      = {30}
            color     = {selected ? "white" : "gray"}
          />
        }
        <div className = "contain-labels-chat">
          { modeCE ?
            <label
              className = "label-chat-id"
            >
              #{chat.pedido.idpedido}
            </label> : 
            <></>
          }
          <label
            className = {selected ? "label-chat-label--white": "label-chat-label"}
          >
            {chat.nomeUsuario}
          </label>
          <label
            className = {
              (
                chat.ultimaMensagem.status === "ENVIADA" &&
                !chat.ultimaMensagem.criadaPorAdmin
              )
                ?
              "label-chat-text-hig"
                :
              "label-chat-text"
            }
          >
            {ReturnMsg(chat.ultimaMensagem.texto)}
          </label>
          <div className = "contain-data-check-b">
            <h2>{Services.ReturnDataMsg(_date)}</h2>
          </div>
        </div>
      </button>
    );
  } else {
    const _date = new Date(chat.createdAt);
    //console.log("Chat! Selecionado: ", chat);
    return(
      <button
        className = {selected ? "contain-comp-btt-chat-hig" : "contain-comp-btt-chat"}
        onClick   = {() => onClick(chat)}
      >
        <BiEnvelopeOpen
          className = "icon-comp-chat"
          size      = {30}
          color     = {selected ? "white" : "gray"}
        />
        <div className = "contain-labels-chat">
          { modeCE ?
            <label
              className = "label-chat-id"
            >
              #{chat.pedido.idpedido}
            </label> : 
            <label
              className = "label-chat-id"
            >
              #{chat.chat_empresa_usuario.usuario.idusuario}
            </label>
          }
          <label
            className = "label-chat-label"
            style = {{color: "white"}}
          >
            {chat.nomeUsuario}
          </label>
          <label
            className = {"label-chat-text"}
          >
            {"..."}
          </label>
          <div className = "contain-data-check-b">
            <h2>{Services.ReturnDataMsg(_date)}</h2>
          </div>
        </div>
      </button>
    );
  }
}
