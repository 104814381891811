import React                from "react";
// Componentes da página de login
import { 
  ContainMax, ContainMid 
} from "../../components/Primar";
import { Header }           from "../../components/Complext";
import { FormLogin }        from "../../components/Forms";

import "./stylesL.css"
import { LocalStorage } from "../../services";

export default function Login() {
  
  React.useEffect(() => {
    document.title = "Acessar Conta | Gestor Agendei";
  }, []);
  LocalStorage.clear();
  return( 
    <ContainMax>
      <Header
        telaPg = "login"
      />
      <ContainMid>
        <div className = "contain-centraliza-login">
          <FormLogin/>
        </div>
      </ContainMid>
    </ContainMax>
  );
}
