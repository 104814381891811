import React                      from "react";
import { ContainMax, ContainMid } from "../../components/Primar";
import { Header }                 from "../../components/Complext";
import { Loading }                from "../../components/Basics";
import { AuthContext }            from "../../contexts/AuthContext";
import { LocalStorage }           from "../../services";
import RouterHook                 from "../../contexts/RouterHook";

export default function AcessEst() {

  const { isLogged } = React.useContext(AuthContext);
  const serverRouter = RouterHook();

  React.useEffect(() => {
    // Verifica se esta logado
    isLogged().then((resp) => {
      if(resp.status) {
        serverRouter("estatisticas");
      } else {
        //console.log("Não Logado");
        LocalStorage.set("isEstatisticas", true); // Seta a flag para redirecionar do login para as estatisticas
        serverRouter("login");
      }
    });
  }, []);

  return (
    <ContainMax>
      <ContainMid>
        <div className = "contain-loading">
          <Loading
            mode  = {true}
            text  = {"Carregando Estatísticas..."}
          />
        </div>
      </ContainMid>
    </ContainMax>
  );
}