import React        from "react";
import { MdEdit }   from "react-icons/md";
import moment       from "moment";

import "./stylesGC.css";
import { Loading } from "../../Basics";
import Swal from "sweetalert2";

export default function GerClient({cliente, onClick}) {

  const [loading, setLoading]     = React.useState(false);

  function ReturnLabelStatus(){
    switch(cliente.status) {
      case "APROVADO": return `Autorizado por ${cliente.aprovadoPor}`;
      
      case "RECUSADO" : return `Recusado por ${cliente.recusadoPor}`;
    
      case "PENDENTE" : return `Solicitado em ${moment(cliente.createdAt).format("DD/MM/YY")}`;

      case "BLOQUEADO" : return `Bloqueado por ${cliente.recusadoPor}`;
    
      case "REVOGADO"  : return `Revogado por ${cliente.recusadoPor}`;
      
      case "SOLICITADO": return `Solicitado ${moment(cliente.createdAt).startOf("minute").fromNow().toLowerCase()}`;
    } 
  }

  function ReturnColorStatus(){
    if(cliente.status === "APROVADO")   return "#23A820";
    else if(cliente.status === "RECUSADO") return "#F05546";
    else if(cliente.status === "PENDENTE") return "#FFC107";
    else if(cliente.status === "BLOQUEADO") return "#F05546";
    else if(cliente.status === "REVOGADO") return "#F05546";
    else if(cliente.status === "SOLICITADO") return "#FFC107";
  }

  //console.log("GerClient.js - cliente: ", cliente)
  return (
    <>
      { 
        loading ?
        <div>
          <Loading mode={false}/>
        </div>
          :
        <div 
          className = "contain-gerenciar-cliente"
          style     = {{borderColor: ReturnColorStatus()}} 
        >
          <h2 
            className = "text-status-cliente"
            style     = {{color: ReturnColorStatus()}}
          >
            {cliente.status === "APROVADO" ? "AUTORIZADO" : cliente.status}
          </h2>
          <h2 className = "text-nome-cliente">
            {cliente.usuario.nome}
          </h2>
          <p className = "text-telefone-cliente">{cliente.usuario.atleta.telefone}</p>
          <div className = "contain-message-cliente">
            <MdEdit
              size  = {20}
              color = "#FF7043"
            />
            <p className = "text-message-cliente">
              {cliente.mensagemCliente !== null && cliente.mensagemCliente !== "" ? cliente.mensagemCliente : "Sem mensagem para o gestor"}
            </p>
          </div>
          <div className = "contain-btts-ger-cliente">
            <button
              className = {ReturnBttGer(false)}
              onClick   = {async () => {
                const validStt = cliente.status === "SOLICITADO" || cliente.status === "APROVADO";
                const text = cliente.status === "SOLICITADO" ? `Deseja recusar o acesso para ${cliente.usuario.nome}?` : `Deseja desautorizar o acesso para ${cliente.usuario.nome}?`;
                if(validStt){ 
                  Swal.fire({
                    icon          : "warning",
                    title         : cliente.status === "SOLICITADO" ? "Recusar cliente" : "Desautorizar cliente",
                    text          : text,
                    showCancelButton  : true,
                    confirmButtonColor: "#bf3324",
                    cancelButtonColor : "#00cda4",
                    confirmButtonText : "Não",
                    cancelButtonText  : "Sim",
                  }).then(async (result) => {
                   //console.log("Result: ", result);
                    if (result.isDismissed && result.dismiss === "cancel"){
                      setLoading(true);
                      if(cliente.status === "APROVADO") await onClick("revogar");
                      else                              await onClick("desautorizar");
                      setLoading(false);
                    }
                  });
                }
              }}
            >
              {cliente.status === "SOLICITADO" ? "Recusar" : "Desautorizar"}
            </button>
            <button
              className = {ReturnBttGer(true)}
              onClick   = {async () => {
                if(cliente.status === "APROVADO") return;
                const text = cliente.status === "REVOGADO" ? `Deseja autorizar novamente o acesso para ${cliente.usuario.nome}?` : `Deseja autorizar o acesso para ${cliente.usuario.nome}?`;
                Swal.fire({
                  icon          : "warning",
                  title         : "Autorizar cliente",
                  text          : text,
                  showCancelButton  : true,
                  confirmButtonColor: "#bf3324",
                  cancelButtonColor : "#00cda4",
                  confirmButtonText : "Não",
                  cancelButtonText  : "Sim",
                }).then(async (result) => {
                 //console.log("Result: ", result);
                  if (result.isDismissed && result.dismiss === "cancel"){
                    setLoading(true);
                    await onClick("autorizar");
                    setLoading(false);
                  }
                });
              }}
            >
              {cliente.status === "REVOGADO" ? "Autorizar novamente" : "Autorizar"}
            </button>
          </div>
          {
            <p className = "text-cliente-status-fez">
              {ReturnLabelStatus()}
            </p>
          }
        </div>
      }
    </>
  );

  function ReturnBttGer(val) { // val = true -> autorizar, val = false -> desautorizar
    switch(cliente.status) {
      case "APROVADO"   : return val ? "btt-gerenciar-cliente"                : "btt-gerenciar-cliente btt-desautorizar";
      
      case "RECUSADO"   : return val ? "btt-gerenciar-cliente btt-autorizar"  : "btt-gerenciar-cliente";
      
      case "PENDENTE"   : return val ? "btt-gerenciar-cliente btt-autorizar"  : "btt-gerenciar-cliente btt-desautorizar";
      
      case "BLOQUEADO"  : return val ? "btt-gerenciar-cliente btt-autorizar"  : "btt-gerenciar-cliente";
      
      case "REVOGADO"   : return val ? "btt-gerenciar-cliente btt-autorizar"  : "btt-gerenciar-cliente";

      case "SOLICITADO" : return val ? "btt-gerenciar-cliente btt-autorizar"  : "btt-gerenciar-cliente btt-desautorizar";
      
      default           : return val ? "btt-gerenciar-cliente"                : "btt-gerenciar-cliente";
    }    
  }

}

