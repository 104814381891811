import api      from './api';
import moment   from 'moment';

const EmpresaAPI = {
  // Buscar Estatisticas
  BuscarEstatisticaEmpresa      : async ( token, idempresa ) => {
    const req = await api.buscar_estatistica_empresa( token, idempresa );
    //console.log("BuscarEstatisticaEmpresa: ", req);
    if(req.status){
      if(req.req.retorno == null){
        return {status: true, req: null};
      }
      // Monsta o bloco de dados para consumo do front-end
      const estatisticas = {
        ...req.req.retorno,
        dia_mais_agendado           : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "dia_mais_agendado" })[0],
        quadra_mais_agendada        : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "quadra_mais_agendada" })[0],
        horario_mais_agendado       : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "horario_mais_agendado" })[0],
        cliente_menos_cancelou      : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "cliente_menos_cancelou" })[0],
        cliente_mais_agendou        : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "cliente_mais_agendou" })[0],
        principal_forma_pagamento   : req.req.retorno.eventos_significativos.filter((item) => { return item.descricao === "principal_forma_pagamento" })[0],
      }
      // Remover os eventos significativos do objeto
      delete estatisticas.eventos_significativos;
      return {status: true, req: estatisticas};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  // Fluxo de caixa 
  FiltrarFluxoDeCaixa           : async ( token, categoria, dataInicial, dataFinal, empresa_idempresa, limit, offset, countAll) => {
    const data = {
      categorias : categoria, dataInicial, dataFinal, empresa_idempresa, limit, offset, countAll
    };
    //console.log("FiltrarFluxoDeCaixa: ", data);
    const req = await api.fluxo_caixa( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Calcular o fluxo de caixa
  CalcularFluxoDeCaixa          : async ( token, categoria, empresa_idempresa, dataInicial, dataFinal ) => {
    const data = {
      categorias : categoria, empresa_idempresa, dataInicial, dataFinal
    };
    const req = await api.calcular_fluxo_caixa( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Adicionar um pagamento ao fluxo de caixa
  AdicionarPagamentoFluxoCaixa  : async ( token, dataRecebivel, observacao, valor, tipo, categoria, empresa_idempresa, criadoPor ) => {
    const data = {
      dataRecebivel, observacao, valor:parseFloat(valor), tipo, categoria, empresa_idempresa, criadoPor
    };
    const req = await api.criar_pagamento_fluxo_caixa( token, data );
    //console.log("AdicionarPagamentoFluxoCaixa: ", req);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Deletar um pagamento do fluxo de caixa
  DeletarPagamentoFluxoCaixa    : async ( token, idpagamento ) => {
    //console.log("DeletarPagamentoFluxoCaixa: ", idpagamento);
    const req = await api.deletar_pagamento_fluxo_caixa( token, idpagamento );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Mensalista
  RealizarPedidoFixo            : async ( token, horaFixa, quadra, usuario, formaPagamento, diaSemana, idregraCancelamento) => {
    const data = {
      cupomdesconto_idcupomDesconto : null,
      regraCancelamentoPedidos      : idregraCancelamento,
      regraAgendamentoPedidos       : quadra.regraAgendamento_idregraAgendamento,
      valorDescontoCupom            : 0,
      usuario_idusuario             : usuario.idusuario,
      empresa_idempresa             : quadra.empresa_idempresa,
      quadra_idquadra               : quadra.idquadra,
      valorPorPedido                : horaFixa.prePedidos[0].valor,
      tipoMensalista                : horaFixa.tipoMensalista,
      formaPagamento                : formaPagamento,
      diaSemana                     : diaSemana,
      pedidos                       : horaFixa.prePedidos.filter((item) => { return item.status === 'LIVRE' }),
    };
    
    console.log("Hora Fixa", horaFixa);
    console.log("Data", data);
    const req = await api.realizar_pedido_fixo( token, data );
    console.log("RealizarPedidoFixo: ", req);
    if(req.status){
      return {status: true, req: req.reqs};
    } else {
      return {status: false, req: req.reqs, message: req.message};
    }

  },
  SimularMensalista : async (token, idquadra, horarios, tipoMensalista, diaSemana) => {
    // Explica atributos: 
    //  - idquadra: id da quadra
    //  - horarios: horários que o usuário deseja simular
    //  - tipoMensalista: tipo de mensalista (Mensal, Trimensal, Semestral, Anual, Indeterminado)
    //  - diaSeman: dia da semana que o usuário deseja simular (0 - 6)
    const buffer = {
      quadra_idquadra     : idquadra,
      primeiroAgendamento : {
        dataInicio : horarios[0].dataInicio,
        dataFinal  : horarios[horarios.length - 1].dataFinal,
      },
      tipoMensalista, 
      diaSemana
    }
    console.log("Buffer Simular Mensalista: ", buffer);
    const req = await api.simular_mensalista(token, buffer);
    console.log("Req Simular Mensalista: ", req);
    if(req.status){
      return {status: true, req: req.req.registro};
    } else {
      return {status: false, req: req};
    }
  }, 
  BuscarMensalistaId            : async ( token, id ) => {
    const req = await api.buscar_mensalista_id( token, id);
    if (req.status) {
      return {status : true, req : req.reqs};
    } else {
      return {status : false, req : req.reqs, message: req.message};
    }
  },
  BuscarMensalistasEmpresaId    : async ( token, idempresa, limit,  countAll, offset ) => {
    const buf = {
      idempresa, limit, countAll, offset
    }
    //console.log("BUFFER Busca Clientes", buf);
    const ress = await api.buscar_mensalista_empresa_id( token, buf );
    if(ress.status) return {status : true,    reqs: ress.reqs};
    else            return {status : false,   reqs: ress.reqs, message: ress.message};
  },
  BuscarMensalistasClienteId    : async ( token, idusuario, idempresa, limit,  countAll, offset ) => {
    const buf = {
      idusuario, idempresa, limit, countAll, offset
    }
   //console.log("BUFFER Busca Clientes", buf);
    const ress = await api.buscar_mensalista_cliente_id( token, buf);
    if(ress.status) return {status : true,    reqs: ress.reqs};
    else            return {status : false,   reqs: ress.reqs, message: ress.message};
  },
  AprovarMensalistaCliente      : async ( token, idmensalista, aprovadoPor ) => {
    const data = { id: idmensalista, aprovadoPor };
   //console.log("data");
    const req = await api.aprovar_pedido_fixo( token, data);
    if(req.status){
      return {status: true, req: req.reqs};
    } else {
      return {status: false, req: req.reqs, message: req.message};
    }
  },
  UpdateGestor                  : async (token, gestor) => {
    const reqs = await api.atualizar_usuario(token, gestor);
    return reqs;
  },
  RecusarMensalistaCliente      : async ( token, idmensalista, canceladaPor ) => {
    const data = { id: idmensalista, canceladaPor };
    const req = await api.recusar_pedido_fixo( token, data);
    if(req.status){
      return {status: true, req: req.reqs};
    } else {
      return {status: false, req: req.reqs, message: req.message};
    }
  },
  CancelarMensalistaCliente     : async ( token, idmensalista, canceladaPor, motivoCancelamento ) => {
    const data = { id: idmensalista, canceladaPor, motivoCancelamento, marketplace: true };
   //console.log("CancelarMensalistaCliente: ", data);
    const req = await api.cancelar_pedido_fixo( token, data );
    if(req.status)  return {status: true, req: req.reqs};
    else            return {status: false, req: req.reqs, message: req.message};
  },
  BuscarMensalistaSolicitados   : async ( token, idempresa ) => {
    const req = await api.buscar_mensalistas_solicitados( token, idempresa);
    if(req.status){
      return { status: true, req: req.req };
    } else {
      return { status: false, req: req.req, message: req.message };
    }
  },
  BuscarHorariosFixos           : async ( token, idempresa, limit, offset, countAll ) => {
    const data = {
      idempresa, limit, offset, countAll
    }
    const req = await api.buscar_horarios_fixos( token, data);
    if(req.status){
      return { status: true, req: req.reqs };
    } else {
      return { status: false, req: req.reqs, message: req.message };
    }
  },

  // Pedidos
  RealizarPedido                : async ( token, gestor, cliente, quadra, pedido, payMents, obs, gmtE ) => {
    // Consumir 2 tipos de pedido! Pagamento total ou sinal!
    function MontaAgendamentos(agenda){
      const array = [];
      for(let hor of agenda){
        array.push({
          /*Valores que precisa!!!!
            id                  : hor.id,
            horario_idhorario   : hor.horario_idhorario,
            value               : hor.value,
            data                : hor.data,
            quadra_idquadra     : hor.quadra_idquadra,
            reservaMinutos      : hor.reservaMinutos,
            valor               : hor.valor,
          */
          ...hor,
          usuario_idusuario   : Number.isInteger(cliente.idusuario) ? cliente.idusuario : null,
          criadoPor           : "APP",
          fixa                : 0,
        });
      }
      return array;
    }
    function retornaData(data, hora, minuto, gmt) {
      let dataFormatada = '';
    
      //if (hora === '00') {
      //    dataFormatada = moment(data).add(1, 'd');
      //} else {
      dataFormatada = moment(data);
    
      //}
      let stringData = dataFormatada.format('YYYY-MM-DD') + 'T' + hora + ':' + minuto + ':00' + defineGMT(gmt);
      return stringData;
    }
    // Retorna o GMT
    function defineGMT(gmt) {
      switch (gmt) {
          case -5:
              return '-05:00';
          case -4:
              return '-04:00';
          case -3:
              return '-03:00';
          case -2:
              return '-02:00';
          default:
              return '-03:00';
      }
    }
   
    const dataTotal = {
      fromSite                : 1,
      duracao                 : pedido.duracaoTotal,
      marketplace             : false,
      valorTotal              : pedido.valorTotal < 1 ? 1 : pedido.valorTotal,
      quadra_idquadra         : pedido.idquadra,
      empresa_idempresa       : quadra.empresa_idempresa,
      dataInicio              : retornaData(moment(pedido.agenda[0].data).utcOffset(gmtE).format('YYYY-MM-DD'), pedido.agenda[0].label.substr(0, 2), pedido.agenda[0].label.substr(3, 2), gmtE),
      dataFinal               : retornaData(moment(pedido.agenda[pedido.agenda.length - 1].data).utcOffset(gmtE).format('YYYY-MM-DD'), pedido.agenda[pedido.agenda.length - 1].label.substr(9, 2), pedido.agenda[pedido.agenda.length - 1].label.substr(12, 2), gmtE),
      jogadores               : "",
      criadoPor               : gestor.nome,
      nomeUsuario             : Number.isInteger(cliente.idusuario) ? "" : cliente.nome,
      telefone                : Number.isInteger(cliente.idusuario) ? "" : cliente.atleta.telefone,
      observacoes             : obs,
      formaPagamento          : payMents,
      agendamentos            : MontaAgendamentos(pedido.agenda), // Array de horarios do pedido
      // se idusuario for inteiro, manda idusuario, se não manda null
      usuario_idusuario       : Number.isInteger(cliente.idusuario) ? cliente.idusuario : null,
      idAdmin                 : gestor.idusuario,
    }
    /* Rectangle 62 */

    //console.log("Pedido Total: ", dataTotal);
    //return {status : false, message: "Teste faliw"};
    const ress = await api.realizar_pedido( token, dataTotal);
    //console.log("Resposta: Total", ress);
    if(ress.status) return {status : true,    req: ress.reqs};
    else            return {status : false,   req: ress.reqs, message: ress.message};
    
  },
  BuscarMyReservas              : async ( token, idempresa, idquadra, dataInicial, dataFinal, status, fixo, limit, countAll, offset ) => {
    /* JSON de entrada!
      "idempresa"     : inteiro,
      "idquadra"      : inteiro,
      "dataInicial"   : formato YYYY-MM-DD,
      "dataFinal"     : formato YYYY-MM-DD,
      "status"        : "SOLICITADO" || "AGENDADO" || "CANCELADO" || "REALIZADO",
      "limit"         : limit,
      "offset"        : offset,
      "fixo"          : "TODOS" || "FIXO" || "AVULSO",
      "countAll"      : countAll
    }*/
    const date = {
      idempresa, idquadra, limit, status, countAll, 
      offset, dataInicial, dataFinal, fixo
    }
    //console.log("DATE", date);
    const ress = await api.buscar_reservas_empresa_id( token, date );
    //console.log("RESS", ress);
    
    if(ress.status) return {status : true,    req: ress.reqs};
    else            return {status : false,   req: ress.reqs, message: ress.message};
  },
  BuscarPedidosSolicitados      : async ( token, idempresa ) => {
    const req = await api.buscar_reservas_solicitadas( token, idempresa );
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  AprovarPedido                 : async ( token, idpedido, aprovadoPor ) => {
    const data = {
      idpedido    : idpedido,
      nomeGestor  : aprovadoPor,
    }
    const req = await api.aprovar_pedido( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  RecusarPedido                 : async ( token, idpedido, recusadoPor, motivo ) => {
    const data = {
      idpedido            : idpedido,
      nomeGestor          : recusadoPor,
      motivoCancelamento  : motivo,
    }
    const req = await api.cancelar_pedido( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  AtualizarValorPedido          : async ( token, idpedido, valorTotal ) => {
    const data = {
      idpedido, 
      valorTotalAtualizado: valorTotal,
    }
    const req = await api.atualizar_valor_pedido( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  AdicionarPagamento            : async ( token, idpagamento, usuario_idusuario, pedido_idpedido, valor, dataRecebivel, criadoPor, observacao, tipo ) => {
    /*
    {
      "idpagamento": 30,
      "valor": 5,
      "tipo": "parcial",
      "formaPagamento": "DINHEIRO",
      "dataRecebivel": "2022-05-30T15:01:00.000Z",
      "criadoPor": "Gestor Fonseca Silva",
      "usuario_idusuario": 4,
      "pedido_idpedido": 91,
      "observacao": "Recebi no balcão da quadra"
    }
    */
   const data = {
    idpagamento, valor, tipo, dataRecebivel,
    formaPagamento: "DINHEIRO",
    criadoPor,
    usuario_idusuario,
    pedido_idpedido,
    observacao,
   };
    const req = await api.adicionar_pagamento_pedido( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  DeletarPagamento              : async ( token, idpagamento ) => {
    const req = await api.deletar_pagamento_pedido( token, idpagamento );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  BuscarReservasCliente         : async ( token, idusuario, idEmpresa, limit, countAll, offset) => {
    const date = {
      idusuario, idEmpresa, limit, countAll, offset
    }
   //console.log("DATE", date);
    const ress = await api.buscar_pedidos_cliente(token, date);
   //console.log("RESS", ress);
    if(ress.status) return {status : true,    req: ress.reqs};
    else            return {status : false,   req: ress.reqs};
  },







  // DayUse
  BuscarDiariasEmpresa          : async ( token, idempresa, dia, date ) => {
    //console.log("DIA: ", dia);
    const data = {
      idempresa : idempresa,
      diaSemana : dia,
      data      : date.toString(),
    }
    const ress = await api.buscar_diarias( token, data);
    if(ress.status)   return {status : true, req : ress.req};
    else              return {status : false, req : ress.req, message: ress.message};
  },
  CancelarCompraDayUse          : async ( token, idcompraD, nome, motivo ) => {
    const data = {
      motivoCancelamento  : motivo,
      idcompraDayuse      : idcompraD,
      canceladoPor        : nome,
    }
    //console.log("CancelarCompraDayUse: ", data);
    const req = await api.cancelar_diaria( token, data);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  SolicitarDiaria               : async ( token, idUser, userName, dataDiaria, formaPay, diaria ) => {
    const data = {
      fromSite              : 1,
      criadoPorGestor       : 1,
      criadoPor             : userName,
      dayuse_iddayuse       : diaria.iddayuse,
      usuario_idusuario     : idUser,
      data                  : dataDiaria,
      formaPagamento        : formaPay.metodPay,
      valorRealizado        : formaPay.metodPay === 'LOCAL' ? 0 : diaria.valor,
      valorTotal            : diaria.valor,
      transacao             : formaPay.transacao,
    }
    //console.log("Solicitar Diaria: ", data);
    const reqs = await api.marcar_diaria( token, data);
    //console.log("Solicitar Diaria: Services API", reqs);
    //const reqs = {status: true, req: {data: {idpedido: 1}}};
    if(reqs.status) return {status : true,    req: reqs.req};
    else            return {status : false,   req: reqs.req, message: reqs.message};
  },
  BuscarDayUseSolicitadas       : async ( token, idempresa ) => {
    const req = await api.buscar_diarias_solicitadas( token, idempresa);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarTodasDayUse             : async ( token, idempresa, limit, offset, countAll ) => {
    const data = {
      idempresa, limit, offset, countAll
    }
    const req = await api.buscar_diarias_da_empresa( token, data );
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarDayUseVendidasPorData   : async ( token, idempresa, data ) => {
    const buffer = {
      idempresa, data
    };
   //console.log("BUFFER: ", buffer);
    const req = await api.buscar_diarias_vendidas( token, buffer );
    if(req.status){
      return { status: true,  req: req.reqs }
    } else {
      return { status: false, req: req.reqs, message: req.message }
    }
  },
  AtivarDesativarDiarias        : async ( token, idlicenca, moduloDiaria ) => {
    const data = {
      idlicenca, moduloDiaria
    }
    const req = await api.ativar_desativar_diarias(token, data);
   //console.log("AtivarDesativarDiarias: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  AtivarDayUse                  : async ( token, iddayuse ) => {
    const req = await api.ativar_dayuse( token, iddayuse);
   //console.log("AtivarDayUse: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  DesativarDayUse               : async ( token, iddayuse, inativadoPor ) => {
    const data = {
      iddayuse, inativadoPor
    }
    const req = await api.desativar_dayuse( token, data);
   //console.log("DesativarDayUse: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarDayUseVendidosCliente   : async ( token, idusuario, idempresa, limit, offset, countAll ) => {
    const data = {
      idusuario, idempresa, data: null, limit, offset, countAll
    }
    const req = await api.buscar_dayuse_usuario( token, data);
   //console.log("BuscarDayUseVendidosCliente: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarCompraDayUsePorID       : async ( token, idcompraDayuse ) => {
    const req = await api.buscar_diaria_id( token, idcompraDayuse );
   //console.log("BuscarCompraDayUsePorID: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  EditarValorDiaria             : async ( token, iddayuse, valor ) => {
    const buff = {
      idcompraDayuse            : iddayuse,
      valorRealizadoAtualizado  : valor,
    };

    const req = await api.editar_valor_diaria( token, buff);
    //console.log("EditarValorDiaria: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },






  // Empresas
  BuscaNovasSolicitacoesEmpresa : async ( token, idempresa ) => {
    const req = await api.buscar_solicitacoes_empresa( token, idempresa );
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarEmpresaPorID            : async ( token, idempresa ) => {
    const req = await api.buscar_empresa_id( token, idempresa );
   //console.log("BuscarEmpresaPorID: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarAgendaQuadra            : async ( token, idquadra, data ) => {
    const req = await api.buscar_agenda_quadra( token, idquadra, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  BuscarClientsEmpresa          : async ( token, idEmpresa, limit, offset, countAll ) => {
    const data = { idEmpresa, limit, offset, countAll, clienteReal: null };
   //console.log("Chamando API: buscar_clients_empresa", data);
    const req = await api.buscar_clients_empresa( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  BuscarSolicitacaoAcess        : async ( token, idempresa, status ) => {
    const data = { idempresa, status };
    const req = await api.buscar_solicitacao_acess( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  AprovarCliente                : async ( token, idcliente, aprovadoPor ) => {
    const data = { idcliente, aprovadoPor };
    const req = await api.aprovar_cliente( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },                
  RecusarCliente                : async ( token, idcliente, recusadoPor ) => {
    const data = { idcliente, recusadoPor };
    const req = await api.recusar_cliente( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },                
  RevogarCliente                : async ( token, idcliente, revogadoPor ) => {
    const data = { idcliente, revogadoPor };
    const req = await api.revogar_cliente( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },                
  BuscarRegraCancelamento       : async ( token, idempresa ) => {
    const req = await api.buscar_regra_cancelamento( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  BuscarClientesPorFiltro       : async ( token, idEmpresa, filtro ) => {
    const data = { idEmpresa, texto: filtro, telefone: "" };
   //console.log("DATA: ", data);
    const req = await api.buscar_clientes_empresa_filtro( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  BuscarBlackListEmpresa        : async ( token, idempresa ) => {
    const req = await api.buscar_blacklist_empresa( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  AdicionarUserInBlackList      : async ( token, idEmpresa, idUsuario, idGestor ) => {
    const data = { idEmpresa, idUsuario, idGestor };
    const req = await api.adicionar_user_blacklst_emp( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  RemoverUserBlackList          : async ( token, idEmpresa, idUsuario, idGestor ) => {
    const data = { idEmpresa, idUsuario, idGestor };
    const req = await api.remover_user_blacklst_emp( token, data );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },

  // Usuarios
  ValidaSenhaAtual              : async ( token, id, senha ) => {
    const data = {idUser: id, senha}
    const reqs = await api.validar_senha_atual( token, data);
    if(reqs.status){ 
      if (reqs.req) return {status : true};
      else          return {status : false, msg : "Senha atual incorreta", req: reqs.req};
    } else          return {status : false, msg : "Peço desculpa, estamos com problemas para se conectar ao nosso servidor. Por favor, tente mais tarde.", req: reqs};
  },
  VerificaEmailCadastro         : async ( token, email ) => {
    const req = await api.verificar_email( token, email);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  AtualizarClienteManual        : async ( token, cliente ) => {
    const req = await api.atualizar_usuario( token, cliente );
   //console.log("AtualizarClienteManual: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },
  BuscarPerfilAcesso            : async ( token, idusuario, idempresa ) => {
    const req = await api.buscar_perfil_acesso( token, idusuario, idempresa );
    //console.log("BuscarPerfilAcesso: ", req);
    if(req.status){
      return { status: true,  req: req.req }
    } else {
      return { status: false, req: req.req, message: req.message }
    }
  },

  // Quadras
  BuscarPrecoQuadraDia          : async ( token, idquadra, dia ) => {
    const ress = await api.precos_avulsos_dia_semana( token, idquadra, dia);
    if(ress.status) return {status : true, req : ress.req};
    else            return {status : false, req : ress.req, message: ress.message};
  },
  BuscaQuadrasEmpresa           : async ( token, idempresa ) => {
    const req = await api.buscar_quadras_empresa( token, idempresa);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },

  // Reservas
  CalcularAgendamento           : async ( token, idquadra, dia, agenda ) => {
    const data = {
      idquadra    : idquadra,
      diaSemana   : dia,
      valorTotal  : 0,
      agenda      : agenda
    };
    const ress = await api.calcular_reserva( token, data); 
    if(ress.status){
      return {status : true, ress};
    } else {
      return {status : false, ress, message: ress.message};
    }
  },
  
  // Auth

  // Avaliacoes
  BuscarAvsDaEmpresa            : async ( token, idempresa, limit, offset, countAll ) => {
    const data = { idempresa, limit, offset, countAll };
    const req = await api.buscar_aval_empresa( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req, message: req.message};
    }
  },
  // Cupons
  // Buscar cupons disponiveis para ingresso da empresa
  BuscarCuponsDisponiveisIngrsso : async ( token, idempresa ) => {
    const req = await api.buscar_cupons_disponiveis_ingresso( token, idempresa);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Buscar cuponsvinculados a empresa
  BuscarCuponsVinculadosEmpresa : async ( token, idempresa ) => {
    const req = await api.buscar_cupons_vinculados_empresa( token, idempresa);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Aderir cupom a empresa
  AderirCupomEmpresa : async ( token, idempresa, idcupomDesconto, ativadoPor ) => {
    const data = { idempresa, idcupomDesconto, ativadoPor };
    const req = await api.aderir_cupom( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Desativar cupom da empresa
  DesativarCupomEmpresa : async ( token, idempresa, idcupomDesconto ) => {
    const data = { idempresa, idcupomDesconto };
    //console.log("DATA: ", data);
    const req = await api.desativar_cupom( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Reativar cupom da empresa
  ReativarCupomEmpresa : async ( token, idempresa, idcupomDesconto ) => {
    const data = { idempresa, idcupomDesconto };
    const req = await api.reativar_cupom( token, data);
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Ciclo Faturamento
  BuscarCicloFaturas : async ( token, idempresa ) => {
    const req = await api.buscar_faturas( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Buscar Fatura aberta
  BuscarFaturaAberta : async ( token, idempresa ) => {
    const req = await api.buscar_ciclo_faturamento_aberto( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  // Buscar Fatura fechada
  BuscarFaturaFechada : async ( token, idempresa ) => {
    const req = await api.buscar_ciclo_faturamento_fechado( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  BuscarFaturaId : async ( token, idfatura ) => {
    const req = await api.buscar_fatura_id( token, idfatura );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  EnviarFaturaPorEmail : async ( token, idfatura ) => {
    const req = await api.enviar_fatura_email( token, idfatura );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  BuscarFaturaFechadaEmpresaID : async ( token, idempresa ) => {
    const req = await api.buscar_ciclo_faturamento_fechado( token, idempresa );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  CriarPedidoClienteReal : async ( token, idusuario, idquadra, nomeGestor, dataInit, dataFim, valorTotal, formaPagamento, obs, idregraCancelamento) => {
    const buffer = {
      regraCancelamento_idregraCancelamento : idregraCancelamento,
      usuario_idusuario     :   idusuario,  
      quadra_idquadra       :   idquadra,
      nomeGestor            :   nomeGestor,
      dataInicio            :   dataInit,
      dataFinal             :   dataFim,
      valorTotal            :   valorTotal,
      formaPagamento        :   formaPagamento,
      observacoes           :   obs,
      fromSite              :   true,
    } 
    const req = await api.criar_pedido_client_real( token, buffer );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
  CriarPedidoClienteFicticio : async ( token, nomeCliente, idquadra, nomeGestor, dataInit, dataFim, valorTotal, formaPagamento, obs, idregraCancelamento) => {
    const buffer = {
      regraCancelamento_idregraCancelamento : idregraCancelamento,
      nomeCliente           :   nomeCliente,
      quadra_idquadra       :   idquadra,
      nomeGestor            :   nomeGestor,
      dataInicio            :   dataInit,
      dataFinal             :   dataFim,
      valorTotal            :   valorTotal,
      formaPagamento        :   formaPagamento,
      observacoes           :   obs,
      fromSite              :   true,
    } 
    const req = await api.criar_pedido_client_fict( token, buffer );
    if(req.status){
      return {status: true, req: req.req};
    } else {
      return {status: false, req: req.req};
    }
  },
}

export default EmpresaAPI ;

